export const tabOptions = {
  overview: 'overview',
  customerLoyaltyEvents: 'customerLoyaltyEvents',
  learningContent: 'learningContent',
  achievementsAndCertificates: 'achievementsAndCertificates',
  iltSessions: 'iltSessions'
};

export const rangeMarks = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 50,
    label: '50%'
  },
  {
    value: 100,
    label: '100%'
  }
];
