import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import { StyledDropdown } from '@components/StyledComponents/Filters.styled';

const DropdownFilter = (props) => {
  const { config, dataSource, onFilterChange } = props;
  const device = config.device.join(' ');

  const onChange = (e, data) => {
    const current = [...dataSource];
    current.forEach((el) => (el.checked = data.value.includes(el.value)));

    onFilterChange(config, data);
  };

  return (
    <Grid.Row only={`${device}`} data-test-id={'dropdownFilterContainer'}>
      <Grid.Column computer={12} largeScreen={10} widescreen={10} tablet={14} mobile={16}>
        <Form>
          <Form.Field>
            <label>{Localize.get(`App.${config.name}`)}</label>
          </Form.Field>
        </Form>
        <StyledDropdown
          styledicon={config.styledIcon ? 'true' : null}
          icon={config?.icon ? config.icon : 'dropdown'}
          fluid
          multiple={config.multiple}
          selection
          placeholder={Localize.get(`Catalog.${config.placeholder}`)}
          options={dataSource}
          value={dataSource.filter((el) => el.checked).map((el) => el.value)}
          onChange={onChange}
        />
      </Grid.Column>
    </Grid.Row>
  );
};
export default React.memo(DropdownFilter);
