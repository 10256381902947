import React, { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';

import style from '@components/Filters/style/Filters.module.scss';
import { AccordionTitle } from '@components/StyledComponents/Filters.styled';

import CheckboxFilter from './CheckboxFilter.jsx';
import RadioButtonFilter from './RadioButtonFilter.jsx';
import RatingFilter from './RatingFilter.jsx';

const AccordionFilter = (props) => {
  const { config, dataSource, onFilterChange, shouldResetFilters, active } = props;
  const device = config.device.join(' ');

  const [dataSourceLocal, setDataSourceLocal] = useState(dataSource);
  const [openAccordion, setOpenAccordion] = useState(active);

  useEffect(() => {
    if (dataSource) {
      setDataSourceLocal(dataSource);
    }
  }, [dataSource]);

  const onAccordionClick = () => {
    setOpenAccordion(!openAccordion);
  };

  const getFilterRow = () => {
    switch (config.subtype) {
      case 'checkBox':
        return (
          <CheckboxFilter
            config={config}
            dataSource={dataSourceLocal}
            onFilterChange={onFilterChange}
          />
        );
      case 'rating':
        return <RatingFilter config={config} onFilterChange={onFilterChange} />;
      case 'radio':
        return (
          <RadioButtonFilter
            config={config}
            onFilterChange={onFilterChange}
            shouldResetFilters={shouldResetFilters}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Grid.Row only={`${device}`} data-test-id={'accordionContainer'}>
      <Grid.Column computer={16} largeScreen={16} widescreen={16} tablet={16} mobile={16}>
        <Accordion styled fluid className={style.accordion}>
          <AccordionTitle active={openAccordion} onClick={onAccordionClick}>
            <Icon name='dropdown' />
            {Localize.get(`App.${config.name}`)}
          </AccordionTitle>
          <Accordion.Content active={openAccordion}>{getFilterRow(config)}</Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
};
export default React.memo(AccordionFilter);
