const messages = {
  accountCreatedMailMsg: 'accountCreatedContentMsg',
  userAuthenticated: 'accountActivatedContentMsg',
  activateAccount: 'activateAccountContentMsg',
  alreadyExistingAccountMailMsg: 'alreadyExistingAccountMailMsgContentMsg',
  mailResend: 'mailResendContentMsg',
  userAlreadyAuthenticated: 'userAlreadyAuthenticatedContentMsg',
  badCredentials: 'badCredentialsContentMsg',
  accountLoginNotActive: 'accountLoginNotActiveContentMsg',
  disabledAccount: 'disabledAccountContentMsg',
  confirmationTokenExpired: 'confirmationTokenExpiredContentMsg',
  sessionExpired: 'sessionExpiredContentMessage',
  sessionTokenMissing: 'sessionTokenMissingContentMsg',
  sessionCorrupted: 'sessionCorruptedContentMsg',
  forgottenPassword: 'forgottenPasswordContentMsg',
  resetPassword: 'resetPasswordContentMsg',
  somethingWentWrongMsg: 'somethingWentWrongMsgContent'
};

export default messages;
