import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createEventService = async (itemID, itemType, callBackError = null) => {
  let route = '';
  switch (itemType) {
    case 'event':
      route = getService(
        `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventDetails}${itemID}`,
        callBackError
      );
      break;
    case 'course':
      route = getService(
        `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.courses}${itemID}`,
        callBackError
      );
      break;
    case 'iltSession':
      route = getService(
        `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaSessions}${itemID}`,
        callBackError
      );
      break;
    default:
      break;
  }
  return route;
};
