import React, { useState, useEffect } from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

import Logger from '@common/Logger.js';
import LoadingPlaceholder from '@components/LoadingPlaceholder/LoadingPlaceholder.jsx';
import useFetching from '@hooks/Fetching/Fetching.js';

import SessionDetails from './SessionDetails.jsx';
import { createSessionDetailsService } from './SessionsHelper.js';
import SessionSpeakers from './SessionSpeakers.jsx';
import style from './style/SessionCard.module.scss';

const SessionCard = (props) => {
  const { session, sessionId } = props;

  const [sessionSpeakers, setSessionSpeakers] = useState(null);
  const [sessionAndRoomData, setSessionAndRoomData] = useState({
    sessionData: null,
    roomsData: null
  });

  const [sessionData, isLoading, , fetchSessionDetails] = useFetching(
    createSessionDetailsService.bind(null, session)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sessionData?.data) {
          // fetchRoomsData();
          setSessionAndRoomData(sessionData.data);
          setSessionSpeakers(sessionData.data.speakers);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sessionId) {
          fetchSessionDetails();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  return (
    <>
      <Grid.Column stretched data-test-id={'sessionCardContainer'}>
        {isLoading || isLoading ? (
          <LoadingPlaceholder needImagePlaceHolder={false} />
        ) : (
          <Segment>
            <Grid>
              <SessionDetails
                session={session}
                sessionData={sessionAndRoomData.sessionData}
                roomsData={sessionAndRoomData.roomsData}
              />

              {sessionSpeakers && sessionSpeakers.length ? (
                <Grid.Row className={style.dividerHolder}>
                  <Grid.Column width={16} className={style.dividerContainer}>
                    <Divider />
                  </Grid.Column>
                </Grid.Row>
              ) : null}
              <Grid.Row className={style.speakersContainer}>
                <Grid columns={3} container stackable className={style.speakersHolder}>
                  {isLoading ? (
                    <LoadingPlaceholder
                      needImagePlaceHolder={true}
                      styleName={style.speakersPlaceholderLoader}
                    />
                  ) : sessionSpeakers && sessionSpeakers.length ? (
                    sessionSpeakers.map((speakers, index) => (
                      <Grid.Column key={index} verticalAlign='middle'>
                        <SessionSpeakers speakers={speakers} />
                      </Grid.Column>
                    ))
                  ) : null}
                </Grid>
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </Grid.Column>
    </>
  );
};

export default SessionCard;
