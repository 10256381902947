import React from 'react';

import { MainCatalogGrid } from '@components/StyledComponents/Catalog.styled.js';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import CoursesTab from '@pages/Catalog/Tabs/CoursesTab';

const CatalogMain = () => {
  const [contextData] = useGlobalContext();

  return (
    <MainCatalogGrid data-test-id={'catalogMainGridContainer'}>
      <CoursesTab contextData={contextData} />
    </MainCatalogGrid>
  );
};
export default CatalogMain;
