import React from 'react';
import Localize from 'react-intl-universal';

import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import { CardFooter, FooterCoursePrice } from '@components/StyledComponents/EventItem.styled';

const EmployeeItemFooter = (props) => {
  const { config, coursesCount, certificatesCount } = props;

  return (
    <>
      <CardFooter extra config={config} data-test-id={'employeeItemFooter'}>
        <div>
          <FooterCoursePrice visible={true}>
            <Icon disabled name='user' size='small' />
            {coursesCount && <>{coursesCount}</>} {Localize.get('TeamArea.courses')}
          </FooterCoursePrice>
          <FooterCoursePrice visible={true}>
            <Icon disabled name='trophy' size='small' />
            {certificatesCount && <>{certificatesCount}</>} {Localize.get('TeamArea.certificates')}
          </FooterCoursePrice>
        </div>
      </CardFooter>
    </>
  );
};

export default EmployeeItemFooter;
