import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Logger from '@common/Logger.js';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useFetching from '@hooks/Fetching/Fetching.js';

import { createSessionService, calculateMenuItemsCount } from './AgendaHelper.js';
import AgendaTimelineMenu from './AgendaTimelineMenu.jsx';
import SessionCard from './SessionCard.jsx';
import style from './style/Agenda.module.scss';

const Agenda = (props) => {
  const { id } = props;
  const [contextData] = useGlobalContext();
  const [itemsPerScreen, setItemsPerScreen] = React.useState(0);
  const [sessions, setSessions] = React.useState([]);
  const [menuItems, setMenuItems] = React.useState([]);
  const [activeMenuItem, setActiveMenuItem] = React.useState(null);
  const [firstMenuItem, setFirstMenuItem] = React.useState(0);
  const [lastMenuItem, setLastMenuItem] = React.useState(itemsPerScreen);

  const [sessionsData, isLoading] = useFetching(createSessionService.bind(null, id));

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (sessionsData?.data?.sessions && sessionsData?.data?.menuDateItems) {
          setMenuItems(sessionsData.data.menuDateItems);
          setActiveMenuItem(sessionsData.data.menuDateItems[0]);
          setSessions(sessionsData.data.sessions);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchEvents();
  }, [sessionsData]);

  useEffect(() => {
    if (contextData.size && menuItems && menuItems.length) {
      const maxItems = calculateMenuItemsCount(contextData.size, menuItems);
      if (maxItems) {
        setItemsPerScreen(maxItems);
      }
    }
  }, [menuItems, contextData.size]);

  useEffect(() => {
    if (itemsPerScreen) {
      setLastMenuItem(itemsPerScreen);
    }
  }, [itemsPerScreen]);

  const updateMenuIndexes = (direction) => {
    switch (direction) {
      case 'forward':
        if (menuItems.length !== lastMenuItem) {
          setFirstMenuItem(firstMenuItem + 1);
          setLastMenuItem(lastMenuItem + 1);
        }
        break;
      case 'back':
        if (firstMenuItem !== 0) {
          setFirstMenuItem(firstMenuItem - 1);
          setLastMenuItem(lastMenuItem - 1);
        }
        break;
      case 'first':
        if (firstMenuItem !== 0) {
          setFirstMenuItem(0);
          setLastMenuItem(itemsPerScreen);
        }
        break;
      case 'last':
        if (menuItems.length !== lastMenuItem) {
          setFirstMenuItem(menuItems.length - itemsPerScreen);
          setLastMenuItem(menuItems.length);
        }
        break;
      default:
        setFirstMenuItem(0);
        setLastMenuItem(itemsPerScreen);
        break;
    }
  };

  const selectedDateMenuIndex =
    menuItems && menuItems.length && activeMenuItem
      ? menuItems.findIndex((element) => element === activeMenuItem)
      : null;

  const getSessions = () => {
    if ((selectedDateMenuIndex || selectedDateMenuIndex === 0) && sessions && sessions.length) {
      return sessions[selectedDateMenuIndex].map((sess, index) => (
        <SessionCard key={index} session={sess} sessionId={sess.temId} />
      ));
    }

    return null;
  };

  return (
    <>
      <Grid data-test-id={'agendaContainer'} columns={1} stackable>
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        {menuItems && menuItems.length !== 0 && (
          <AgendaTimelineMenu
            menuItems={menuItems.slice(firstMenuItem, lastMenuItem)}
            activeItem={activeMenuItem}
            setActiveItem={setActiveMenuItem}
            updateMenuIndexes={updateMenuIndexes}
          />
        )}

        {sessions && sessions.length ? (
          getSessions()
        ) : (
          <div>
            <Grid columns={3} stackable className='tablet computer only'>
              <Grid.Row />
              <Grid.Row>
                <Grid.Column computer={1} tablet={1} />
                <Grid.Column computer={14} tablet={14}>
                  <NoContentComponent infoType={Localize.get('EventDetails.noSess')} />
                </Grid.Column>
                <Grid.Column computer={1} tablet={1} />
              </Grid.Row>
            </Grid>
            <Grid columns={1} className='mobile only'>
              <Grid.Column mobile={16} className={style.noSessionsMobileResolution}>
                <NoContentComponent infoType={Localize.get('EventDetails.noSess')} />
              </Grid.Column>
            </Grid>
          </div>
        )}
      </Grid>
    </>
  );
};

export default Agenda;
