import React from 'react';

import { get } from 'lodash';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
const FormSelect = ({
  field = {},
  form: { touched, errors } = {},
  fieldWidth = 16,
  label = '',
  required = false,
  setFieldValue,
  setFieldTouched,
  ...rest
}) => {
  return (
    <>
      <Form.Field width={fieldWidth} required={required}>
        <label>{label}</label>
        <Dropdown
          {...field}
          {...rest}
          fluid
          selection
          onBlur={(e, { name, value }) => setFieldTouched(name, value)}
          onChange={(e, { name, value }) => setFieldValue(name, value)}
          error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
        />
      </Form.Field>
    </>
  );
};

export default FormSelect;
