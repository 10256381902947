import { getService, getAxiosConfiguration, getServiceByConfig } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createAttachmentService = async (guid) => {
  return getService(`${serviceConstants.MEA_SERVER_CONFIGURATION.resource.attachments}${guid}`);
};

export const createAttachmentContentService = (attachmentId) => {
  let configuration = getAxiosConfiguration(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.sessionAttachment}${attachmentId}`
  );
  configuration.responseType = 'blob';
  return getServiceByConfig(configuration);
};
