import React from 'react';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import * as countriesCodes from '@common/Countries.js';
import { formatDateStringToDateObject, formatDateDMY } from '@common/Util';
import {
  StyledCardHeader,
  CardHeaderTypeColumn,
  TextShrink,
  CardHeaderPopup,
  CardHeaderFlagColumn,
  CardHeaderDateColumn,
  CardHeaderCalendarColumn
} from '@components/StyledComponents/EventItem.styled';

const EventItemHeader = (props) => {
  const { eventTypeName, startDate, langId, config } = props;

  const customConfig = config;

  return (
    <>
      <StyledCardHeader config={customConfig} extra data-test-id={'eventItemHeader'}>
        <Grid columns={4}>
          <CardHeaderTypeColumn computer={4} tablet={6} mobile={4}>
            <CardHeaderPopup
              config={customConfig}
              trigger={<TextShrink>{eventTypeName}</TextShrink>}
              content={eventTypeName}
              position='top center'
              basic
            />
          </CardHeaderTypeColumn>
          <CardHeaderFlagColumn computer={4} tablet={3} mobile={2}>
            <Flag
              name={
                langId
                  ? countriesCodes.countryCodeByCountry[langId]
                    ? countriesCodes.countryCodeByCountry[langId]
                    : ''
                  : ''
              }
            />
          </CardHeaderFlagColumn>

          <CardHeaderDateColumn
            computer={6}
            tablet={5}
            mobile={4}
            floated='right'
            textAlign='right'
          >
            <TextShrink>{formatDateDMY(formatDateStringToDateObject(startDate))}</TextShrink>
          </CardHeaderDateColumn>
          <CardHeaderCalendarColumn computer={2} tablet={2} mobile={1} floated='right'>
            <Icon name='calendar alternate outline' />
          </CardHeaderCalendarColumn>
        </Grid>
      </StyledCardHeader>
    </>
  );
};

export default EventItemHeader;
