import React from 'react';

import { Grid, Select } from 'semantic-ui-react';

const bookingOptions = [
  {
    key: '1',
    value: 'employee',
    text: 'Employee'
  },
  {
    key: '2',
    value: 'manager',
    text: 'Manager'
    // disabled: true
  }
];

const BookingType = ({ participantDetails, setParticipantDetails }) => {
  const handleChange = ({ value }) => {
    setParticipantDetails({ ...participantDetails, bookingType: value });
  };

  return (
    <Grid.Row>
      <Grid.Column
        width={6}
        textAlign='center'
        verticalAlign='bottom'
        // style={{ minHeight: '250px', paddingTop: '100px' }}
      >
        <Select
          placeholder='Chose usertype'
          options={bookingOptions}
          onChange={(e, value) => handleChange(value)}
          value={participantDetails.bookingType}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

export default BookingType;
