// https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/internals/constants/localeTextConstants.ts

function getLocalesText(Localize) {
  return {
    // Root
    noRowsLabel: Localize.get('Tables.NoRows'),
    noResultsOverlayLabel: Localize.get('Tables.NoRows'),
    errorOverlayDefaultLabel: Localize.get('Tables.Error'),

    // Columns selector toolbar button text
    toolbarColumns: Localize.get('Tables.Columns'),

    // Filters toolbar button text
    toolbarFilters: Localize.get('Tables.Filters'),
    toolbarFiltersTooltipShow: Localize.get('Tables.ShowFilters'),
    toolbarFiltersTooltipHide: Localize.get('Tables.HideFilters'),
    filterPanelDeleteIconLabel: Localize.get('Tables.DeleteFilters'),

    // Density selector toolbar button text
    toolbarDensity: Localize.get('Tables.Density'),
    toolbarDensityCompact: Localize.get('Tables.Compact'),
    toolbarDensityStandard: Localize.get('Tables.Standard'),
    toolbarDensityComfortable: Localize.get('Tables.Comfortable'),

    // Checkbox selection text
    checkboxSelectionHeaderName: Localize.get('Tables.CheckboxSelection'),
    checkboxSelectionSelectAllRows: Localize.get('Tables.SelectAllRows'),
    checkboxSelectionUnselectAllRows: Localize.get('Tables.UnselectAllRows'),
    checkboxSelectionSelectRow: Localize.get('Tables.SelectRow'),
    checkboxSelectionUnselectRow: Localize.get('Tables.UnselectRow'),

    // Rows selected footer text
    footerRowSelected: (count) => `${Localize.get('Tables.RowSelected')}: ${count}`,

    // Filter operators text
    filterOperatorContains: Localize.get('Tables.FilterValues.Contains'),
    filterOperatorEndsWith: Localize.get('Tables.FilterValues.EndsWith'),
    filterOperatorStartsWith: Localize.get('Tables.FilterValues.StartsWith'),
    filterOperatorEquals: Localize.get('Tables.FilterValues.Equals'),

    // Filter panel text
    filterPanelColumns: Localize.get('Tables.Columns'),
    filterPanelOperators: Localize.get('Tables.Operators'),
    filterPanelInputPlaceholder: Localize.get('Tables.InputPlaceholder'),
    filterPanelInputLabel: Localize.get('Tables.Value'),
    filterOperatorIs: Localize.get('Tables.FilterValues.Is'),
    filterOperatorNot: Localize.get('Tables.FilterValues.IsNot'),
    filterOperatorAfter: Localize.get('Tables.FilterValues.IsAfter'),
    filterOperatorOnOrAfter: Localize.get('Tables.FilterValues.IsOnOrAfter'),
    filterOperatorBefore: Localize.get('Tables.FilterValues.IsBefore'),
    filterOperatorOnOrBefore: Localize.get('Tables.FilterValues.IsOnOrBefore'),
    filterOperatorIsEmpty: Localize.get('Tables.FilterValues.IsEmpty'),
    filterOperatorIsNotEmpty: Localize.get('Tables.FilterValues.IsNotEmpty'),

    // Columns panel text
    columnsPanelTextFieldLabel: Localize.get('Tables.FindColumn'),
    columnsPanelTextFieldPlaceholder: Localize.get('Tables.ColumnTitle'),
    columnsPanelShowAllButton: Localize.get('Tables.ShowAll'),
    columnsPanelHideAllButton: Localize.get('Tables.HideAll'),
    columnHeaderSortIconLabel: Localize.get('Tables.Sort'),

    MuiTablePagination: { labelRowsPerPage: Localize.get('Tables.RowPerPage') }
  };
}

export default getLocalesText;
