import React, { useState, useEffect } from 'react';

import { Grid, Step, Button, Loader, Dimmer, Label } from 'semantic-ui-react';

import { formatDateStringToDateTimeObject, formatDateDMYHM } from '@common/Util.js';

import BookingType from './BookingType';
import ParticipantInformation from './ParticipantInformation';
import ParticipantInformationManager from './ParticipantInformationManager';
import style from './style/RegistrationEvent.module.scss';
import useRegistrationEventLogic from './useRegistrationEventLogic';

const testParticipantDetails = {
  bookingType: '',
  customerNo: '1000258',
  managerName: 'Peter Mayer',
  contact: 'Alex Baiser',
  hotelRequired: false,
  arrivalDate: '',
  departureDate: '',
  turnsAndConditions: false,
  personalTurnsAndConditions: false,
  participants: []
};

const RegistrationEvent = ({ event, setRegistrationActive }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    stepperState,
    setStepperState,
    page,
    participantDetails,
    setParticipantDetails,
    nextButtonEnabled,
    setNextButtonEnabled,
    handlePageChange
  } = useRegistrationEventLogic(testParticipantDetails, setRegistrationActive, setIsLoading);

  // check for completed step
  useEffect(() => {
    let stepperStateNew = { ...stepperState };

    if (participantDetails.bookingType) stepperStateNew[0].completed = true;
    else stepperStateNew[0].completed = false;

    // stepper: 1. step
    if (participantDetails.bookingType === 'employee' && !participantDetails.hotelRequired)
      stepperStateNew[1].completed = true;
    else if (
      participantDetails.bookingType === 'employee' &&
      participantDetails.hotelRequired &&
      participantDetails.arrivalDate &&
      participantDetails.departureDate
    )
      stepperStateNew[1].completed = true;
    else if (
      participantDetails.bookingType === 'manager' &&
      participantDetails?.participants.length > 0
    )
      stepperStateNew[1].completed = true;
    else stepperStateNew[1].completed = false;

    // stepper: 2. step
    if (participantDetails.bookingType === 'employee' && participantDetails.turnsAndConditions)
      stepperStateNew[2].completed = true;
    else if (
      participantDetails.bookingType === 'manager' &&
      participantDetails.turnsAndConditions &&
      participantDetails.personalTurnsAndConditions
    )
      stepperStateNew[2].completed = true;
    else stepperStateNew[2].completed = false;

    let nextButtonEnabled;
    if (page === 0 && stepperStateNew[0].completed) nextButtonEnabled = true;
    if (page === 0 && !stepperStateNew[0].completed) nextButtonEnabled = false;
    if (page === 1 && stepperStateNew[1].completed) nextButtonEnabled = true;
    if (page === 1 && !stepperStateNew[1].completed) nextButtonEnabled = false;
    if (page === 2 && stepperStateNew[2].completed) nextButtonEnabled = true;
    if (page === 2 && !stepperStateNew[2].completed) nextButtonEnabled = false;
    if (page === 3 && stepperStateNew[3].completed) nextButtonEnabled = true;
    if (page === 3 && !stepperStateNew[3].completed) nextButtonEnabled = false;

    setStepperState(stepperStateNew);
    setNextButtonEnabled(nextButtonEnabled);
  }, [participantDetails, page]);

  return (
    <>
      <Grid.Row>
        <Grid.Column computer={3} tablet={4} textAlign='left'>
          <b>Date</b> {formatDateDMYHM(formatDateStringToDateTimeObject(event.EVENT_START))} -{' '}
          {formatDateDMYHM(formatDateStringToDateTimeObject(event.EVENT_END))}
        </Grid.Column>
        <Grid.Column computer={4} tablet={6} textAlign='center' className={style.venueColumn}>
          <b>Venue</b>{' '}
          {event.CITY && event.COUNTRY_NAME ? `${event.CITY}, ${event.COUNTRY_NAME}` : ''} |
          {event.BUILDING_NAME} | {event.STREET} {event.NUMBER} |{event.POSTAL_CODE} {event.CITY}
        </Grid.Column>
        <Grid.Column computer={2} tablet={4} textAlign='right'>
          <b>Price</b>{' '}
          {event.EVENT_PRICE_LATEST && (
            <Label as='a' basic color='yellow' className={style.priceLabelStyle}>
              {event.EVENT_PRICE_LATEST +
                ' ' +
                Number()
                  .toLocaleString(undefined, {
                    style: 'currency',
                    currency: event.EVENT_PRICE_CURRENCY ? event.EVENT_PRICE_CURRENCY : 'EUR'
                  })
                  .slice(0, 1)}
            </Label>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className={style.stepperRow}>
        <Grid.Column width={12} textAlign='center'>
          <Step.Group ordered stackable='tablet' size='tiny'>
            <Step
              completed={stepperState[0].completed}
              active={stepperState[0].active}
              disabled={stepperState[0].disabled}
            >
              <Step.Content>
                <Step.Title>Booking Type</Step.Title>
                <Step.Description>Choose your booking type</Step.Description>
              </Step.Content>
            </Step>

            <Step
              completed={stepperState[1].completed}
              active={stepperState[1].active}
              disabled={stepperState[1].disabled}
            >
              <Step.Content>
                <Step.Title>Participant</Step.Title>
                <Step.Description>Enter participant information</Step.Description>
              </Step.Content>
            </Step>

            <Step
              completed={stepperState[2].completed}
              active={stepperState[2].active}
              disabled={stepperState[2].disabled}
            >
              <Step.Content>
                <Step.Title>Summary</Step.Title>
                <Step.Description>Check your informations</Step.Description>
              </Step.Content>
            </Step>

            <Step
              completed={stepperState[3].completed}
              active={stepperState[3].active}
              disabled={stepperState[3].disabled}
            >
              <Step.Content>
                <Step.Title>Confirmation</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>
        </Grid.Column>
      </Grid.Row>
      {page === 0 && (
        <BookingType
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
        />
      )}
      {page === 1 && participantDetails?.bookingType === 'employee' && (
        <ParticipantInformation
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          nonEditMode={false}
        />
      )}
      {page === 2 && participantDetails?.bookingType === 'employee' && (
        <ParticipantInformation
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          nonEditMode={true}
        />
      )}
      {page === 1 && participantDetails?.bookingType === 'manager' && (
        <ParticipantInformationManager
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          nonEditMode={false}
        />
      )}
      {page === 2 && participantDetails?.bookingType === 'manager' && (
        <ParticipantInformationManager
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          nonEditMode={true}
        />
      )}
      {page === 3 && (
        <div>
          {isLoading && (
            <Dimmer active inverted>
              <Loader size='large'>Thank you for booking the training!</Loader>
            </Dimmer>
          )}
          {!isLoading && <div> Thank you for booking the training! </div>}
        </div>
      )}

      <Grid.Row className={style.buttonRow}>
        <Grid.Column computer={4} textAlign='left'>
          {(page === 1 || page === 2) && (
            <Button
              color='blue'
              content='BACK'
              floated='left'
              // className={styles.registerButton}
              onClick={() => handlePageChange('back')}
              // disabled={getIsEventPassed(event)}
            />
          )}
        </Grid.Column>
        <Grid.Column computer={4} textAlign='right'>
          <Button
            color='blue'
            content={page === 0 || page === 1 ? 'NEXT' : page === 2 ? 'CONFIRM' : 'CLOSE'}
            floated='right'
            // className={styles.registerButton}
            onClick={() => handlePageChange('next')}
            disabled={!nextButtonEnabled}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default RegistrationEvent;
