import React from 'react';
import Localize from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// import axios from 'axios';
import { intersection } from 'lodash';
// semantic
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';

import { StyledNavLink, MenuItemStyled } from '@components/StyledComponents/Header.styled';
import * as routerConstants from '@navigation/Constants.js';
import tokenUtil from '@services/tokenUtil';

import { getSamlLogoutLink } from './AppNavBarHelper.js';

const AppNavBarMenuItems = (props) => {
  const appUser = tokenUtil.getUser();
  const { setMenuVisible, configuration, dropDownMenu, user } = props;
  let history = useHistory();

  const location = useLocation();

  const handleMenuClick = (routePath) => {
    if (setMenuVisible) {
      props.setMenuVisible(false);

      history.push({
        pathname: routePath,
        state: {
          from: history?.location?.state?.from ? history.location.state.from : '',
          path: history?.location?.state?.path ? history.location.state.path : ''
        }
      });
    }
  };

  // const logout = () => {
  //   // "{"token":"eyJhbGciOiJSUzI1NiJ9.eyJqdGkiOiI2MGY2NjdmMC00N2MwLTQ2ODQtYjg1Yy02MTY0OTU0YzAzMmEiLCJzdWIiOiJQMDAwMDExIiwiRW1haWwiOlsibGVhcm5lcjFAY29udHJpbW8uY29tIl0sIlVzZXJJZCI6WyJQMDAwMDExIl0sIkZpcnN0TmFtZSI6WyJMZWFybmVyIl0sIlJvbGVzIjpbIkxlYXJuZXIiXSwiTGFzdE5hbWUiOlsibGVhcm5lcjFAY29udHJpbW8uY29tIiwiQWNhZGVteSAxIl0sIlVzZXJVdWlkIjpbImQxZDlkNDUyLWZkMjctNDQ2OS05YmU3LTMzZGFkNzc0MWFkMCJdLCJleHAiOjE2OTY4MjU0ODh9.N7fUOYsvuZpnOiJ_9L1RYbOPfWY7bWnm1dPaGkd4QPRS_gtfEPTqYQ5pwjJ0_TTNIaKyOc3fZj1ZL6gOti7sTk0Moz4VyMCPpr6DvMlBWwrg_Qj4nY3knGZEWktRuHvhGWaYgb9doI7yA96KvD2uk15BnSC1nboXjXLfdgU2IA4f92C8ewq6969EhCAwMzetouR6yEy-oDEPNJrk9Xs_6SD9PgbZm6WBTKgCzZS7zdX2WZcb0CNAZfBtyVh_FoL755LSLdhx3_r3C0uptve62K4vIFc1HyVZ-xEBqYAk5qXIAXcn4snRtRfO8MppNkQLDZrgrwpLYDdMZQYXixTK4g"}"
  //   axios
  //     .get(
  //       // 'http://localhost:8080/api/v1/user/check-temp-token',
  //       // 'https://api.auth.tem.testcontrimo.com/api/v1/user/check-temp-token',
  //       // `https://api.auth.tem.testcontrimo.com/api/v1/litmos-users/${appUser.email}`,
  //       `${process.env.REACT_APP_TEM_LINK}/api/v1/saml-logout`,
  //       // { headers: { Authorization: `Bearer ${data}` } }
  //       { headers: { Authorization: `Bearer ${appUser.accessToken}` } }
  //     )
  //     .then(({ data }) => {
  //       localStorage.clear();
  //       window.open(data, '_self');
  //     });
  // };

  const logout = async () => {
    const userRoles = appUser?.role ? appUser.role.split(',') : [];

    if (userRoles.includes('Admin') || userRoles.includes('Team Admin')) {
      localStorage.clear();
      window.open(`${process.env.REACT_APP_TEM_LINK}`, '_self');
      return;
    }

    try {
      const logoutLink = await getSamlLogoutLink();
      console.log('logoutLink', logoutLink);

      if (logoutLink.data) {
        localStorage.clear();
        window.open(logoutLink.data.logoutUrl, '_self');
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const login = () => {
    window.open(`${process.env.REACT_APP_TEM_LINK}/login`, '_self');
  };

  // const check = () => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_TEM_API_LINK}/api/v1/litmos-users/${appUser.email}`,
  //       // {},
  //       { headers: { Authorization: `Bearer ${appUser.accessToken}` } }
  //     )
  //     .then(({ data }) => {
  //       window.open(data.loginKey, '_self');
  //     });
  // };

  const userRole = appUser?.role ? appUser.role.split(',') : [];

  const capitalLetters = configuration?.capitalLetters;
  let itemsToMap = dropDownMenu
    ? user
      ? configuration?.items.filter(
          (item) =>
            (!item?.userRoles ||
              (item?.userRoles && intersection(item.userRoles, userRole).length > 0)) &&
            !item?.hidden
        )
      : configuration?.logInItem
    : configuration?.items.filter((item) => !item?.hidden);

  // console.log('itemsToMap', itemsToMap);

  // // TODO: after integration with litmos, accessibleMenuItems will be rendered
  // // eslint-disable-next-line no-unused-vars
  // const accessibleMenuItems = config?.header.userMenuItems.items.filter(
  //   (item) =>
  //     !item?.userRoles || (item?.userRoles && intersection(item.userRoles, userRole).length > 0)
  // );

  /////////////

  // console.log('appUser', appUser);

  // TODO -> CHECK appUser.role if exist ??
  // const roleList = appUser ? appUser.role.split(',').map((role) => role.trim()) : [];
  // const roleList = [];
  // const newItemsToMap = itemsToMap.filter((item) => {
  //   // console.log('item', item);
  //   if (item.name === 'administration') {
  //     if (roleList.includes('Admin') || roleList.includes('Team Admin'))
  //       return { ...item, disabled: false };
  //     else return;
  //   }

  //   if (item.name === 'teamArea') {
  //     if (roleList.includes('Manager')) return item;
  //     else return;
  //   }

  //   if (item.name === 'trainerArea') {
  //     if (roleList.includes('Trainer')) return item;
  //     else return;
  //   }

  //   if (item.name === 'locationArea') {
  //     if (roleList.includes('Location Responsible')) return item;
  //     else return;
  //   }

  //   return item;
  // });

  // console.log('newItemsToMap', newItemsToMap);

  // const menuItems = newItemsToMap.map((item) => {
  const menuItems = itemsToMap.map((item) => {
    // if (item.name === 'administration') {
    //   return (
    //     <Menu.Item
    //       key={item.name}
    //       name='Administration'
    //       active={true}
    //       onClick={() => window.open(`${process.env.REACT_APP_TEM_LINK}`, '_self')}
    //     />
    //   );
    // }
    if (item.name === 'logOut') {
      return <Menu.Item key={item.name} name='logout' active={true} onClick={logout} />;
    }
    if (item.name === 'logIn') {
      return <Menu.Item key={item.name} name='log in' active={true} onClick={login} />;
    }

    // if (item.name === 'myProfile') {
    //   return <Menu.Item key={item.name} name='My Profile' active={true} onClick={check} />;
    // }

    //////////////

    if (item.name === 'home') {
      return (
        <MenuItemStyled
          key={item.name}
          name={
            capitalLetters
              ? Localize.get(`AppNavBar.${item.name}`).toUpperCase()
              : Localize.get(`AppNavBar.${item.name}`)
          }
          data-test-id={`${item.name}MenuButton`}
          onClick={() => {
            console.log('');
          }}
        />
      );
    }

    return item.externalRoute ? (
      <MenuItemStyled
        key={item.name}
        data-test-id={`${item.name}MenuButton`}
        name={
          capitalLetters
            ? Localize.get(`AppNavBar.${item.name}`).toUpperCase()
            : Localize.get(`AppNavBar.${item.name}`)
        }
        // onClick={() => {
        //   // open TEM external link
        //   window.open(`${process.env.REACT_APP_TEM_LINK}`, '_self');
        // }}
        href={item.route}
        disabled={item.disabled ? item.disabled : false}
      />
    ) : (
      <Menu.Item
        active={
          routerConstants.routes.path[item.route] === location.pathname.split('/')[1] ||
          // when user clicks on course details from my area page
          (item.route === routerConstants.routes.name.myarea.split('/')[1] &&
            history?.location?.state?.path === routerConstants.routes.name.myarea &&
            location.pathname.split('/')[1] ===
              routerConstants.routes.path.courseDetails.split('/')[1]) ||
          // when user clicks on ilt session details from my area page
          (item.route === routerConstants.routes.name.myarea.split('/')[1] &&
            history?.location?.state?.path === routerConstants.routes.name.myarea &&
            location.pathname.split('/')[1] ===
              routerConstants.routes.path.iltSessionDetails.split('/')[1]) ||
          // when user clicks on learning path details from catalog page
          (item.route === routerConstants.routes.name.catalog.split('/')[1] &&
            history?.location?.state?.path === routerConstants.routes.name.catalog &&
            location.pathname.split('/')[1] ===
              routerConstants.routes.path.learningPathDetails.split('/')[1]) ||
          // when user clicks on course details from catalog page
          (item.route === routerConstants.routes.name.catalog.split('/')[1] &&
            history?.location?.state?.path === routerConstants.routes.name.catalog &&
            location.pathname.split('/')[1] ===
              routerConstants.routes.path.courseDetails.split('/')[1]) ||
          // when user clicks on event details from events page
          (item.route === routerConstants.routes.name.events.split('/')[1] &&
            location.pathname.split('/')[1] ===
              routerConstants.routes.path.eventDetails.split('/')[1]) ||
          // when user clicks on ilt session details from Trainer Area
          (item.route === routerConstants.routes.name.trainerArea.split('/')[1] &&
            location.pathname.split('/')[1] ===
              routerConstants.routes.path.iltSessionDetails.split('/')[1] &&
            location?.state?.path.split('/')[1] ===
              routerConstants.routes.name.trainerArea.split('/')[1]) ||
          // when user clicks on ilt session details from Location Area
          (item.route === routerConstants.routes.name.locationArea.split('/')[1] &&
            location.pathname.split('/')[1] ===
              routerConstants.routes.path.iltSessionDetails.split('/')[1] &&
            location?.state?.path.split('/')[1] ===
              routerConstants.routes.name.locationArea.split('/')[1]) ||
          // when url is empty and we are on My Area
          (item.route === routerConstants.routes.name.myarea.split('/')[1] &&
            (location.pathname.split('/')[1] === '' ||
              location.pathname.split('/')[1] === routerConstants.routes.name.home.split('/')[1]))
        }
        key={item.name}
        data-test-id={`${item.name}MenuButton`}
        name={
          capitalLetters
            ? Localize.get(`AppNavBar.${item.name}`).toUpperCase()
            : Localize.get(`AppNavBar.${item.name}`)
        }
        as={StyledNavLink}
        to={{
          pathname: routerConstants.routes.path[item.route],
          state: {
            from: Localize.get(`AppNavBar.${item.name}`),
            path: routerConstants.routes.path[item.route]
          }
        }}
        exact
        onClick={() => handleMenuClick(item.route)}
        disabled={item.disabled ? item.disabled : false}
      />
    );
  });
  // console.log('menuItems', menuItems);
  return menuItems ? menuItems : null;
};
export default AppNavBarMenuItems;
