import axios from 'axios';

import * as constant from '@services/Constants';

import tokenUtil from './tokenUtil.js';

const instance = axios.create();

export const getBaseUrl = () => {
  const host = constant.MEA_SERVER_CONFIGURATION.port
    ? `${constant.MEA_SERVER_CONFIGURATION.host}:${constant.MEA_SERVER_CONFIGURATION.port}`
    : `${constant.MEA_SERVER_CONFIGURATION.host}`;
  return `${constant.MEA_SERVER_CONFIGURATION.protocol}://${host}${constant.MEA_SERVER_CONFIGURATION.apiPath}`;
};

export const getBaseHeaders = () => {
  // const auth = 'Basic ' + btoa(`${constant.CREDENTIALS.user}:${constant.CREDENTIALS.password}`);
  return {
    // Authorization: auth,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
  };
};

instance.interceptors.request.use(
  (config) => {
    const token = tokenUtil.getLocalAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    const originalConfig = res.config;
    if (originalConfig.url.includes(constant.MEA_SERVER_CONFIGURATION.resource.login)) {
      // user is logged in successfully
      tokenUtil.setUser(res.data);
    }
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (
      !originalConfig.url.includes(constant.MEA_SERVER_CONFIGURATION.resource.login) &&
      err.response
    ) {
      // Access token was expired
      if (err.response.status === 403 && !originalConfig._retry) {
        localStorage.clear();
        originalConfig._retry = true;

        try {
          const urlPart = getBaseUrl() + constant.MEA_SERVER_CONFIGURATION.resource.refreshToken;

          const response = await instance.post(urlPart, {
            refreshToken: tokenUtil.getLocalRefreshToken()
          });

          const { accessToken } = response.data;
          tokenUtil.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (error) {
          // Session expired; log out user
          localStorage.clear();
          if (
            error?.response?.data?.code === 'sessionExpired' ||
            error?.response?.data?.code === 'sessionTokenMissing' ||
            error?.message === 'sessionCorrupted'
          ) {
            // TODO: remove user
            // tokenUtil.removeUser();
            localStorage.clear();
          }
          return Promise.reject(error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
