import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useHistory, useLocation } from 'react-router-dom';

import { differenceInCalendarDays } from 'date-fns';
import moment from 'moment/moment';
// semantic
import { Divider, List } from 'semantic-ui-react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Logger from '@common/Logger.js';
import EventGridListInfinity from '@components/EventGridListView/EventGridList/EventGridListInfinity';
import EventGridList from '@components/EventGridListView/EventGridList/EventList';
import EventList from '@components/EventListView/EventList/EventList';
import EventListInfinity from '@components/EventListView/EventList/EventListInfinity';
import NoContentComponent from '@components/NoContentComponent/NoContentComponent';
import Pagination from '@components/Pagination/Pagination';
import {
  OverviewGridContainer,
  MainColumnContainer,
  OverviewGrid,
  RightColumnContainer,
  CalendarSegment,
  StyledCalendar,
  TodayButtonContainer,
  TodayButton,
  TodayDateIconContainer,
  ListContainer,
  OverviewFilterSegmentMini,
  UpcomingEventsSegment,
  UpcomingEventsTextContainer,
  UpcomingEventsListContainer,
  PaginationContainer,
  MobileCalendarContainer,
  OverviewFilterSegmentContainer,
  ListSpan,
  ListContent
} from '@components/StyledComponents/MyAreaOverview.styled';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants';

import {
  createSessionRangeCalendarService,
  createSessionCalendarService,
  createUpcomingIltSessionsService
} from '../../MyArea/MainHelper';

const Overview = (props) => {
  const { handleOverviewFilterClick, contextData } = props;

  let history = useHistory();
  const [calendarValue, setCalendarValue] = React.useState(new Date());
  const [showTodayCalendar, setShowTodayCalendar] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1); // TO DO set to 0 after BE is finished
  const [sessions, setSessions] = React.useState([]);
  const [upcomingSessions, setUpcomingSessions] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const [searchTerm] = React.useState('');
  const [filters] = React.useState({ searchString: '' });
  const [originalFilterData] = React.useState(null);
  const [highlightedDates, setHighlightedDates] = React.useState([]);
  const [activeDate, setActiveDate] = React.useState(new Date());
  const [sortingOption] = React.useState({
    name: 'startDateDesc',
    iconName: 'sort content descending',
    sortValue: 'startDate desc'
  });

  //we use same component on the Location Area page
  const location = useLocation();
  //--------------- TO DO !!!! --------------- when BE for locations will be finish uncomment  this
  // const isTrainerAreaPage = location.pathname === routerConstants.routes.path.trainerArea;

  //--------------- TO DO !!!! ------------Delete this--- when BE for locations will be finish
  const isTrainerAreaPage = true; // when BE for te locations is finished delete  this
  const isTrainerAreaPageTemporary = location.pathname === routerConstants.routes.path.trainerArea;

  const config = contextData?.configuration;

  const isInfinityScroll = isTrainerAreaPage
    ? config?.global?.trainerOverviewInfinityScroll
    : config?.global?.locationOverviewInfinityScroll;

  const overviewItem = isTrainerAreaPage
    ? config?.TrainerAreaOverviewItem
    : config?.LocationOverviewItem;

  const [eventData, isLoading, setIsLoading, fetchEvents] = useFetching(
    createSessionCalendarService.bind(null, calendarValue, isTrainerAreaPage),
    true
  );

  const [sessionsInMonth, , , fetchSessionsInMonth] = useFetching(
    createSessionRangeCalendarService.bind(null, activeDate, isTrainerAreaPage),
    true
  );
  const FILTER_STATUS = { future: 'future', past: 'past', cancelled: 'cancelled' };

  const [sessionsUpcoming, isLoadingUpcoming, setIsLoadingUpcoming, fetchSessionsUpcoming] =
    useFetching(
      createUpcomingIltSessionsService.bind(null, currentPage - 1, contextData, isTrainerAreaPage),
      true
    );

  const eventListMobile = React.useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        fetchEvents();
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size) {
          setIsLoadingUpcoming(true);
          fetchSessionsUpcoming();
          fetchSessionsInMonth();
        }
        if (contextData?.size && contextData?.size.device === 'Mobile') {
          if (eventListMobile?.current) {
            eventListMobile?.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, contextData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eventData?.data?.sessions) {
          setSessions(eventData.data.sessions);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sessionsInMonth?.data?.sessions) {
          setHighlightedDates(sessionsInMonth?.data?.sessions.map((el) => el.startDateTime));
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsInMonth]);

  const formatSession = (sessions) =>
    sessions.map((session) => ({
      ID: session.id,
      DESCRIPTION: session.name,
      EVENT_START_STRING: session.startDateTime
    }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sessionsUpcoming?.data?.sessions) {
          setUpcomingSessions(formatSession(sessionsUpcoming.data.sessions));
          setTotalPages(
            Math.ceil(
              sessionsUpcoming.data.sessionsCount /
                contextData?.configuration?.PAGINATION.trainerOverviewLimit.grid[
                  contextData?.size.device.toLowerCase()
                ]
            )
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsUpcoming]);

  const handleTodayLabelClick = () => {
    setCalendarValue(new Date());
    setShowTodayCalendar(true);
    setActiveDate(new Date());
    fetchSessionsInMonth();
  };

  const onChangeDate = (value) => {
    setCalendarValue(value);
    setShowTodayCalendar(false);
  };
  const onActiveStartDateChange = ({ activeStartDate }) => {
    setActiveDate(activeStartDate);
    try {
      if (currentPage && contextData?.size) {
        fetchSessionsInMonth();
      }
      if (contextData?.size && contextData?.size.device === 'Mobile') {
        if (eventListMobile?.current) {
          eventListMobile?.current.scrollIntoView();
        }
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  const isSameDay = (a, b) => {
    return differenceInCalendarDays(new Date(a), b) === 0;
  };
  const tileClassName = ({ date, view }) => {
    if (
      view === 'month' &&
      highlightedDates.find((highlightedDate) => isSameDay(highlightedDate, date))
    ) {
      return 'highlight';
    }
  };

  const getCalendar = () => {
    if (showTodayCalendar) {
      return (
        <StyledCalendar
          key='today'
          onChange={onChangeDate}
          value={new Date()}
          tileClassName={tileClassName}
        />
      );
    }
    return (
      <StyledCalendar
        key='changed'
        onChange={onChangeDate}
        value={calendarValue}
        tileClassName={tileClassName}
        onActiveStartDateChange={onActiveStartDateChange}
      />
    );
  };

  const onAccordionClick = () => {
    setActive(!active);
  };

  const onItemClick = (eventId, eventTypeName) => {
    history.push({
      pathname: routerConstants.routes.name.iltSessionDetails + '/' + eventId,
      state: {
        from: Localize.get('TrainerArea.overview'),
        path: isTrainerAreaPageTemporary
          ? routerConstants.routes.name.trainerArea
          : routerConstants.routes.name.locationArea,
        data: eventTypeName
      }
    });
  };

  const onListItemClick = (e, data) => {
    history.push({
      pathname: routerConstants.routes.name.iltSessionDetails + '/' + data.id,
      state: {
        from: Localize.get('TrainerArea.overview'),
        path: isTrainerAreaPageTemporary
          ? routerConstants.routes.name.trainerArea
          : routerConstants.routes.name.locationArea
      }
    });
  };

  const getContent = () => {
    if (isInfinityScroll) {
      return (
        <EventListInfinity
          events={upcomingSessions}
          configuration={overviewItem}
          pagination={contextData?.configuration?.PAGINATION.trainerOverviewLimit}
          listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
          onItemClick={onItemClick}
          fetchEvents={fetchEvents}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          filters={filters}
          originalFilterData={originalFilterData}
          sortingOption={sortingOption}
          listView={'grid'}
          infoType={Localize.get('TrainerArea.noSessions')}
          deviceSize={
            contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
          }
        />
      );
    } else {
      return (
        <EventList
          events={upcomingSessions}
          configuration={overviewItem}
          listColumnCount={contextData?.size && contextData?.size.device === 'Computer' ? 4 : 3}
          onItemClick={onItemClick}
          infoType={Localize.get('TrainerArea.noSessions')}
        />
      );
    }
  };

  const getMobileContent = () => {
    if (isInfinityScroll) {
      return (
        <EventGridListInfinity
          events={upcomingSessions}
          configuration={overviewItem}
          pagination={contextData?.configuration?.PAGINATION.trainerOverviewLimit}
          listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
          onItemClick={onItemClick}
          fetchEvents={fetchEvents}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          searchTerm={searchTerm}
          filters={filters}
          originalFilterData={originalFilterData}
          sortingOption={sortingOption}
          listView={'grid'}
          deviceSize={
            contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
          }
        />
      );
    } else {
      return (
        <EventGridList
          events={upcomingSessions}
          configuration={overviewItem}
          listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
          onItemClick={onItemClick}
        />
      );
    }
  };

  return (
    <OverviewGridContainer>
      <MainColumnContainer computer={16} largeScreen={16} widescreen={16} tablet={16} mobile={16}>
        {/* desktop && tablet layout - menu and content*/}
        <OverviewGrid className={'tablet computer only'} centered data-testid={'overviewGrid'}>
          <Grid.Column computer={5} largeScreen={5} widescreen={5} tablet={6}>
            <CalendarSegment raised>
              {isLoading && (
                <Dimmer active inverted>
                  <Loader size='large'>{Localize.get('App.loading')}</Loader>
                </Dimmer>
              )}
              {getCalendar()}
              <TodayButtonContainer>
                <TodayButton
                  role='button'
                  tabIndex={0}
                  onClick={handleTodayLabelClick}
                  onKeyPress={handleTodayLabelClick}
                >
                  {Localize.get('MyArea.today').toUpperCase()}
                </TodayButton>
              </TodayButtonContainer>
              <Divider />
              <TodayDateIconContainer>
                {moment(calendarValue).format('DD/MM/YYYY')}
                <Icon name='calendar alternate outline' />
              </TodayDateIconContainer>
              {sessions && sessions.length ? (
                <ListContainer>
                  <List>
                    {sessions.map((evt, index) => (
                      <List.Item key={index} id={evt.id} onClick={onListItemClick}>
                        <List.Icon name='clock outline' />
                        <ListContent>
                          {moment(evt.startDateTime).format('hh:mm')}
                          <ListSpan>{evt.name}</ListSpan>
                        </ListContent>
                      </List.Item>
                    ))}
                  </List>
                </ListContainer>
              ) : (
                <NoContentComponent
                  data-test-id={'trainerEventListNoContent'}
                  infoType={Localize.get('TrainerArea.noSessionsForDate')}
                  infoMessage={Localize.get('TrainerArea.selectNewDate')}
                />
              )}
            </CalendarSegment>
          </Grid.Column>
          <RightColumnContainer computer={11} largeScreen={11} widescreen={11} tablet={10}>
            <Grid stackable container centered>
              {isLoadingUpcoming && (
                <Dimmer active inverted>
                  <Loader size='large'>{Localize.get('App.loading')}</Loader>
                </Dimmer>
              )}
              <Grid.Row columns={3}>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick(FILTER_STATUS.future)}
                  >
                    <div>
                      <Icon name='history' />
                      {Localize.get('TrainerArea.futureSessions')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick(FILTER_STATUS.past)}
                  >
                    <div>
                      <Icon name='check circle outline' />
                      {Localize.get('TrainerArea.pastSessions')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick(FILTER_STATUS.cancelled)}
                  >
                    <div>
                      <Icon name='times circle' />
                      {Localize.get('TrainerArea.cancelledSessions')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <UpcomingEventsSegment raised>
                    <UpcomingEventsTextContainer>
                      <Icon name='calendar alternate outline' />
                      <div ref={eventListMobile}>
                        {Localize.get('TrainerArea.upcomingSessions')}
                      </div>
                    </UpcomingEventsTextContainer>
                    <UpcomingEventsListContainer>{getContent()}</UpcomingEventsListContainer>
                    {!!totalPages && !isInfinityScroll && (
                      <Grid>
                        <PaginationContainer centered>
                          <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            noSiblings={totalPages > 3}
                          />
                        </PaginationContainer>
                      </Grid>
                    )}
                  </UpcomingEventsSegment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </RightColumnContainer>
        </OverviewGrid>

        {/* mobile layout - content*/}
        <OverviewGrid centered className={'mobile only'} data-testid={'mobileOverviewGrid'}>
          <Grid.Column width={16}>
            <Grid.Row>
              <Accordion styled fluid>
                <Accordion.Title
                  active={active}
                  onClick={onAccordionClick}
                  content={Localize.get('MyArea.calendarOverview')}
                />
                <MobileCalendarContainer active={active}>
                  {isLoading && (
                    <Dimmer active inverted>
                      <Loader size='large'>{Localize.get('App.loading')}</Loader>
                    </Dimmer>
                  )}
                  {getCalendar()}
                  <TodayButtonContainer>
                    <TodayButton role='button' tabIndex={0} onClick={handleTodayLabelClick}>
                      {Localize.get('MyArea.today').toUpperCase()}
                    </TodayButton>
                  </TodayButtonContainer>
                  <TodayDateIconContainer>
                    {moment(calendarValue).format('DD/MM/YYYY')}
                    <Icon name='calendar alternate outline' />
                  </TodayDateIconContainer>
                  {sessions && sessions.length ? (
                    <ListContainer>
                      <List>
                        {sessions.map((evt, index) => (
                          <List.Item key={index} id={evt.id} onClick={onListItemClick}>
                            <List.Icon name='clock outline' />
                            <ListContent>
                              {moment(evt.startDateTime).format('hh:mm')}
                              <ListSpan>{evt.name}</ListSpan>
                            </ListContent>
                          </List.Item>
                        ))}
                      </List>
                    </ListContainer>
                  ) : (
                    <NoContentComponent
                      data-test-id={'TrainereventListNoContentMob'}
                      infoType={Localize.get('TrainerArea.noSessionsForDate')}
                      infoMessage={Localize.get('TrainerArea.selectNewDate')}
                    />
                  )}
                </MobileCalendarContainer>
              </Accordion>
            </Grid.Row>
            <Grid.Row>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick(FILTER_STATUS.future)}
                >
                  <div>
                    <Icon name='history' />
                    {Localize.get('TrainerArea.futureSessions')} {new Date().getFullYear()}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick(FILTER_STATUS.past)}
                >
                  <div>
                    <Icon name='check circle outline' />
                    {Localize.get('TrainerArea.pastSessions')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick(FILTER_STATUS.cancelled)}
                >
                  <div>
                    <Icon name='times circle' />
                    {Localize.get('TrainerArea.cancelledSessions')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
            </Grid.Row>
            <Grid.Row>
              <OverviewFilterSegmentContainer>
                <UpcomingEventsSegment raised>
                  <UpcomingEventsTextContainer>
                    <Icon name='calendar alternate outline' />
                    <div>{Localize.get('TrainerArea.upcomingSessions')}</div>
                  </UpcomingEventsTextContainer>
                  <UpcomingEventsListContainer>{getMobileContent()}</UpcomingEventsListContainer>

                  {!!totalPages && !isInfinityScroll && (
                    <Grid centered>
                      <PaginationContainer centered>
                        <Pagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          totalPages={totalPages}
                          noSiblings={totalPages > 3}
                        />
                      </PaginationContainer>
                    </Grid>
                  )}
                </UpcomingEventsSegment>
              </OverviewFilterSegmentContainer>
            </Grid.Row>
          </Grid.Column>
        </OverviewGrid>
      </MainColumnContainer>
    </OverviewGridContainer>
  );
};

export default Overview;
