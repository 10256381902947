import Localize from 'react-intl-universal';

import { Field } from 'formik';

import FormCheckBox from './FormComponents/FormCheckBox';
import FormInput from './FormComponents/FormInput';
import FormPasswordInput from './FormComponents/FormPasswordInput';
import FormSelect from './FormComponents/FormSelect';

const FormComponent = (
  { component, label, placeholder, control, ...rest },
  fieldIndex,
  setFieldValue,
  setFieldTouched,
  selectOptions = []
) => {
  switch (component) {
    case 'text':
      return (
        <Field
          key={fieldIndex}
          label={Localize.get(label)}
          placeholder={Localize.get(placeholder)}
          {...rest}
          type={component}
          component={control ? control : FormInput}
        />
      );
    case 'checkBox':
      return (
        <Field
          key={fieldIndex}
          label={Localize.get(label)}
          placeholder={Localize.get(placeholder)}
          {...rest}
          type={component}
          component={control ? control : FormCheckBox}
          setFieldValue={setFieldValue}
        />
      );
    case 'passwordInput':
      return (
        <Field
          key={fieldIndex}
          label={Localize.get(label)}
          {...rest}
          type='text'
          component={control ? control : FormPasswordInput}
        />
      );
    case 'select':
      return (
        <Field
          key={fieldIndex}
          label={Localize.get(label)}
          {...rest}
          type={component}
          options={selectOptions[rest.name]}
          component={control ? control : FormSelect}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      );

    default:
      break;
  }
};

export default FormComponent;
