import React, { useEffect, useRef, useState } from 'react';
import Localize from 'react-intl-universal';

import Button from 'semantic-ui-react/dist/commonjs/elements/Button';

import CourseItem from '@components/CourseListView/CourseListItem/CourseItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import {
  EventListContainer,
  EventListMainContainer,
  EventListItemStyled
} from '@components/StyledComponents/EventList.styled';
import { InfinityObserverList } from '@hooks/Observer/InfinityObserver';

import style from './style/CourseList.module.scss';

const CourseListInfinity = (props) => {
  const {
    courses,
    configuration,
    pagination,
    onItemClick,
    currentPage,
    setCurrentPage,
    filteredCourses,
    totalPages,
    listView
  } = props;
  const loader = useRef(null);
  const [allCourses, setAllCourses] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const paginationLimit = pagination.coursesLimit[listView]['computer'];

  useEffect(() => {
    if (filteredCourses && filteredCourses.length) {
      setAllCourses(filteredCourses);
      setBtnDisabled(true);
    } else if (courses && courses.length <= paginationLimit) {
      setBtnDisabled(false);
      // Make unique list of courses
      setAllCourses((prevCourses) => [
        ...new Map([...prevCourses, ...courses].map((item) => [item['ID'], item])).values()
      ]);
    }
  }, [courses, filteredCourses]);

  // Needed for infinity scroll
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };

    const observer = InfinityObserverList(
      totalPages,
      currentPage,
      setCurrentPage,
      setBtnDisabled,
      options
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  const loadMore = () => {
    if (totalPages > currentPage) {
      setCurrentPage((prevPageNumber) => prevPageNumber + 1);
    } else {
      setBtnDisabled(true);
    }
  };

  return (
    <>
      <EventListMainContainer stackable columns={1}>
        <EventListContainer stretched only='computer tablet'>
          {allCourses && allCourses.length ? (
            <>
              {allCourses.map((course, index) => (
                <EventListItemStyled key={index}>
                  <CourseItem
                    config={configuration}
                    key={course.ID}
                    courseId={course.ID}
                    courseName={course.DESCRIPTION}
                    locationInfo={course.COURSE_LOCATIONS}
                    courseTypeName={course.COURSE_TYPE_NAME}
                    onItemClick={onItemClick}
                    price={course.COURSE_PRICE}
                    startDate={course.START_DATE}
                    endDate={course.END_DATE}
                    image={course.COURSE_IMAGE_URL}
                  />
                </EventListItemStyled>
              ))}
              {!btnDisabled ? (
                <div ref={loader} className={style.centralized}>
                  {Localize.get('App.loading')}...
                </div>
              ) : (
                <></>
              )}
              <div className={style.centralized}>
                <Button active={true} disabled={btnDisabled} onClick={loadMore}>
                  {Localize.get('Catalog.loadMore')}
                </Button>
              </div>
            </>
          ) : (
            <NoContentComponent
              data-test-id={'courseListInfinityNoContent'}
              infoType={Localize.get('Catalog.noCourses')}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </EventListMainContainer>
    </>
  );
};

export default CourseListInfinity;
