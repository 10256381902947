import { formatDateHHTimeZone } from '@common/Util.js';

export const getDuration = (dateTimeStart, dateTimeEnd) => {
  if (!dateTimeStart || !dateTimeEnd) {
    return '';
  }

  let day = parseInt(dateTimeStart.substring(0, 2), 10);
  let month = parseInt(dateTimeStart.substring(3, 5), 10);
  let year = parseInt(dateTimeStart.substring(6, 10), 10);
  let hour = parseInt(dateTimeStart.substring(11, 13), 10);
  let minute = parseInt(dateTimeStart.substring(14, 16), 10);

  const startDate = new Date(year, month - 1, day, hour, minute);

  day = parseInt(dateTimeEnd.substring(0, 2), 10);
  month = parseInt(dateTimeEnd.substring(3, 5), 10);
  year = parseInt(dateTimeEnd.substring(6, 10), 10);
  hour = parseInt(dateTimeEnd.substring(11, 13), 10);
  minute = parseInt(dateTimeEnd.substring(14, 16), 10);

  const endDate = new Date(year, month - 1, day, hour, minute);
  const diff = endDate - startDate;

  const dayDiff = parseInt((diff / (24 * 3600 * 1000)).toString(), 10);
  const hourDiff = parseInt((diff / 1000 / 60 / 60).toString(), 10);
  const minDiff = parseInt((diff / 1000 / 60).toString(), 10);

  let dayDiffNew = dayDiff;
  let hourDiffNew = hourDiff;
  let minDiffNew = minDiff;

  if (dayDiff >= 1) {
    hourDiffNew = diff - dayDiff * 24 * 1000 * 3600;
  } else {
    hourDiffNew *= 1000 * 60 * 60;
  }
  const hoursLeft = parseInt((hourDiffNew / 1000 / 60 / 60).toString(), 10);

  if (hoursLeft >= 1) {
    if (dayDiff >= 1) {
      minDiffNew = hourDiffNew - hoursLeft * 1000 * 3600;
    } else {
      minDiffNew = diff - hourDiffNew;
    }
  } else {
    if (minDiffNew / 60 / 24 === dayDiff) {
      // case when duration is exact no of days
      // (24h, 48h, etc.)
      minDiffNew = 0;
    } else {
      // case when duration is exact no of days and minutes
      // (24h and 15 min)
      minDiffNew = (minDiff - dayDiff * 60 * 24) * 1000 * 60;
    }
  }

  return {
    dayDiff: dayDiffNew,
    hourDiff: hoursLeft,
    minDiff: parseInt((minDiffNew / 1000 / 60).toString(), 10)
  };
};

export const getSessionStartTime = (dateTimeStart) => {
  if (!dateTimeStart) {
    return '';
  }

  let hour = parseInt(dateTimeStart.substring(11, 13), 10);
  let minute = parseInt(dateTimeStart.substring(14, 16), 10);
  let date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);

  return formatDateHHTimeZone(date);
};

export const getIsEventPassed = () => {
  // if (!event) {
  //   return true;
  // }

  // return new Date() > formatDateStringToDateObject(event.EVENT_END);
  return false;
};
