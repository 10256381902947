import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import * as globalConstants from '@common/Constants.js';
import { setLanguage } from '@common/Util.js';
import AppFooter from '@components/AppFooter/AppFooter';
import Header from '@components/AppHeader/Header';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useViewportSize from '@hooks/Viewport/Viewport.js';
import * as constants from '@navigation/Constants.js';

import style from './style/AppContainer.module.scss';

const AppContainer = (props) => {
  const { rest, component, configuration } = props;

  const size = useViewportSize();
  const logInRouteMatch = useRouteMatch(constants.routes.path.login);
  const signUpRouteMatch = useRouteMatch(constants.routes.path.signup);
  const homeRouteMatch = useRouteMatch(constants.routes.path.home);
  const registerRouteMatch = useRouteMatch(constants.routes.path.register);
  const forgotPassRouteMatch = useRouteMatch(constants.routes.path.forgotPass);
  const resetPassRouteMatch = useRouteMatch(constants.routes.path.resetPass);
  const [contextData, setContextData] = useGlobalContext();

  useEffect(() => {
    if (size) {
      setContextData({
        ...contextData,
        size: size,
        lang: setLanguage()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  useEffect(() => {
    if (configuration) {
      setContextData({
        ...contextData,
        configuration: configuration
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  const getPageContent = (arg) => {
    if (size.device.toUpperCase() === arg.toUpperCase()) {
      return <Route {...rest} component={component} />;
    }
    return null;
  };

  const getHeader = () => {
    if (size && size.device !== globalConstants.deviceSize.mobile) {
      if (
        logInRouteMatch ||
        signUpRouteMatch ||
        registerRouteMatch ||
        forgotPassRouteMatch ||
        resetPassRouteMatch
      ) {
        return null;
      }
      return <Header config={configuration} />;
    } else {
      // mobile
      return <Header config={configuration} content={getPageContent('mobile')} />;
    }
  };
  // need for testing purpose
  //console.log(contextData.size);
  return (
    <>
      <Grid centered className={`${style.appGrid} ${style.appContainer}`}>
        {getHeader()}

        <Grid.Row
          data-test-id={'pageDesktopAppContainer'}
          only='tablet computer'
          className={style.pageDesktopContainer}
          style={{ background: configuration?.global?.background }}
        >
          <Grid.Column
            only='tablet computer'
            computer={logInRouteMatch || signUpRouteMatch || homeRouteMatch ? 16 : 14}
            className={
              size && size.device === globalConstants.deviceSize.computer
                ? style.pageDesktopContainerContent
                : style.pageTabletContainerContent
            }
          >
            {size && size.device === globalConstants.deviceSize.computer
              ? getPageContent('computer')
              : size && size.device === globalConstants.deviceSize.tablet
              ? getPageContent('tablet')
              : null}
          </Grid.Column>
        </Grid.Row>

        {logInRouteMatch ||
        signUpRouteMatch ||
        registerRouteMatch ||
        forgotPassRouteMatch ||
        resetPassRouteMatch ? null : (
          <Grid.Row only='tablet computer mobile' stretched className={style.footersContainer}>
            <Grid.Column computer={16} tablet={16} mobile={16} className={style.footersContainer}>
              <AppFooter config={configuration} />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  );
};
export default AppContainer;
