import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Rating from 'semantic-ui-react/dist/commonjs/modules/Rating';
import Card from 'semantic-ui-react/dist/commonjs/views/Card';

import { defaultThumbnail } from '@common/Constants';
import { formatDateDMY, formatLongDateStringToDateObject } from '@common/Util';
import {
  StyledCardContent,
  CardContentImage,
  CardContentGrid,
  CardContentDescriptionContainer,
  CardContentContainer,
  CardContentEventProps,
  ContentDescriptionName,
  DescriptionSubHeader,
  ContentEventRating,
  EvtNameHeader,
  CardHeaderDateColumn,
  TextShrink,
  CardHeaderCalendarColumn,
  FooterCity,
  FooterLocation,
  ContentEventRatingCount,
  CardHeaderCourseFlagColumn,
  StyledCardContentTraining,
  TrainingContentDescriptionName
} from '@components/StyledComponents/EventItem.styled';

const CourseItemContent = (props) => {
  const {
    config,
    image,
    courseName,
    courseTypeName,
    description,
    langId,
    startDate,
    endDate,
    location,
    courseTitle
  } = props;

  const imgName = config?.content?.img ? (image ? image : config.content.img) : null;
  const oneLineMax = 1;
  const twoLineMax = 1;
  const courseNameBrakeLine = 53;
  const userRatingCount = 1; // TODO: after integration with Litmos, no of users rated event should part of the data

  const getImageLabel = () => {
    if (
      config?.content?.showBanner &&
      config?.content?.markLabel &&
      config.content.markLabel[courseTypeName]
    ) {
      return {
        content: Localize.get(`CourseDetails.${courseTypeName}`),
        ribbon: true,
        markcolor: `${config.content.markLabel[courseTypeName].markLabelBackgroundText}`,
        markcolortext: `${config.content.markLabel[courseTypeName].markLabelColorText}`
      };
    }
    return null;
  };

  const getImageSource = () => {
    if (image && image !== defaultThumbnail.name) {
      config.content.notDefaultImg = true;
      return image;
    } else {
      config.content.notDefaultImg = false;
      return require(`../../../assets/Images/${config.content.img}`).default;
    }
  };

  const getContent = () => {
    switch (config.name) {
      case 'catalog':
        return (
          <>
            {imgName && imgName !== 'undefined' ? (
              <>
                <CardContentImage
                  config={config}
                  wrapped
                  src={getImageSource()}
                  label={getImageLabel()}
                />
                <StyledCardContent>
                  <CardContentContainer>
                    <CardContentEventProps>
                      {config?.content?.showMarkLabelInTile && (
                        <div>{Localize.get(`CourseDetails.${courseTypeName}`)}</div>
                      )}
                      <ContentEventRating>
                        <Rating icon='star' maxRating={5} disabled />
                      </ContentEventRating>
                      <ContentEventRatingCount>
                        <div>({userRatingCount})</div>
                      </ContentEventRatingCount>
                      <CardHeaderCourseFlagColumn computer={4} tablet={3} mobile={2}>
                        <Flag name={langId ? langId.toLowerCase() : ''} />
                      </CardHeaderCourseFlagColumn>
                    </CardContentEventProps>

                    <ContentDescriptionName namelength={courseName.length}>
                      <EvtNameHeader
                        config={config}
                        data-text={courseName}
                        namelength={courseName.length}
                        itemcontent='grid'
                      >
                        {courseName}
                      </EvtNameHeader>
                    </ContentDescriptionName>
                    {config?.content?.showDate && (
                      <CardHeaderDateColumn
                        computer={6}
                        tablet={5}
                        mobile={4}
                        floated='left'
                        textAlign='left'
                      >
                        {startDate && endDate
                          ? formatDateDMY(formatLongDateStringToDateObject(startDate)) +
                            '-' +
                            formatDateDMY(formatLongDateStringToDateObject(endDate))
                          : ''}
                        <CardHeaderCalendarColumn
                          computer={2}
                          tablet={2}
                          mobile={1}
                          floated='right'
                        >
                          {startDate && endDate ? <Icon name='calendar alternate outline' /> : ''}
                        </CardHeaderCalendarColumn>
                      </CardHeaderDateColumn>
                    )}
                    <FooterLocation
                      visible={
                        config?.footer?.columns.includes('location') &&
                        config?.content?.showLocation
                      }
                      needsPadding={config?.footer?.columns.includes('globeIcon')}
                    >
                      <FooterCity>{location}</FooterCity>
                    </FooterLocation>
                  </CardContentContainer>
                </StyledCardContent>
              </>
            ) : (
              <Card.Content textAlign='center' data-test-id={'courseItemContent'}>
                <CardContentGrid>
                  <Grid.Row centered>
                    <EvtNameHeader
                      config={config}
                      data-text={courseName}
                      namelength={courseName.length}
                      itemcontent='grid'
                    >
                      {courseName}
                    </EvtNameHeader>
                  </Grid.Row>
                  <CardHeaderCourseFlagColumn computer={4} tablet={3} mobile={2}>
                    <Flag name={langId ? langId.toLowerCase() : ''} />
                  </CardHeaderCourseFlagColumn>
                  {config?.content?.showDate && (
                    <>
                      <CardHeaderDateColumn
                        computer={6}
                        tablet={5}
                        mobile={4}
                        floated='right'
                        textAlign='right'
                      >
                        <TextShrink>
                          {startDate && endDate
                            ? formatDateDMY(formatLongDateStringToDateObject(startDate)) +
                              '-' +
                              formatDateDMY(formatLongDateStringToDateObject(endDate))
                            : ''}
                        </TextShrink>
                      </CardHeaderDateColumn>
                      <CardHeaderCalendarColumn computer={2} tablet={2} mobile={1} floated='right'>
                        <Icon name='calendar alternate outline' />
                      </CardHeaderCalendarColumn>
                    </>
                  )}

                  <CardContentDescriptionContainer
                    centered
                    maxlines={
                      courseName && courseName.length > courseNameBrakeLine
                        ? oneLineMax
                        : twoLineMax
                    }
                  >
                    <Header as='h4' textAlign='center'>
                      <DescriptionSubHeader
                        maxlines={
                          courseName && courseName.length > courseNameBrakeLine
                            ? oneLineMax
                            : twoLineMax
                        }
                        inheritcolor='true'
                      >
                        {description ? description : ''}
                      </DescriptionSubHeader>
                    </Header>
                  </CardContentDescriptionContainer>
                  <FooterLocation
                    visible={
                      config?.footer?.columns.includes('location') && config?.content?.showLocation
                    }
                    needsPadding={config?.footer?.columns.includes('globeIcon')}
                  >
                    <FooterCity>{location}</FooterCity>
                  </FooterLocation>
                </CardContentGrid>
              </Card.Content>
            )}
          </>
        );
      case 'training':
        return (
          <>
            <CardContentImage
              config={config}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentTraining>
              <CardContentContainer>
                <CardContentEventProps>
                  {config?.content?.showMarkLabelInTile && (
                    <div>{Localize.get(`CourseDetails.${courseTypeName}`)}</div>
                  )}
                  {config?.content?.showRate && (
                    <>
                      <ContentEventRating>
                        <Rating icon='star' maxRating={5} disabled />
                      </ContentEventRating>
                      <ContentEventRatingCount>
                        <div>({userRatingCount})</div>
                      </ContentEventRatingCount>
                    </>
                  )}
                </CardContentEventProps>
                <TrainingContentDescriptionName namelength={courseTitle.length}>
                  <EvtNameHeader>{courseTitle}</EvtNameHeader>
                </TrainingContentDescriptionName>
              </CardContentContainer>
            </StyledCardContentTraining>
          </>
        );
      default:
        return null;
    }
  };

  return getContent();
};

export default CourseItemContent;
