import Localize from 'react-intl-universal';

import * as constants from './Constants';

export const getMobileMenuItems = (activeItem, handleItemClickLocally, config, tabsToShow) => {
  if (!activeItem || !handleItemClickLocally || !tabsToShow) {
    return null;
  }
  return constants.mobileTabDefinitions
    .filter(
      ({ labelKey }) => tabsToShow[`show${labelKey.charAt(0).toUpperCase()}${labelKey.slice(1)}Tab`]
    )
    .map(({ labelKey, icon }) => ({
      name: constants.tabOptions[labelKey],
      active: activeItem === constants.tabOptions[labelKey],
      onClick: handleItemClickLocally,
      key: Localize.get(`EventDetails.${labelKey}`),
      text: Localize.get(`EventDetails.${labelKey}`),
      value: Localize.get(`EventDetails.${labelKey}`),
      icon: { name: icon },
      selected: null,
      disabled: !config?.AllSessionsTabs?.[labelKey]
    }));
};
