import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Rating from 'semantic-ui-react/dist/commonjs/modules/Rating';
import Card from 'semantic-ui-react/dist/commonjs/views/Card';

import { defaultThumbnail } from '@common/Constants';
import {
  formatDateStringToDateObject,
  formatDateDMYSlashDelimiter,
  momentFormatDateDMYSlashDelimiter,
  formatLongDateStringToDateObject,
  formatDateDMY,
  formatDateTimeStringToDateTimeObject
} from '@common/Util';
import {
  StyledCardContent,
  StyledCardContentTraining,
  StyledCardContentPlainTraining,
  CardContentImage,
  PlainCardContentImage,
  CardContentGrid,
  CardContentDescriptionContainer,
  CardContentContainer,
  CardContentEventProps,
  ContentDescription,
  ContentDescriptionName,
  TrainingContentDescriptionName,
  PlainTrainingContentDescriptionName,
  TrainingCardContentEventProps,
  PlainTrainingCardContentEventProps,
  DescriptionSubHeader,
  ContentEventRating,
  TrainingContentEventRating,
  ContentEventRatingCount,
  EvtNameHeader,
  TextShrink,
  TrainingCardHeaderDateColumn,
  TrainingCardHeaderCalendarColumn,
  TrainingLocationDatesContainer,
  PlainTrainingLocationContainer,
  TrainingLocationContainer,
  TrainingCityText,
  TrainingExpDates,
  TrainingOverdueText,
  TrainingExpText,
  ListViewDateContainer,
  ListViewCourseCalendarIcon
} from '@components/StyledComponents/EventItem.styled';
// Fields COMBINED_VISIT, AUTOMATIC_CONFIRMATION, GUIDE_REQUIRED and NAME_TAG_PRINTING
// are used as indicators for rating, date and location, overdue date and access expired date respectively.
// After integration for Litmos, should be replaced with actual rating, date and location, overdue date and access expired date data
const EventItemContent = (props) => {
  const {
    eventTypeName,
    city,
    country,
    startDate,
    endDate,
    eventName,
    description,
    image, // TODO: after integration with litmos, image should part of the data
    config,
    combinedVisit, // rating availability;
    guidedReq, // overdue date availability;
    nameTag, // access expired date availability;
    noFlex = false,
    eventStartString
  } = props;

  const customConfig = config;

  const imgName = customConfig?.content?.img ? (image ? image : customConfig.content.img) : null;
  const userRatingCount = 1; // TODO: after integration with litmos, no of users rated event should part of the data
  const oneLineMax = 1;
  const twoLineMax = 1;
  const eventNameBrakeLine = 53;

  const getImageLabel = () => {
    // as we don't know yet how litmos will give data for events,
    // for now it is hardcoded only for seminars and roadshows
    if (config?.content?.markLabel && config.content.markLabel[eventTypeName]) {
      return {
        content: `${eventTypeName}`,
        ribbon: true,
        markcolor: `${config.content.markLabel[eventTypeName].markLabelBackgroundText}`,
        markcolortext: `${config.content.markLabel[eventTypeName].markLabelColorText}`
      };
    }
    return null;
  };

  const getImageSource = () => {
    if (image && image !== defaultThumbnail.name) {
      customConfig.content.notDefaultImg = true;
      return image;
    } else {
      customConfig.content.notDefaultImg = false;
      return require(`../../../assets/Images/${customConfig.content.img}`).default;
    }
  };

  const getContent = () => {
    switch (config?.name) {
      case 'catalog':
        return (
          <>
            {imgName && imgName !== 'undefined' ? (
              <>
                <CardContentImage config={customConfig} wrapped src={getImageSource()} />
                <StyledCardContent>
                  <CardContentContainer>
                    <CardContentEventProps>
                      <div>{eventTypeName}</div>
                      <ContentEventRating>
                        <Rating icon='star' maxRating={5} disabled />
                      </ContentEventRating>
                      <ContentEventRatingCount>
                        <div>({userRatingCount})</div>
                      </ContentEventRatingCount>
                    </CardContentEventProps>

                    <ContentDescriptionName namelength={eventName.length}>
                      <EvtNameHeader>{eventName}</EvtNameHeader>
                    </ContentDescriptionName>

                    <ContentDescription
                      desclength={description ? description.length : 0}
                      namelength={eventName.length}
                      name={eventName}
                    >
                      <Header as='h4'>
                        <DescriptionSubHeader
                          maxlines={
                            eventName && eventName.length > eventNameBrakeLine
                              ? oneLineMax
                              : twoLineMax
                          }
                        >
                          {description ? description : ''}
                        </DescriptionSubHeader>
                      </Header>
                    </ContentDescription>
                  </CardContentContainer>
                </StyledCardContent>
              </>
            ) : (
              <Card.Content textAlign='center' data-test-id={'eventItemContent'}>
                <CardContentGrid>
                  <Grid.Row centered>
                    <Header as='h3'>{eventName}</Header>
                  </Grid.Row>

                  <CardContentDescriptionContainer
                    centered
                    maxlines={
                      eventName && eventName.length > eventNameBrakeLine ? oneLineMax : twoLineMax
                    }
                  >
                    <Header as='h4' textAlign='center'>
                      <DescriptionSubHeader
                        maxlines={
                          eventName && eventName.length > eventNameBrakeLine
                            ? oneLineMax
                            : twoLineMax
                        }
                        inheritcolor='true'
                      >
                        {description ? description : ''}
                      </DescriptionSubHeader>
                    </Header>
                  </CardContentDescriptionContainer>
                </CardContentGrid>
              </Card.Content>
            )}
          </>
        );
      case 'training':
        return (
          <>
            <CardContentImage
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentTraining>
              <CardContentContainer>
                <TrainingContentDescriptionName namelength={eventName.length}>
                  <EvtNameHeader>{eventName}</EvtNameHeader>
                </TrainingContentDescriptionName>
                <TrainingCardContentEventProps>
                  {combinedVisit && (
                    <>
                      <TrainingContentEventRating>
                        <Rating icon='star' maxRating={5} disabled />
                      </TrainingContentEventRating>
                      <ContentEventRatingCount>
                        <div>({userRatingCount})</div>
                      </ContentEventRatingCount>
                    </>
                  )}

                  <TrainingCardHeaderDateColumn
                    computer={6}
                    tablet={5}
                    mobile={4}
                    floated='right'
                    textAlign='right'
                    textcolor={customConfig.content.dateTextColor}
                  >
                    <TextShrink>
                      {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                    </TextShrink>
                  </TrainingCardHeaderDateColumn>
                  <TrainingCardHeaderCalendarColumn
                    computer={2}
                    tablet={2}
                    mobile={1}
                    floated='right'
                    textcolor={customConfig.content.dateCalendarColor}
                  >
                    <Icon name='calendar alternate outline' />
                  </TrainingCardHeaderCalendarColumn>
                </TrainingCardContentEventProps>

                <TrainingLocationDatesContainer>
                  <TrainingLocationContainer color={customConfig.content.locationColor}>
                    <TrainingCityText>{city}</TrainingCityText>
                    <TextShrink>{country}</TextShrink>
                  </TrainingLocationContainer>
                  <TrainingExpDates justify={nameTag} align={nameTag}>
                    {guidedReq && (
                      <TrainingOverdueText
                        color={config.content.overdueTextColor}
                        background={config.content.overdueTextBackground}
                        radius={config.content.overdueExpTextCornerRadius}
                      >
                        {Localize.get('App.overdue').toUpperCase()}{' '}
                        {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                      </TrainingOverdueText>
                    )}
                    {nameTag && (
                      <TrainingExpText
                        color={config.content.accessExpTextColor}
                        background={config.content.accessExpTextBackground}
                        radius={config.content.overdueExpTextCornerRadius}
                      >
                        {Localize.get('Training.accessExp').toUpperCase()}{' '}
                        {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                      </TrainingExpText>
                    )}
                  </TrainingExpDates>
                </TrainingLocationDatesContainer>
              </CardContentContainer>
            </StyledCardContentTraining>
          </>
        );
      case 'myArea':
        return (
          <>
            <PlainCardContentImage
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentPlainTraining>
              <CardContentContainer>
                <PlainTrainingContentDescriptionName namelength={eventName.length}>
                  <EvtNameHeader>{eventName}</EvtNameHeader>
                </PlainTrainingContentDescriptionName>
                <PlainTrainingCardContentEventProps>
                  <TrainingCardHeaderDateColumn
                    computer={6}
                    tablet={5}
                    mobile={4}
                    floated='left'
                    textAlign='left'
                    textcolor={customConfig.content.dateTextColor}
                  >
                    <TextShrink>{formatDateTimeStringToDateTimeObject(startDate)}</TextShrink>
                  </TrainingCardHeaderDateColumn>
                </PlainTrainingCardContentEventProps>
              </CardContentContainer>
            </StyledCardContentPlainTraining>
          </>
        );
      case 'trainerArea':
        return (
          <>
            <PlainCardContentImage
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentPlainTraining>
              <CardContentContainer>
                <PlainTrainingContentDescriptionName namelength={eventName.length}>
                  <EvtNameHeader>{eventName}</EvtNameHeader>
                </PlainTrainingContentDescriptionName>
                <PlainTrainingCardContentEventProps>
                  <TrainingCardHeaderDateColumn
                    computer={6}
                    tablet={5}
                    mobile={4}
                    floated='left'
                    textAlign='left'
                    textcolor={customConfig.content.dateTextColor}
                  >
                    <TextShrink>
                      {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                    </TextShrink>
                  </TrainingCardHeaderDateColumn>
                  <TrainingCardHeaderCalendarColumn
                    computer={2}
                    tablet={2}
                    mobile={1}
                    floated='right'
                    textcolor={customConfig.content.dateCalendarColor}
                  >
                    {momentFormatDateDMYSlashDelimiter(eventStartString)}
                    <Icon name='calendar alternate outline' />
                  </TrainingCardHeaderCalendarColumn>
                </PlainTrainingCardContentEventProps>

                <PlainTrainingLocationContainer color={customConfig.content.locationColor}>
                  <TrainingCityText>{city}</TrainingCityText>
                  <TextShrink>{country}</TextShrink>
                </PlainTrainingLocationContainer>
              </CardContentContainer>
            </StyledCardContentPlainTraining>
          </>
        );
      case 'allSessions':
        return (
          <>
            {imgName && imgName !== 'undefined' ? (
              <>
                <CardContentImage config={customConfig} wrapped src={getImageSource()} />
                <StyledCardContent>
                  <CardContentContainer>
                    <CardContentEventProps>
                      {eventTypeName ? Localize.get(`TrainerArea.${eventTypeName}`) : null}
                    </CardContentEventProps>

                    <ContentDescriptionName namelength={eventName.length}>
                      <EvtNameHeader>{eventName}</EvtNameHeader>
                    </ContentDescriptionName>
                    <ListViewDateContainer
                      textcolor={customConfig.content.dateTextColor}
                      noFlex={noFlex}
                    >
                      {startDate && endDate
                        ? formatDateDMY(formatLongDateStringToDateObject(startDate)) +
                          '-' +
                          formatDateDMY(formatLongDateStringToDateObject(endDate))
                        : ''}
                      {startDate && endDate ? (
                        <ListViewCourseCalendarIcon name='calendar alternate outline' />
                      ) : (
                        ''
                      )}
                    </ListViewDateContainer>
                    <ContentDescription
                      desclength={description ? description.length : 0}
                      namelength={eventName.length}
                      name={eventName}
                    >
                      <Header as='h4'>
                        <DescriptionSubHeader
                          maxlines={
                            eventName && eventName.length > eventNameBrakeLine
                              ? oneLineMax
                              : twoLineMax
                          }
                        >
                          {description ? description : ''}
                        </DescriptionSubHeader>
                      </Header>
                    </ContentDescription>
                  </CardContentContainer>
                </StyledCardContent>
              </>
            ) : (
              <Card.Content textAlign='center' data-test-id={'eventItemContent'}>
                <CardContentGrid>
                  <Grid.Row centered>
                    <Header as='h3'>{eventName}</Header>
                  </Grid.Row>
                  {startDate && endDate && (
                    <ListViewDateContainer textcolor={customConfig.content.dateTextColor}>
                      {formatDateDMY(formatLongDateStringToDateObject(startDate)) +
                        '-' +
                        formatDateDMY(formatLongDateStringToDateObject(endDate))}
                      <ListViewCourseCalendarIcon name='calendar alternate outline' />
                    </ListViewDateContainer>
                  )}
                  <CardContentDescriptionContainer
                    centered
                    maxlines={
                      eventName && eventName.length > eventNameBrakeLine ? oneLineMax : twoLineMax
                    }
                  >
                    <Header as='h4' textAlign='center'>
                      <DescriptionSubHeader
                        maxlines={
                          eventName && eventName.length > eventNameBrakeLine
                            ? oneLineMax
                            : twoLineMax
                        }
                        inheritcolor='true'
                      >
                        {description ? description : ''}
                      </DescriptionSubHeader>
                    </Header>
                  </CardContentDescriptionContainer>
                </CardContentGrid>
              </Card.Content>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return getContent();
};

export default EventItemContent;
