import { useState, useEffect } from 'react';

import Logger from '@common/Logger.js';

import useIsMounted from '../IsMounted/isMounted';

function useFetching(fetchFn, fetchOnQ = false, withoutLoadingOnMount = false) {
  const setStateIfMounted = useIsMounted();
  const [shouldFetch, setShouldFetch] = useState(!fetchOnQ);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(!withoutLoadingOnMount && !fetchOnQ);

  useEffect(() => {
    async function asyncFetch() {
      if (shouldFetch) {
        try {
          setLoading(true);
          fetchFn()
            .then((response) => {
              if (response && response instanceof Array && response.length) {
                setStateIfMounted(setData, response);
              } else if (response && response.data) {
                if (response.data.d) {
                  setStateIfMounted(setData, response.data.d);
                } else if (response.data.root) {
                  setStateIfMounted(setData, response.data.root);
                } else {
                  setStateIfMounted(setData, response);
                }
              }
            })
            .catch(function () {
              setStateIfMounted(setData, null);
            })
            .finally(() => {
              setStateIfMounted(setLoading, false);
              setStateIfMounted(setShouldFetch, false);
            });
        } catch (e) {
          Logger.error(e);
        }
      }
    }
    asyncFetch();
  }, [shouldFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, loading, setLoading, setShouldFetch.bind(null, true), setData];
}

export default useFetching;
