import React from 'react';

// semantic
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab';

import style from '@pages/MyArea/style/MyArea.module.scss';

import useMyAreaIltSessionLogic from './hooks/useMyAreaIltSessionLogic';

const IltSessions = (props) => {
  const { contextData } = props;

  const { activeTabIndex, panes, handleIltSessionsTabsChange } =
    useMyAreaIltSessionLogic(contextData);

  return (
    <Tab
      data-testid='IltSessions'
      className={style.tabbedContent}
      activeIndex={activeTabIndex}
      panes={panes}
      menu={{
        attached: false,
        widths: 3,
        stackable: true
      }}
      onTabChange={handleIltSessionsTabsChange}
    />
  );
};

export default IltSessions;
