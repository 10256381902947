/* eslint-disable complexity */
import React from 'react';
import Localize from 'react-intl-universal';
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';

// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import { courseDescriptionType } from '@common/Constants';
import * as globalConstants from '@common/Constants';
import * as countriesCodes from '@common/Countries';
import * as routerConstants from '@navigation/Constants';
import CourseDescription from '@pages/CourseDetails/Components/CourseDescription';
import DetailsMenuItems from '@pages/EventDetails/DetailsMenuItems';
import styles from '@pages/EventDetails/style/EventDetails.module.scss';
import '../RegistrationCourse/style/RegistrationCourse.module.scss';

import RegistrationCourse from '../RegistrationCourse/RegistrationCourse';

const CourseDetailsDesktop = ({
  course,
  config,
  disableRegistrationButton,
  isLoading,
  setShowConfirmationPage,
  participantDetails,
  setParticipantDetails,
  handleActiveComponent,
  handleRegisterButtonClick,
  handleItemClick,
  registrationActive,
  activeItem,
  courseDetailsType,
  history,
  setRegistrationResponse,
  setIsResponseStatusLoading
}) => {
  let previousPath = '';
  let previousPathName = '';

  if (history?.location?.state?.from) {
    previousPathName = history.location.state.from;
    previousPath = history.location.state.path;
  }

  const handleCourseContentClick = () => {
    if (course?.EXTERNAL_LINK !== null) {
      window.open(course.EXTERNAL_LINK, '_blank');
    }
  };

  return (
    <Grid
      columns={1}
      centered
      relaxed
      className={`tablet computer only ${styles.detailsPageContainer}`}
      stackable
    >
      {isLoading && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}

      {/* desktop && tablet layout - message*/}
      <Grid.Row
        only='tablet computer'
        className={styles.girdRowContainer}
        style={{
          background: config?.configuration?.CourseDetailsMessage.background
        }}
      >
        <Grid.Column computer={12} largeScreen={12} widescreen={12} tablet={12}>
          {/* desktop && tablet layout - link*/}
          {courseDetailsType === globalConstants.courseDetailsType.assigned &&
            previousPath &&
            previousPathName && (
              <Grid.Row only='tablet computer'>
                <Grid.Column computer={14} tablet={14} widescreen={14} textAlign='right'>
                  <Form.Group className={styles.linkContainer}>
                    <Form.Field className={styles.linkPart}>
                      <Link
                        to={{
                          pathname: routerConstants.routes.path.myarea,
                          state: {
                            from: Localize.get('CourseDetails.courseDetails')
                          }
                        }}
                      >
                        {Localize.get('AppNavBar.myarea')} /
                      </Link>
                    </Form.Field>
                    <Form.Field className={styles.linkPart}>
                      <Link
                        to={{
                          pathname: previousPath,
                          state: {
                            from: previousPathName,
                            path: previousPath,
                            activeIndex: 1
                          }
                        }}
                      >
                        {previousPathName} /
                      </Link>
                    </Form.Field>
                    <Form.Field>{Localize.get('CourseDetails.details')}</Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            )}
          <Message
            data-testid={'courseDetailsMessage'}
            style={{
              background: config?.configuration?.CourseDetailsMessage.background,
              boxShadow: config?.configuration?.CourseDetailsMessage.boxShadow
            }}
          >
            <Message.Header className={styles.messageHeaderStyle}>
              {course.DESCRIPTION}
            </Message.Header>
            <Message.Content className={styles.messageHeaderContainer}>
              <Grid relaxed centered stackable className={styles.messageHeaderContainerGrid}>
                <Grid.Column width={14} className={styles.messageCourseTypeContainer}>
                  {course.COURSE_TYPE_NAME &&
                    Localize.get(`CourseDetails.${course.COURSE_TYPE_NAME}`)}
                  <Flag
                    className={styles.messageFlag}
                    name={
                      course && course.COUNTRY_NAME
                        ? countriesCodes.countryISOCodeByCountry[course.COUNTRY_NAME.toLowerCase()]
                        : ''
                    }
                  />
                  {(course?.COURSE_PREREQUISITES?.COURSES.length > 0 ||
                    course?.COURSE_PREREQUISITES?.LEARNING_PATHS.length > 0) && (
                    <>
                      <Icon name='info' /> {Localize.get('CourseDetails.prerequisiteInfoMessage')}
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={2} className={styles.registerButtonContainer}>
                  {courseDetailsType === globalConstants.courseDetailsType.unassigned ? (
                    <Button
                      color={config?.configuration?.Registration?.stepperButtonColor}
                      content={
                        registrationActive
                          ? Localize.get('App.close')
                          : Localize.get('App.register')
                      }
                      floated='right'
                      className={styles.registerButton}
                      onClick={handleRegisterButtonClick}
                      disabled={disableRegistrationButton}
                    />
                  ) : (
                    <Grid centered stackable>
                      {config?.configuration?.CourseDetailsContent?.showOpenCourseContent && (
                        <Grid.Column width={8}>
                          <Button
                            color={config?.configuration?.Registration?.stepperButtonColor}
                            content={Localize.get('CourseDetails.openCourseContent')}
                            floated='right'
                            className={styles.registerButton}
                            onClick={handleCourseContentClick}
                            disabled={false}
                          />
                        </Grid.Column>
                      )}
                      {config?.configuration?.CourseDetailsContent?.showDeregisterFromCourse && (
                        <Grid.Column width={8}>
                          {/*TO DO: DeRegistration process*/}
                          <Button
                            color={config?.configuration?.Registration?.stepperButtonColor}
                            content={Localize.get('CourseDetails.deregisterFromCourse')}
                            floated='right'
                            className={styles.registerButton}
                            disabled={true}
                          />
                        </Grid.Column>
                      )}
                    </Grid>
                  )}
                </Grid.Column>
              </Grid>
            </Message.Content>
            <Message.Content className={styles.messageHeaderContainer}>
              <CourseDescription
                description={course.COURSE_DESCRIPTION}
                type={courseDescriptionType.course}
              />
            </Message.Content>
          </Message>
        </Grid.Column>
      </Grid.Row>
      {!registrationActive &&
        config?.size?.device &&
        [globalConstants.deviceSize.computer, globalConstants.deviceSize.tablet].includes(
          config.size.device
        ) && (
          <>
            <Grid.Row
              className={styles.girdRowContainer}
              style={{
                background: config?.configuration?.CourseDetailsMessage.background
              }}
            >
              <Grid.Column computer={12} largeScreen={12} widescreen={12} tablet={12}>
                <Menu
                  pointing
                  secondary
                  className={styles.menuTabletResolution}
                  style={{
                    background: config?.configuration?.CourseDetailsContent?.menuItemBackground
                  }}
                >
                  <DetailsMenuItems
                    activeItem={activeItem}
                    handleItemClick={handleItemClick}
                    showIcon={config.size.device === globalConstants.deviceSize.computer}
                    entity={'course'}
                    showItem={{
                      overview: config?.configuration?.CourseDetailsContent?.showOverviewTab,
                      agenda: config?.configuration?.CourseDetailsContent?.showAgendaTab,
                      information:
                        course?.COURSE_PREREQUISITES?.COURSES.length > 0 ||
                        course?.COURSE_PREREQUISITES?.LEARNING_PATHS.length > 0,
                      attachments: config?.configuration?.CourseDetailsContent?.showAttachmentsTab,
                      notes: config?.configuration?.CourseDetailsContent?.showNotes
                    }}
                  />
                </Menu>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                style={{
                  background: config?.configuration?.CourseDetailsContent.background
                }}
                computer={12}
                largeScreen={12}
                widescreen={12}
                tablet={12}
              >
                <Grid columns={1} centered>
                  {handleActiveComponent(activeItem)}
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      {registrationActive &&
        config?.size?.device &&
        [globalConstants.deviceSize.computer, globalConstants.deviceSize.tablet].includes(
          config.size.device
        ) && (
          <Grid.Row
            style={{
              background: config?.configuration?.CourseDetailsContent.background
            }}
          >
            <Grid.Column computer={12} largeScreen={12} widescreen={12} tablet={12}>
              <RegistrationCourse
                course={course}
                setShowConfirmationPage={setShowConfirmationPage}
                config={config}
                participantDetails={participantDetails}
                setParticipantDetails={setParticipantDetails}
                setRegistrationResponse={setRegistrationResponse}
                setIsResponseStatusLoading={setIsResponseStatusLoading}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      <ScrollToTop
        smooth
        color={config?.configuration?.CourseDetailsContent?.scrollUpButton?.fill}
        viewBox={config?.configuration?.ScrollUpButton?.viewBox}
        svgPath={config?.configuration?.ScrollUpButton?.svgPath}
        style={{
          backgroundColor:
            config?.configuration?.CourseDetailsContent?.scrollUpButton?.backgroundColor
        }}
      />
    </Grid>
  );
};

export default CourseDetailsDesktop;
