import Localize from 'react-intl-universal';
import { Link } from 'react-router-dom';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import { entityTypes } from '@common/Constants';
import * as countriesCodes from '@common/Countries';
import DetailsMenuMobile from '@pages/EventDetails/DetailsMenuMobile';
import styles from '@pages/EventDetails/style/EventDetails.module.scss';

import LearningPathDescription from './LearningPathDescription';

const LearningPathDetailsMobile = ({
  learningPath = {},
  contextData = {},
  handleActiveComponent = null,
  activeItem = null,
  setActiveItem = null
}) => {
  let previousPath = '';
  let previousPathName = '';

  const handleTabClick = (e, data) => {
    setActiveItem && setActiveItem(data.name);
  };

  if (history?.location?.state?.from) {
    previousPathName = history.location.state.from + ' /';
    previousPath = history.location.state.path;
  }

  return (
    <Grid
      style={{ width: '100%' }}
      centered
      container
      className={`mobile only ${styles.gridMobileTop}`}
    >
      {/* mobile layout - link */}
      <Grid.Row className={styles.navLinkContainerMobile}>
        <Link
          to={{
            pathname: previousPath,
            state: {
              from: previousPath,
              path: previousPath
            }
          }}
        >
          {previousPathName}
        </Link>
        <div className={styles.mobileLinkHolder}>{Localize.get('EventDetails.detailsInfo')}</div>
      </Grid.Row>

      <Grid.Row className={styles.messageMobileContainer}>
        <Message
          data-testid='learningPathDetailsMobileMessage'
          style={{
            background: contextData?.configuration?.LearningPathDetailsMessage.background,
            boxShadow: contextData?.configuration?.LearningPathDetailsMessage.boxShadow
          }}
        >
          <Message.Header className={styles.messageHeaderStyle}>
            {learningPath?.NAME}
          </Message.Header>
          <Message.Content className={styles.messageHeaderContainer}>
            <Grid relaxed centered stackable className={styles.messageHeaderContainerGrid}>
              <Grid.Column className={styles.messageCourseTypeContainer}>
                {Localize.get('LearningPathDetails.title')}
                <Flag
                  className={styles.messageFlag}
                  name={
                    learningPath && learningPath?.COURSES[0]?.COUNTRY_NAME
                      ? countriesCodes.countryISOCodeByCountry[
                          learningPath?.COURSES[0]?.COUNTRY_NAME.toLowerCase()
                        ]
                      : ''
                  }
                />
                {(learningPath?.COURSES[0]?.COURSE_PREREQUISITES?.COURSES.length > 0 ||
                  learningPath?.COURSES[0]?.COURSE_PREREQUISITES?.LEARNING_PATHS.length > 0) && (
                  <>
                    <Icon name='info' />
                    {Localize.get('LearningPathDetails.requiredPrerequisitesMessage')}
                  </>
                )}
              </Grid.Column>
            </Grid>
          </Message.Content>
          <Message.Content className={styles.messageHeaderContainer}>
            <LearningPathDescription description={learningPath?.COURSES[0]?.COURSE_DESCRIPTION} />
          </Message.Content>
        </Message>
      </Grid.Row>

      <Grid.Row>
        {contextData?.configuration?.LearningPathDetailsContent?.showAssignment && (
          <Grid.Column width={8}>
            <Button
              color={contextData?.configuration?.Registration?.stepperButtonColor}
              content={Localize.get('LearningPathDetails.assignment')}
              floated='right'
              className={styles.registerButton}
            />
          </Grid.Column>
        )}
        {contextData?.configuration?.LearningPathDetailsContent?.showRegistration && (
          <Grid.Column width={8}>
            <Button
              color={contextData?.configuration?.Registration?.stepperButtonColor}
              content={Localize.get('LearningPathDetails.registration')}
              floated='left'
              className={styles.registerButton}
            />
          </Grid.Column>
        )}
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={14} stretched className={styles.menuMobileCenter}>
          <DetailsMenuMobile
            activeItem={activeItem}
            handleItemClick={handleTabClick}
            entity={entityTypes.learningPath}
          />
        </Grid.Column>
      </Grid.Row>

      <Grid style={{ width: '100%' }}>
        <Grid.Column>{handleActiveComponent && handleActiveComponent(activeItem)}</Grid.Column>
      </Grid>
    </Grid>
  );
};

export default LearningPathDetailsMobile;
