import React from 'react';
import Localize from 'react-intl-universal';

import { Rating } from 'semantic-ui-react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import { defaultThumbnail } from '@common/Constants';
import { formatDateDMY, formatLongDateStringToDateObject } from '@common/Util';
import {
  CardImageListView,
  StyledCardContentListView,
  CardContentListViewGrid,
  ListViewNameRow,
  EvtNameHeader,
  ListViewFavColumn,
  FooterStyledIcon,
  FooterFavorite,
  FooterShare,
  ListViewDetailRow,
  ListViewDetailColumn,
  ListViewDateContainer,
  TextShrink,
  CardPriceLabel,
  ListViewCourseCalendarIcon,
  CardDescriptionName,
  ListViewRatingContainer,
  ListViewRatingCount,
  ListViewProgress,
  StyledProgress,
  FooterProgressPercentage
} from '@components/StyledComponents/EventItem.styled';

const CourseItemContent = (props) => {
  const {
    config,
    courseTypeName,
    courseName,
    locationInfo,
    startDate,
    endDate,
    price,
    image,
    courseTitle,
    progress
  } = props;

  const userRatingCount = 1; // TODO: after integration with Litmos, no of users rated event should part of the data

  const [favIcon, setFavIcon] = React.useState(
    config?.footer?.favIconSelected ? config?.footer?.favIconSelected : 'heart outline'
  );
  const customConfig = config;

  const onFavIconClick = (e) => {
    e.stopPropagation();
    if (favIcon.includes('outline')) {
      setFavIcon(config?.footer?.favIcon ? config?.footer?.favIcon : 'heart');
    } else {
      setFavIcon(
        config?.footer?.favIconSelected ? config?.footer?.favIconSelected : 'heart outline'
      );
    }

    // TODO after integration with litmos
    // update users favorite courses
  };

  const getImageLabel = () => {
    // as for now Litmos is giving image data inside details service for Course and that is big resource consumption,
    // for now it is hardcoded only for ELearnings
    if (
      config?.content?.showBanner &&
      config?.content?.markLabel &&
      config.content.markLabel[courseTypeName]
    ) {
      return {
        content: Localize.get(`CourseDetails.${courseTypeName}`),
        ribbon: true,
        markcolor: `${config.content.markLabel[courseTypeName].markLabelBackgroundText}`,
        markcolortext: `${config.content.markLabel[courseTypeName].markLabelColorText}`
      };
    }
    return null;
  };

  const getImageSource = () => {
    if (image && image !== defaultThumbnail.name) {
      config.content.notDefaultImg = true;
      return image;
    } else {
      config.content.notDefaultImg = false;
      return require(`../../../assets/Images/${config.content.img}`).default;
    }
  };

  const getContent = () => {
    switch (config.name) {
      case 'catalog':
        return (
          <>
            <CardImageListView
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentListView data-test-id={'courseListViewContent'}>
              <CardContentListViewGrid stretched>
                <ListViewNameRow>
                  <Grid.Column floated='left' computer={15} tablet={14}>
                    <CardDescriptionName namelength={courseName.length}>
                      <EvtNameHeader
                        config={config}
                        data-text={courseName}
                        namelength={courseName.length}
                        itemcontent='list'
                      >
                        {courseName}
                      </EvtNameHeader>
                    </CardDescriptionName>
                  </Grid.Column>
                  <ListViewFavColumn floated='right' computer={1} tablet={2}>
                    <FooterFavorite visible={config?.content?.showFavouriteIcon}>
                      <FooterStyledIcon
                        config={customConfig}
                        name={favIcon}
                        onClick={(evt) => onFavIconClick(evt)}
                      />
                    </FooterFavorite>
                    <FooterShare visible={config?.content?.showShareIcon}>
                      <FooterStyledIcon config={customConfig} name='share alternate' />
                    </FooterShare>
                  </ListViewFavColumn>
                </ListViewNameRow>
                <ListViewDetailRow>
                  {config?.content?.showDate && (
                    <ListViewDetailColumn
                      computer={5}
                      tablet={6}
                      largeScreen={5}
                      widescreen={5}
                      verticalAlign='middle'
                    >
                      <ListViewDateContainer textcolor={customConfig.content.dateTextColor}>
                        {startDate && endDate
                          ? formatDateDMY(formatLongDateStringToDateObject(startDate)) +
                            '-' +
                            formatDateDMY(formatLongDateStringToDateObject(endDate))
                          : ''}
                        {startDate && endDate ? (
                          <ListViewCourseCalendarIcon name='calendar alternate outline' />
                        ) : (
                          ''
                        )}
                      </ListViewDateContainer>
                    </ListViewDetailColumn>
                  )}
                  {price && price.length && (
                    <ListViewDetailColumn
                      computer={2}
                      tablet={2}
                      largeScreen={2}
                      widescreen={2}
                      verticalAlign='middle'
                    >
                      <CardPriceLabel basic color='orange' size='large'>
                        {price}
                      </CardPriceLabel>
                    </ListViewDetailColumn>
                  )}
                </ListViewDetailRow>
                <ListViewDetailRow>
                  <ListViewDetailColumn width={3}>
                    {config?.content?.showMarkLabelInTile && (
                      <TextShrink>{Localize.get(`CourseDetails.${courseTypeName}`)}</TextShrink>
                    )}
                  </ListViewDetailColumn>
                  {config?.content?.showLocation && (
                    <ListViewDetailColumn width={13}>
                      <TextShrink>{locationInfo}</TextShrink>
                    </ListViewDetailColumn>
                  )}
                </ListViewDetailRow>
              </CardContentListViewGrid>
            </StyledCardContentListView>
          </>
        );
      case 'training':
        return (
          <>
            <CardImageListView
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentListView data-test-id={'eventListViewContent'}>
              <CardContentListViewGrid stretched>
                <ListViewNameRow>
                  <Grid.Column floated='left' computer={15} tablet={14}>
                    <EvtNameHeader>{courseTitle}</EvtNameHeader>
                  </Grid.Column>
                  <ListViewFavColumn floated='right' computer={1} tablet={2}>
                    <FooterFavorite visible={config?.content?.showFavouriteIcon}>
                      <FooterStyledIcon
                        config={customConfig}
                        name={favIcon}
                        onClick={(evt) => onFavIconClick(evt)}
                      />
                    </FooterFavorite>
                    <FooterShare visible={config?.content?.showShareIcon}>
                      <FooterStyledIcon config={customConfig} name='share alternate' />
                    </FooterShare>
                  </ListViewFavColumn>
                </ListViewNameRow>
                <ListViewDetailRow>
                  {config?.content?.showRate && (
                    <Grid.Column computer={3} tablet={4} largeScreen={3} widescreen={3}>
                      <ListViewRatingContainer>
                        <Rating icon='star' maxRating={5} disabled />
                        <ListViewRatingCount>({userRatingCount})</ListViewRatingCount>
                      </ListViewRatingContainer>
                    </Grid.Column>
                  )}
                  <ListViewDetailColumn
                    computer={3}
                    tablet={4}
                    largeScreen={2}
                    widescreen={2}
                    verticalAlign='middle'
                  ></ListViewDetailColumn>
                  <ListViewDetailColumn
                    computer={3}
                    tablet={4}
                    largeScreen={3}
                    widescreen={3}
                    verticalAlign='middle'
                  ></ListViewDetailColumn>
                </ListViewDetailRow>
                <ListViewDetailRow>
                  {config?.content?.showMarkLabelInTile && (
                    <div>{Localize.get(`CourseDetails.${courseTypeName}`)}</div>
                  )}
                  <ListViewDetailColumn width={16}>
                    <ListViewProgress visible={true}>
                      <StyledProgress size='tiny' percent={progress} />
                      <FooterProgressPercentage>{`${Math.floor(
                        progress
                      )}%`}</FooterProgressPercentage>
                    </ListViewProgress>
                  </ListViewDetailColumn>
                </ListViewDetailRow>
              </CardContentListViewGrid>
            </StyledCardContentListView>
          </>
        );
      default:
        return null;
    }
  };

  return getContent();
};

export default CourseItemContent;
