import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import EventItem from '@components/EventGridListView/EventGridItem/EventItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import {
  EventListContainer,
  EventListItemStyled
} from '@components/StyledComponents/EventList.styled';

const EventList = (props) => {
  const { events, onItemClick, configuration, listColumnCount, noFlex, infoType } = props;

  return (
    <>
      <Grid stackable columns={listColumnCount ? listColumnCount : 3}>
        <EventListContainer stretched>
          {events && events.length ? (
            events.map((evt, index) => (
              <EventListItemStyled key={index}>
                <EventItem
                  config={configuration}
                  key={evt.ID}
                  eventId={evt.ID}
                  eventTypeName={evt.EVENT_TYPE_NAME}
                  eventName={evt.DESCRIPTION}
                  startDate={evt.EVENT_START}
                  eventStartString={evt.EVENT_START_STRING}
                  endDate={evt.END_DATE}
                  city={evt.CITY}
                  langId={evt.COUNTRY_NAME?.toLowerCase()}
                  description={evt.EVENT_DESCRIPTION}
                  price={
                    evt.EVENT_PRICE_LATEST ? parseFloat(evt.EVENT_PRICE_LATEST).toFixed(2) : ''
                  }
                  currency={evt.EVENT_PRICE_CURRENCY ? evt.EVENT_PRICE_CURRENCY : ''}
                  country={evt.COUNTRY_NAME}
                  onItemClick={onItemClick}
                  combinedVisit={evt.COMBINED_VISIT}
                  guidedReq={evt.GUIDE_REQUIRED}
                  nameTag={evt.NAME_TAG_PRINTING}
                  image={evt.EVENT_THUMBNAIL}
                  noFlex={noFlex}
                />
              </EventListItemStyled>
            ))
          ) : (
            <NoContentComponent
              data-test-id={'eventListNoContent'}
              infoType={infoType ? infoType : Localize.get('Catalog.noEvents')}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </Grid>
    </>
  );
};

export default EventList;
