/* eslint-disable complexity */
import React from 'react';
import Localize from 'react-intl-universal';
import ScrollToTop from 'react-scroll-to-top';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import * as globalConstants from '@common/Constants';
import * as countriesCodes from '@common/Countries';
import styles from '@pages/EventDetails/style/EventDetails.module.scss';

import ConfirmationPage from '../RegistrationCourse/components/ConfirmationPage';

const ConfirmationPageDesktop = ({
  course,
  config,
  isLoading,
  setShowConfirmationPage,
  participantDetails,
  setParticipantDetails,
  setRegistrationActive,
  registrationResponse
}) => {
  return (
    <Grid
      columns={1}
      centered
      relaxed
      className={`tablet computer only ${styles.detailsPageContainer}`}
      stackable
    >
      {isLoading && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}

      {/* desktop && tablet layout*/}
      <Grid.Row
        only='tablet computer'
        style={{
          background: config?.configuration?.CourseDetailsMessage.background
        }}
      >
        <Grid.Column computer={12} largeScreen={12} widescreen={12} tablet={12}>
          <Message
            data-testid={'courseDetailsConfirmationMessage'}
            style={{
              background: config?.configuration?.CourseDetailsMessage.background,
              boxShadow: config?.configuration?.CourseDetailsMessage.boxShadow
            }}
          >
            <Message.Header className={styles.messageHeaderStyle}>
              <h2>{`${Localize.get('RegistrationForm.thankYouForBooking')}`}</h2>
            </Message.Header>

            <Message.Header className={styles.messageContentContainer}>
              {course.DESCRIPTION}
            </Message.Header>

            <Message.Content className={styles.messageHeaderContainer}>
              <Grid relaxed className={styles.messageHeaderContainerGrid}>
                <Grid.Column width={14} className={styles.messageCourseTypeContainer}>
                  {course.COURSE_TYPE_NAME &&
                    Localize.get(`CourseDetails.${course.COURSE_TYPE_NAME}`)}
                  <Flag
                    className={styles.messageFlag}
                    name={
                      course && course.COUNTRY_NAME
                        ? countriesCodes.countryISOCodeByCountry[course.COUNTRY_NAME.toLowerCase()]
                        : ''
                    }
                  />
                </Grid.Column>
                <Grid.Column width={2} className={styles.registerButtonContainer} />
              </Grid>
            </Message.Content>
          </Message>
        </Grid.Column>
      </Grid.Row>
      {(config?.size?.device === globalConstants.deviceSize.computer ||
        config?.size?.device === globalConstants.deviceSize.tablet) && (
        <Grid.Row>
          <Grid.Column computer={12} largeScreen={12} widescreen={12} tablet={12}>
            <ConfirmationPage
              courseTypeName={course.COURSE_TYPE_NAME}
              setShowConfirmationPage={setShowConfirmationPage}
              config={config}
              participantDetails={participantDetails}
              setParticipantDetails={setParticipantDetails}
              setRegistrationActive={setRegistrationActive}
              courseId={course.id}
              registrationResponse={registrationResponse}
            />
          </Grid.Column>
        </Grid.Row>
      )}

      <ScrollToTop
        smooth
        color={config?.configuration?.CourseDetailsContent?.scrollUpButton?.fill}
        viewBox={config?.configuration?.ScrollUpButton?.viewBox}
        svgPath={config?.configuration?.ScrollUpButton?.svgPath}
        style={{
          backgroundColor:
            config?.configuration?.CourseDetailsContent?.scrollUpButton?.backgroundColor
        }}
      />
    </Grid>
  );
};

export default ConfirmationPageDesktop;
