import { format, isValid } from 'date-fns';

import { getService, postService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createManagerEmployeesWithCoursesService = (email) => {
  const path =
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.managerEmployeesWithCourses}` +
    `${email}`;

  return getService(path);
};

export const createRegistrationForCoursesService = (courseId, participantDetails) => {
  const path = `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.registerToCourse}${courseId}`;

  const iltSessions = participantDetails.iltModulesSessions.map(({ iltSession }, index) => {
    return {
      sessionId: iltSession.id,
      hotelRequired: participantDetails.hotelsRequired[index],
      arrivalDate: isValid(participantDetails?.dates[index]?.arrivalDate)
        ? format(participantDetails.dates[index].arrivalDate, 'yyyy-MM-dd')
        : '',
      departureDate: isValid(participantDetails?.dates[index]?.departureDate)
        ? format(participantDetails?.dates[index]?.departureDate, 'yyyy-MM-dd')
        : ''
    };
  });

  const additionalParticipants = participantDetails.participants.map(
    ({ participantInfo, iltModulesSessions, hotelsRequired, dates }) => {
      return {
        employeeId: participantInfo.employeeId,
        userName: participantInfo.userName,
        iltSessions: iltModulesSessions.map(({ iltSession }, index) => {
          return {
            sessionId: iltSession.id,
            hotelRequired: hotelsRequired[index],
            arrivalDate: isValid(dates[index]?.arrivalDate)
              ? format(dates[index].arrivalDate, 'yyyy-MM-dd')
              : '',
            departureDate: isValid(dates[index]?.departureDate)
              ? format(dates[index].departureDate, 'yyyy-MM-dd')
              : ''
          };
        })
      };
    }
  );

  return postService(path, [
    {
      participantId: participantDetails.participantId,
      userName: participantDetails.userName,
      iltSessions
    },
    ...additionalParticipants
  ]);
};
