import React from 'react';
import Localize from 'react-intl-universal';

import LegalNoticeDeConfig from '../../config/legalNoticeDe.config';

const LegalNotice = () => {
  const configByLocale = {
    // en: LegalNoticeEnConfig, // TO DO: uncomment when legalNoticeEn.config is added
    de: <LegalNoticeDeConfig />
  };

  const legalNoticeConfig = configByLocale[Localize.options.currentLocale] || (
    <LegalNoticeDeConfig />
  );

  return (
    <div data-testid={'LegalNotice'} style={{ paddingLeft: '1rem' }}>
      {legalNoticeConfig}
    </div>
  );
};
export default LegalNotice;
