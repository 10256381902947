import React from 'react';

// semantic
import Placeholder from 'semantic-ui-react/dist/commonjs/elements/Placeholder';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

const LoadingPlaceholder = (props) => {
  const { needImagePlaceHolder, styleName } = props;

  return (
    <Segment className={styleName ? styleName : ''}>
      <Placeholder>
        <Placeholder.Header data-test-id={'imgLoadingPlaceholder'} image={needImagePlaceHolder}>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Segment>
  );
};

export default LoadingPlaceholder;
