import { BACKEND_FILTER_VALUES } from '@config/filterOperators';

export const formatSortingFieldName = (sortField) => {
  let formattedSortField = null;
  if (sortField != null) {
    switch (sortField) {
      case 'employee':
        formattedSortField = 'fullname';
        break;
      case 'course':
        formattedSortField = 'coursename';
        break;
      case 'registrationDate':
        formattedSortField = 'assigneddate';
        break;
      case 'status':
        formattedSortField = 'status';
        break;
      case 'progress':
        formattedSortField = 'percentagecomplete';
        break;
      case 'completedDate':
        formattedSortField = 'datecompleted';
        break;
      case 'price':
        formattedSortField = 'price';
        break;
      default:
        break;
    }
  }
  return formattedSortField;
};

export const formatDateFieldValue = (fieldValue) => {
  if (fieldValue) {
    return fieldValue.replace('T', ' ') + ':00';
  } else {
    return null;
  }
};

export const formatEmployeesFilter = (filterObject) => {
  if (
    filterObject?.field &&
    (filterObject?.value ||
      filterObject?.operator === 'isEmpty' ||
      filterObject?.operator === 'isNotEmpty')
  ) {
    return {
      key: formatSortingFieldName(filterObject.field),
      value:
        filterObject.field === 'registrationDate' || filterObject.field === 'completedDate'
          ? formatDateFieldValue(filterObject.value)
          : filterObject.value,
      operation: BACKEND_FILTER_VALUES[filterObject.operator]
    };
  } else {
    return null;
  }
};
