import Localize from 'react-intl-universal';

import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

import { getDuration } from '../Util.js';

export const createSessionDetailsService = async (session) => {
  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.sessionCard}${session.temId}`
  );
};

export const getSessionDuration = (session) => {
  const diff = getDuration(session.from, session.to);
  let displayString = '';
  if (diff.dayDiff && diff.dayDiff >= 1) {
    displayString += diff.dayDiff + ' ';
    if (diff.dayDiff === 1) {
      displayString += Localize.get('EventDetails.day');
    } else {
      displayString += Localize.get('EventDetails.days');
    }
  }

  if (diff.hourDiff && diff.hourDiff >= 1) {
    if (displayString.length > 0) {
      displayString += ', ';
    }

    displayString += diff.hourDiff + ' ';
    if (diff.hourDiff === 1) {
      displayString += Localize.get('EventDetails.hour');
    } else {
      displayString += Localize.get('EventDetails.hours');
    }
  }

  if (diff.minDiff && diff.minDiff >= 1) {
    if (displayString.length > 0) {
      displayString += ', ';
    }

    displayString += diff.minDiff + ' ' + Localize.get('EventDetails.minutes');
  }

  return displayString;
};

export const getSessionBuilding = (sessionData) => {
  if (sessionData && sessionData.results && sessionData.results.length) {
    return sessionData.results[0].BUILDING_NAME;
  }

  return null;
};

export const getSessionDescription = (sessionData) => {
  if (
    sessionData &&
    sessionData.results &&
    sessionData.results.length &&
    sessionData.results[0].SESSION_DESCRIPTION
  ) {
    return sessionData.results[0].SESSION_DESCRIPTION;
  }

  return null;
};

export const getSessionRoom = (roomData) => {
  if (roomData && roomData.results && roomData.results.length) {
    return roomData.results[0].ROOM_NAME;
  }

  return null;
};
