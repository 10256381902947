import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';

import {
  ToggleRowContainer,
  ToggleColumnContainer
} from '@components/StyledComponents/Filters.styled';

const ToggleFilter = (props) => {
  const { config, dataSource, onFilterChange } = props;

  const device = config.device.join(' ');
  const columnCount = config.columnCount;

  const onChange = (e, data) => {
    onFilterChange(config, data);
  };

  return (
    <>
      {columnCount && columnCount > 1 ? (
        <ToggleRowContainer only={`${device}`} data-test-id={'toggleFilterContainer'}>
          {dataSource ? (
            dataSource.map((type, index) => (
              <ToggleColumnContainer stretched key={index} width={8}>
                <Checkbox
                  fitted
                  toggle
                  disabled={type.disabled}
                  checked={type.checked}
                  id={type.key}
                  label={type.text}
                  onChange={onChange}
                />
              </ToggleColumnContainer>
            ))
          ) : (
            <div data-test-id={'noEventType'}>{Localize.get('Catalog.noTypes')}</div>
          )}
        </ToggleRowContainer>
      ) : (
        <></>
      )}
    </>
  );
};
export default React.memo(ToggleFilter);
