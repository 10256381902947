export const tabOptions = {
  information: 'Information',
  agenda: 'Agenda',
  attachments: 'Attachments',
  notes: 'Notes',
  sessions: 'Sessions',
  speakers: 'Speakers',
  hotels: 'Hotels',
  messages: 'Messages',
  overview: 'Overview'
};
