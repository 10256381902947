import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

const AttachmentOptionsField = ({ params, handleOptions }) => {
  return (
    <Box data-testid={'attachmentOptionsField'}>
      <IconButton aria-label='download' name='download' id={params?.id} onClick={handleOptions}>
        <DownloadIcon />
      </IconButton>
      <IconButton aria-label='edit' name='edit' id={params?.id} onClick={handleOptions}>
        <EditIcon />
      </IconButton>
      <IconButton aria-label='delete' name='delete' id={params?.id} onClick={handleOptions}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default AttachmentOptionsField;
