/* eslint-disable jsx-a11y/no-static-element-interactions */
/*!
 * Range slider for Semantic UI.
 * Original source code: https://github.com/iozbeyli/react-semantic-ui-range
 *
 * Original was based on React Class components,
 * now it is moved onto React Hooks.
 *
 * Value of the slider can be Array of values for multiple slider.
 * Original logic was that method setValuePosition calls setting of value and position for every value's value.
 * Now, setting of value and position is called only once, except for discrete sliders, which preserved original logic.
 *
 *
 */

import React, { useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';

import Logger from '@common/Logger.js';

import styles from './SliderStyleHelper.js';
const Slider = (props) => {
  const [value, setValue] = React.useState(
    props.value ? props.value : props.multiple ? [...props.settings.start] : props.settings.start
  );

  const [position, setPosition] = React.useState(props.multiple ? [] : 0);
  const [numberOfThumbs] = React.useState(props.multiple ? value.length : 1);
  const [offset] = React.useState(10);
  const [precision, setPrecision] = React.useState(0);
  const [mouseDown, setMouseDown] = React.useState(false);
  const track = React.useRef(null);
  const inner = React.useRef(null);
  const innerLeft = React.useRef(null);
  const innerRight = React.useRef(null);
  const trackFill = React.useRef(null);

  const determinePosition = useCallback(
    (sliderValue) => {
      // eslint-disable-next-line react/no-find-dom-node
      const trackLeft = ReactDOM.findDOMNode(track.current).getBoundingClientRect().left;
      // eslint-disable-next-line react/no-find-dom-node
      const innerLeft = ReactDOM.findDOMNode(inner.current).getBoundingClientRect().left;
      const ratio = (sliderValue - props.settings.min) / (props.settings.max - props.settings.min);
      return Math.round(ratio * inner.current.offsetWidth) + trackLeft - innerLeft - offset;
    },
    [offset, props.settings.max, props.settings.min]
  );

  const setValuesAndPositions = useCallback(
    (triggeredByUser, setSliderValueFn, isMultiple, value) => {
      if (isMultiple) {
        let positions = [];
        setSliderValueFn(value, triggeredByUser);
        value.forEach((val, i) => {
          positions[i] = determinePosition(val);
        });
        return positions;
      } else {
        setSliderValueFn(value, triggeredByUser);
        return determinePosition(value);
      }
    },
    [determinePosition]
  );

  const setSliderValueWithValue = useCallback(
    (sliderValue, triggeredByUser) => {
      if (typeof triggeredByUser === 'undefined') {
        triggeredByUser = true;
      }
      let newValue, needUpdate;
      if (props.multiple) {
        needUpdate = isDifferentArrays(sliderValue, value);
      } else {
        needUpdate = sliderValue !== value;
      }
      if (needUpdate) {
        if (props.multiple) {
          newValue = [...sliderValue];
        } else {
          newValue = sliderValue;
        }

        setValue(newValue);
        if (props.settings.onChange) {
          props.settings.onChange(newValue, {
            triggeredByUser: triggeredByUser
          });
        }
      }
    },
    [props.multiple, props.settings, value]
  );

  const setSliderValueDiscrete = useCallback(
    (sliderValue, triggeredByUser, thumbInd) => {
      if (typeof triggeredByUser === 'undefined') {
        triggeredByUser = true;
      }
      let newValue;
      const currentValue = props.multiple ? value[thumbInd] : value;
      if (sliderValue !== currentValue) {
        if (props.multiple) {
          newValue = [...value];
          newValue[thumbInd] = sliderValue;
        } else {
          newValue = sliderValue;
        }
        setValue(newValue);
        if (props.settings.onChange) {
          props.settings.onChange(newValue, {
            triggeredByUser: triggeredByUser
          });
        }
      }
    },
    [props.multiple, props.settings, value]
  );

  const determinePrecision = useCallback((step) => {
    let split = String(step).split('.');
    let decimalPlaces;
    if (split.length === 2) {
      decimalPlaces = split[1].length;
    } else {
      decimalPlaces = 0;
    }
    setPrecision(Math.pow(10, decimalPlaces));
  }, []);

  const isDifferentArrays = (a, b) => {
    let different = false;
    a.some((val, i) => {
      if (val !== b[i]) {
        different = true;
        return true;
      }
      return false;
    });
    return different;
  };

  const determineValue = (startPos, endPos, currentPos) => {
    let ratio = (currentPos - startPos) / (endPos - startPos);
    let range = props.settings.max - props.settings.min;
    let difference = Math.round((ratio * range) / props.settings.step) * props.settings.step;
    // Use precision to avoid ugly Javascript floating point rounding issues
    // (like 35 * .01 = 0.35000000000000003)
    difference = Math.round(difference * precision) / precision;
    return difference + props.settings.min;
  };

  const determineThumb = (positionHelp) => {
    if (!props.multiple) {
      return 0;
    }
    if (positionHelp <= position[0]) {
      return 0;
    }
    if (positionHelp >= position[numberOfThumbs - 1]) {
      return numberOfThumbs - 1;
    }
    let index = 0;

    for (let i = 0; i < numberOfThumbs - 1; i++) {
      if (positionHelp >= position[i] && positionHelp < position[i + 1]) {
        const distanceToSecond = Math.abs(positionHelp - position[i + 1]);
        const distanceToFirst = Math.abs(positionHelp - position[i]);
        if (distanceToSecond <= distanceToFirst) {
          return i + 1;
        } else {
          return i;
        }
      }
    }
    return index;
  };

  const setValuePosition = (sliderValue, triggeredByUser, thumbIndex) => {
    if (props.multiple) {
      const positions = [...position];
      positions[thumbIndex] = determinePosition(sliderValue);
      setSliderValueWithValue(sliderValue, triggeredByUser);
      setPosition(positions);
    } else {
      setSliderValueWithValue(sliderValue, triggeredByUser);
      setPosition(determinePosition(sliderValue));
    }
  };

  const setValuePositionDiscrete = (sliderValue, triggeredByUser) => {
    if (props.multiple) {
      const positions = [...position];
      sliderValue.forEach((val, i) => {
        setSliderValueDiscrete(val, triggeredByUser, i);
        positions[i] = determinePosition(val);
      });
      setPosition(positions);
    } else {
      setSliderValueWithValue(sliderValue, triggeredByUser);
      setPosition(determinePosition(sliderValue));
    }
  };

  const setThumbsPosition = (positionHelp, thumbIndex) => {
    if (props.multiple) {
      const newPosition = [...position];
      newPosition[thumbIndex] = positionHelp;
      setPosition(newPosition);
    } else {
      setPosition(position);
    }
  };

  const rangeMouseDown = (isTouch, e) => {
    e.stopPropagation();
    if (!props.disabled) {
      if (!isTouch) {
        e.preventDefault();
      }

      setMouseDown(true);
      // eslint-disable-next-line react/no-find-dom-node
      let innerBoundingClientRect = ReactDOM.findDOMNode(inner.current).getBoundingClientRect();
      innerLeft.current = innerBoundingClientRect.left;
      innerRight.current = innerLeft.current + inner.current.offsetWidth;
      rangeMouse(isTouch, e);
    }
  };

  const rangeMouse = (isTouch, e) => {
    let pageX;
    let event = isTouch ? e.touches[0] : e;
    if (event.pageX) {
      pageX = event.pageX;
    } else {
      Logger.error('Slider: PageX undefined');
    }
    let val = determineValue(innerLeft.current, innerRight.current, pageX);
    if (pageX >= innerLeft.current && pageX <= innerRight.current) {
      if (val >= props.settings.min && val <= props.settings.max) {
        const positionHelp = pageX - innerLeft.current - offset;
        const thumbIndex = props.multiple ? determineThumb(positionHelp) : undefined;
        let newValue;
        if (props.multiple) {
          newValue = [...value];
          newValue[thumbIndex] = val;
        }
        if (props.discrete) {
          // discrete slider
          if (!props.multiple) {
            // normal slider, value has one integer value e.g. value = 11
            setValuePosition(val, false, thumbIndex);
          } else {
            // multiple slider, value is an array of integers, e.g. value = [3, 5, 11]
            setValuePositionDiscrete(newValue, false);
          }
        } else {
          if (!props.multiple) {
            newValue = val;
          }
          setThumbsPosition(positionHelp, thumbIndex);
          setSliderValueWithValue(newValue, undefined);
        }
      }
    }
  };

  const rangeMouseMove = (isTouch, e) => {
    e.stopPropagation();
    if (!isTouch) {
      e.preventDefault();
    }
    if (mouseDown) {
      rangeMouse(isTouch, e);
    }
  };

  const rangeMouseUp = () => {
    setMouseDown(false);
  };

  // initial calculations
  useEffect(() => {
    determinePrecision(props.settings.step);
    setPosition(setValuesAndPositions(false, setSliderValueWithValue, props.multiple, value));
    window.addEventListener('mouseup', rangeMouseUp);

    return () => {
      inner.current = null;
      innerLeft.current = null;
      innerRight.current = null;
      window.removeEventListener('mouseup', rangeMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update recalculation
  useEffect(() => {
    if (props.value) {
      if (props.multiple) {
        if (isDifferentArrays(props.value, value)) {
          const pos = setValuesAndPositions(
            false,
            setSliderValueWithValue,
            props.multiple,
            props.value
          );

          if (isDifferentArrays(pos, position)) {
            setPosition(pos);
          }
        }
      } else {
        const pos = setValuesAndPositions(
          false,
          setSliderValueWithValue,
          props.multiple,
          props.value
        );

        if (pos !== position) {
          setPosition(pos);
        }
      }
    } else {
      if (!props.multiple) {
        // no value
        const pos = setValuesAndPositions(false, setSliderValueWithValue, props.multiple, value);

        if (pos !== position) {
          setPosition(pos);
        }
      }
    }
  }, [
    offset,
    position,
    props.multiple,
    props.settings,
    props.value,
    setSliderValueWithValue,
    setValuesAndPositions,
    value
  ]);

  return (
    <>
      <div>
        <div
          data-test-id={'sliderMainContainer'}
          onMouseDown={(event) => rangeMouseDown(false, event)}
          onMouseMove={(event) => rangeMouseMove(false, event)}
          onMouseUp={(event) => rangeMouseUp(false, event)}
          onTouchEnd={(event) => rangeMouseUp(true, event)}
          onTouchMove={(event) => rangeMouseMove(true, event)}
          onTouchStart={(event) => rangeMouseDown(true, event)}
          style={{
            ...styles.range,
            ...(props.disabled ? styles.disabled : {}),
            ...(props.style ? props.style : {})
          }}
        >
          <div
            className='semantic_ui_range_inner'
            ref={inner}
            style={{
              ...styles.inner,
              ...(props.style ? (props.style.inner ? props.style.inner : {}) : {})
            }}
          >
            <div
              ref={track}
              style={{
                ...styles.track,
                ...(props.inverted ? styles.invertedTrack : {}),
                ...(props.style ? (props.style.track ? props.style.track : {}) : {})
              }}
            />
            <div
              ref={trackFill}
              style={{
                ...styles.trackFill,
                ...(props.inverted ? styles.invertedTrackFill : {}),
                ...styles[props.inverted ? 'inverted-' + props.color : props.color],
                ...(props.style ? (props.style.trackFill ? props.style.trackFill : {}) : {}),
                ...(props.disabled ? styles.disabledTrackFill : {}),
                ...(props.style
                  ? props.style.disabledTrackFill
                    ? props.style.disabledTrackFill
                    : {}
                  : {}),
                ...{ width: position + offset + 'px' },
                ...(props.multiple && position.length > 0
                  ? {
                      left: position[0],
                      width: position[numberOfThumbs - 1] - position[0]
                    }
                  : {})
              }}
            />

            {props.multiple ? (
              position.map((pos, i) => (
                <div
                  key={i}
                  style={{
                    ...styles.thumb,
                    ...(props.style ? (props.style.thumb ? props.style.thumb : {}) : {}),
                    ...{ left: pos + 'px' }
                  }}
                />
              ))
            ) : (
              <div
                style={{
                  ...styles.thumb,
                  ...(props.style ? (props.style.thumb ? props.style.thumb : {}) : {}),
                  ...{ left: position + 'px' }
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Slider;
