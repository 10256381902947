import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import coverImage from '@assets/Images/coverImage.jpg';
import laptopImage from '@assets/Images/laptopImage.jpg';
import planeImage from '@assets/Images/planeImage.jpg';
import EntryCardComponent from '@components/EntryCardComponent/EntryCardComponent.jsx';
import useFetching from '@hooks/Fetching/Fetching';
import * as constants from '@navigation/Constants.js';
import tokenUtil from '@services/tokenUtil';

import { createEventService } from './HomeHelper.js';
import styles from './style/HomePageStyles.module.scss';

const Home = () => {
  if (tokenUtil.getUser()) window.location.href = '/myarea';
  // const [events, isLoadingEvents, ,] = useFetching(createEventService.bind(null));
  // const eventCount = events?.data ? events.data.eventsCount + ' ' : '';
  // const typeCount = events?.data ? events.data.eventTypes + ' ' : '';

  return (
    <>
      {/* {isLoadingEvents && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )} */}

      <div className={styles.containerWelcomePage} data-test-id={'homeMainContainer'}>
        <Grid className={styles.mainContainer}>
          <Grid.Row only='tablet computer' verticalAlign='bottom' className={styles.titleContainer}>
            <h1 className={styles.mainTitle}>
              {Localize.get('HomePage.welcomeToContrimo')}{' '}
              {Localize.get('HomePage.eventsCapitalFirst')}
            </h1>
          </Grid.Row>
          <Grid.Row only='mobile' className={styles.titleMobileContainer}>
            <h1>{Localize.get('HomePage.welcomeToContrimo')}</h1>
          </Grid.Row>
          <Grid.Row only='mobile' className={styles.titleMobileContainer}>
            <h1>{Localize.get('HomePage.eventsCapitalFirst')}</h1>
          </Grid.Row>

          <Grid.Row only='tablet computer'>
            <Grid container columns={2} centered className={styles.headerGrid}>
              <Grid.Column
                only='tablet computer'
                className={styles.leftContainerInfo}
                textAlign='right'
              >
                <Grid.Row>{Localize.get('HomePage.benefitKnowledge')}</Grid.Row>
              </Grid.Column>
              <Grid.Column
                only='tablet computer'
                className={styles.desktopHeaderTopic}
                textAlign='left'
              >
                {Localize.get('HomePage.rightDescription')}
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row centered only='mobile'>
            <Grid container centered className={styles.headerGrid}>
              <Grid.Column stretched className={styles.mobileHeaderTopic}>
                <p>{Localize.get('HomePage.rightDescription')}</p>
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row>
            <Grid stackable container columns={3} className={styles.gridAlign}>
              {/* <Grid.Row stretched only='tablet computer'>
                <Grid.Column>
                  <EntryCardComponent
                    image={laptopImage}
                    headerContent={Localize.get('AppNavBar.genInfo')}
                    description={Localize.get('HomePage.getOverview')}
                    infoContent={'4 ' + Localize.get('HomePage.newInfo')}
                    linkTo={constants.routes.path.generalinfo}
                    needsLogIn={false}
                  />
                </Grid.Column>
                <Grid.Column>
                  <EntryCardComponent
                    image={coverImage}
                    headerContent={Localize.get('AppNavBar.learningContent')}
                    description={Localize.get('HomePage.browseCatalog')}
                    infoContent={eventCount + Localize.get('HomePage.events')}
                    additionalInfo={typeCount + Localize.get('HomePage.types')}
                    linkTo={constants.routes.path.catalog}
                    needsLogIn={false}
                  />
                </Grid.Column>
                <Grid.Column>
                  <EntryCardComponent
                    image={planeImage}
                    headerContent={Localize.get('AppNavBar.myEvents')}
                    description={Localize.get('HomePage.stayInTouch')}
                    infoContent={Localize.get('HomePage.myEventCardPlaceholder')}
                    // linkTo={constants.routes.path.myevents}
                    needsLogIn={true}
                  />
                </Grid.Column>
              </Grid.Row> */}

              {/* mobile layout */}
              {/* <Grid.Row className={styles.gridMobileAlign} only='mobile'>
                <Grid.Column>
                  <EntryCardComponent
                    headerContent={Localize.get('AppNavBar.genInfo')}
                    description={Localize.get('HomePage.getOverview')}
                    infoContent={'4 ' + Localize.get('HomePage.newInfo')}
                    linkTo={constants.routes.path.generalinfo}
                    needsLogIn={false}
                  />
                </Grid.Column>
                <Grid.Column>
                  <EntryCardComponent
                    headerContent={Localize.get('AppNavBar.learningContent')}
                    description={Localize.get('HomePage.browseCatalog')}
                    infoContent={eventCount + Localize.get('HomePage.events')}
                    additionalInfo={typeCount + Localize.get('HomePage.types')}
                    linkTo={constants.routes.path.catalog}
                    needsLogIn={false}
                  />
                </Grid.Column>
                <Grid.Column>
                  <EntryCardComponent
                    headerContent={Localize.get('AppNavBar.myEvents')}
                    description={Localize.get('HomePage.stayInTouch')}
                    infoContent={Localize.get('HomePage.myEventCardPlaceholder')}
                    // linkTo={constants.routes.path.myevents}
                    needsLogIn={true}
                  />
                </Grid.Column>
              </Grid.Row> */}
            </Grid>
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};

export default Home;
