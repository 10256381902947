import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import Localize from 'react-intl-universal';

import de from 'date-fns/locale/de';
//semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import 'react-datepicker/dist/react-datepicker.css';

import style from '@components/Filters/style/Filters.module.scss';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';

// eslint-disable-next-line react/display-name
const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <Input
    onClick={onClick}
    ref={ref}
    icon='calendar alternate outline'
    className={style.reactDatepickerInput}
    value={value}
  />
));

const CalendarFilterForOneDate = (props) => {
  const { config, dataSource, onFilterChange, shouldResetFilters } = props;
  registerLocale('de', de);

  const [startDate, setStartDate] = React.useState(dataSource.from);
  const [clearable, setClearable] = React.useState(true);

  useEffect(() => {
    if (shouldResetFilters && startDate) {
      setStartDate('');
      setClearable(false);
    }
  }, [shouldResetFilters]);

  const [contextData] = useGlobalContext();

  const handleDayClick = (date) => {
    setClearable(true);
    setStartDate(date);
    onFilterChange(config, date ? date : '');
  };

  const device = config.device.join(' ');

  return (
    <>
      <Grid.Row
        only={`${device}`}
        data-test-id={'calendarContainerForOneDate'}
        className={style.reactDatepickerWrapper}
      >
        <Grid.Column mobile={16} computer={16}>
          <Form>
            <Form.Field>
              <label>{Localize.get(`App.${config.name}`)}</label>
            </Form.Field>
          </Form>
          <DatePicker
            style={{ width: '100%' }}
            isClearable={clearable}
            showPopperArrow={false}
            selectsRange={false}
            dateFormat='dd.MM.yyyy'
            locale={contextData?.lang ? contextData.lang.toLowerCase() : 'en'}
            selected={startDate}
            minDate={dataSource.initStartDate}
            onChange={handleDayClick}
            customInput={<ExampleCustomInput />}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
export default CalendarFilterForOneDate;
