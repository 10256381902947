import React from 'react';
import Localize from 'react-intl-universal';

import List from 'semantic-ui-react/dist/commonjs/elements/List/List';

import { formatDateDMYHMWithDayName } from '@common/Util';

import style from './style/SessionCard.module.scss';

const SessionCard = (props) => {
  const { sessions } = props;
  const moduleSessions = sessions;

  return (
    <>
      <div className={style.sessionCardBlock}>
        {moduleSessions?.length ? (
          <>
            <h5>{Localize.get('CourseDetails.possibleDatesAndLocations')}</h5>
            <List divided>
              {moduleSessions.map((session, index) => (
                <List.Item key={index} className={style.listItem}>
                  {formatDateDMYHMWithDayName(new Date(session.startDateTime))}
                  {' - '}
                  {formatDateDMYHMWithDayName(new Date(session.endDateTime))}{' '}
                  {session.venues?.length &&
                    Localize.get('App.location') +
                      ': ' +
                      session.venues.map((venue) => ' ' + venue.name)}{' '}
                  {/*'TODO: Enable line below when Participant tab is finished in TEM'*/}
                  {session.seats?.length && Localize.get('CourseDetails.availableSeats') + ': '}
                </List.Item>
              ))}
            </List>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SessionCard;
