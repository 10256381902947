import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

import styles from './style/CommonModal.module.scss';

const CommonModal = (props) => {
  const { open, setOpen, header, content, buttonName, handleButton } = props;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    handleButton();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      data-testid={'commonModal'}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      className={styles.commonModal}
      dimmer='inverted'
    >
      <Modal.Header className={styles.centeredHeader}>{header}</Modal.Header>
      <Modal.Content className={styles.modalContent}>{content}</Modal.Content>
      <Modal.Actions className={styles.modalAction}>
        <Button color='blue' onClick={handleCancel}>
          {Localize.get('App.cancel').toUpperCase()}
        </Button>
        <Button color='red' onClick={handleSave}>
          {buttonName}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CommonModal;
