/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import * as globalConstants from '@common/Constants.js';
import * as countriesCodes from '@common/Countries.js';
import Logger from '@common/Logger.js';
import { initialSnackbarState, errorSnackbarState } from '@components/Snackbar/Constants';
import CustomizedSnackbar from '@components/Snackbar/Snackbar';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants.js';
import Attachments from '@pages/AllSession/Tabs/Attachments';
import { courseSessionType } from '@pages/CourseDetails/Constants.js';

import IltSessionLayoutLink from './Components/IltSessionLayoutLink';
import IltSessionRegistrationControl from './Components/IltSessionRegistrationControl';
import * as constants from './Constants';
import DetailsMenuItems from './DetailsMenuItems.jsx';
import DetailsMobileMenu from './DetailsMobileMenu';
import styles from './style/AllSessionDetails.module.scss';
import Agenda from './Tabs/Agenda';
import Hotel from './Tabs/Hotel';
import Information from './Tabs/Information.jsx';
import Notes from './Tabs/Notes.jsx';
import Participants from './Tabs/Participants';
import { createEventService } from '../EventDetails/EventDetailsHelper.js';
import RegistrationEvent from '../EventDetails/RegistrationEvent/RegistrationEvent.jsx';
import {
  createILTSessionAgendaService,
  getInstructorsNamesFromSessionDays,
  getLocationInfoFromSessionDays
} from '../EventDetails/Tabs/AgendaHelper';

const AllSessionDetails = ({ match, history }) => {
  const [registrationActive, setRegistrationActive] = useState(false);
  const [activeItem, setActiveItem] = useState(constants.tabOptions.information);
  const [iltSessionID, setIltSessionID] = useState(
    match && match.params && match.params.id ? match.params.id : ''
  );
  let previousPath = '';
  let previousPathName = '';
  let participantStatus = '';
  let participantStatusKey = '';
  let iltSessionStatus = '';
  const [contextData] = useGlobalContext();
  const [iltSession, setILTSession] = useState({});
  const [iltSessionSessionDays, setILTSessionSessionDays] = useState([]);
  const [registrationSnackbarState, setRegistrationSnackbarState] = useState(initialSnackbarState);

  let tabsToShow = undefined;
  let numberOfVisibleTabs = 0;

  const redirectToHome = () => {
    return history.push({
      pathname: '/',
      state: {
        from: 'Home',
        path: '/'
      }
    });
  };

  const setRegistrationSnackbarToInit = () => {
    setRegistrationSnackbarState(initialSnackbarState);
  };

  const callBackError = (error) => {
    setRegistrationSnackbarState({
      ...errorSnackbarState,
      message: error?.response?.data?.message
        ? error.response.data.message
        : Localize.get('Tables.Error')
    });
  };

  const [iltSessionData, isLoading, , fetchILTSession] = useFetching(
    createEventService.bind(null, iltSessionID, 'iltSession', redirectToHome),
    true
  );
  const [sessionDaysData, isLoadingDays, , fetchSessionDays] = useFetching(
    createILTSessionAgendaService.bind(null, iltSessionID),
    true
  );

  // --- TO DO --- delete this data after BE is finished
  const hardcodedHotelData = {
    checkIn: '10.12.2024',
    checkOut: '20.12.2024',
    assignedHotel: 'Hayat Regency',
    roomCategory: 'Two bedroom apartment',
    address: 'Hotel Street 10 72122 Ruesheim',
    services: 'Breakfast included',
    facilities: 'Swimming Pool',
    checkInTimesStart: '3:00 pm',
    checkInTimesEnd: '10:00 pm',
    checkOutTime: '11:00 am',
    brekfastStart: '7:00 am',
    brekfastEnd: '10:00 am',
    additionalInformation: 'XYZ',
    other: 'XYZ'
  };

  useEffect(() => {
    if (iltSessionID && iltSessionID?.length) {
      fetchILTSession();
      fetchSessionDays();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iltSessionID]);

  useEffect(() => {
    const fetchILTSession = async () => {
      try {
        if (iltSessionData?.data) {
          setILTSession(iltSessionData.data);
        }
        if (sessionDaysData?.data?.content) {
          setILTSessionSessionDays(
            sessionDaysData.data.content.sort((a, b) => new Date(a.dayDate) - new Date(b.dayDate))
          );
          iltSession.instructors = getInstructorsNamesFromSessionDays(sessionDaysData.data.content);
          iltSession.locationInfo =
            iltSession?.type?.key === courseSessionType.webinar
              ? Localize.get('App.online')
              : getLocationInfoFromSessionDays(sessionDaysData.data.content);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchILTSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iltSessionData, sessionDaysData]);

  useEffect(() => {
    if (!iltSessionID && match?.params?.id) {
      setIltSessionID(match.params.id);
    }
  }, [iltSessionID, match]);

  const informationComponent = (
    <Information
      iltSession={iltSession}
      config={contextData}
      isLoading={isLoadingDays}
      notesData={iltSession.notes}
    />
  );
  const agendaComponent = <Agenda sessionsData={iltSessionSessionDays} />;
  const attachmentsComponent = (
    <Attachments match={match} config={contextData} callBackError={callBackError} />
  );
  const notesComponent = (
    <Notes
      notesData={iltSession.notes}
      config={contextData}
      isLoading={isLoadingDays}
      previousPath={previousPath}
    />
  );
  const hotelComponent = (
    <Hotel
      hotelData={hardcodedHotelData}
      config={contextData}
      isLoading={isLoadingDays}
      notesData={iltSession.notes}
    />
  );
  const participantsComponent = (
    <Participants
      match={match}
      sessionDaysData={sessionDaysData?.data?.content}
      config={contextData}
      iltSessionName={iltSession?.name}
      callBackError={callBackError}
    />
  );

  const handleActiveComponent = (component) => {
    switch (component) {
      case constants.tabOptions.information:
        return informationComponent;
      case constants.tabOptions.agenda:
        return agendaComponent;
      case constants.tabOptions.attachments:
        return attachmentsComponent;
      case constants.tabOptions.notes:
        return notesComponent;
      case constants.tabOptions.hotel:
        return hotelComponent;
      case constants.tabOptions.participants:
        return participantsComponent;
      default:
        return informationComponent;
    }
  };

  const handleItemClick = (e, data) => {
    setActiveItem(data.name);
  };

  /*
    TODO: In Router v6, the location state is generally accessed using the useLocation hook from react-router-dom. 
    Code example:
    import { useLocation } from 'react-router-dom';
    const location = useLocation();
    const { state: locationState } = location;
    Now, the code uses useLocation to get the location object and accesses the state directly from there.
    A comment is left to guide developers during the migration process.
  */
  if (history?.location?.state?.from) {
    previousPathName = history.location.state.from;
    previousPath = history.location.state.path;
    if (previousPath === routerConstants.routes.path.myarea) {
      participantStatus = history.location.state.data.participantStatus;
      participantStatusKey = history.location.state?.data?.participantStatusKey
        ? history.location.state.data.participantStatusKey
        : '';
      iltSessionStatus = history.location.state?.data?.iltSessionStatus
        ? history.location.state.data.iltSessionStatus
        : '';
    }

    tabsToShow =
      previousPath === routerConstants.routes.path.myarea
        ? contextData?.configuration?.MyAreaAllSessionsItem?.tabsToShow
        : previousPath === routerConstants.routes.path.trainerArea
        ? contextData?.configuration?.TrainerAllSessionsItem?.tabsToShow
        : contextData?.configuration?.LocationAllSessionsItem?.tabsToShow;
    numberOfVisibleTabs = tabsToShow ? Object.values(tabsToShow).filter(Boolean).length : 0;
  }

  return (
    <>
      {/* desktop && tablet layout*/}
      <Grid centered>
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        {/* desktop && tablet layout - link*/}
        {previousPath && previousPathName && (
          <Grid.Row className={`tablet computer only ${styles.link}`}>
            <Grid.Column computer={14} tablet={14} widescreen={14} textAlign='right'>
              <IltSessionLayoutLink
                previousPathName={previousPathName}
                previousPath={previousPath}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {/* desktop && tablet layout - message*/}
        <Grid.Column
          computer={14}
          largeScreen={14}
          widescreen={14}
          tablet={14}
          className={styles.message}
          only='computer tablet'
        >
          <Message
            data-testid={'allSessionDetailsMessage'}
            style={{
              background: contextData?.configuration?.CourseDetailsMessage.background,
              boxShadow: contextData?.configuration?.CourseDetailsMessage.boxShadow
            }}
          >
            <Message.Header className={styles.messageHeaderStyle}>{iltSession.name}</Message.Header>

            <Message.Content className={styles.messageHeaderContainer}>
              <Grid relaxed centered stackable className={styles.messageHeaderContainerGrid}>
                <Grid.Column width={9} className={styles.messageTypeContainer}>
                  <span className={styles.messageTypeLabel}>
                    {iltSession?.type?.key}{' '}
                    <Flag
                      className={styles.messageFlag}
                      name={
                        iltSession?.nativeLanguage
                          ? countriesCodes.countryISOCodeByCountry[
                              iltSession.nativeLanguage.key.toLowerCase()
                            ]
                          : ''
                      }
                    />
                  </span>
                  {participantStatusKey === globalConstants.registrationStatuses.registered ? (
                    <>
                      <span className={styles.statusTypeLabel}>
                        {Localize.get('ILTSession.status')}
                      </span>
                      : {participantStatus}
                    </>
                  ) : null}
                </Grid.Column>
                <Grid.Column width={7}>
                  {previousPath === routerConstants.routes.path.myarea && (
                    <IltSessionRegistrationControl
                      iltSession={iltSession}
                      participantStatusKey={participantStatusKey}
                      iltSessionStatus={iltSessionStatus}
                      config={contextData}
                    />
                  )}
                </Grid.Column>
              </Grid>
            </Message.Content>
          </Message>
        </Grid.Column>
      </Grid>

      {/* desktop && tablet layout - menu and content*/}
      {!registrationActive &&
        (contextData?.size?.device === globalConstants.deviceSize.computer ||
          contextData?.size?.device === globalConstants.deviceSize.tablet) && (
          <Grid centered className={`${styles.gridMargin}`}>
            <Grid.Column
              style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
              className={styles.columnPadding}
              computer={14}
              largeScreen={14}
              widescreen={14}
              tablet={14}
            >
              <Menu
                pointing
                secondary
                widths={numberOfVisibleTabs}
                className={styles.menuTabletResolution}
              >
                <DetailsMenuItems
                  activeItem={activeItem}
                  handleItemClick={handleItemClick}
                  showIcon={contextData.size.device === globalConstants.deviceSize.computer}
                  config={contextData?.configuration}
                  tabsToShow={tabsToShow}
                />
              </Menu>

              {handleActiveComponent(activeItem)}
            </Grid.Column>
          </Grid>
        )}

      {registrationActive &&
        (contextData?.size?.device === globalConstants.deviceSize.computer ||
          contextData?.size?.device === globalConstants.deviceSize.tablet) && (
          <Grid
            className={`tablet computer only ${styles.menuCenter}`}
            centered
            style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
          >
            <RegistrationEvent event={iltSession} setRegistrationActive={setRegistrationActive} />
          </Grid>
        )}

      {/* mobile layout */}
      <Grid centered container className={`mobile only ${styles.gridMobileTop}`}>
        {/* mobile layout - link */}
        <Grid.Row className={styles.navLinkContainerMobile}>
          <IltSessionLayoutLink previousPathName={previousPathName} previousPath={previousPath} />
        </Grid.Row>

        {/* mobile layout - message*/}
        <Grid.Row only='mobile' centered className={styles.messageMobileContainer}>
          <Grid.Column width={16}>
            <Message
              style={{
                background: contextData?.configuration?.CourseDetailsMessage.background,
                boxShadow: contextData?.configuration?.CourseDetailsMessage.boxShadow
              }}
            >
              <Message.Header className={styles.messageHeaderStyle}>
                {iltSession.name}
              </Message.Header>
              <Message.Content className={styles.messageHeaderContainer}>
                <Grid relaxed>
                  <Grid.Column width={16} className={styles.messageTypeContainerMobile}>
                    <span className={styles.messageTypeLabel}>
                      {iltSession?.type?.key}
                      <Flag
                        className={styles.messageFlag}
                        name={
                          iltSession?.nativeLanguage
                            ? countriesCodes.countryISOCodeByCountry[
                                iltSession.nativeLanguage.key.toLowerCase()
                              ]
                            : ''
                        }
                      />
                    </span>
                    {participantStatusKey === globalConstants.registrationStatuses.registered ? (
                      <>
                        <span className={styles.statusTypeLabel}>
                          {Localize.get('ILTSession.status')}
                        </span>
                        : {participantStatus}
                      </>
                    ) : null}
                  </Grid.Column>
                  {previousPath === routerConstants.routes.path.myarea && (
                    <IltSessionRegistrationControl
                      iltSession={iltSession}
                      participantStatusKey={participantStatusKey}
                      iltSessionStatus={iltSessionStatus}
                      config={contextData}
                    />
                  )}
                </Grid>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>

        {!registrationActive && (
          <Grid.Row only='mobile' centered>
            <Grid.Column width={14} stretched className={styles.menuMobileCenter}>
              <DetailsMobileMenu
                activeItem={activeItem}
                handleItemClick={handleItemClick}
                config={contextData?.configuration}
                tabsToShow={tabsToShow}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {/* mobile layout - content*/}
        {!registrationActive && contextData?.size?.device === globalConstants.deviceSize.mobile && (
          <Grid.Column
            width={16}
            className={`mobile only ${styles.tabContainer}`}
            style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
          >
            {handleActiveComponent(activeItem)}
          </Grid.Column>
        )}
      </Grid>
      <CustomizedSnackbar
        open={registrationSnackbarState?.open}
        handleClose={setRegistrationSnackbarToInit}
        status={registrationSnackbarState?.status}
        message={registrationSnackbarState?.message}
      />
    </>
  );
};

export default AllSessionDetails;
