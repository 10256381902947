import { getAxiosConfiguration, getServiceWithCallback } from '@services/axiosUtil.js';
import * as constant from '@services/Constants';

export const createLoginService = (callbackSuccess, callbackError, data) => {
  const configuration = getAxiosConfiguration(constant.MEA_SERVER_CONFIGURATION.resource.login);
  configuration.method = constant.BASE.method.post;
  configuration.data = data;

  return getServiceWithCallback(configuration, callbackSuccess, callbackError);
};

export const createEnableAccountService = (callbackSuccess, callbackError, email) => {
  const configuration = getAxiosConfiguration(
    `${constant.MEA_SERVER_CONFIGURATION.resource.reactivate}${email}`
  );
  return getServiceWithCallback(configuration, callbackSuccess, callbackError);
};
