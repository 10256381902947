/**
 * @param {Array} fieldsConfig - fields configuration which should be extended with new props
 * @param {Object} propsToMap - object which will be used to map on configuration fields.
 * Keys of object should match name property from fields array from the configuration.
 * Value is object with props which will be mapped to that field.
 * @returns {Array} fieldsConfig - new fields configuration which will be passed to form component
 * @example mapPropsToComponent(fieldConfiguration, {['email']: { control: FormInputStyled })
 */
export const mapPropsToComponent = (fieldsConfig = [], propsToMap = {}) => {
  if (!fieldsConfig.length) {
    return [];
  }

  if (!propsToMap) {
    return fieldsConfig;
  }

  return fieldsConfig[0].groups.map((config) =>
    config.map((field) => {
      if (propsToMap[field.name]) {
        return { ...field, ...propsToMap[field.name] };
      }
      return field;
    })
  );
};

/**
 * @param {Array} fieldsConfig - fields configuration which should be mapped to ComponentType (e.g. input, select, etc)
 * @param {Object} propsToMap - ComponentType which will be used for mapped field.
 * Value is component with props which will be mapped to that field.
 * @returns {Array} fieldsConfig - new fields configuration which will be passed to form component
 * @example mapPropsToComponentType(fieldConfiguration, { ['passwordInput']: FormPasswordStyled })
 */
export const mapPropsToComponentType = (fieldsConfig = [], propsToMap = {}) => {
  if (!fieldsConfig.length) {
    return [];
  }

  if (!propsToMap) {
    return fieldsConfig;
  }

  return fieldsConfig[0].groups.map((config) =>
    config.map((field) => {
      if (propsToMap[field.component]) {
        return { ...field, control: propsToMap[field.component] };
      }
      return field;
    })
  );
};
