import React from 'react';

import { StyledCalendar } from '@components/StyledComponents/MyAreaOverview.styled';

const Calendar = ({
  onChangeDate,
  calendarValue,
  tileClassName,
  onActiveStartDateChange,
  showTodayCalendar
}) => {
  return (
    <StyledCalendar
      data-testid={'StyledCalendar'}
      key={showTodayCalendar ? 'today' : 'changed'}
      onChange={onChangeDate}
      value={calendarValue ? calendarValue : new Date()}
      tileClassName={tileClassName}
      onActiveStartDateChange={onActiveStartDateChange}
    />
  );
};

export default Calendar;
