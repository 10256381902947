import Localize from 'react-intl-universal';

import { remove, uniqBy } from 'lodash';

import { formatDateStringToDateObject } from '@common/Util';

const isFilterChecked = (filter, val) => {
  if (!filter || !filter.length) {
    return false;
  }
  return filter.includes(val) || (val === Localize.get('App.all') && filter[0] === undefined);
};

export const getEventTypes = (eventTypes, typesFilter) => {
  if (!eventTypes) {
    return [];
  }

  return eventTypes.map((type) => {
    let item = {};
    item.key = type.key;
    item.text = type.text;
    item.value = type.key;
    item.checked = isFilterChecked(typesFilter, type.key);
    return item;
  });
};

export const getEventStatuses = (eventStatuses, statusFilter) => {
  if (!eventStatuses) {
    return [];
  }

  return eventStatuses.map((status) => {
    let item = {};
    item.key = status.key;
    item.text = status.text;
    item.value = status.key;
    item.checked = isFilterChecked(statusFilter, status.key);
    return item;
  });
};

export const getEventCities = (events, cityFilter) => {
  if (!events) {
    return [];
  }

  const cities = uniqBy(events, 'CITY');

  if (cities.length) {
    return remove(
      cities.map((event, ind) => {
        let item = {};
        item.key = ind;
        item.text = event.CITY;
        item.value = event.CITY;
        item.checked = isFilterChecked(cityFilter, event.CITY);
        return item;
      }),
      (item) => item.text !== ''
    );
  }
  return cities;
};

export const getTrainingNumbers = (events, trainingNumberFilter) => {
  if (!events) {
    return [];
  }

  const trainingNumbers = uniqBy(events, 'ORD_ID');

  if (trainingNumbers.length) {
    return remove(
      trainingNumbers.map((event, ind) => {
        let item = {};
        item.key = ind;
        item.text = event.ORD_ID;
        item.value = event.ORD_ID;
        item.checked = isFilterChecked(trainingNumberFilter, event.ORD_ID);
        return item;
      }),
      (item) => item.text !== ''
    );
  }
  return trainingNumbers;
};

export const getContentTypes = (events) => {
  if (!events) {
    return [];
  }

  const contentTypes = ['Scheduled Trainings', 'e-Learning', 'Learning Path'];

  return contentTypes.map((type) => {
    let item = {};
    item.key = type;
    item.text = type;
    item.value = type;
    item.checked = false;
    return item;
  });
};

export const getEventCountries = (events, countryFilter) => {
  if (!events) {
    return [];
  }

  const countries = uniqBy(events, 'COUNTRY_NAME');

  if (countries.length) {
    return remove(
      countries.map((event, ind) => {
        let item = {};
        item.key = ind;
        item.text = event.COUNTRY_NAME;
        item.value = event.COUNTRY_NAME;
        item.checked = isFilterChecked(countryFilter, event.COUNTRY_NAME);
        return item;
      }),
      (item) => item.text !== ''
    );
  }
  return countries;
};

export const getEventPricesAndDates = (events) => {
  if (!events || !events.length) {
    return {
      date: {
        from: undefined,
        startTimestamp: undefined,
        to: undefined,
        endTimestamp: undefined
      },
      price: {
        minPrice: -1,
        maxPrice: 1000,
        initMinPrice: 0,
        initMaxPrice: 1000
      }
    };
  }

  let startDate = formatDateStringToDateObject(events[0].EVENT_START.substring(0, 8));
  let endDate = formatDateStringToDateObject(events[0].EVENT_END.substring(0, 8));
  let startTimestamp = '';
  let endTimestamp = '';
  let minPrice = events[0].EVENT_PRICE_LATEST ? parseFloat(events[0].EVENT_PRICE_LATEST) : -1;
  let maxPrice = events[0].EVENT_PRICE_LATEST ? parseFloat(events[0].EVENT_PRICE_LATEST) : -1;
  let initMinPrice, initMaxPrice;

  events.forEach((event) => {
    const startDateVariable = formatDateStringToDateObject(event.EVENT_START.substring(0, 8));
    if (startDateVariable <= startDate) {
      startDate = startDateVariable;
      startTimestamp = event.EVENT_START;
    }

    const endDateVariable = formatDateStringToDateObject(event.EVENT_END.substring(0, 8));
    if (endDateVariable >= endDate) {
      endDate = endDateVariable;
      endTimestamp = event.EVENT_END;
    }

    if (minPrice === -1 && parseFloat(event.EVENT_PRICE_LATEST)) {
      minPrice = parseFloat(event.EVENT_PRICE_LATEST);
    }

    if (parseFloat(event.EVENT_PRICE_LATEST) < minPrice) {
      minPrice = parseFloat(event.EVENT_PRICE_LATEST);
    }

    if (parseFloat(event.EVENT_PRICE_LATEST) > maxPrice) {
      maxPrice = parseFloat(event.EVENT_PRICE_LATEST);
    }
  });

  if (minPrice === maxPrice) {
    if (minPrice === -1) {
      // there is no event with price
      // slider should not be visible
      initMinPrice = 0;
      initMaxPrice = 1000;
      maxPrice = 1000;
    } else if (minPrice !== -1) {
      // there is only one event with price
      // slider should not be visible
      minPrice = -1;
      initMinPrice = 0;
      initMaxPrice = 1000;
      maxPrice = 1000;
    }
  } else {
    // there are more events with price
    // slider should be visible
    initMinPrice = minPrice;
    initMaxPrice = maxPrice;
  }

  return {
    date: {
      from: startDate,
      startTimestamp: startTimestamp,
      to: endDate,
      endTimestamp: endTimestamp
    },
    price: {
      minPrice: minPrice,
      maxPrice: maxPrice,
      initMinPrice: initMinPrice,
      initMaxPrice: initMaxPrice
    }
  };
};

export const getEventStartDate = (events) => {
  if (!events || !events.length) {
    return {
      date: {
        from: undefined,
        startTimestamp: undefined
      }
    };
  }

  let startDate = formatDateStringToDateObject(events[0].EVENT_START.substring(0, 8));
  let startTimestamp = '';

  events.forEach((event) => {
    if (event.EVENT_START !== null) {
      const startDateVariable = formatDateStringToDateObject(event.EVENT_START.substring(0, 8));
      if (startDateVariable <= startDate) {
        startDate = startDateVariable;
        startTimestamp = event.EVENT_START;
      }
    }
  });

  return {
    date: {
      from: startDate,
      startTimestamp: startTimestamp
    }
  };
};

export const getEventVenues = (events, venueFilter) => {
  if (!events) {
    return [];
  }

  let venues = [];
  const uniqueEvents = uniqBy(events, 'EVENT_VENUES');

  if (uniqueEvents.length) {
    uniqueEvents.map((event, ind) => {
      if (event.EVENT_VENUES.length) {
        event.EVENT_VENUES.map((venue) => {
          let item = {};
          item.key = Number(`${venue.id}${ind}`);
          item.text = venue.name;
          item.value = venue.name;
          item.checked = isFilterChecked(venueFilter, venue.name);
          item.disabled = false;
          venues.push(item);
        });
      }
    });
    if (venues.length) {
      venues.unshift({
        key: Localize.get('App.all'),
        text: Localize.get('App.all'),
        checked: !venues.find((venue) => !venue.checked),
        disabled: false
      });
    }
    // Make array of venues unique in case when one Venue is assigned to many Events
    venues = [...new Map(venues.map((item) => [item.value, item])).values()];
  }
  return venues;
};

export const filterHandlers = {
  typeFilters: {
    onTypeFilterChange: (data, eventTypeOptions, filters, setEventTypeOptions, setFilters) => {
      let types = [...eventTypeOptions];
      let currentTypes = [...filters.type];
      if (data.id && data.id === Localize.get('App.all')) {
        types.forEach((type) => {
          type.checked = data.checked;
        });
        if (data.checked) {
          currentTypes = types.map((t) => t.key);
        } else {
          currentTypes = [];
        }
      } else {
        if (data.checked) {
          currentTypes.push(data.id);

          let checkedFilter = types.find((type) => type.key === data.id);
          checkedFilter.checked = true;

          // if all are selected but 'All', select 'All'
          const allButAllChecked = types.every(function (val) {
            if (val.key === Localize.get('App.all')) {
              return true;
            }
            return currentTypes.includes(val.key);
          });
          if (allButAllChecked) {
            let allFilter = types.find((type) => type.key === Localize.get('App.all'));
            allFilter.checked = true;
            currentTypes.push(Localize.get('App.all'));
          }
        } else {
          // remove unchecked type from type filters
          const selectedIndex = currentTypes.findIndex((type) => type === data.id);
          currentTypes.splice(selectedIndex, 1);

          const allCheckedIndex = currentTypes.findIndex(
            (type) => type === Localize.get('App.all')
          );
          if (allCheckedIndex !== -1) {
            currentTypes.splice(allCheckedIndex, 1);
          }

          let checkedFilter = types.find((type) => type.key === data.id);
          checkedFilter.checked = false;

          // if 'All' is checked, uncheck it
          const allCheckedButton = types.find(
            (element) => element.text === Localize.get('App.all')
          );
          allCheckedButton.checked = false;
        }
      }

      setEventTypeOptions(types);

      setFilters({
        ...filters,
        type: currentTypes
      });
    },
    onTypeFilterChangeMobile: (data, filters, setFilters) => {
      setFilters({
        ...filters,
        type: data.value
      });
    }
  },
  langFilter: {
    onLangFilterChange: (data, filters, setFilters) => {
      setFilters({
        ...filters,
        lang: data.value
      });
    }
  },
  cityFilter: {
    onCityFilterChange: (data, filters, setFilters) => {
      setFilters({
        ...filters,
        city: data.value
      });
    }
  },
  countryFilter: {
    onCountryFilterChange: (data, filters, setFilters) => {
      setFilters({
        ...filters,
        country: data.value
      });
    }
  },
  priceFilter: {
    onPriceFilterChange: (data, filters, setFilters) => {
      setFilters({
        ...filters,
        minPrice: data[0],
        maxPrice: data[1]
      });
    }
  },
  dateFilter: {
    onDayFilterChange: (newRange, filters, setFilters) => {
      setFilters({
        ...filters,
        minDate: newRange ? newRange[0] : undefined,
        maxDate: newRange ? newRange[1] : undefined
      });
    },
    onUniqueDayFilterChange: (newDate, filters, setFilters, localFilters, setLocalFilters) => {
      setLocalFilters({
        ...localFilters,
        startDate: newDate ? newDate : ''
      });
      setFilters({
        ...filters,
        startDate: newDate ? newDate : ''
      });
    }
  },
  waitingListFilter: {
    onWaitingListChange: (data, filters, setFilters, localFilters, setLocalFilters) => {
      setLocalFilters({
        ...localFilters,
        hasWaitingList: data.value === 'Yes'
      });
      setFilters({
        ...filters,
        hasWaitingList: data.value === 'Yes'
      });
    }
  },
  checkBoxFilter: {
    onCheckBoxFilter: (
      config,
      data,
      dataSource,
      setDataSourceFn,
      filters,
      setFilters,
      localFilters,
      setLocalFilters
    ) => {
      const current = [...dataSource];
      let currentSelected = [...filters[config.value]];

      // Handle click on option All
      if (data.key && data.key === Localize.get('App.all')) {
        if (data.checked) {
          current.map((item) => {
            item.checked = true;
            currentSelected.push(item.value);
          });
        } else {
          current.map((item) => {
            item.checked = false;
            currentSelected = [];
          });
        }
      } else {
        if (data.checked) {
          currentSelected.push(data.value);

          let checkedFilter = current.find((type) => type.value === data.value);
          if (checkedFilter) {
            checkedFilter.checked = true;
          }

          // if all are selected but 'All', select 'All'
          const allButAllChecked = current.every((item) => {
            if (item.key === Localize.get('App.all')) {
              return true;
            }
            return currentSelected.includes(item.key);
          });
          if (allButAllChecked) {
            let allFilterOption = current.find((item) => item.key === Localize.get('App.all'));
            if (allFilterOption) {
              allFilterOption.checked = true;
            }
            currentSelected.push(Localize.get('App.all'));
          }
        } else {
          // remove unchecked type from filters
          const selectedIndex = currentSelected.findIndex((type) => type === data.value);
          currentSelected.splice(selectedIndex, 1);

          let checkedFilter = current.find((type) => type.value === data.value);
          checkedFilter.checked = false;

          // if 'All' is checked, uncheck it
          const allOptionCheckedIndex = currentSelected.findIndex(
            (type) => type === Localize.get('App.all')
          );
          if (allOptionCheckedIndex !== -1) {
            currentSelected.splice(allOptionCheckedIndex, 1);
          }

          const allCheckedButton = current.find(
            (element) => element.text === Localize.get('App.all')
          );
          if (allCheckedButton) {
            allCheckedButton.checked = false;
          }
        }
      }

      setLocalFilters({
        ...localFilters,
        [config.value]: currentSelected
      });
      setFilters({
        ...filters,
        [config.value]: currentSelected
      });
    }
  },
  transformFn: {
    repackTypesForMobile: {
      repackTypes: (eventTypeOptions) => {
        return remove(
          eventTypeOptions.map((type) => {
            let item = {};
            item.key = type.key;
            item.text = type.text;
            item.value = type.key;
            item.checked = type.checked;
            return item;
          }),
          (type) => type.text !== Localize.get('App.all')
        );
      }
    }
  }
};
