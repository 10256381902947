import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import * as commonConstants from '@common/Constants.js';
import { createSignUpConfirmService, createSignUpReConfirmService } from '@common/Helper.js';
import ImageColumnContentPlaceholder from '@components/ImageColumnContentPlaceholder/ImageColumnContentPlaceholder.jsx';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useFetching from '@hooks/Fetching/Fetching.js';
import usePromptOnReload from '@hooks/PromptOnReload/PromptOnReload.js';
import * as constants from '@navigation/Constants.js';

import style from './style/AccountCreationMessage.module.scss';
import { getTitle, getContent, getColumnsCount } from './Util.js';

const AccountCreationMessage = ({ match, history }) => {
  const callbackServiceCall = (response) => {
    setResponseMessage(response.data);
  };

  const [contextData, setContextData] = useGlobalContext();
  const [responseMessage, setResponseMessage] = React.useState(
    history?.location?.state?.data ? history.location.state.data : null
  );
  const [confCode, setConfCode] = React.useState('');
  const [userEmail, setUserEmail] = React.useState(
    contextData?.userData?.email ? contextData.userData.email : ''
  );
  const [activationEnabled, setActivationEnabled] = React.useState(false);
  const [setShowReloadPrompt] = usePromptOnReload(false);

  const [, , , confirmSignUpUser] = useFetching(
    createSignUpConfirmService.bind(null, callbackServiceCall, confCode, userEmail),
    true
  );
  const [, , , reSendConfirmationMail] = useFetching(
    createSignUpReConfirmService.bind(null, callbackServiceCall, userEmail),
    true
  );

  const handleCodeConfirmation = () => {
    setActivationEnabled(true);
  };

  const handleHomeClick = () => {
    history.push('/home');
  };

  const onBackClick = () => {
    history.push({
      pathname: constants.routes.name.signup,
      state: {
        from: '',
        path: constants.routes.name.confirm
      }
    });
  };

  const handleOnResendEmailClick = () => {
    reSendConfirmationMail();
  };

  useEffect(() => {
    if (responseMessage.code === 'alreadyExistingAccountMailMsg') {
      setShowReloadPrompt(true);
    }
  }, [responseMessage]);

  useEffect(() => {
    if (history.action === 'POP' && history.location.state) {
      setShowReloadPrompt(false);
      history.push('/home');
    }
  }, [history, setShowReloadPrompt]);

  useEffect(() => {
    if (
      match?.params?.confirmationCode &&
      match?.params?.confirmationCode !== commonConstants.confirmationCodes.confirm
    ) {
      if (match.params.email) {
        setUserEmail(match.params.email);
      }

      setConfCode(match.params.confirmationCode);
      setActivationEnabled(true);
    }

    return () => {
      const email = contextData?.userData?.email;
      if (email) {
        let dataSignUpForm = { ...contextData };
        delete dataSignUpForm.userData;
        dataSignUpForm.userData = { email: email };
        setContextData(dataSignUpForm);
      }
    };
  }, [match]);

  useEffect(() => {
    if (confCode && activationEnabled) {
      confirmSignUpUser();
    }
  }, [activationEnabled, confCode, confirmSignUpUser]);

  useEffect(() => {
    if (
      responseMessage &&
      (responseMessage.code === 'userAuthenticated' ||
        responseMessage.code === 'userAlreadyAuthenticated')
    ) {
      history.push({
        pathname: `${constants.routes.name.login}/${commonConstants.confirmationCodes.confirm}/${userEmail}`,
        state: {
          from: '',
          path: constants.routes.name.verify,
          data: responseMessage
        }
      });
    }
  }, [history, responseMessage]);

  const msgTitle = getTitle(responseMessage);
  let msgContent = getContent(msgTitle);

  const messageContent = () => {
    return (
      <Grid stretched className={style.componentContainer}>
        <Grid.Row only='tablet computer' className={style.homeIconContainer}>
          <Grid.Column computer={10} tablet={10}>
            <Icon name='home' size='big' onClick={handleHomeClick} />
          </Grid.Column>

          <Grid.Column computer={6} tablet={6} />
        </Grid.Row>

        <Grid.Row>
          <Grid.Column computer={3} tablet={3} mobile={1} />
          <Grid.Column computer={10} tablet={10} mobile={14}>
            <Grid stretched>
              <Grid.Row centered>
                <Header as='h2'>{Localize.get('ServerMessages.' + msgTitle)}</Header>
              </Grid.Row>
              <Grid.Row>
                <Divider hidden />
              </Grid.Row>
              <Grid.Row centered={msgTitle === 'somethingWentWrongMsg'}>
                <Message positive size='large' className={style.userMessage}>
                  <Grid>
                    <Grid.Row centered>
                      <p className={style.userMessageFontColor}>
                        {Localize.getHTML('ServerMessages.' + msgContent)}
                      </p>
                    </Grid.Row>
                    {(msgTitle === 'accountCreatedMailMsg' || msgTitle === 'activateAccount') && (
                      <Grid.Row centered>
                        <p>
                          <Button className={style.linkButton} onClick={handleOnResendEmailClick}>
                            {Localize.get('MessagePage.reSendEmail')}
                          </Button>
                        </p>
                      </Grid.Row>
                    )}
                  </Grid>
                </Message>
              </Grid.Row>
              <Grid.Row>
                <Divider hidden />
              </Grid.Row>

              {msgTitle === 'alreadyExistingAccountMailMsg' && (
                <Grid.Row centered>
                  <Button primary icon labelPosition='left' onClick={onBackClick}>
                    {Localize.get('App.back')}
                    <Icon name='left arrow' />
                  </Button>
                </Grid.Row>
              )}

              {msgTitle === 'accountCreatedMailMsg' && (
                <Grid.Row>
                  <Grid.Column computer={2} tablet={2} mobile={1} />
                  <Grid.Column computer={12} tablet={12} mobile={14} textAlign='center'>
                    <Grid.Row>
                      <Header as='h2'>{Localize.get('MessagePage.checkEmail')}</Header>
                    </Grid.Row>
                    <Grid.Row>
                      <Divider hidden />
                    </Grid.Row>
                    <Grid.Row>
                      <Grid centered>
                        <Grid.Column
                          computer={getColumnsCount(contextData.size).computerColumnCount}
                          tablet={12}
                          mobile={14}
                          textAlign='center'
                        >
                          <Container>
                            <p>
                              {Localize.getHTML('MessagePage.activationCodeNotice', {
                                userEmail: userEmail
                              })}
                            </p>
                          </Container>
                        </Grid.Column>
                      </Grid>
                    </Grid.Row>
                    <Grid.Row>
                      <Divider hidden />
                    </Grid.Row>
                    <Grid.Row>
                      <Grid centered>
                        <Grid.Column
                          computer={getColumnsCount(contextData.size).computerColumnCount}
                          tablet={12}
                          mobile={14}
                          textAlign='center'
                        >
                          <Input
                            className={style.activationCodeInput}
                            fluid
                            placeholder={Localize.get('MessagePage.activationCodePlaceholder')}
                            value={confCode}
                            onChange={(event, data) => setConfCode(data.value)}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Row>
                    <Grid.Row>
                      <Divider hidden />
                    </Grid.Row>
                    <Grid.Row>
                      <Grid centered>
                        <Grid.Column
                          computer={getColumnsCount(contextData.size).computerColumnCount - 2}
                          tablet={10}
                          mobile={12}
                          textAlign='center'
                        >
                          <Button
                            fluid
                            primary
                            size='big'
                            compact
                            onClick={() => handleCodeConfirmation(confCode)}
                          >
                            {Localize.get('MessagePage.confirmEmail')}
                          </Button>
                        </Grid.Column>
                      </Grid>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column computer={2} tablet={2} mobile={1} />
                </Grid.Row>
              )}
            </Grid>
          </Grid.Column>
          <Grid.Column computer={3} tablet={3} mobile={1} />
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <>
      {!responseMessage && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}
      <ImageColumnContentPlaceholder
        data-test-id={'userMessagesParentContainer'}
        content={messageContent()}
      />
    </>
  );
};
export default AccountCreationMessage;
