import React from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

import IltSessionDetails from './IltSessionDetails';
import IltSessionSpeakers from './IltSessionSpeakers';
import style from '../EventDetails/Tabs/style/SessionCard.module.scss';

const IltSessionCard = (props) => {
  const { session, contextData } = props;

  return (
    <Segment data-testid={'iltSessionCard'}>
      <Grid>
        <IltSessionDetails session={session} contextData={contextData} />
        <Divider className={style.dividerMargin} />
        <Grid.Row>
          <Grid.Column computer={1} tablet={2} mobile={3} />
          <Grid.Column computer={15} tablet={14} mobile={13}>
            {session?.instructors &&
              session?.instructors.map((speakers, index) => (
                <IltSessionSpeakers speakers={speakers} key={index} contextData={contextData} />
              ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default IltSessionCard;
