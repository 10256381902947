import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

import { cloneDeep } from 'lodash';
// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';

import Logger from '@common/Logger.js';
import EventGridList from '@components/EventGridListView/EventGridList/EventList';
import EventList from '@components/EventListView/EventList/EventList';
import FilterButtons from '@components/FilterButtons/FilterButtons';
import Filters from '@components/Filters/Filters.jsx';
import GridListSwitch from '@components/GridListSwitch/GridListSwitch';
import Pagination from '@components/Pagination/Pagination';
import {
  TrainingGridContainer,
  TrainingGridColumnContainer,
  TrainingListContainer,
  TrainingSearchColumnContainer,
  TrainingPaginationContainer,
  TrainingContentColumnContainer,
  TrainingContentRowContainer,
  TrainingContentListRowContainer,
  TrainingSearchRowContainer,
  StyledDimmer,
  StyledSidebar,
  FlexRowContainer,
  FilterSegment,
  FilterHeader,
  FilterContainerHeader,
  FilterContainerCloseIcon,
  FilterSortIconsColumnContainer,
  FilterSortIconsContainer,
  FiltersMobileRowContainer
} from '@components/StyledComponents/Training.styled';
import predefinedFilterValues from '@config/predefinedFilterValues.config.json';
import useFetching from '@hooks/Fetching/Fetching';
import { createEventTypesService } from '@pages/Catalog/MainHelper';
import {
  repackEventTypes,
  getInitialFilterConfiguration,
  getFiltersUrlPart
} from '@pages/Catalog/Util';

import { createEventService, createEventStatusesService } from '../MainHelper';

const Training = (props) => {
  const { contextData, overviewFilter } = props;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [events, setEvents] = React.useState([]);
  const [eventTypeOptions, setEventTypeOptions] = React.useState([]);
  const [eventStatusesOptions, setEventStatusesOptions] = React.useState([]);
  const [filtersConfig, setFiltersConfig] = React.useState(
    contextData?.configuration?.MyAreaTrainingFilters.filtersConfig
  );
  const [predefinedFilter, setPredefinedFilter] = React.useState(overviewFilter);
  const [filters, setFilters] = React.useState({ searchString: '' });
  const [listView, setListView] = React.useState('grid');
  const [showFilters, setShowFilters] = React.useState(false);
  const [activeFiltersMobile, setActiveFiltersMobile] = React.useState(false);
  const [showResetButtonIcon, setShowResetButtonIcon] = React.useState(false);
  const [appliedFilters, setAppliedFilters] = React.useState({ searchString: '' });

  const resolveFilters = (filterObject = filters, filtersConfigName = filtersConfig) => {
    let searchParams = '';
    if (filterObject && filtersConfigName) {
      searchParams = getFiltersUrlPart(filters, filtersConfigName);
    }
    setShowResetButtonIcon(searchParams !== '');
    return searchParams;
  };

  const [eventData, isLoading, setIsLoading, fetchEvents] = useFetching(
    createEventService.bind(
      null,
      true,
      resolveFilters,
      filters,
      currentPage,
      filtersConfig,
      contextData,
      listView
    ),
    true
  );

  const [typesData, , ,] = useFetching(createEventTypesService);
  const [eventStatusesData, , ,] = useFetching(createEventStatusesService);

  const searchCompRef = React.useRef(null);
  const searchMobRef = React.useRef(null);
  const filtersRef = React.useRef(null);

  useEffect(() => {
    if (contextData?.configuration) {
      setFiltersConfig(contextData?.configuration?.MyAreaTrainingFilters.filtersConfig);
    }
  }, [contextData.configuration]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (filtersConfig) {
          let fltConfig = getInitialFilterConfiguration(filtersConfig);
          if (!Object.keys(predefinedFilter).length) {
            setFilters(fltConfig);
            setAppliedFilters(fltConfig);
          } else {
            const filterValue =
              predefinedFilterValues.filters[Object.keys(predefinedFilter)[0]][
                Object.values(predefinedFilter)[0]
              ];
            let filtersInitConfig = cloneDeep(fltConfig);
            filtersInitConfig[Object.keys(predefinedFilter)[0]].push(filterValue);

            setFilters(filtersInitConfig);
            setAppliedFilters(filtersInitConfig);
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersConfig, predefinedFilter]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size) {
          setIsLoading(true);
          fetchEvents();
          if (searchCompRef?.current?.inputRef?.current) {
            searchCompRef?.current.inputRef.current.scrollIntoView();
          }
          if (searchMobRef?.current?.inputRef?.current) {
            searchMobRef?.current.inputRef.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, contextData]);

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listView]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eventData?.data?.events) {
          setEvents(eventData.data.events);

          setTotalPages(
            Math.ceil(
              eventData.data.eventsCount /
                contextData?.configuration?.PAGINATION.trainingLimit[listView][
                  contextData.size.device.toLowerCase()
                ]
            )
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          typesData &&
          typesData.data &&
          typesData.data.types &&
          typesData.data.types.length &&
          eventData &&
          eventData.data &&
          eventData.data.events &&
          eventData.data.events.length
        ) {
          setEventTypeOptions(typesData.data.types);
          repackEventTypes(eventData.data.events, typesData.data.types);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [typesData, eventData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          eventStatusesData &&
          eventStatusesData.data &&
          eventStatusesData.data.statuses &&
          eventStatusesData.data.statuses.length
        ) {
          setEventStatusesOptions(eventStatusesData.data.statuses);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [eventStatusesData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // after 3 characters are provided,
        // search will be executed
        if (filters.searchString.length > 2 || filters.searchString.length === 0) {
          setIsLoading(true);
          setCurrentPage(1);
          fetchEvents();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.searchString]);

  const onSearchEvents = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setAppliedFilters(filters);

    fetchEvents();
    setShowFilters(false);
  };

  const onResetFilters = () => {
    setIsLoading(true);

    setFilters({
      ...getInitialFilterConfiguration(filtersConfig),
      searchString: filters.searchString
    });
    setAppliedFilters({
      ...getInitialFilterConfiguration(filtersConfig),
      searchString: filters.searchString
    });
    setCurrentPage(1);
    // resets predefined filter
    setPredefinedFilter('');
    // resets event type options
    let types = [...eventTypeOptions];
    types.forEach((type) => {
      type.checked = false;
      type.disabled = false;
    });
    setEventTypeOptions(types);

    // resets event status options
    let statuses = [...eventStatusesOptions];
    statuses.forEach((status) => {
      status.checked = false;
      status.disabled = false;
    });
    setEventStatusesOptions(statuses);

    fetchEvents();
    setShowFilters(false);
  };

  const onSearchFieldChange = (e) => {
    if (e.key === 'Enter') {
      if (filters.searchString.length) {
        // Enter is pressed by user
        // and there is at least 1 character in search field
        setIsLoading(true);
        setCurrentPage(1);
        fetchEvents();
      }
    } else {
      const reg = /'/g;

      setFilters({
        ...filters,
        // eslint-disable-next-line quotes
        searchString: encodeURIComponent(e.target.value.replace(reg, "''").trim())
      });
      setAppliedFilters({
        ...filters,
        // eslint-disable-next-line quotes
        searchString: encodeURIComponent(e.target.value.replace(reg, "''").trim())
      });
    }
  };

  const getContent = () => {
    if (contextData?.size?.device !== 'Mobile') {
      if (listView === 'grid') {
        return (
          <EventGridList
            events={events}
            configuration={contextData?.configuration?.TrainingEventItem}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
            onItemClick={null}
          />
        );
      } else {
        return (
          <EventList
            events={events}
            configuration={contextData?.configuration?.TrainingEventItem}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
            onItemClick={null}
            infoType={Localize.get('Catalog.noEvents')}
          />
        );
      }
    } else {
      return (
        <EventGridList
          events={events}
          configuration={contextData?.configuration?.TrainingEventItem}
          listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
          onItemClick={null}
        />
      );
    }
  };

  const onCloseFilters = () => {
    setFilters(appliedFilters);
    filtersRef.current(events, eventTypeOptions, eventStatusesOptions, appliedFilters);
  };

  return (
    <TrainingGridContainer data-test-id={'trainingGridContainer'}>
      <TrainingGridColumnContainer
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout*/}
        {isLoading && (
          <StyledDimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </StyledDimmer>
        )}

        {/* desktop && tablet layout - menu and content*/}

        <TrainingListContainer className={'tablet computer only'} centered>
          <TrainingContentColumnContainer
            computer={16}
            largeScreen={16}
            widescreen={16}
            tablet={16}
          >
            <Sidebar.Pushable>
              <StyledSidebar
                animation='overlay'
                icon='labeled'
                onHide={() => setShowFilters(false)}
                visible={showFilters}
                width='wide'
                onHidden={onCloseFilters}
              >
                <FilterSegment>
                  <FilterContainerHeader>
                    <FilterHeader>{Localize.get('Catalog.filters')}</FilterHeader>
                    <FilterContainerCloseIcon
                      name='close'
                      size='large'
                      onClick={() => setShowFilters(false)}
                    />
                  </FilterContainerHeader>
                  <Filters
                    allEvents={events}
                    events={events}
                    eventTypes={eventTypeOptions}
                    eventStatuses={eventStatusesOptions}
                    filters={filters}
                    setFilters={setFilters}
                    configuration={contextData?.configuration?.MyAreaTrainingFilters}
                    device={contextData?.size?.device}
                    visible={true}
                    onSearchFn={onSearchEvents}
                    predefinedFilters={predefinedFilter}
                    filtersRef={filtersRef}
                  />
                  <FilterButtons onSearchEvents={onSearchEvents} onResetFilters={onResetFilters} />
                </FilterSegment>
              </StyledSidebar>

              <Sidebar.Pusher dimmed={showFilters}>
                <Grid stackable container centered>
                  <TrainingContentRowContainer>
                    <Grid columns={3}>
                      <FilterSortIconsColumnContainer
                        computer={2}
                        largeScreen={2}
                        widescreen={1}
                        tablet={2}
                      >
                        <FilterSortIconsContainer>
                          <Icon
                            name='filter'
                            size='large'
                            onClick={() => setShowFilters(!showFilters)}
                          />

                          <Dropdown
                            icon={<Icon name='sort numeric down' size='large' />}
                            size='large'
                            floating
                            className='icon'
                          >
                            <Dropdown.Menu>
                              <Dropdown.Item
                                active
                                selected
                                icon={<Icon name='sort numeric down' size='large' />}
                              />
                              <Dropdown.Item>
                                <Icon name='sort numeric up' size='large' />
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Icon name='sort alphabet down' size='large' />
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Icon name='sort alphabet up' size='large' />
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Icon name='sort amount down' size='large' />
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <Icon name='sort amount up' size='large' />
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </FilterSortIconsContainer>
                      </FilterSortIconsColumnContainer>

                      <TrainingSearchColumnContainer
                        computer={12}
                        largeScreen={12}
                        widescreen={14}
                        tablet={12}
                      >
                        <Input
                          fluid
                          icon='search'
                          iconPosition='left'
                          placeholder={Localize.get('Catalog.searchPlaceholder')}
                          onKeyUp={(e) => onSearchFieldChange(e)}
                          ref={searchCompRef}
                        />
                      </TrainingSearchColumnContainer>
                      <Grid.Column computer={2} largeScreen={2} widescreen={1} tablet={2}>
                        <GridListSwitch activeOption={listView} setView={setListView} />
                      </Grid.Column>
                    </Grid>
                  </TrainingContentRowContainer>
                  <TrainingContentListRowContainer id='rooow'>
                    {getContent()}
                  </TrainingContentListRowContainer>
                </Grid>
                <TrainingPaginationContainer centered>
                  {!!totalPages && (
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                    />
                  )}
                </TrainingPaginationContainer>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </TrainingContentColumnContainer>
        </TrainingListContainer>

        {/* mobile layout - content*/}

        <TrainingListContainer centered className={'mobile only'}>
          <Grid.Column width={15}>
            <TrainingSearchRowContainer>
              <Input
                fluid
                icon='search'
                iconPosition='left'
                placeholder={Localize.get('Catalog.searchPlaceholder')}
                onKeyUp={(e) => onSearchFieldChange(e)}
                ref={searchMobRef}
              />
            </TrainingSearchRowContainer>
            <FiltersMobileRowContainer>
              <Accordion styled fluid>
                <Accordion.Title
                  data-test-id={'trainingFiltersMobile'}
                  active={activeFiltersMobile}
                  onClick={() => setActiveFiltersMobile(!activeFiltersMobile)}
                >
                  <FlexRowContainer>
                    <FlexRowContainer>
                      <Icon name='caret right' />
                      <div>{Localize.get('Catalog.filters')}</div>
                    </FlexRowContainer>
                    {showResetButtonIcon && <Icon name='undo alternate' onClick={onResetFilters} />}
                  </FlexRowContainer>
                </Accordion.Title>
                <Accordion.Content active={activeFiltersMobile}>
                  <Filters
                    allEvents={events}
                    events={events}
                    eventTypes={eventTypeOptions}
                    eventStatuses={eventStatusesOptions}
                    filters={filters}
                    setFilters={setFilters}
                    configuration={contextData?.configuration?.MyAreaTrainingFilters}
                    device={contextData?.size?.device}
                    visible={true}
                    predefinedFilters={predefinedFilter}
                    onSearchFn={onSearchEvents}
                  />
                  <FilterButtons onSearchEvents={onSearchEvents} onResetFilters={onResetFilters} />
                </Accordion.Content>
              </Accordion>
            </FiltersMobileRowContainer>
            <Grid.Row>{getContent()}</Grid.Row>
            <Grid.Row centered>
              <TrainingPaginationContainer centered>
                {!!totalPages && (
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    noSiblings={totalPages > 3}
                  />
                )}
              </TrainingPaginationContainer>
            </Grid.Row>
          </Grid.Column>
        </TrainingListContainer>
      </TrainingGridColumnContainer>
    </TrainingGridContainer>
  );
};

export default Training;
