import React from 'react';
import Localize from 'react-intl-universal';

import { Grid, Radio, Icon } from 'semantic-ui-react';

import {
  dateTimeSort,
  formatDateDMYHMWithDayName,
  getIltSessionPrice,
  getSessionLocation
} from '@common/Util';

import style from '../style/RegistrationCourse.module.scss';

const IltSessionsDescription = ({
  iltSessionsForModule,
  selectedSession = '',
  handleIltChange = () => {},
  config
}) => {
  if (!iltSessionsForModule) {
    return null;
  }

  // First we sort Ilt Session based on start date time, then we first show sessions which can be
  // selected (registration period has not expired) and then sessions which can not be selected.
  // Both selected and unselected ilt sessions should be visible only if starting date is in future.
  const now = new Date();

  const sortedIltSessionsForModule = iltSessionsForModule.sort((iltSessionA, iltSessionB) => {
    return dateTimeSort(iltSessionA.startDateTime, iltSessionB.startDateTime);
  });

  const selectableIltSessionsForModule = sortedIltSessionsForModule.filter((session) => {
    const startDate = new Date(session.startDateTime);
    const bookableUntil = new Date(session.registrationUntil);
    return session.hasRegistrationPeriod && bookableUntil > now && startDate > now;
  });

  return selectableIltSessionsForModule?.length ? (
    selectableIltSessionsForModule.map((iltSession, index) => {
      return (
        <Grid.Row key={index} data-testid={'IltSessionsDescription'}>
          <Grid.Column width={1}>
            <Radio
              className={style.largeRadio}
              name='radioGroup'
              value={iltSession.id}
              checked={iltSession.id === selectedSession}
              onChange={handleIltChange}
            />
          </Grid.Column>
          <Grid.Column width={15}>
            <Grid.Row>
              <b>
                {`${formatDateDMYHMWithDayName(
                  new Date(iltSession?.startDateTime)
                )} - ${formatDateDMYHMWithDayName(new Date(iltSession?.endDateTime))}`}
              </b>
            </Grid.Row>
            <Grid.Row className={style.girdRowContainer}>
              <b>{`${getSessionLocation(iltSession)} | ${iltSession?.seminarNumber}`}</b>
            </Grid.Row>
            <Grid.Row>
              <Grid>
                <Grid.Column width={1}>
                  <Icon
                    name={config?.configuration?.header?.userIconName}
                    size={config?.configuration?.header?.userIconSizeDesktop}
                  />
                </Grid.Column>
                {config?.configuration?.Registration?.showSeats && (
                  <Grid.Column width={3}>{`${Localize.get('CourseDetails.Seats')} ${
                    iltSession?.availableSeats
                  }`}</Grid.Column>
                )}
                {config?.configuration?.Registration?.showWaitingList && (
                  <Grid.Column width={3}>
                    {iltSession?.hasWaitingList && Localize.get('CourseDetails.waitingList')}
                  </Grid.Column>
                )}
                {config?.configuration?.Registration?.showPrice && (
                  <Grid.Column width={3}>{`${Localize.get(
                    'CourseDetails.price'
                  )} ${getIltSessionPrice(iltSession)}`}</Grid.Column>
                )}
              </Grid>
            </Grid.Row>
            <Grid.Row>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <Grid.Row>
                      {config?.configuration?.Registration?.showBookingUntil &&
                        iltSession?.hasRegistrationPeriod && (
                          <Grid.Column>{Localize.get('CourseDetails.bookableUntil')}</Grid.Column>
                        )}
                    </Grid.Row>
                    <Grid.Row>
                      {config?.configuration?.Registration?.showCancellationDeadline &&
                        iltSession?.hasCancellationDeadline && (
                          <Grid.Column>
                            {Localize.get('CourseDetails.cancellableUntil')}
                          </Grid.Column>
                        )}
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Grid.Row>
                      {config?.configuration?.Registration?.showBookingUntil &&
                        iltSession?.hasRegistrationPeriod && (
                          <Grid.Column>{`${formatDateDMYHMWithDayName(
                            new Date(iltSession?.registrationUntil)
                          )}`}</Grid.Column>
                        )}
                    </Grid.Row>
                    <Grid.Row>
                      {config?.configuration?.Registration?.showCancellationDeadline &&
                        iltSession?.hasCancellationDeadline && (
                          <Grid.Column>{`${formatDateDMYHMWithDayName(
                            new Date(iltSession?.cancellationDeadline)
                          )}`}</Grid.Column>
                        )}
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      );
    })
  ) : (
    <Grid.Row data-testid={'IltSessionsDescriptionNoContent'} />
  );
};

export default IltSessionsDescription;
