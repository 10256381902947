const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user?.accessToken;
};

const updateLocalAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    user.accessToken = token;
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    throw new Error('sessionCorrupted');
  }
};

const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
  // return { email: 'tobias.rothley@contrimo.com' };
};

const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const getProfile = () => {
  return JSON.parse(localStorage.getItem('profile'));
  // return { email: 'tobias.rothley@contrimo.com' };
};

const setProfile = (profile) => {
  localStorage.setItem('profile', JSON.stringify(profile));
};

const removeUser = () => {
  localStorage.removeItem('user');
};

const tokenUtil = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  setProfile,
  getProfile
};

export default tokenUtil;
