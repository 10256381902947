import React, { useState } from 'react';

import tokenUtil from '@services/tokenUtil';

const GlobalContext = React.createContext(undefined);

const factoryUseContext = (name, context) => {
  return () => {
    const ctx = React.useContext(context);

    if (ctx === undefined) {
      throw new Error(`use${name}Context must be used within a ${name}ContextProvider.`);
    }
    return ctx;
  };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useGlobalContext = factoryUseContext('GlobalContext', GlobalContext);

const GlobalContextProvider = (props) => {
  const authData = tokenUtil.getUser();
  const [contextData, setContextData] = useState({
    loggedIn: !!authData?.email,
    user: authData?.email ? authData.email : false,
    accessToken: authData?.accessToken ? authData.accessToken : null
  });

  return (
    <GlobalContext.Provider value={[contextData, setContextData]}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
