import React, { useEffect, useState } from 'react';
// import useFetching from '@hooks/Fetching/Fetching';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import tokenUtil from '@services/tokenUtil';

import { getTemUser } from './HomeHelper.js';

const TemPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [contextData, setContextData] = useGlobalContext();

  let history = useHistory();

  const email = queryParams.get('email');
  const roles = queryParams.get('roles');
  const token = queryParams.get('token');

  const check = async (email, token, roles) => {
    // axios
    //   .get(
    //     // 'http://localhost:8080/api/v1/user/check-temp-token',
    //     // 'https://api.auth.tem.testcontrimo.com/api/v1/user/check-temp-token',
    //     `https://api.auth.tem.testcontrimo.com/api/v1/litmos-users/${email}`,
    //     // 'https://api.auth.tem.testcontrimo.com/api/v1/saml-logout',
    //     // {},
    //     // { headers: { Authorization: `Bearer ${data}` } }
    //     { headers: { Authorization: `Bearer ${token}` } }
    //   )
    //   .then(({ data }) => {
    //     console.log('data', data);
    //     tokenUtil.setUser({ email: email, role: roles, accessToken: token, profil: data });

    //     setContextData({
    //       ...contextData,
    //       user: email,
    //       role: roles,
    //       accessToken: token,
    //       profil: data
    //     });
    //   })
    //   .catch((e) => {
    //     console.log('CATHC', e);
    //     setContextData({
    //       ...contextData,
    //       user: email,
    //       role: roles,
    //       accessToken: token,
    //       profil: null
    //     });
    //   })

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_TEM_API_LINK}/api/v1/litmos-users/${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': 'true'
          }
        }
      );
      console.log('res', res);
    } catch (e) {
      console.log('e', e);
    }
  };

  // const getTemAndLitmosUser = async () => {
  //   return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.temUser);
  // };

  const LitmosLogIn = async () => {
    try {
      const user = await getTemUser();
      console.log('user', user.data);

      if (user.data.temUser?.token) {
        // tokenUtil.setUser({ email: email, role: roles, accessToken: token });
        // setContextData({
        //   ...contextData,
        //   temUser: user.data.temUser,
        //   litmosUser: user.data.litmosUser
        // });

        // setContextData({
        //   ...contextData,
        //   user: email,
        //   role: roles,
        //   accessToken: token,
        //   profilTEM: user.data.temUser,
        //   profilLitmos: user.data.litmosUser
        // });
        tokenUtil.setProfile({ profilTem: user.data.temUser, profilLitmos: user.data.litmosUser });

        window.location.href = user.data.litmosUser.loginKey;
        // window.location.href = '/';
      } else {
        localStorage.clear();
        window.location.href = '/';
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  console.log('roles', roles);
  console.log('email', email);
  console.log(contextData);

  useEffect(() => {
    tokenUtil.setUser({ email: email, role: roles, accessToken: token });

    setContextData({
      ...contextData,
      user: email,
      role: roles,
      accessToken: token
    });
    // check(email, token, roles);
  }, []);

  useEffect(() => {
    console.log(tokenUtil.getUser());
    // history.push('/');

    LitmosLogIn();
    // window.location.href = '/';
  }, [contextData]);

  return <div>Loading...</div>;
};

export default TemPage;
