import React from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer';
import style from '@pages/EventDetails/Tabs/style/Agenda.module.scss';

import ModuleCard from '../Components/ModuleCard';

const Agenda = (props) => {
  const { course, config } = props;
  const courseModules = course.COURSE_MODULES;

  const isLoading = false;

  const getCourseModules = () => {
    return courseModules.map((module, index) => (
      <ModuleCard key={index} itemNo={index} module={module} course={course} config={config} />
    ));
  };

  return (
    <>
      <Grid data-test-id={'courseAgendaContainer'} columns={1} stackable>
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        {courseModules && courseModules.length ? (
          getCourseModules()
        ) : (
          <div>
            <Grid columns={3} stackable className='tablet computer only'>
              <Grid.Row />
              <Grid.Row>
                <Grid.Column computer={1} tablet={1} />
                <Grid.Column computer={14} tablet={14}>
                  <NoContentComponent infoType={Localize.get('CourseDetails.noModules')} />
                </Grid.Column>
                <Grid.Column computer={1} tablet={1} />
              </Grid.Row>
            </Grid>
            <Grid columns={1} className='mobile only'>
              <Grid.Column mobile={16} className={style.noSessionsMobileResolution}>
                <NoContentComponent infoType={Localize.get('CourseDetails.noModules')} />
              </Grid.Column>
            </Grid>
          </div>
        )}
      </Grid>
    </>
  );
};

export default Agenda;
