import React from 'react';
import Localize from 'react-intl-universal';

import PrivacyPolicyDeConfig from '../../config/privacyPolicyDe.config';

const PrivacyPolicy = () => {
  const configByLocale = {
    // en: PrivacyPolicyEnConfig, // TO DO: uncomment when privacyPolicyEn.config is added
    de: <PrivacyPolicyDeConfig />
  };

  const privacyPolicyConfig = configByLocale[Localize.options.currentLocale] || (
    <PrivacyPolicyDeConfig />
  );

  return (
    <div data-testid={'PrivacyPolicy'} style={{ paddingLeft: '1rem' }}>
      {privacyPolicyConfig}
    </div>
  );
};
export default PrivacyPolicy;
