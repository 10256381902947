import React from 'react';

// semantic
import { Image, List } from 'semantic-ui-react';

import style from '../EventDetails/Tabs/style/SessionSpeakers.module.scss';

const IltSessionSpeakers = (props) => {
  const { speakers, contextData } = props;

  //TO DO set this value to appropriate speakers key
  const imageSource = null;

  return (
    <>
      <List horizontal data-testid={'iltSessionCardSpeakers'}>
        <List.Item className={style.itemMargin}>
          {speakers && imageSource ? (
            <Image size='mini' src={imageSource} className={style.avatarStyle} />
          ) : (
            <List.Icon bordered size='large' className={`user outline ${style.listIcon}`} />
          )}

          <List.Content>
            <List.Header>{speakers.fullName}</List.Header>
            {contextData?.configuration?.AllSessionAgendaTab.speakersOrganisationName && (
              <List.Description>{speakers?.organisationName}</List.Description>
            )}
            {contextData?.configuration?.AllSessionAgendaTab.speakersRole && (
              <List.Description className={style.speakerIconColorStyle}>
                {speakers.role}
              </List.Description>
            )}
          </List.Content>
        </List.Item>
      </List>
    </>
  );
};

export default IltSessionSpeakers;
