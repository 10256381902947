import React from 'react';

// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';

import { mapPropsToComponentType } from '@common/mapPropsToComponent';
import formComponent from '@components/Form/FormComponents';

import { FormInputStyled, FormPasswordStyled } from './style/FormComponents.styled';

const LogInForm = (props) => {
  const { config } = props;
  const fields = mapPropsToComponentType(config, {
    ['text']: FormInputStyled,
    ['passwordInput']: FormPasswordStyled
  });

  return (
    <>
      <Form>
        {fields.map((fields, groupIndex) => {
          return (
            <Form.Group key={groupIndex}>
              {fields.map(({ component, label, ...rest }, fieldIndex) => {
                return formComponent({ component, label, ...rest }, fieldIndex);
              })}
            </Form.Group>
          );
        })}
      </Form>
    </>
  );
};
export default LogInForm;
