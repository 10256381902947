import { format } from 'date-fns';

export const iltSessionsTableColumns = [
  {
    field: 'startDateTime',
    headerName: 'MyAreaIltSessionsTab.startTime',
    type: 'dateTime',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 150,
    sortable: true,
    valueGetter: (params) => new Date(params?.row?.startDateTime),
    valueFormatter: (params) => format(params?.value, 'dd.MM.yyyy HH:mm')
  },
  {
    field: 'endDateTime',
    headerName: 'MyAreaIltSessionsTab.endTime',
    type: 'dateTime',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 150,
    sortable: true,
    valueGetter: (params) => new Date(params?.row?.endDateTime),
    valueFormatter: (params) => format(params?.value, 'dd.MM.yyyy HH:mm')
  },
  {
    field: 'instructors',
    headerName: 'MyAreaIltSessionsTab.instructors',
    type: 'singleSelect',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 210,
    sortable: true
  },
  {
    field: 'name',
    headerName: 'MyAreaIltSessionsTab.name',
    type: 'string',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 210,
    sortable: true
  },
  {
    field: 'courseName',
    headerName: 'MyAreaIltSessionsTab.course',
    type: 'singleSelect',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 210,
    sortable: true
  },
  {
    field: 'moduleName',
    headerName: 'MyAreaIltSessionsTab.module',
    type: 'singleSelect',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 220,
    sortable: true
  },
  {
    field: 'sessionType',
    headerName: 'MyAreaIltSessionsTab.sessionType',
    type: 'string',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 160,
    sortable: true
  },
  {
    field: 'status',
    headerName: 'MyAreaIltSessionsTab.status',
    type: 'string',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 120,
    sortable: true
  },
  {
    field: 'seminarNumber',
    headerName: 'MyAreaIltSessionsTab.seminarNumber',
    type: 'string',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 180,
    sortable: true
  },
  {
    field: 'locations',
    headerName: 'MyAreaIltSessionsTab.location',
    type: 'singleSelect',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 220,
    sortable: true
  },
  {
    field: 'cities',
    headerName: 'MyAreaIltSessionsTab.city',
    type: 'singleSelect',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    width: 180,
    sortable: true
  }
];
