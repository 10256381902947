export const bookingType = {
  forLoggedInUser: 'forMe',
  forMyTeam: 'forMyTeam',
  forSummary: 'forSummary',
  confirmationPage: 'confirmationPage'
};

export const bookingRole = {
  manager: 'Manager',
  learner: 'Learner',
  trainer: 'Trainer',
  admin: 'Admin',
  teamAdmin: 'Team Admin',
  locationResponsible: 'Location Responsible'
};

export const courseSessionType = {
  classroom: 'CLASSROOM',
  webinar: 'WEBINAR'
};

export const moduleType = {
  iltModule: 'ILTModule'
};

export const steps = {
  back: 'back',
  next: 'next',
  skip: 'skip'
};

export const days = {
  three: 3,
  four: 4
};
