import React from 'react';

import EventItemContent from '@components/EventGridListView/EventGridItems/EventItemContent';
import EventItemFooter from '@components/EventGridListView/EventGridItems/EventItemFooter';
import EventItemHeader from '@components/EventGridListView/EventGridItems/EventItemHeader';
import { StyledCard } from '@components/StyledComponents/EventItem.styled';

const EventItem = (props) => {
  const {
    eventId,
    eventTypeName,
    eventName,
    startDate,
    endDate,
    city,
    description,
    langId,
    price,
    currency,
    country,
    onItemClick,
    image, // TODO: after integration with litmos, image should part of the data
    config,
    combinedVisit,
    guidedReq,
    nameTag,
    noFlex,
    eventStartString
  } = props;

  const customConfig = config;

  return (
    <>
      <StyledCard
        data-test-id={'eventItemContent'}
        fluid
        link
        onClick={onItemClick ? () => onItemClick(eventId, eventTypeName) : null}
      >
        {/* header */}
        <EventItemHeader
          eventTypeName={eventTypeName}
          startDate={startDate}
          langId={langId}
          config={customConfig}
        />
        {/* content */}
        <EventItemContent
          startDate={startDate}
          endDate={endDate}
          eventTypeName={eventTypeName}
          eventName={eventName}
          description={description}
          eventStartString={eventStartString}
          image={image}
          config={customConfig}
          city={city}
          country={country}
          combinedVisit={combinedVisit}
          guidedReq={guidedReq}
          nameTag={nameTag}
          noFlex={noFlex}
        />
        {/* footer */}
        {customConfig && customConfig.footer && (
          <EventItemFooter
            city={city}
            price={price}
            currency={currency}
            country={country}
            config={customConfig}
          />
        )}
      </StyledCard>
    </>
  );
};

export default EventItem;
