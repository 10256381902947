export const tabOptions = {
  information: 'Information',
  agenda: 'Agenda',
  participants: 'Participants',
  hotel: 'Hotel',
  attachments: 'Attachments',
  notes: 'Notes'
};

export const notesTypes = {
  agenda: { name: 'Agenda', key: 'AGENDA' },
  trainer: { name: 'Trainer Note', key: 'TRAINER_NOTE' },
  location: { name: 'Location Area Note', key: 'LOCATION_AREA_NOTE' }
};

export const customFooterSelectItems = [
  'none',
  'markComplete',
  'markIncomplete',
  'markAttended',
  'markAbsent'
];

export const TRAINER_AREA_PATH = '/trainerArea';
export const uploadModalVisibilityItems = [
  {
    id: 'visibility',
    name: 'visibleToAdministrators',
    label: 'administration',
    disabled: true
  },
  {
    name: 'visibleToTrainers',
    label: 'trainers',
    disabled: true
  },
  {
    name: 'visibleToParticipants',
    label: 'participants',
    disabled: false
  },
  {
    name: 'visibleToLocationResponsible',
    label: 'locationResp',
    disabled: false
  }
];

export const menuItems = [
  {
    key: tabOptions.information,
    label: 'EventDetails.information',
    iconName: 'info',
    dataTestId: 'detailsMenuItemsInfo',
    showTab: 'showInformationTab',
    configName: 'information'
  },
  {
    key: tabOptions.agenda,
    label: 'EventDetails.agenda',
    iconName: 'calendar outline',
    dataTestId: 'detailsMenuItemsAgenda',
    showTab: 'showAgendaTab',
    configName: 'agenda'
  },
  {
    key: tabOptions.participants,
    label: 'TrainerAreaDetails.participants',
    iconName: 'user',
    dataTestId: 'detailsMenuItemsParticipants',
    showTab: 'showParticipantsTab',
    configName: 'participants'
  },
  {
    key: tabOptions.hotel,
    label: 'TrainerAreaDetails.hotel',
    iconName: 'bed',
    dataTestId: 'detailsMenuItemsHotel',
    showTab: 'showHotelTab',
    configName: 'hotel'
  },
  {
    key: tabOptions.attachments,
    label: 'EventDetails.attachments',
    iconName: 'attach',
    dataTestId: 'detailsMenuItemsAttachments',
    showTab: 'showAttachmentsTab',
    configName: 'attachments'
  },
  {
    key: tabOptions.notes,
    label: 'EventDetails.notes',
    iconName: 'clipboard outline',
    dataTestId: 'detailsMenuItemsNotes',
    showTab: 'showNotesTab',
    configName: 'notes'
  }
];

export const mobileTabDefinitions = [
  { labelKey: 'information', icon: 'info' },
  { labelKey: 'agenda', icon: 'calendar outline' },
  { labelKey: 'participants', icon: 'user' },
  { labelKey: 'hotel', icon: 'bed' },
  { labelKey: 'attachments', icon: 'attach' },
  { labelKey: 'notes', icon: 'clipboard outline' }
];
