import React from 'react';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import NoContentComponent from './NoContentComponent.jsx';

const NoContentComponentContainer = (props) => {
  const { infoType, infoMessage } = props;

  return (
    <Grid centered columns={1} container>
      <Grid.Row>
        <Grid.Column computer={16} mobile={14}>
          <NoContentComponent
            data-test-id={'noContentComponent'}
            infoType={infoType}
            infoMessage={infoMessage}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default NoContentComponentContainer;
