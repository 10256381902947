import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';

export const formatObjForEdit = (attach) => ({
  displayName: attach?.descriptionField?.description,
  visibleToAdministrators: attach?.availableFor?.visibleToAdministrators,
  visibleToTrainers: attach?.availableFor?.visibleToTrainers,
  visibleToParticipants: attach?.availableFor?.visibleToParticipants,
  visibleToLocationResponsible: attach?.availableFor?.visibleToLocationResponsible
});

export const getObjectDiff = (a, b) =>
  reduce(a, (result, value, key) => (isEqual(value, b[key]) ? result : result.concat(key)), []);
