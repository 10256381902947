import React, { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';

import { SimpleTableDiv } from '@components/StyledComponents/SimpleTable.styled';
import Stack from '@mui/material/Stack';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import CustomFooter from './CustomFooter';

export const PAGE_SIZE_OPTIONS = [5, 10, 15, 20, 100];

const SimpleTable = ({
  data = [],
  columns,
  paginationModel,
  setPaginationModel,
  elementsCount,
  isMobile = false,
  config,
  buttonConfig,
  showButton = false,
  showSelect = false,
  onMenuClick = () => {},
  paginationMode = 'server',
  onRowClick = () => {},
  allowCheckBox = true //show or hide checkBox at the beginning of row
}) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    name: true,
    // for the Hörmann we will not have result
    result: false
  });

  const [action, setAction] = useState('');

  const [rowCountState, setRowCountState] = useState(elementsCount || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      elementsCount !== undefined ? elementsCount : prevRowCountState
    );
  }, [elementsCount, setRowCountState]);

  const CustomNoRowsOverlay = () => (
    <Stack height='100%' alignItems='center' justifyContent='center'>
      {Localize.get('TrainerAreaParticipantsTable.noRows')}
    </Stack>
  );

  return (
    <SimpleTableDiv data-testid={'simpleTable'} isMobile={isMobile.toString()}>
      <DataGrid
        sx={{
          fontSize: '14px',
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
            fontWeight: 'bold'
          },
          '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            height: 'unset !important'
          },
          '& .MuiDataGrid-columnHeaders': {
            // Forced to use important since overriding inline styles
            maxHeight: '168px !important'
          },
          '& .MuiDataGrid-toolbarContainer': {
            paddingLeft: '1rem'
          },
          '& .MuiTablePagination-selectLabel': {
            margin: '0 !important'
          },
          '& .MuiTablePagination-displayedRows': {
            margin: '0 !important'
          },
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          }
        }}
        rows={data}
        columns={columns}
        getRowId={(data) => data?.id ?? ''}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        checkboxSelection={allowCheckBox}
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
          footer: CustomFooter,
          noRowsOverlay: CustomNoRowsOverlay
        }}
        slotProps={{
          toolbar: {
            printOptions: {
              disableToolbarButton:
                config?.configuration?.AllSessionParticipantsTab.disableToolbarButton
            },
            csvOptions: {
              disableToolbarButton:
                config?.configuration?.AllSessionParticipantsTab.disableToolbarButton
            }
          },
          footer: {
            buttonConfig: buttonConfig,
            showSelect: showSelect,
            showButton: showButton,
            action: action,
            setAction: setAction,
            onMenuClick: onMenuClick
          }
        }}
        autoHeight
        disableRowSelectionOnClick
        rowCount={paginationMode === 'server' ? rowCountState : null}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationModel={paginationModel}
        paginationMode={paginationMode}
        onPaginationModelChange={setPaginationModel}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        onRowClick={onRowClick}
      />
    </SimpleTableDiv>
  );
};

export default SimpleTable;
