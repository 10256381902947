import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Item from 'semantic-ui-react/dist/commonjs/views/Item';

import { isAfterToday } from '@common/Util';
import SessionCard from '@pages/CourseDetails/Components/SessionCard';
import style from '@pages/EventDetails/Tabs/style/SessionCard.module.scss';
import speakerStyle from '@pages/EventDetails/Tabs/style/SessionSpeakers.module.scss';

const ModuleCard = (props) => {
  const { itemNo, module, course, config } = props;
  const [courseSessions, setCourseSessions] = useState(null);

  useEffect(() => {
    if (course.COURSE_SESSIONS?.length) {
      setCourseSessions(
        course.COURSE_SESSIONS.filter((session) => session.module.id === module.ID).filter(
          (session) => isAfterToday(session.startDateTime)
        )
      );
    } else {
      setCourseSessions(null);
    }
  }, [course]);

  return (
    <>
      <Grid.Column stretched data-test-id={'moduleCardContainer'}>
        <Segment>
          <Grid.Row className={style.speakersContainer}>
            <Grid columns={1} container stackable className={style.speakersHolder}>
              <Grid.Column verticalAlign='middle'>
                <Item
                  className={speakerStyle.speakerContainer}
                  data-test-id={'moduleCardContainerItem'}
                >
                  <Grid columns={16}>
                    <Grid.Column
                      width={3}
                      verticalAlign='middle'
                      className={speakerStyle.avatarContainer}
                    >
                      <Item.Content className={speakerStyle.speakerDetails}>
                        <Item.Description className={speakerStyle.speakerDetailsStyle}>
                          {config?.configuration?.useLitmosModulesTypesNames
                            ? Localize.get(`CourseDetails.moduleType${module.MODULE_TYPE_NAME}`)
                            : Localize.get(`CourseDetails.moduleType${itemNo}`)}
                        </Item.Description>
                      </Item.Content>
                    </Grid.Column>
                    <Grid.Column width={13} className={speakerStyle.detailsContainer}>
                      <Item.Content className={speakerStyle.speakerDetails}>
                        <Item.Description>{module.DESCRIPTION}</Item.Description>
                        {course.COURSE_TYPE_NAME === 'Course' && courseSessions?.length ? (
                          <SessionCard sessions={courseSessions} />
                        ) : (
                          <></>
                        )}
                      </Item.Content>
                    </Grid.Column>
                  </Grid>
                </Item>
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Segment>
      </Grid.Column>
    </>
  );
};

export default ModuleCard;
