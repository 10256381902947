import React, { useState } from 'react';
import Localize from 'react-intl-universal';
import { useHistory } from 'react-router-dom';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

import {
  MyAreaGridContainer,
  MyAreaColumnContentContainer,
  MyAreaGridColumnContainer,
  MyAreaRowContentContainer,
  MyAreaContentContainer,
  MyAreaMobileMenu,
  StyledTabMenu
} from '@components/StyledComponents/MyArea.styled';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import { HOERMANN_LINKS } from '@services/Constants';

import * as constants from './Constants';
import TabMenuItems from './MyAreaTabItems';
import IltSessions from './Tabs/IltSessions';
import LearningContent from './Tabs/LearningContent';
import Overview from './Tabs/Overview';
import Training from './Tabs/Training';

const MyArea = () => {
  const [contextData] = useGlobalContext();

  let history = useHistory();
  const [activeItem, setActiveItem] = useState(
    history?.location?.state?.from === Localize.get('MyArea.learningContent')
      ? constants.tabOptions.learningContent
      : history?.location?.state?.from === Localize.get('MyArea.iltSessions')
      ? constants.tabOptions.iltSessions
      : constants.tabOptions.overview
  );
  const [overviewFilter, setOverviewFilter] = useState('');

  const handleOverviewFilterClick = (filter) => {
    const filterName = Object.keys(filter)[0];
    switch (filterName) {
      case 'booked':
        break;
      case 'status':
        // TODO depending on status user will be redirected to
        // Learning Content or ILT Sessions page with predefined filters
        // for now he is redirected to Learning Content page
        setActiveItem(constants.tabOptions.learningContent);
        setOverviewFilter(filter);
        break;
      case 'assignedContent':
        break;

      default:
        break;
    }
  };

  const learningContentComponent = (
    <LearningContent contextData={contextData} overviewFilter={overviewFilter} />
  );

  const overviewComponent = (
    <Overview contextData={contextData} handleOverviewFilterClick={handleOverviewFilterClick} />
  );

  const trainingComponent = <Training contextData={contextData} overviewFilter={overviewFilter} />;
  const achievementsAndCertificatesComponent = <div>achievementsAndCertificates</div>;
  const iltSessionsComponent = <IltSessions contextData={contextData} />;

  const handleActiveComponent = (component) => {
    switch (component) {
      case constants.tabOptions.overview:
        return overviewComponent;
      case constants.tabOptions.customerLoyaltyEvents:
        return trainingComponent;
      case constants.tabOptions.learningContent:
        return learningContentComponent;
      case constants.tabOptions.achievementsAndCertificates:
        return achievementsAndCertificatesComponent;
      case constants.tabOptions.iltSessions:
        return iltSessionsComponent;
      default:
        return null;
    }
  };

  const handleItemClick = (_, data) => {
    if (data.name === constants.tabOptions.achievementsAndCertificates) {
      window.open(HOERMANN_LINKS.achievementsAndCertificates, '_blank');
    } else {
      setOverviewFilter('');
      setActiveItem(data.name);
    }
  };

  return (
    <MyAreaGridContainer data-test-id={'myAreaGridContainer'}>
      <MyAreaGridColumnContainer
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout - menu and content*/}
        <MyAreaContentContainer className={'tablet computer only'} centered>
          <MyAreaColumnContentContainer computer={16} largeScreen={14} widescreen={10} tablet={16}>
            <StyledTabMenu pointing secondary widths={4}>
              <TabMenuItems activeItem={activeItem} handleItemClick={handleItemClick} />
            </StyledTabMenu>

            <Grid stackable container centered>
              <MyAreaRowContentContainer>
                {handleActiveComponent(activeItem)}
              </MyAreaRowContentContainer>
            </Grid>
          </MyAreaColumnContentContainer>
        </MyAreaContentContainer>

        {/* mobile layout - content*/}

        {/* 
        condition check is necessary 
        if it is not present it will render the child component 
        (and all other sub-components) more than once, 
        because of the current implementation
        */}

        {contextData?.size?.device === 'Mobile' && (
          <MyAreaGridContainer centered className={'mobile only'}>
            <Grid.Column width={16}>
              <Grid.Row>
                <Dropdown
                  placeholder={Localize.get(`MyArea.${activeItem}`)}
                  fluid
                  className='ui selection'
                >
                  <MyAreaMobileMenu>
                    <TabMenuItems activeItem={activeItem} handleItemClick={handleItemClick} />
                  </MyAreaMobileMenu>
                </Dropdown>
              </Grid.Row>
              <Grid.Row>{handleActiveComponent(activeItem)}</Grid.Row>
            </Grid.Column>
          </MyAreaGridContainer>
        )}
      </MyAreaGridColumnContainer>
    </MyAreaGridContainer>
  );
};

export default MyArea;
