import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import * as constants from './Constants';
import style from './style/DetailsMenuItems.module.scss';

const DetailsMenuItems = (props) => {
  const { activeItem, handleItemClick, showIcon, entity, showItem } = props;

  return (
    <>
      {showItem?.overview && (
        <Menu.Item
          data-testid={'overviewMenuItemEventDetails'}
          name={constants.tabOptions.overview}
          active={activeItem === constants.tabOptions.overview}
          onClick={handleItemClick}
          disabled={false}
        >
          {Localize.get('EventDetails.overview')}
          {showIcon && <Icon name='calendar outline' className={style.itemIcon} />}
        </Menu.Item>
      )}
      {showItem?.information && (
        <Menu.Item
          name={constants.tabOptions.information}
          active={activeItem === constants.tabOptions.information}
          onClick={handleItemClick}
          disabled={false}
        >
          {Localize.get('EventDetails.information')}
          {showIcon && <Icon name='info' className={style.itemIcon} />}
        </Menu.Item>
      )}
      {showItem?.agenda && (
        <Menu.Item
          data-testid={'agendaMenuItemEventDetails'}
          name={constants.tabOptions.agenda}
          active={activeItem === constants.tabOptions.agenda}
          onClick={handleItemClick}
          disabled={false}
        >
          {Localize.get('EventDetails.agenda')}
          {showIcon && <Icon name='calendar outline' className={style.itemIcon} />}
        </Menu.Item>
      )}
      {showItem?.attachments && (
        <Menu.Item
          name={constants.tabOptions.attachments}
          active={activeItem === constants.tabOptions.attachments}
          onClick={handleItemClick}
          disabled={entity === 'course'}
        >
          {Localize.get('EventDetails.attachments')}
          {showIcon && <Icon name='attach' className={style.itemIcon} />}
        </Menu.Item>
      )}
      {showItem?.notes && (
        <Menu.Item
          name={constants.tabOptions.notes}
          active={activeItem === constants.tabOptions.notes}
          onClick={handleItemClick}
          disabled={entity === 'course'}
        >
          {Localize.get('EventDetails.notes')}
          {showIcon && <Icon name='clipboard outline' className={style.itemIcon} />}
        </Menu.Item>
      )}
      <Menu.Item
        name={constants.tabOptions.sessions}
        active={activeItem === constants.tabOptions.sessions}
        onClick={handleItemClick}
        className={style.hiddenTabsStyle}
        disabled={entity === 'course'}
      >
        {Localize.get('EventDetails.sessions')}
        {showIcon && <Icon name='list' className={style.itemIcon} />}
      </Menu.Item>
      <Menu.Item
        name={constants.tabOptions.speakers}
        active={activeItem === constants.tabOptions.speakers}
        onClick={handleItemClick}
        className={style.hiddenTabsStyle}
        disabled={entity === 'course'}
      >
        {Localize.get('EventDetails.speakers')}
        {showIcon && <Icon name='user outline' className={style.itemIcon} />}
      </Menu.Item>
      <Menu.Item
        name={constants.tabOptions.hotels}
        active={activeItem === constants.tabOptions.hotels}
        onClick={handleItemClick}
        className={style.hiddenTabsStyle}
        disabled={entity === 'course'}
      >
        {Localize.get('EventDetails.hotels')}
        {showIcon && <Icon name='hotel' className={style.itemIcon} />}
      </Menu.Item>
      <Menu.Item
        name={constants.tabOptions.messages}
        active={activeItem === constants.tabOptions.messages}
        onClick={handleItemClick}
        className={style.hiddenTabsStyle}
        disabled={entity === 'course'}
      >
        {Localize.get('EventDetails.messages')}
        {showIcon && <Icon name='comment outline' className={style.itemIcon} />}
      </Menu.Item>
    </>
  );
};

export default DetailsMenuItems;
