import React from 'react';
import Localize from 'react-intl-universal';

import { getYear } from 'date-fns';

import { openLegalNoticeTab, openPrivacyPolicyTab } from '@common/Util';

import style from './style/AppFooter.module.scss';
import { Footer, FooterContent, FooterButtons } from '../StyledComponents/Footer.styled';

const AppFooter = (props) => {
  const { config } = props;

  return (
    <Footer configuration={config} data-testid={'footerContainer'}>
      <FooterContent>{`© ${getYear(new Date())} ${config?.footer?.copyRights}`}</FooterContent>
      <FooterButtons>
        <span
          className={style.footerLinks}
          onClick={openLegalNoticeTab}
          onKeyDown={() => {}}
          onKeyUp={() => {}}
          role='button'
          tabIndex={0}
        >
          {Localize.get('Footer.legalNotice')}
        </span>
        <span
          className={style.footerLinks}
          onClick={openPrivacyPolicyTab}
          onKeyDown={() => {}}
          onKeyUp={() => {}}
          role='button'
          tabIndex={0}
        >
          {Localize.get('Footer.privacyPolicy')}
        </span>
      </FooterButtons>
    </Footer>
  );
};
export default AppFooter;
