import React, { useState, useEffect, Fragment } from 'react';
import Localize from 'react-intl-universal';

import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';

import { AccordionTitle } from '@components/StyledComponents/Filters.styled';

const CheckboxFilterGroup = ({
  title = '',
  page = '',
  defaultValue = '',
  types = [],
  resetForm = false,
  handleCheckboxFilters = () => {}
}) => {
  const [openAccordion, setOpenAccordion] = useState(true);
  const [checked, setChecked] = useState({});

  const handleChange = (_, { value }) => {
    setChecked({ value });
    handleCheckboxFilters(value);
  };

  const onAccordionClick = () => {
    setOpenAccordion(!openAccordion);
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange(null, { value: defaultValue });
    }
    if (resetForm) {
      handleReset();
    }
  }, [defaultValue, resetForm]);

  const handleReset = () => {
    setChecked({});
  };

  return (
    <Grid.Row data-testid='CheckboxFilterGroup'>
      <Grid.Column computer={16} largeScreen={16} widescreen={16} tablet={16} mobile={16}>
        <Accordion data-testid='accordionFilterGroup' fluid styled>
          <AccordionTitle
            data-testid='accordionTitle'
            active={openAccordion}
            onClick={onAccordionClick}
          >
            <Icon name='dropdown' />
            {title}
          </AccordionTitle>
          <Accordion.Content data-testid='accordionContent' active={openAccordion}>
            <Form>
              {types?.map((type) => (
                <Fragment key={type}>
                  <Form.Group widths='equal'>
                    <Checkbox
                      data-testid={`${type}`}
                      label={Localize.get(`${page}.${type}`)}
                      name='checkboxGroup'
                      value={`${type}`}
                      checked={checked.value === `${type}`}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Fragment>
              ))}
            </Form>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
};

export default CheckboxFilterGroup;
