import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import truncate from 'lodash/truncate';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import { httpStatusCodes } from '@common/Constants';
import {
  createAttachmentDownloadService,
  createAttachmentDeleteByIdService,
  createAttachmentEditByIdService
} from '@common/Helper';
import CommonModal from '@components/CommonModal/CommonModal';
import { StyledTypography } from '@components/StyledComponents/TableHeading.styled';
import useFetching from '@hooks/Fetching/Fetching';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import useDownloadItem from './hooks/useDownloadItem';
import {
  createAttachmentServiceForIltSessions,
  createAttachmentUploadService
} from '../AllSessionHelper';
import AttachmentAvailableField from '../AttachmentAvailableField';
import AttachmentDescriptionField from '../AttachmentDescriptionField';
import AttachmentOptionsField from '../AttachmentOptionsField';
import SimpleTable from '../SimpleTable';
import style from '../style/Attachments.module.scss';
import UploadModal from '../UploadModal';
import { formatObjForEdit, getObjectDiff } from '../Util';

const initialForm = {
  displayName: '',
  visibleToAdministrators: true,
  visibleToTrainers: true,
  visibleToParticipants: false,
  visibleToLocationResponsible: false
};

const Attachments = (props) => {
  const { match, config, callBackError } = props;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const [sessionID] = useState(match?.params?.id ? match.params.id : '');
  const [attachments, setAttachments] = useState([]);
  const [attachmentId, setAttachmentId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openCommonModal, setOpenCommonModal] = useState(false);
  const [attachmentForDelete, setAttachmentForDelete] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [file, setFile] = useState(null);
  const [attachForm, setAttachForm] = useState(initialForm);

  const [attachmentsData, isLoading, , fetchAttachments] = useFetching(
    createAttachmentServiceForIltSessions.bind(null, sessionID),
    true
  );

  const [attachmentDownloadData, , , fetchAttachmentDownloadData] = useFetching(
    createAttachmentDownloadService.bind(null, attachmentId, callBackError),
    true
  );
  const { downloadFile } = useDownloadItem(attachmentDownloadData);

  const findAttachment = (id) => attachments.find((attachment) => attachment?.id === Number(id));

  const handleOptions = ({ currentTarget }) => {
    setAttachmentId(currentTarget.id);
    const attach = findAttachment(currentTarget.id);
    const attachObjForEdit = formatObjForEdit(attach);
    switch (currentTarget.name) {
      case 'download':
        fetchAttachmentDownloadData();
        break;
      case 'edit':
        setIsEditMode(true);
        setFile({ name: attach?.descriptionField?.fileName });
        setAttachForm(attachObjForEdit);
        setOpenModal(true);
        break;
      case 'delete':
        setAttachmentForDelete(findAttachment());
        setOpenCommonModal(true);
        break;
      default:
    }
  };

  const handleDelete = async () => {
    const response = await createAttachmentDeleteByIdService(
      sessionID,
      attachmentId,
      callBackError
    );
    if (response?.status === httpStatusCodes.ok) {
      fetchAttachments();
    }
  };

  const onSearchFieldChange = () => {}; // TO DO --- use this func after BE is finished

  const getElement = (params) => {
    switch (params.field) {
      case 'descriptionField':
        return <AttachmentDescriptionField params={params?.value} />;
      case 'availableFor':
        return <AttachmentAvailableField params={params?.value} />;
      case 'options':
        return <AttachmentOptionsField params={params} handleOptions={handleOptions} />;
      default:
        return <StyledTypography>{truncate(get(params.row, params.field))}</StyledTypography>;
    }
  };

  const handleUploadModal = () => {
    setOpenModal(true);
  };

  const uploadFile = async (uploadState, file) => {
    const response = await createAttachmentUploadService(
      sessionID,
      'ilt_session',
      uploadState.displayName,
      uploadState.visibleToAdministrators,
      uploadState.visibleToTrainers,
      uploadState.visibleToParticipants,
      uploadState.visibleToLocationResponsible,
      file
    );

    if (response?.status === httpStatusCodes.created) {
      fetchAttachments();
    }
  };

  const editAttachment = async () => {
    const attach = findAttachment(attachmentId);
    const attachObjForEdit = formatObjForEdit(attach);
    let differenceKeyArray = getObjectDiff(attachObjForEdit, attachForm);
    let changedValues = pick(attachForm, differenceKeyArray);
    if (!isEmpty(changedValues)) {
      const response = await createAttachmentEditByIdService(
        attachmentId,
        changedValues,
        callBackError
      );
      if (response?.status === httpStatusCodes.ok) {
        fetchAttachments();
      }
    }
  };

  const formatResponse = (attachmentsArray) => {
    return attachmentsArray?.map((attachment) => {
      return {
        id: attachment.id,
        descriptionField: {
          description: attachment.displayName
            ? attachment.displayName
            : Localize.get('TrainerAreaAttachmentTable.noDescription'),
          type: attachment.extension,
          fileName: attachment.name
        },
        availableFor: {
          visibleToAdministrators: attachment.visibleToAdministrators,
          visibleToLocationResponsible: attachment.visibleToLocationResponsible,
          visibleToParticipants: attachment.visibleToParticipants,
          visibleToTrainers: attachment.visibleToTrainers
        }
      };
    });
  };

  useEffect(() => {
    if (sessionID?.length) {
      fetchAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionID]);

  useEffect(() => {
    if (attachmentsData) {
      setAttachments(formatResponse(attachmentsData.data.attachments));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentsData]);

  useEffect(() => {
    downloadFile();
  }, [attachmentDownloadData]);

  const commonModalContent = (
    <>
      {Localize.get('TrainerAreaAttachmentTable.modalContent')}
      <div className={style.noBold}>{attachmentForDelete?.descriptionField?.description}</div>
      <div className={style.noBold}>{attachmentForDelete?.descriptionField?.fileName}</div>
    </>
  );

  return (
    <>
      <Grid.Column width={14} data-testid={'allSessionAttachmentTab'}>
        {/* desktop && tablet layout*/}
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        <Input
          disabled
          fluid
          icon='search'
          iconPosition='left'
          placeholder={Localize.get('Catalog.searchPlaceholder')}
          onKeyUp={(e) => onSearchFieldChange(e)}
        />

        {/* desktop && tablet layout - menu and content*/}

        <SimpleTable
          data={attachments}
          columns={[
            ...(config?.configuration?.AllSessionAttachmentTab).map((column) => ({
              ...column,
              headerName: Localize.get(column.headerName),
              renderCell: (params) => (
                <div data-testid={`${params.field}-${params?.row?.id ?? 'default'}`}>
                  {getElement(params)}
                </div>
              )
            }))
          ]}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          paginationMode={'client'}
          isLoading={isLoading}
          participantsCount={attachmentsData?.data?.participantsCount}
          config={config}
          showSelect={false}
          showButton={true}
          buttonConfig={{
            text: 'TrainerAreaAttachmentTable.upload',
            icon: <FileUploadIcon />,
            disabled: false,
            isMenuBtn: false
          }}
          onMenuClick={handleUploadModal}
        />
      </Grid.Column>
      <UploadModal
        file={file}
        setFile={setFile}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        initialForm={initialForm}
        open={openModal}
        setOpen={setOpenModal}
        config={config}
        uploadFile={uploadFile}
        attachForm={attachForm}
        setAttachForm={setAttachForm}
        editAttachment={editAttachment}
      />
      <CommonModal
        open={openCommonModal}
        setOpen={setOpenCommonModal}
        header={Localize.get('TrainerAreaAttachmentTable.modalHeader')}
        content={commonModalContent}
        buttonName={Localize.get('App.delete').toUpperCase()}
        handleButton={handleDelete}
      />
    </>
  );
};

export default Attachments;
