import Localize from 'react-intl-universal';

import * as Yup from 'yup';

export default () => {
  let logInValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(Localize.get('Validation.emailFilter'))
      .required(Localize.get('Validation.requiredField')),
    password: Yup.string().required(Localize.get('Validation.requiredField'))
  });

  return logInValidationSchema;
};
