import { addDays, format, startOfDay, startOfMonth, endOfMonth } from 'date-fns';
import moment from 'moment/moment';

import { iltSessionsStatus } from '@common/Constants';
import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

import { handleSessionsAdvancedFilters } from './Util';

export const createEventService = async (
  shouldResolve = false,
  resolveFilters,
  filters,
  page,
  filtersConfig,
  contextData,
  listView,
  isTrainerAreaPage
) => {
  if (isTrainerAreaPage) {
    let searchParams = '';
    if (shouldResolve) {
      searchParams = resolveFilters(filters, filtersConfig);
    }

    const paginationLimit = contextData?.size
      ? contextData?.configuration?.PAGINATION.trainingLimit[listView][
          contextData.size.device.toLowerCase()
        ]
      : contextData?.configuration?.PAGINATION.trainingLimit[listView]['computer'];
    const paginationPart = `?page=${page}&limit=${paginationLimit}`;

    const path = searchParams
      ? `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogEvents}${searchParams}${paginationPart}`
      : `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogEvents}${paginationPart}`;

    return getService(path);
  }
};

export const createEventStatusesService = async () => {
  return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventStatuses);
};

export const createUpcomingEventService = async (page, contextData, isTrainerAreaPage) => {
  if (isTrainerAreaPage) {
    const paginationLimit = contextData?.size
      ? contextData?.configuration?.PAGINATION.myAreaOverviewLimit[
          contextData.size.device.toLowerCase()
        ]
      : contextData?.configuration?.PAGINATION.myAreaOverviewLimit['computer'];
    const paginationPart = `?page=${page}&limit=${paginationLimit}`;
    const upcomingDays = 90;

    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.myAreaOverviewUpcomingEvents}${upcomingDays}/${paginationPart}`
    );
  }
};

export const createUpcomingIltSessionsService = async (page, contextData, isTrainerAreaPage) => {
  const UPCOMING_DAYS = 90;
  const config = contextData?.configuration;
  const limit = isTrainerAreaPage
    ? config?.PAGINATION.trainerOverviewLimit.grid
    : config?.PAGINATION.locationOverviewLimit.grid;

  const paginationLimit = contextData?.size
    ? limit[contextData.size.device.toLowerCase()]
    : limit['computer'];
  const paginationPart = `?page=${page}&limit=${paginationLimit}`;

  return isTrainerAreaPage
    ? getService(
        `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaOverview}${UPCOMING_DAYS}/${paginationPart}`
      )
    : null;
};

export const createEventCalendarService = async (date) => {
  if (date) {
    const dateString = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.myAreaOverviewOnDateEvents}${dateString}`
    );
  }
};

export const createEventsAndSessionsRangeCalendarService = async (id, date) => {
  if (id) {
    const startOfMonthDate = startOfMonth(date);
    const endOfMonthDate = endOfMonth(date);

    const startOfMonthFormatted = format(startOfMonthDate, 'yyyy-MM-dd');
    const endOfMonthFormatted = format(endOfMonthDate, 'yyyy-MM-dd');
    const filters = [
      {
        key: 'startDate',
        operation: 'is_on_or_after',
        value: startOfMonthFormatted
      },
      {
        key: 'endDate',
        operation: 'is_on_or_before',
        value: endOfMonthFormatted
      }
    ];
    const filterParams = filters.length
      ? encodeURIComponent(JSON.stringify({ advancedFilters: filters }))
      : [];
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventsAndSessionsRange}${id}?filters=${filterParams}`
    );
  }
};

export const createUpcomingEventsAndSessionsService = async (id) => {
  if (id) {
    const today = startOfDay(new Date());
    const futureDate = addDays(today, 60);
    const startOfMonthFormatted = format(futureDate, 'yyyy-MM-dd');
    const endOfMonthFormatted = format(today, 'yyyy-MM-dd');
    const filters = [
      {
        key: 'startDate',
        operation: 'is_on_or_after',
        value: endOfMonthFormatted
      },
      {
        key: 'endDate',
        operation: 'is_on_or_before',
        value: startOfMonthFormatted
      }
    ];
    const filterParams = filters.length
      ? encodeURIComponent(JSON.stringify({ advancedFilters: filters }))
      : [];
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventsAndSessionsRange}${id}?filters=${filterParams}`
    );
  }
};

export const createEventsAndSessionsOnDateCalendarService = async (id, date) => {
  if (id) {
    const dateString = format(date, 'yyyy-MM-dd');
    const filters = [
      {
        key: 'startDate',
        operation: 'is_on',
        value: dateString
      }
    ];
    const filterParams = filters.length
      ? encodeURIComponent(JSON.stringify({ advancedFilters: filters }))
      : [];
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventsAndSessionsOnDate}${id}?filters=${filterParams}`
    );
  }
};

export const createSessionCalendarService = async (date, isTrainerAreaPage) => {
  if (date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    return isTrainerAreaPage
      ? getService(
          `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaOverviewOnDate}${dateString}`
        )
      : //--- TO DO --- change this service with proper one when BE is finish!
        null;
  }
};

export const createSessionRangeCalendarService = async (date, isTrainerAreaPage) => {
  if (date) {
    const startOfMonth = moment(date).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(date).endOf('month').format('YYYY-MM-DD');
    return isTrainerAreaPage
      ? getService(
          `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaSessions}?range=${startOfMonth},${endOfMonth}`
        )
      : //--- TO DO --- change this service with proper one when BE is finish!
        null;
  }
};

export const createAllILTSessionsService = async (
  page,
  contextData,
  listView,
  isTrainerAreaPage,
  sortingOption,
  filters
) => {
  const config = contextData?.configuration;
  const limit = isTrainerAreaPage
    ? config?.PAGINATION.trainerAllILTSessionsLimit
    : config?.PAGINATION.locationAllILTSessionsLimit;

  const paginationLimit = contextData?.size
    ? limit[listView][contextData.size.device.toLowerCase()]
    : limit[listView]['computer'];

  let paginationPart = handleSessionsAdvancedFilters(filters, page, paginationLimit);

  if (sortingOption?.sortValue) {
    const sortParams = sortingOption.sortValue.split(' ');
    paginationPart += `&sortBy=${sortParams[0]}&sortDirection=${sortParams[1]}`;
  }
  // --TO DO --- remove this when user management is finished ---
  // const trainerMail = 'tobias.rothley@contrimo.com';
  const trainerMail = contextData?.user;

  const advancedFilters = [{ key: 'lrp-email', operation: 'equal', value: trainerMail }];
  const advancedFilterParams = encodeURIComponent(
    JSON.stringify({ advancedFilters: advancedFilters })
  );
  const advancedFilterPart = `&filters=${advancedFilterParams}`;

  return isTrainerAreaPage
    ? getService(
        `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaSessions}${paginationPart}`
      )
    : //--- For Location Area Tab ---
      getService(
        `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaSessions}${paginationPart}${advancedFilterPart}`
      );
};

export const createMyAreaIltSessionsService = (email, iltSessionsToShow) => {
  const iltSessionsQuery = `?showRegisteredILTSessions=${
    iltSessionsToShow === iltSessionsStatus.registered
  }&showAvailableILTSessions=${
    iltSessionsToShow === iltSessionsStatus.available
  }&showPastILTSessions=${iltSessionsToShow === iltSessionsStatus.past}`;

  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.myAreaIltSessions}${email}${iltSessionsQuery}`
  );
};
