import React, { useState } from 'react';
import Localize from 'react-intl-universal';

import { DownloadButton, StyledSelect } from '@components/StyledComponents/TrainerArea.styled';
import StyledMenu from '@components/StyledMenuItem/StyledMenu';
import DownloadIcon from '@mui/icons-material/Download';
import { ListItemText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';

import { customFooterSelectItems } from './Constants';

const CustomFooter = ({ buttonConfig, showSelect, showButton, action, setAction, onMenuClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);

  const exportTypesConfig = [
    { type: 'csv', label: 'csv', icon: <DownloadIcon /> },
    { type: 'xlsx', label: 'xlsx', icon: <DownloadIcon /> }
  ];

  const handleButtonClick = (event) => {
    buttonConfig.isMenuBtn ? setAnchorEl(event.currentTarget) : onMenuClick();
  };

  const handleSelectAction = (event) => {
    setAction(event.target.value);
  };

  return (
    <>
      <GridFooterContainer data-testid={'simpleTableFooter'}>
        <div>
          {showButton && (
            <DownloadButton
              onClick={handleButtonClick}
              variant='outlined'
              endIcon={buttonConfig?.icon}
              disabled={buttonConfig.disabled}
            >
              {Localize.get(`${buttonConfig?.text}`)}
            </DownloadButton>
          )}
          {showSelect && (
            <FormControl variant='filled' sx={{ ml: 1, minWidth: 120 }} size='small'>
              <InputLabel id='filled-label'>
                {Localize.get('TrainerAreaParticipantsTable.selectAction')}
              </InputLabel>
              <StyledSelect
                disabled
                labelId='select-action-label'
                id='select-action'
                value={action}
                onChange={handleSelectAction}
              >
                {customFooterSelectItems.map((selectItem) => (
                  <MenuItem key={selectItem} value={selectItem}>
                    {Localize.get(`TrainerAreaParticipantsTable.${selectItem}`)}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          )}
        </div>
        <GridFooter
          sx={{
            border: 'none' // To delete double border.
          }}
        />
        <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {exportTypesConfig.map((menuItem) => (
            <MenuItem key={menuItem.label} onClick={() => onMenuClick(menuItem?.type)}>
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText>{menuItem.label}</ListItemText>
            </MenuItem>
          ))}
        </StyledMenu>
      </GridFooterContainer>
    </>
  );
};

export default CustomFooter;
