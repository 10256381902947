export const langs = [
  { text: '--', key: 0, value: '' },
  { text: 'DE', key: 1, value: 'De' }
];

export const initialTypeFilters = {
  type: [],
  lang: [],
  city: [],
  country: [],
  minDate: '',
  maxDate: '',
  minPrice: '',
  maxPrice: '',
  searchString: ''
};

export const initialDateRange = {
  from: undefined,
  to: undefined,
  initStartDate: undefined,
  initEndDate: undefined,
  startTimestamp: '',
  endTimestamp: ''
};

export const initialPrice = {
  initMinPrice: 0,
  initMaxPrice: 0,
  min: 0,
  max: 0
};

export const statusMappings = {
  inProgress: 'In Progress',
  completed: 'Completed',
  notCompliant: 'Not Compliant',
  overdue: 'Overdue',
  notStarted: 'Not Started',
  inBearbeitung: 'In Bearbeitung',
  abgeschlossen: 'Abgeschlossen',
  nichtKompatibel: 'Nicht kompatibel',
  uberfallig: 'Überfällig',
  nichtAngefangen: 'Nicht Angefangen'
};
