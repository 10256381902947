import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const getLearningPathDetails = (learningPathId = null, callBackError = null) => {
  if (!learningPathId) {
    return;
  }

  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.learningPaths}${learningPathId}`,
    callBackError
  );
};
