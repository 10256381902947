import React from 'react';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import {
  StyledMenu,
  NavBarGridColumn,
  MenuContainer
} from '@components/StyledComponents/Header.styled';

import AppNavBarMenuItems from './AppNavBarMenuItems';

const AppNavBar = (props) => {
  const { config, setMenuVisible } = props;

  return (
    <>
      <Grid padded='horizontally' data-test-id={'navBarContainer'}>
        <MenuContainer
          only='tablet computer'
          computer={14}
          largeScreen={16}
          widescreen={14}
          tablet={16}
        >
          <StyledMenu
            secondary
            inverted={config?.header?.menuInverted ? true : false}
            configuration={config?.header}
          >
            <AppNavBarMenuItems configuration={config?.header?.menuItems} dropDownMenu={false} />
          </StyledMenu>
        </MenuContainer>
      </Grid>

      <Grid columns={1} className='mobile only'>
        {/* mobile layout */}
        <NavBarGridColumn only='mobile'>
          <AppNavBarMenuItems
            setMenuVisible={setMenuVisible}
            configuration={config?.header?.menuItems}
            dropDownMenu={false}
          />
        </NavBarGridColumn>
      </Grid>
    </>
  );
};
export default AppNavBar;
