import React from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

import ModuleDescription from '@pages/CourseDetails/Components/ModuleDescription';

const Overview = (props) => {
  const { course, config, courseDetailsType } = props;
  const courseModules = course.COURSE_MODULES;

  return (
    <Grid data-testid={'courseOverviewDateContainer'} columns={1} stackable centered>
      <Grid.Column width={16}>
        <Segment>
          <Grid
            columns={16}
            style={{
              boxShadow: config?.configuration?.CourseDetailsInfoContent.boxShadow
            }}
          >
            {courseModules?.length ? (
              courseModules.map((module, index) => (
                <ModuleDescription
                  key={index}
                  itemNo={index}
                  module={module}
                  course={course}
                  config={config}
                  courseDetailsType={courseDetailsType}
                />
              ))
            ) : (
              <></>
            )}
          </Grid>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Overview;
