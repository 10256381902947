export const routes = {
  path: {
    home: '/',
    catalog: '/catalog',
    events: '/events',
    myevents: '/myevents',
    generalinfo: '/generalinfo',
    signup: '/signup',
    confirm: '/signup/:confirmationCode/:email?',
    verify: '/signup/verify',
    login: '/login',
    mailConfirmation: '/login/:confirmationCode/:email?',
    mailLogin: '/login/:email?',
    forgotPass: '/forgotPass',
    resetPass: '/resetPass/:confirmationCode/:email',
    eventDetails: '/eventDetails/:id',
    courseDetails: '/courseDetails/:id',
    learningPathDetails: '/learningPathDetails/:id',
    register: '/register',
    myarea: '/myarea',
    teamArea: '/teamArea',
    trainerArea: '/trainerArea',
    iltSessionDetails: '/iltSessionDetails/:id',
    locationArea: '/locationArea',
    privacyPolicy: '/privacy-policy',
    legalNotice: '/legal-notice',
    termsAndConditions: '/terms-and-conditions',
    tem: '/tem',
    clearLS: '/clearls'
  },
  name: {
    home: '/home',
    catalog: '/catalog',
    events: '/events',
    myevents: '/myevents',
    generalinfo: '/generalinfo',
    signup: '/signup',
    verify: '/signup/verify/',
    login: '/login',
    confirm: '/signup/',
    mailConfirmation: '/login/',
    mailLogin: '/login/',
    forgotPass: '/forgotPass',
    resetPass: '/resetPass/',
    eventDetails: '/eventDetails',
    courseDetails: '/courseDetails',
    learningPathDetails: '/learningPathDetails',
    register: '/register',
    myarea: '/myarea',
    teamArea: '/teamArea',
    trainerArea: '/trainerArea',
    iltSessionDetails: '/iltSessionDetails',
    locationArea: '/locationArea',
    privacyPolicy: '/privacy-policy',
    legalNotice: '/legal-notice',
    termsAndConditions: '/terms-and-conditions',
    tem: '/tem',
    clearLS: '/clearls'
  }
};
