import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import { styled } from '@mui/styles';

export const DownloadButton = styled(Button)(() => ({
  textTransform: 'none',
  fontSize: 14,
  marginLeft: '1rem'
}));

export const StyledSelect = styled(Select)(() => ({
  '& .MuiFilledInput-input': {
    paddingTop: '15px!important'
  }
}));
