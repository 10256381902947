import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

import { differenceInCalendarDays } from 'date-fns';
// semantic
import { format } from 'date-fns';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import List from 'semantic-ui-react/dist/commonjs/elements/List';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Logger from '@common/Logger.js';
import { formatDateDMY } from '@common/Util';
import Calendar from '@components/Calendar/Calendar';
import EventSessionsList from '@components/EventSessionsList/EventSessionsList';
import NoContentComponent from '@components/NoContentComponent/NoContentComponent';
import Pagination from '@components/Pagination/Pagination';
import {
  OverviewGridContainer,
  MainColumnContainer,
  OverviewGrid,
  RightColumnContainer,
  CalendarSegment,
  TodayButtonContainer,
  TodayButton,
  TodayDateIconContainer,
  ListContainer,
  SelectedDateEventListContent,
  SelectedDateEventDescription,
  OverviewFilterSegmentMini,
  UpcomingEventsSegment,
  UpcomingEventsTextContainer,
  UpcomingEventsListContainer,
  PaginationContainer,
  MobileCalendarContainer,
  OverviewFilterSegmentContainer,
  ListContent,
  ListSpan
} from '@components/StyledComponents/MyAreaOverview.styled';
import useFetching from '@hooks/Fetching/Fetching';
import { TEM_USER_ID } from '@services/Constants';
import tokenUtil from '@services/tokenUtil';

import {
  createEventsAndSessionsRangeCalendarService,
  createEventsAndSessionsOnDateCalendarService,
  createUpcomingEventsAndSessionsService
} from '../MainHelper';
import { getEventStartTime } from '../Util';

const Overview = (props) => {
  const userID = tokenUtil.getProfile()?.profilTem?.user?.id || TEM_USER_ID;
  const { contextData, handleOverviewFilterClick } = props;
  const [calendarValue, setCalendarValue] = React.useState(new Date());
  const [showTodayCalendar, setShowTodayCalendar] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [eventsAndSessions, setEventsAndSessions] = React.useState([]);
  const [upcomingEventsAndSessions, setUpcomingEventsAndSessions] = React.useState([]);
  const [active, setActive] = React.useState(false);
  const [highlightedDates, setHighlightedDates] = React.useState([]);
  const [activeDate, setActiveDate] = React.useState(new Date());

  const config = contextData?.configuration;
  const postsPerPage =
    config?.PAGINATION.myAreaOverviewLimit.list[
      contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
    ];

  const overviewItem = config?.MyAreaOverviewEventItem;

  // sets highlighted dates service for a specific date
  // ID of logged user is mandatory
  const [eventsAndSessionsData, isLoading, setIsLoading, fetchEventsAndSessions] = useFetching(
    createEventsAndSessionsOnDateCalendarService.bind(null, userID, calendarValue),
    true
  );

  const [eventAndSessionUpcoming, isLoadingUpcoming, setIsLoadingUpcoming, fetchUpcoming] =
    useFetching(createUpcomingEventsAndSessionsService.bind(null, userID), true);

  // Sets Events and Sessions for logged user for selected month range
  const [
    eventsAndSessionsInMonth,
    loadingEventsAndSessions,
    setLoadingEventsAndSessions,
    fetchEventsAndSessionsInMonth
  ] = useFetching(createEventsAndSessionsRangeCalendarService.bind(null, userID, activeDate), true);

  const eventListMobile = React.useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        fetchEventsAndSessions();
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarValue]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eventsAndSessionsData?.data?.data) {
          setEventsAndSessions(eventsAndSessionsData.data.data);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsAndSessionsData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size) {
          setIsLoadingUpcoming(true);
          fetchUpcoming();
          fetchEventsAndSessionsInMonth();
        }
        if (contextData?.size && contextData?.size.device === 'Mobile') {
          if (eventListMobile?.current) {
            eventListMobile?.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      } finally {
        setLoadingEventsAndSessions(false);
        setIsLoadingUpcoming(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, contextData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const indexOfLastPost = currentPage * postsPerPage;
        const indexOfFirstPost = indexOfLastPost - postsPerPage;
        if (eventAndSessionUpcoming?.data?.data) {
          setUpcomingEventsAndSessions(
            eventAndSessionUpcoming.data.data.slice(indexOfFirstPost, indexOfLastPost)
          );
          setTotalPages(
            Math.ceil(
              eventAndSessionUpcoming.data.data.length /
                config?.PAGINATION.myAreaOverviewLimit.list[contextData?.size.device.toLowerCase()]
            )
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventAndSessionUpcoming]);

  const handleTodayLabelClick = () => {
    setCalendarValue(new Date());
    setShowTodayCalendar(true);
    setActiveDate(new Date());
    fetchEventsAndSessionsInMonth();
  };

  const onChangeDate = (value) => {
    setCalendarValue(value);
    setShowTodayCalendar(false);
  };

  const onAccordionClick = () => {
    setActive(!active);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eventsAndSessionsInMonth?.data?.data) {
          setHighlightedDates(eventsAndSessionsInMonth.data.data.map((item) => item.startDateTime));
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsAndSessionsInMonth]);

  const isSameDay = (a, b) => {
    return differenceInCalendarDays(new Date(a), b) === 0;
  };

  const tileClassName = ({ date, view }) => {
    if (
      view === 'month' &&
      highlightedDates.find((highlightedDate) => isSameDay(highlightedDate, date))
    ) {
      return 'highlight';
    }
  };

  const onActiveStartDateChange = ({ activeStartDate }) => {
    setActiveDate(activeStartDate);
    try {
      setLoadingEventsAndSessions(true);
      if (currentPage && contextData?.size) {
        fetchEventsAndSessionsInMonth();
      }
      if (contextData?.size && contextData?.size.device === 'Mobile') {
        if (eventListMobile?.current) {
          eventListMobile?.current.scrollIntoView();
        }
      }
    } catch (e) {
      Logger.error(e);
    } finally {
      setLoadingEventsAndSessions(false);
    }
  };

  return (
    <OverviewGridContainer data-testid={'overviewGridContainer'}>
      <MainColumnContainer
        data-testid={'MainColumnContainer'}
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout - menu and content*/}
        <OverviewGrid data-testid={'OverviewGrid'} className={'tablet computer only'} centered>
          <Grid.Column computer={5} largeScreen={5} widescreen={5} tablet={6}>
            <CalendarSegment raised data-testid={'CalendarSegment'}>
              {loadingEventsAndSessions && (
                <Dimmer data-testid={'overviewDimmer'} active inverted>
                  <Loader size='large'>{Localize.get('App.loading')}</Loader>
                </Dimmer>
              )}
              <Calendar
                data-testid={'overviewCalendar'}
                showTodayCalendar={showTodayCalendar}
                onChangeDate={onChangeDate}
                calendarValue={calendarValue}
                tileClassName={tileClassName}
                onActiveStartDateChange={onActiveStartDateChange}
              />
              <TodayButtonContainer>
                <TodayButton
                  data-testid={'TodayButton'}
                  role='button'
                  tabIndex={0}
                  onClick={handleTodayLabelClick}
                  onKeyPress={handleTodayLabelClick}
                >
                  {Localize.get('MyArea.today').toUpperCase()}
                </TodayButton>
              </TodayButtonContainer>
              <Divider />
              <TodayDateIconContainer>
                {formatDateDMY(calendarValue)}
                <Icon name='calendar alternate outline' />
              </TodayDateIconContainer>
              {eventsAndSessions?.length ? (
                <ListContainer>
                  <List>
                    {eventsAndSessions.map((evt) => (
                      <List.Item key={evt.id} id={evt.id}>
                        <List.Icon name='clock outline' />
                        <ListContent>
                          {format(new Date(evt.startDateTime), 'hh:mm')}
                          <ListSpan>{evt.name}</ListSpan>
                        </ListContent>
                      </List.Item>
                    ))}
                  </List>
                </ListContainer>
              ) : (
                <NoContentComponent
                  data-testid={'myAreaListNoContent'}
                  infoType={Localize.get('TrainerArea.noSessionsForDate')}
                  infoMessage={Localize.get('TrainerArea.selectNewDate')}
                />
              )}
            </CalendarSegment>
          </Grid.Column>
          <RightColumnContainer computer={11} largeScreen={11} widescreen={11} tablet={10}>
            <Grid stackable container centered>
              {isLoadingUpcoming && (
                <Dimmer active inverted>
                  <Loader size='large'>{Localize.get('App.loading')}</Loader>
                </Dimmer>
              )}
              <Grid.Row columns={3}>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    data-testid={'OverviewFilterSegmentMini'}
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick({ status: 'bookedLearningPaths' })}
                  >
                    <div>
                      <Icon name='calendar check outline'></Icon>
                      {Localize.get('MyArea.bookedLearningPaths')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick({ status: 'bookedELearnings' })}
                  >
                    <div>
                      <Icon name='play circle outline'></Icon>
                      {Localize.get('MyArea.bookedELearnings')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick({ status: 'bookedCourses' })}
                  >
                    <div>
                      <Icon name='graduation cap'></Icon>
                      {Localize.get('MyArea.bookedCourses')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3} style={{ marginTop: '-1.5rem', marginBottom: '-1rem' }}>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick({ status: 'futureILTSessions' })}
                  >
                    <div>
                      <Icon name='history' />
                      {Localize.get('MyArea.futureILTSessions')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick({ status: 'pastILTSessions' })}
                  >
                    <div>
                      <Icon name='check circle outline'></Icon>
                      {Localize.get('MyArea.pastILTSessions')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
                <Grid.Column>
                  <OverviewFilterSegmentMini
                    textAlign='center'
                    onClick={() => handleOverviewFilterClick({ status: 'canceledILTSessions' })}
                  >
                    <div>
                      <Icon name='times circle'></Icon>
                      {Localize.get('MyArea.canceledILTSessions')}
                    </div>
                  </OverviewFilterSegmentMini>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <UpcomingEventsSegment raised>
                    <UpcomingEventsTextContainer>
                      <Icon name='calendar alternate outline' />
                      <div ref={eventListMobile}>
                        {Localize.get('MyArea.upcomingEventsNext90days')}
                      </div>
                    </UpcomingEventsTextContainer>
                    <UpcomingEventsListContainer>
                      <EventSessionsList
                        events={upcomingEventsAndSessions}
                        configuration={overviewItem}
                        listColumnCount={
                          contextData?.size && contextData?.size.device === 'Computer' ? 4 : 3
                        }
                        infoType={Localize.get('TrainerArea.noSessions')}
                      />
                    </UpcomingEventsListContainer>
                    {!!totalPages && (
                      <Grid>
                        <PaginationContainer centered>
                          <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                            noSiblings={totalPages > 3}
                          />
                        </PaginationContainer>
                      </Grid>
                    )}
                  </UpcomingEventsSegment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </RightColumnContainer>
        </OverviewGrid>

        {/* mobile layout - content*/}

        <OverviewGrid centered className={'mobile only'}>
          <Grid.Column width={16}>
            <Grid.Row>
              <Accordion styled fluid>
                <Accordion.Title
                  active={active}
                  onClick={onAccordionClick}
                  content={Localize.get('MyArea.calendarOverview')}
                />
                <MobileCalendarContainer active={active}>
                  {isLoading && (
                    <Dimmer active inverted>
                      <Loader size='large'>{Localize.get('App.loading')}</Loader>
                    </Dimmer>
                  )}
                  <Calendar
                    data-testid={'overviewCalendar'}
                    showTodayCalendar={showTodayCalendar}
                    onChange={onChangeDate}
                    value={calendarValue}
                    tileClassName={tileClassName}
                    onActiveStartDateChange={onActiveStartDateChange}
                    loadingEventsAndSessions={loadingEventsAndSessions}
                  />
                  <TodayButtonContainer>
                    <TodayButton
                      role='button'
                      tabIndex={0}
                      onClick={handleTodayLabelClick}
                      onKeyPress={handleTodayLabelClick}
                    >
                      {Localize.get('MyArea.today').toUpperCase()}
                    </TodayButton>
                  </TodayButtonContainer>
                  <TodayDateIconContainer>
                    {formatDateDMY(calendarValue)}
                    <Icon name='calendar alternate outline' />
                  </TodayDateIconContainer>
                  <ListContainer>
                    {eventsAndSessions?.length ? (
                      eventsAndSessions.map((evt, index) => (
                        <Grid.Row key={index}>
                          <Grid.Column>
                            <SelectedDateEventListContent>
                              <div>{getEventStartTime(evt)}</div>
                              <Icon name='clock outline' />
                              <SelectedDateEventDescription>
                                {evt.DESCRIPTION}
                              </SelectedDateEventDescription>
                            </SelectedDateEventListContent>
                          </Grid.Column>
                        </Grid.Row>
                      ))
                    ) : (
                      <div></div>
                    )}
                  </ListContainer>
                </MobileCalendarContainer>
              </Accordion>
            </Grid.Row>
            <Grid.Row>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick({ status: 'bookedLearningPaths' })}
                >
                  <div>
                    <Icon name='history' />
                    {Localize.get('MyArea.bookedLearningPaths')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick({ status: 'bookedELearnings' })}
                >
                  <div>
                    <Icon name='history' />
                    {Localize.get('MyArea.bookedELearnings')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick({ status: 'bookedCourses' })}
                >
                  <div>
                    <Icon name='history' />
                    {Localize.get('MyArea.bookedCourses')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick({ status: 'futureILTSessions' })}
                >
                  <div>
                    <Icon name='history' />
                    {Localize.get('MyArea.futureILTSessions')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick({ status: 'pastILTSessions' })}
                >
                  <div>
                    <Icon name='check circle outline' />
                    {Localize.get('MyArea.pastILTSessions')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
              <OverviewFilterSegmentContainer>
                <OverviewFilterSegmentMini
                  textAlign='center'
                  onClick={() => handleOverviewFilterClick({ status: 'canceledILTSessions' })}
                >
                  <div>
                    <Icon name='times circle' />
                    {Localize.get('MyArea.canceledILTSessions')}
                  </div>
                </OverviewFilterSegmentMini>
              </OverviewFilterSegmentContainer>
            </Grid.Row>
            <Grid.Row>
              <OverviewFilterSegmentContainer>
                <UpcomingEventsSegment raised>
                  <UpcomingEventsTextContainer>
                    <Icon name='calendar alternate outline' />
                    <div>{Localize.get('MyArea.upcomingEventsNext90days')}</div>
                  </UpcomingEventsTextContainer>
                  <UpcomingEventsListContainer>
                    <EventSessionsList
                      events={upcomingEventsAndSessions}
                      configuration={overviewItem}
                      listColumnCount={
                        contextData?.size && contextData?.size.device === 'Computer' ? 4 : 3
                      }
                      infoType={Localize.get('TrainerArea.noSessions')}
                    />
                  </UpcomingEventsListContainer>

                  {!!totalPages && (
                    <Grid centered>
                      <PaginationContainer centered>
                        <Pagination
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          totalPages={totalPages}
                          noSiblings={totalPages > 3}
                        />
                      </PaginationContainer>
                    </Grid>
                  )}
                </UpcomingEventsSegment>
              </OverviewFilterSegmentContainer>
            </Grid.Row>
          </Grid.Column>
        </OverviewGrid>
      </MainColumnContainer>
    </OverviewGridContainer>
  );
};

export default Overview;
