import React from 'react';
import Localize from 'react-intl-universal';

import EventItem from '@components/EventListView/EventListItem/EventItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import {
  EventListContainer,
  EventListMainContainer,
  EventListItemStyled
} from '@components/StyledComponents/EventList.styled';

const EventList = (props) => {
  const { events, onItemClick, configuration, infoType } = props;
  return (
    <>
      <EventListMainContainer stackable columns={1}>
        <EventListContainer stretched only='computer tablet'>
          {events && events.length ? (
            events.map((evt, index) => (
              <EventListItemStyled key={index}>
                <EventItem
                  config={configuration}
                  key={evt.ID}
                  eventId={evt.ID}
                  eventTypeName={evt.EVENT_TYPE_NAME}
                  eventName={evt.DESCRIPTION}
                  startDate={evt.EVENT_START}
                  endDate={evt.END_DATE}
                  eventStartString={evt.EVENT_START_STRING}
                  city={evt.CITY}
                  langId={evt.COUNTRY_NAME?.toLowerCase()}
                  description={evt.EVENT_DESCRIPTION}
                  price={
                    evt.EVENT_PRICE_LATEST ? parseFloat(evt.EVENT_PRICE_LATEST).toFixed(2) : ''
                  }
                  currency={evt.EVENT_PRICE_CURRENCY ? evt.EVENT_PRICE_CURRENCY : ''}
                  country={evt.COUNTRY_NAME}
                  maxParticipantsNo={evt.MAX_NO_PARTICIPANTS}
                  regParticipantsNo={evt.NO_OF_PTCPS_REG}
                  onItemClick={onItemClick}
                  combinedVisit={evt.COMBINED_VISIT}
                  automaticConf={evt.AUTOMATIC_CONFIRMATION}
                  guidedReq={evt.GUIDE_REQUIRED}
                  nameTag={evt.NAME_TAG_PRINTING}
                  image={evt.EVENT_THUMBNAIL}
                />
              </EventListItemStyled>
            ))
          ) : (
            <NoContentComponent
              data-test-id={'eventListNoContent'}
              infoType={infoType}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </EventListMainContainer>
    </>
  );
};

export default EventList;
