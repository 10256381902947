import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { debounce } from 'lodash';
// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';

import { courseType } from '@common/Constants';
import Logger from '@common/Logger.js';
import FilterButtons from '@components/FilterButtons/FilterButtons';
import CheckboxFilterGroup from '@components/Filters/CheckboxFilterGroup';
import Filters from '@components/Filters/Filters.jsx';
import RangeSlider from '@components/Filters/RangeSlider';
import GridListSwitch from '@components/GridListSwitch/GridListSwitch';
import Pagination from '@components/Pagination/Pagination';
import {
  TrainingGridContainer,
  TrainingGridColumnContainer,
  TrainingListContainer,
  TrainingSearchColumnContainer,
  TrainingPaginationContainer,
  TrainingContentColumnContainer,
  TrainingContentRowContainer,
  TrainingContentListRowContainer,
  StyledDimmer,
  StyledSidebar,
  FilterSegment,
  FilterHeader,
  FilterContainerHeader,
  FilterContainerCloseIcon,
  FilterSortIconsColumnContainer,
  FilterSortIconsContainer
} from '@components/StyledComponents/Training.styled';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants';
import { createAllAssignedCoursesService } from '@pages/Catalog/MainHelper';

import CourseListView from './Components/CourseListView';
import { rangeMarks } from '../Constants';
import { calculateTotalPages } from '../Util';

const LearningContent = (props) => {
  const { contextData } = props;
  let history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [courses, setCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [listView, setListView] = useState('grid');
  const [showFilters, setShowFilters] = useState(false);
  const [activeSorting, setActiveSorting] = useState('none');
  // search and sort
  const [sortedCourses, setSortedCourses] = useState([]);
  const [queryCourses, setQueryCourses] = useState([]);
  // filters
  const [checkboxTypes, setCheckboxTypes] = useState([]);
  const [progress, setProgress] = useState([0, 100]);
  const [status, setStatus] = useState('');
  const [resetForm, setResetForm] = useState(false);

  const postsPerPage =
    contextData?.configuration?.PAGINATION.myAreaOverviewLimit[listView][
      contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
    ];

  const [allCoursesData, isLoading, setIsLoading, fetchAllCourses] = useFetching(
    createAllAssignedCoursesService.bind(
      null,
      contextData?.user ? contextData.user : 'tobias.rothley@contrimo.com', // TODO: remove temporary email when login is in function
      progress,
      status
    ),
    true
  );

  const searchCompRef = useRef(null);
  const sortingRef = useRef('none');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        fetchAllCourses();
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const courseListViewLocalStorage = window.localStorage.getItem('courseListView');
    setListView(courseListViewLocalStorage ? courseListViewLocalStorage : 'grid');
    if (contextData?.configuration?.MyAreaLearningContent?.filters) {
      setCheckboxTypes(
        contextData?.configuration?.MyAreaLearningContent?.filters.map(({ name }) => name)
      );
    }
  }, []);

  const coursesToSet = useMemo(() => {
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;

    if (searchCompRef?.current?.inputRef?.current?.value !== '' && sortingRef?.current === 'none') {
      return queryCourses.slice(indexOfFirstPost, indexOfLastPost);
    } else if (currentPage && contextData?.size && sortingRef?.current === 'none') {
      return allCourses.slice(indexOfFirstPost, indexOfLastPost);
    } else {
      return sortedCourses.slice(indexOfFirstPost, indexOfLastPost);
    }
  }, [currentPage, contextData]);

  useEffect(() => {
    setCourses(coursesToSet);
  }, [coursesToSet]);

  useEffect(() => {
    window.localStorage.setItem('courseListView', listView);
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listView]);

  useEffect(() => {
    handleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listView, allCoursesData]);

  useEffect(() => {
    if (sortedCourses?.length) {
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      setCourses(sortedCourses.slice(indexOfFirstPost, indexOfLastPost));
    }
  }, [sortedCourses]);

  useEffect(() => {
    if (queryCourses?.length) {
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      if (activeSorting !== 'none') {
        handleSort(activeSorting, queryCourses);
      } else {
        setCourses(queryCourses.slice(indexOfFirstPost, indexOfLastPost));
      }
    }
  }, [queryCourses]);

  const handleData = async () => {
    try {
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;

      if (searchCompRef.current.inputRef.current.value !== '') {
        if (sortingRef.current === 'none') {
          setCourses(queryCourses.slice(indexOfFirstPost, indexOfLastPost));
        } else {
          handleSort(sortingRef.current, queryCourses);
        }
        setTotalPages(calculateTotalPages(queryCourses.length, contextData, listView));
      } else if (allCoursesData?.data?.courses) {
        setAllCourses(allCoursesData.data.courses);

        if (activeSorting !== 'none') {
          await handleSort(activeSorting, allCoursesData.data.courses);
        } else {
          setCourses(allCoursesData.data.courses.slice(indexOfFirstPost, indexOfLastPost));
        }

        setTotalPages(
          calculateTotalPages(allCoursesData?.data?.courses.length, contextData, listView)
        );
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  const onResetFilters = async () => {
    setStatus('');
    setProgress([0, 100]);
    setResetForm(true);
    clearFunction();
    await fetchAllCourses();
  };

  const onSearchEvents = async () => {
    clearFunction();
    await fetchAllCourses();
  };

  const clearFunction = () => {
    setShowFilters(false);
    setQueryCourses([]);
    setSortedCourses([]);
    onSortClick('none');
    if (searchCompRef.current) {
      searchCompRef.current.inputRef.current.value = '';
    }
  };

  const handleSearch = useCallback(
    debounce((searchTerm) => {
      const indexOfLastPost = currentPage * postsPerPage;
      const indexOfFirstPost = indexOfLastPost - postsPerPage;
      if (searchTerm === '') {
        // clear all
        onResetFilters();
      } else {
        // search by TITLE only since description is not available for user on UI
        const filteredItems = allCourses.filter((item) => {
          return item.TITLE.toLowerCase().includes(searchTerm.trim().toLowerCase());
        });
        setCurrentPage(1);
        setCourses(filteredItems.slice(indexOfFirstPost, indexOfLastPost));
        setQueryCourses(filteredItems);
        const count =
          contextData?.configuration?.PAGINATION.myAreaOverviewLimit[listView][
            contextData.size.device.toLowerCase()
          ];
        setTotalPages(Math.ceil(filteredItems.length / count));
      }
    }, 700),
    [allCourses]
  );

  const onSearchFieldChange = ({ target }) => {
    const searchTerm = target?.value;
    handleSearch(searchTerm);
  };

  const handleCourseClick = (courseId, courseTypeName) => {
    // TO DO: Remove when Learning Path details page is implement on My Area Learning content
    if (courseTypeName === Localize.get(`App.${courseType.learningPath}`)) {
      return null;
    }
    history.push({
      pathname: routerConstants.routes.name.courseDetails + '/' + courseId,
      state: {
        from: Localize.get('MyArea.learningContent'),
        path: routerConstants.routes.path.myarea,
        data: courseTypeName
      }
    });
    return null;
  };

  const onCloseFilters = () => {
    // TO DO
  };

  const onSortClick = (sortType) => {
    if (queryCourses.length) {
      handleSort(sortType, queryCourses);
    } else {
      handleSort(sortType, allCourses);
    }
  };

  const handleSort = (sortType, data) => {
    sortingRef.current = sortType;
    setCurrentPage(1);
    setActiveSorting(sortType);

    // Sort the filtered data based on the selected sort option
    let sortedData = [...data];
    switch (sortType) {
      case 'none':
      case 'amount up':
        sortedData.sort(
          (a, b) => new Date(b.ASSIGNED_DATE).getTime() - new Date(a.ASSIGNED_DATE).getTime()
        );
        break;
      case 'amount down':
        sortedData.sort(
          (a, b) => new Date(a.ASSIGNED_DATE).getTime() - new Date(b.ASSIGNED_DATE).getTime()
        );
        break;
      case 'alphabet down':
        sortedData.sort((a, b) => a.TITLE.localeCompare(b.TITLE));
        break;
      case 'alphabet up':
        sortedData.sort((a, b) => b.TITLE.localeCompare(a.TITLE));
        break;
      case 'numeric down':
        sortedData.sort((a, b) => parseFloat(a.PROGRESS) - parseFloat(b.PROGRESS));
        break;
      case 'numeric up':
        sortedData.sort((a, b) => parseFloat(b.PROGRESS) - parseFloat(a.PROGRESS));
        break;
      default:
        // 'none' option default sorting is by date
        sortedData.sort(
          (a, b) => new Date(b.ASSIGNED_DATE).getTime() - new Date(a.ASSIGNED_DATE).getTime()
        );
        break;
    }
    sortData(sortedData);
  };

  const sortData = (sortedData) => {
    setSortedCourses(sortedData);
  };

  const handleProgress = (event, newValue) => {
    setProgress(newValue);
  };

  const handleCheckboxFilters = (value) => {
    setStatus(value);
  };

  return (
    <TrainingGridContainer data-testid='LearningContentGridContainer'>
      <TrainingGridColumnContainer
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout*/}
        {isLoading && (
          <StyledDimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </StyledDimmer>
        )}

        {/* desktop && tablet layout - menu and content*/}

        <TrainingListContainer className={'tablet computer only'} centered>
          <TrainingContentColumnContainer
            computer={16}
            largeScreen={16}
            widescreen={16}
            tablet={16}
          >
            <Sidebar.Pushable>
              <StyledSidebar
                animation='overlay'
                icon='labeled'
                onHide={() => setShowFilters(false)}
                visible={showFilters}
                width='wide'
                onHidden={onCloseFilters}
              >
                <FilterSegment>
                  <FilterContainerHeader>
                    <FilterHeader>{Localize.get('Catalog.filters')}</FilterHeader>
                    <FilterContainerCloseIcon
                      name='close'
                      size='large'
                      onClick={() => setShowFilters(false)}
                    />
                  </FilterContainerHeader>
                  {contextData?.configuration && <Filters />}
                  <CheckboxFilterGroup
                    title={Localize.get('Catalog.status')}
                    page='Catalog'
                    defaultValue={null}
                    types={checkboxTypes}
                    resetForm={resetForm}
                    handleCheckboxFilters={handleCheckboxFilters}
                  />
                  <RangeSlider
                    title={Localize.get('Catalog.progressRange')}
                    ariaLabel={Localize.get('Catalog.range')}
                    value={progress}
                    marks={rangeMarks}
                    handleChange={handleProgress}
                  />
                  <FilterButtons onSearchEvents={onSearchEvents} onResetFilters={onResetFilters} />
                </FilterSegment>
              </StyledSidebar>

              <Sidebar.Pusher dimmed={showFilters}>
                <Grid stackable container centered>
                  <TrainingContentRowContainer>
                    <Grid columns={3}>
                      <FilterSortIconsColumnContainer
                        computer={2}
                        largeScreen={2}
                        widescreen={1}
                        tablet={2}
                      >
                        <FilterSortIconsContainer>
                          <Icon
                            name='filter'
                            size='large'
                            onClick={() => setShowFilters(!showFilters)}
                          />
                          {/* data-tip data-place are part of component ReactTooltip that are integrated with Dropdown*/}
                          <ReactTooltip />
                          <Dropdown
                            size='large'
                            data-testid='sorting-dropdown'
                            floating
                            className='icon'
                            icon={
                              <Icon
                                name={
                                  sortingRef.current === 'none'
                                    ? 'sort amount up'
                                    : 'sort ' + sortingRef.current
                                }
                                size='large'
                              />
                            }
                          >
                            <Dropdown.Menu>
                              {contextData?.configuration?.LearningContentSorters?.sorters.map(
                                (option) => (
                                  <Dropdown.Item
                                    data-testid='sorting-option-item'
                                    key={option?.sortType}
                                    icon={
                                      <Icon
                                        name={option.iconName}
                                        size='large'
                                        style={{
                                          color:
                                            activeSorting === option.sortType
                                              ? contextData?.configuration?.LearningContentSorters
                                                  .customStyle?.selected
                                              : contextData?.configuration?.LearningContentSorters
                                                  .customStyle?.unselected
                                        }}
                                      />
                                    }
                                    data-tip={Localize.get(`${option?.label}`)}
                                    data-place={'right'}
                                    onClick={() => onSortClick(option?.sortType)}
                                  />
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </FilterSortIconsContainer>
                      </FilterSortIconsColumnContainer>

                      <TrainingSearchColumnContainer
                        computer={12}
                        largeScreen={12}
                        widescreen={14}
                        tablet={12}
                      >
                        <Input
                          data-testid={'TrainingSearchColumnInput'}
                          fluid
                          icon='search'
                          iconPosition='left'
                          placeholder={Localize.get('Catalog.searchPlaceholder')}
                          onKeyUp={(e) => onSearchFieldChange(e)}
                          ref={searchCompRef}
                        />
                      </TrainingSearchColumnContainer>
                      <Grid.Column computer={2} largeScreen={2} widescreen={1} tablet={2}>
                        <GridListSwitch activeOption={listView} setView={setListView} />
                      </Grid.Column>
                    </Grid>
                  </TrainingContentRowContainer>
                  <TrainingContentListRowContainer>
                    <CourseListView
                      data-testid='course-list-view'
                      courses={courses}
                      listView={listView}
                      contextData={contextData}
                      handleCourseClick={handleCourseClick}
                    />
                  </TrainingContentListRowContainer>
                </Grid>
                <TrainingPaginationContainer centered>
                  {!!totalPages && (
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                    />
                  )}
                </TrainingPaginationContainer>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </TrainingContentColumnContainer>
        </TrainingListContainer>

        {/* TO DO: */}
        {/* mobile layout - content*/}
        {/* TrainingListContainer */}
      </TrainingGridColumnContainer>
    </TrainingGridContainer>
  );
};

export default LearningContent;
