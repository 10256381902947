import React from 'react';
import Localize from 'react-intl-universal';

import TermsAndConditionsDeConfig from '../../config/termsAndConditions/TermsAndConditionsDe';

const TermsAndConditions = () => {
  const configByLocale = {
    // en: TermsAndConditionsEnConfig, // TO DO: uncomment when termsAndConditionsEn.config is added
    de: <TermsAndConditionsDeConfig />
  };

  const termsAndConditionsConfig = configByLocale[Localize.options.currentLocale] || (
    <TermsAndConditionsDeConfig />
  );

  return (
    <div data-testid={'TermsAndConditions'} style={{ paddingLeft: '1rem' }}>
      {termsAndConditionsConfig}
    </div>
  );
};
export default TermsAndConditions;
