import React from 'react';

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid';

const TableToolbar = ({
  isFilterVisible = true,
  isColumnsVisible = true,
  isDensityVisible = true,
  extendedComponents: ExtendedComponents = () => <div />,
  ...rest
}) => (
  <GridToolbarContainer>
    {isColumnsVisible && <GridToolbarColumnsButton data-test-id='columns-button' />}
    {isFilterVisible && <GridToolbarFilterButton data-test-id='filters-button' />}
    {isDensityVisible && <GridToolbarDensitySelector data-test-id='density-button' />}
    <ExtendedComponents {...rest} />
  </GridToolbarContainer>
);

export default TableToolbar;
