import { formatDateToTimestamp } from '@common/Util.js';

/*
 * Configuration for filters:
 * filterName - local (app) name of filter; array in case of interval(range) filtering
 * filterFieldName - name of the field in DB; array in case of multiple DB filed filtering
 * filterValue - initial value of filter (empty)
 * filterType - indicates type of data by which service is filtered
 * filterFieldType - indicates type of data by which service is filtered for interval(range) filters
 * filterTransformFn - transform data method (e.g. format Date into timestamp)
 */
export const filtersConfigurationTEM = [
  {
    filterName: 'type',
    filterFieldName: 'eventType',
    filterFieldNameDef: 'eventTypeId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'status',
    filterFieldName: 'eventStatus',
    filterFieldNameDef: 'eventStatusId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'lang',
    filterFieldName: '',
    filterFieldNameDef: '',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'city',
    filterFieldName: 'CITY',
    filterFieldNameDef: 'CITY',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'country',
    filterFieldName: 'COUNTRY_NAME',
    filterFieldNameDef: 'COUNTRY_NAME',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'startDate',
    filterFieldName: 'startDate',
    filterFieldNameDef: 'startDate',
    filterValue: '',
    filterType: 'string',
    filterFieldType: 'date',
    filterTransformFn: formatDateToTimestamp
  },
  {
    filterName: ['minPrice', 'maxPrice'],
    filterFieldName: 'EVENT_PRICE_LATEST',
    filterFieldNameDef: 'EVENT_PRICE_LATEST',
    filterValue: '',
    filterType: 'interval',
    filterFieldType: 'int',
    filterTransformFn: parseFloat
  },
  {
    filterName: 'searchString',
    filterFieldName: 'search',
    filterFieldNameDef: 'search',
    filterValue: '',
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'venues',
    filterFieldName: 'venueName',
    filterFieldNameDef: 'venueName',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'hasWaitingList',
    filterFieldName: 'hasWaitingList',
    filterFieldNameDef: 'hasWaitingList',
    filterValue: '',
    filterType: 'string',
    filterFieldType: 'boolean',
    filterTransformFn: null
  }
];

export const courseFiltersConfigurationTEM = [
  {
    filterName: 'types',
    filterFieldName: 'courseTypes',
    filterFieldNameDef: 'courseTypesId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'languages',
    filterFieldName: 'courseLanguages',
    filterFieldNameDef: 'courseLanguagesId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'topics',
    filterFieldName: 'courseTopics',
    filterFieldNameDef: 'courseTopicsId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'tags',
    filterFieldName: 'courseTags',
    filterFieldNameDef: 'courseTagsId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'skills',
    filterFieldName: 'courseSkills',
    filterFieldNameDef: 'courseSkillsId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  }
];

export const filtersConfigurationHoermann = [
  {
    filterName: 'type',
    filterFieldName: 'eventType',
    filterFieldNameDef: 'eventTypeId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'status',
    filterFieldName: 'eventStatus',
    filterFieldNameDef: 'eventStatusId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'lang',
    filterFieldName: '',
    filterFieldNameDef: '',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'trainingNumber',
    filterFieldName: 'ORD_ID',
    filterFieldNameDef: 'ORD_ID',
    filterValue: [],
    filterType: 'int',
    filterFieldType: 'int',
    filterTransformFn: null
  },
  {
    filterName: 'country',
    filterFieldName: 'COUNTRY_NAME',
    filterFieldNameDef: 'COUNTRY_NAME',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'city',
    filterFieldName: 'CITY',
    filterFieldNameDef: 'CITY',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'startDate',
    filterFieldName: 'startDate',
    filterFieldNameDef: 'startDate',
    filterValue: '',
    filterType: 'string',
    filterFieldType: 'date',
    filterTransformFn: formatDateToTimestamp
  },
  {
    filterName: 'searchString',
    filterFieldName: 'search',
    filterFieldNameDef: 'search',
    filterValue: '',
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'venues',
    filterFieldName: 'venueName',
    filterFieldNameDef: 'venueName',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'string',
    filterTransformFn: null
  },
  {
    filterName: 'hasWaitingList',
    filterFieldName: 'hasWaitingList',
    filterFieldNameDef: 'hasWaitingList',
    filterValue: '',
    filterType: 'string',
    filterFieldType: 'boolean',
    filterTransformFn: null
  }
];

export const courseFiltersConfigurationHoermann = [
  {
    filterName: 'types',
    filterFieldName: 'courseTypes',
    filterFieldNameDef: 'courseTypesId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'languages',
    filterFieldName: 'courseLanguages',
    filterFieldNameDef: 'courseLanguagesId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'topics',
    filterFieldName: 'courseTopics',
    filterFieldNameDef: 'courseTopicsId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'tags',
    filterFieldName: 'courseTags',
    filterFieldNameDef: 'courseTagsId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  },
  {
    filterName: 'skills',
    filterFieldName: 'courseSkills',
    filterFieldNameDef: 'courseSkillsId',
    filterValue: [],
    filterType: 'string',
    filterFieldType: 'number',
    filterTransformFn: null
  }
];
