import React from 'react';
import Localize from 'react-intl-universal';

import { StyledTabMenuItem } from '@components/StyledComponents/MyArea.styled';

import * as constants from './Constants';

const TabMenuItems = (props) => {
  const { activeItem, handleItemClick } = props;
  return (
    <>
      <StyledTabMenuItem
        data-test-id={'overviewTabMenuItem'}
        name={constants.tabOptions.overview}
        active={activeItem === constants.tabOptions.overview}
        onClick={handleItemClick}
        disabled={false}
      >
        {Localize.get('MyArea.overview')}
      </StyledTabMenuItem>
      <StyledTabMenuItem
        name={constants.tabOptions.learningContent}
        active={activeItem === constants.tabOptions.learningContent}
        onClick={handleItemClick}
        disabled={false}
      >
        {Localize.get('MyArea.learningContent')}
      </StyledTabMenuItem>
      <StyledTabMenuItem
        name={constants.tabOptions.iltSessions}
        active={activeItem === constants.tabOptions.iltSessions}
        onClick={handleItemClick}
        disabled={false}
      >
        {Localize.get('MyArea.iltSessions')}
      </StyledTabMenuItem>
      <StyledTabMenuItem
        name={constants.tabOptions.achievementsAndCertificates}
        active={activeItem === constants.tabOptions.achievementsAndCertificates}
        onClick={handleItemClick}
        disabled={false}
      >
        {Localize.get('MyArea.achievementsAndCertificates')}
      </StyledTabMenuItem>
      {/*<StyledTabMenuItem
        name={constants.tabOptions.customerLoyaltyEvents}
        active={activeItem === constants.tabOptions.customerLoyaltyEvents}
        onClick={handleItemClick}
        disabled={false}
      >
        {Localize.get('MyArea.customerLoyaltyEvents')}
      </StyledTabMenuItem>*/}
    </>
  );
};

export default TabMenuItems;
