import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import moment from 'moment';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';
import Item from 'semantic-ui-react/dist/commonjs/views/Item';

import style from '../EventDetails/style/EventDetails.module.scss';

const IltSessionDetails = (props) => {
  const { session, contextData } = props;

  const durationMeasureOfTime = 'h';
  const startTime = moment(session.startTime, ['HH.mm']).format('hh:mm A');
  const formattedDuration = moment.utc(session.duration * 1000).format('HH:mm');

  return (
    <>
      <Grid.Row data-testid={'iltSessionCardDetails'}>
        <Grid.Column computer={1} tablet={2} mobile={16}>
          {contextData?.configuration?.AllSessionAgendaTab.startTime && (
            <Label color='blue' ribbon content={startTime} className={style.labelRibbon} />
          )}
        </Grid.Column>
        <Grid.Column computer={15} tablet={14} mobile={16}>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header>{session.name}</Item.Header>
                <Item.Meta className={style.itemFlex}>
                  {session?.startTime &&
                    contextData?.configuration?.AllSessionAgendaTab.startTime && (
                      <div className={style.itemPadding}>
                        <p className={style.timeInfo}>{Localize.get('EventDetails.startTime')}:</p>
                        <p className={style.timeBlock}>{session.startTime}</p>
                      </div>
                    )}

                  {session?.endTime && contextData?.configuration?.AllSessionAgendaTab.endTime && (
                    <div className={style.itemPadding}>
                      <p className={style.timeInfo}>{Localize.get('EventDetails.endTime')}:</p>{' '}
                      <p className={style.timeBlock}>{session.endTime}</p>
                    </div>
                  )}
                  {contextData?.configuration?.AllSessionAgendaTab.duration && (
                    <div className={style.itemPadding}>
                      {Localize.get('EventDetails.duration')}
                      <p className={style.timeBlock}>
                        {formattedDuration}
                        {durationMeasureOfTime}
                      </p>
                    </div>
                  )}
                  {session?.venue && contextData?.configuration?.AllSessionAgendaTab.venue && (
                    <div className={style.itemPadding}>
                      <Icon name='map marker alternate' className={style.iconPadding} />
                      {session.venue}
                    </div>
                  )}
                  {session?.room && contextData?.configuration?.AllSessionAgendaTab.room && (
                    <div>
                      <Icon name='flag outline' className={style.iconPadding} />
                      {session.room}
                    </div>
                  )}
                </Item.Meta>
                {contextData?.configuration?.AllSessionAgendaTab.description && (
                  <Item.Description>{session.description}</Item.Description>
                )}
                {contextData?.configuration?.AllSessionAgendaTab.webinarUrl && (
                  <Item.Description>
                    <a href={`${session.webinarUrl}`} target='_blank' rel='noopener noreferrer'>
                      {session.webinarUrl}
                    </a>
                  </Item.Description>
                )}
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default IltSessionDetails;
