import React, { Fragment } from 'react';
import Localize from 'react-intl-universal';

import { Grid, Checkbox } from 'semantic-ui-react';

import { courseType } from '@common/Constants';
import { openPrivacyPolicyTab, openTermsAndConditionsTab } from '@common/Util';
import CustomizedSnackbar from '@components/Snackbar/Snackbar';

import BookingHeader from './BookingHeader';
import BookingInformation from './BookingInformation';
import { bookingType } from '../../Constants';
import style from '../style/RegistrationCourse.module.scss';

const SummaryPage = ({
  participantDetails,
  setParticipantDetails,
  courseTypeName,
  config,
  registrationSnackbarState,
  setRegistrationSnackbarToInit,
  checkBookingStatusForParticipant,
  checkBookingStatusForTeamMembers
}) => {
  return (
    <Grid
      data-testid={'SummaryPage'}
      className={`tablet computer only ${style.menuCenter}`}
      centered
      style={{ background: config?.configuration?.CourseDetailsContent.background }}
    >
      <Grid.Row>
        <Grid.Column width={3}>
          <b>{Localize.get('RegistrationForm.participant')}</b>
        </Grid.Column>
        <Grid.Column width={13}>
          {courseTypeName === Localize.get(`App.${courseType.course}`) && (
            <BookingHeader config={config} bookingInformationType={bookingType.forSummary} />
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={3}>{participantDetails.name}</Grid.Column>
        <Grid.Column width={13}>
          <Grid>
            {participantDetails.iltModulesSessions.length &&
            courseTypeName === Localize.get(`App.${courseType.course}`) ? (
              participantDetails.iltModulesSessions.map((iltModule, index) => (
                <BookingInformation
                  key={index}
                  participantDetails={participantDetails}
                  setParticipantDetails={setParticipantDetails}
                  iltModule={iltModule}
                  iltModuleIndex={index}
                  config={config}
                  bookingInformationType={bookingType.forSummary}
                  bookingInformationStatus={checkBookingStatusForParticipant(iltModule)}
                />
              ))
            ) : (
              <Fragment />
            )}
          </Grid>
        </Grid.Column>
      </Grid.Row>
      {participantDetails?.participants &&
        participantDetails.participants.map((participant, participantIndex) => (
          <Grid.Row
            key={participantIndex}
            style={{ paddingTop: config?.configuration.Registration.paddingTop }}
          >
            <Grid.Column width={3}>{participant.name}</Grid.Column>
            <Grid.Column width={13}>
              <Grid>
                {participant.iltModulesSessions.length &&
                courseTypeName === Localize.get(`App.${courseType.course}`) ? (
                  participant.iltModulesSessions.map((iltModule, index) => (
                    <BookingInformation
                      key={index}
                      participantDetails={participant}
                      setParticipantDetails={setParticipantDetails}
                      iltModule={iltModule}
                      iltModuleIndex={index}
                      config={config}
                      participantIndex={participantIndex}
                      bookingInformationType={bookingType.forSummary}
                      bookingInformationStatus={checkBookingStatusForTeamMembers(
                        iltModule,
                        participantIndex
                      )}
                    />
                  ))
                ) : (
                  <Fragment />
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        ))}

      {config?.configuration?.Registration?.showPrivacyPolicy && (
        <Grid.Row column={1} style={{ paddingTop: config?.configuration.Registration.paddingTop }}>
          <Grid.Column width={1}>
            <Checkbox
              checked={participantDetails.privacyPolicyMessage}
              onChange={(_, v) =>
                setParticipantDetails({ ...participantDetails, privacyPolicyMessage: v.checked })
              }
            />
          </Grid.Column>
          <Grid.Column width={11}>
            {Localize.get('RegistrationForm.privacyPolicyMessage')}{' '}
            <span
              className={style.registrationLink}
              onClick={openPrivacyPolicyTab}
              onKeyDown={() => {}}
              onKeyUp={() => {}}
              role='button'
              tabIndex={0}
            >
              {Localize.get('RegistrationForm.privacyPolicy')}
            </span>
            {'.'}
          </Grid.Column>
        </Grid.Row>
      )}
      {config?.configuration?.Registration?.showTermsAndConditions && (
        <Grid.Row column={1}>
          <Grid.Column width={1}>
            <Checkbox
              checked={participantDetails.termsAndConditions}
              onChange={(_, v) =>
                setParticipantDetails({ ...participantDetails, termsAndConditions: v.checked })
              }
            />
          </Grid.Column>
          <Grid.Column width={11}>
            {Localize.get('RegistrationForm.IAccept')}{' '}
            <span
              className={style.registrationLink}
              onClick={openTermsAndConditionsTab}
              onKeyDown={() => {}}
              onKeyUp={() => {}}
              role='button'
              tabIndex={0}
            >
              {Localize.get('RegistrationForm.termsAndConditions')}
            </span>
            {'.'}
          </Grid.Column>
        </Grid.Row>
      )}
      <CustomizedSnackbar
        open={registrationSnackbarState?.open}
        handleClose={setRegistrationSnackbarToInit}
        status={registrationSnackbarState?.status}
        message={registrationSnackbarState?.message}
      />
    </Grid>
  );
};

export default SummaryPage;
