import React, { useState, Fragment } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import {
  formatDateDMYHMWithDayName,
  formatLongDateTimeStringToDateTimeObject,
  getIltSessionPrice
} from '@common/Util';
import styles from '@pages/LearningPathDetails/Components/style/LearningPathDetails.module.scss';

import LearningPathDescription from '../Components/LearningPathDescription';

const Overview = ({ learningPath = {}, config = {} }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onAccordionTitleClick = (e, { index, COURSE_MODULES }) => {
    // Clicked on Popup
    if (e?.target?.className === 'learningPathDescription') {
      return;
    }

    // No modules, no need to expend accordion
    if (COURSE_MODULES.length === 0) {
      return;
    }

    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Grid data-testid='learningPathOverviewContainer' columns={1} stackable centered>
      <Grid.Column style={{ width: '100%' }} width={16}>
        {/* Courses */}
        {learningPath?.COURSES?.map(
          (
            {
              ID: courseID = '',
              DESCRIPTION = '',
              COURSE_DESCRIPTION = '',
              COURSE_MODULES = [],
              COURSE_SESSIONS = []
            },
            index
          ) => (
            <Fragment key={courseID}>
              <Accordion fluid styled>
                <Accordion.Title
                  style={{
                    backgroundColor:
                      config?.configuration?.LearningPathOverviewTab.accordionTitleBackground,
                    padding: config?.configuration?.LearningPathOverviewTab.accordionTitlePadding,
                    marginBottom: '10px',
                    opacity: COURSE_MODULES.length === 0 ? '0.4' : '1'
                  }}
                  active={activeIndex === index}
                  index={index}
                  onClick={(e) => onAccordionTitleClick(e, { index, COURSE_MODULES })}
                >
                  <Icon name='dropdown' />
                  <Header
                    as='h4'
                    className={styles.overviewTabAccordionTitle}
                    style={{
                      margin: config?.configuration?.LearningPathOverviewTab.accordionTitleMargin
                    }}
                  >
                    {DESCRIPTION}
                  </Header>
                  {COURSE_DESCRIPTION !== '' && (
                    <Popup
                      style={{ maxHeight: '400px', overflow: 'auto' }}
                      hoverable
                      position='right center'
                      trigger={<Icon name='info circle' />}
                    >
                      <LearningPathDescription description={COURSE_DESCRIPTION} />
                    </Popup>
                  )}
                </Accordion.Title>

                {/* Modules */}
                {COURSE_MODULES.map(
                  ({ ID: moduleID = '', NAME = '', DESCRIPTION = '' }, courseModuleIndex) => (
                    <Fragment key={moduleID}>
                      <Accordion.Content
                        active={activeIndex === index}
                        style={{
                          background: config?.configuration?.LearningPathOverviewTab.background,
                          padding:
                            config?.configuration?.LearningPathOverviewTab.accordionContentPadding,
                          margin:
                            config?.configuration?.LearningPathOverviewTab.accordionContentMargin
                        }}
                      >
                        <Message.Header as='h5'>{NAME}</Message.Header>
                        <Message.Content
                          style={{
                            padding:
                              config?.configuration?.LearningPathOverviewTab.descriptionPadding
                          }}
                        >
                          <LearningPathDescription description={DESCRIPTION} />

                          {/* Sessions */}
                          {COURSE_SESSIONS?.map(
                            (
                              {
                                module: courseModule = {},
                                availableSeats = 0,
                                registrationUntil = null,
                                location = '',
                                startDateTime = null,
                                endDateTime = null,
                                cancellationDeadline = null,
                                iltPrices = []
                              },
                              index
                            ) =>
                              courseModule?.id === moduleID && (
                                <Fragment key={index}>
                                  {index === 0 && (
                                    <Message.Header
                                      as='h5'
                                      style={{
                                        margin:
                                          config?.configuration?.LearningPathOverviewTab
                                            .possibleDatesAndLocationsPadding
                                      }}
                                    >
                                      {Localize.get(
                                        'LearningPathDetails.possibleDatesAndLocations'
                                      )}
                                    </Message.Header>
                                  )}
                                  <Grid columns={4} key={index}>
                                    <Grid.Column width={6}>
                                      <Message.Content className={styles.flexColumn}>
                                        <p className={styles.noMargin}>
                                          {formatLongDateTimeStringToDateTimeObject(startDateTime)}-
                                          {formatLongDateTimeStringToDateTimeObject(endDateTime)}
                                        </p>
                                        <p style={{ margin: 0 }}>{location}</p>
                                      </Message.Content>
                                    </Grid.Column>

                                    <Grid.Column width={3}>
                                      <Message.Content className={styles.flexColumn}>
                                        <p className={styles.noMargin}>
                                          <Icon name='user' />
                                          {Localize.get('CourseDetails.Seats')}{' '}
                                          {availableSeats || Localize.get('App.missingField')}
                                        </p>
                                        <p className={styles.noMargin}>
                                          <Icon name='user' />
                                          {Localize.get('CourseDetails.waitingList')}
                                          {/* TODO Add field when we get it from the BE */}
                                          {}
                                        </p>
                                      </Message.Content>
                                    </Grid.Column>

                                    <Grid.Column width={5}>
                                      <Message.Content className={styles.flexColumn}>
                                        <p className={styles.noMargin}>
                                          {Localize.get('CourseDetails.bookableUntil')}{' '}
                                          {formatDateDMYHMWithDayName(new Date(registrationUntil))}
                                        </p>

                                        <p className={styles.noMargin}>
                                          {Localize.get('CourseDetails.cancellableUntil')}{' '}
                                          {formatDateDMYHMWithDayName(
                                            new Date(cancellationDeadline)
                                          )}
                                        </p>
                                      </Message.Content>
                                    </Grid.Column>

                                    <Grid.Column width={2}>
                                      <Message.Content className={styles.flexColumn}>
                                        <p className={styles.noMargin}>
                                          {Localize.get('CourseDetails.price')}
                                          {getIltSessionPrice({ iltPrices })}
                                        </p>
                                      </Message.Content>
                                    </Grid.Column>
                                  </Grid>
                                </Fragment>
                              )
                          )}
                        </Message.Content>
                      </Accordion.Content>

                      {/* Show Divider only for active Accordion and don't show for the last module */}
                      {activeIndex === index && courseModuleIndex < COURSE_MODULES.length - 1 && (
                        <Divider />
                      )}
                    </Fragment>
                  )
                )}
              </Accordion>
            </Fragment>
          )
        )}
      </Grid.Column>
    </Grid>
  );
};

export default Overview;
