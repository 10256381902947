import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import { menuItems } from './Constants';
import style from '../EventDetails/style/DetailsMenuItems.module.scss';

const generateMenuItem = ({
  name,
  label,
  iconName,
  dataTestId,
  active,
  onClick,
  disable,
  showIcon
}) => (
  <Menu.Item
    key={name}
    data-testid={dataTestId}
    name={name}
    active={active}
    onClick={onClick}
    disabled={disable}
  >
    {Localize.get(label)}
    {showIcon && <Icon name={iconName} className={style.itemIcon} />}
  </Menu.Item>
);

const DetailsMenuItems = ({ activeItem, handleItemClick, showIcon, config, tabsToShow }) => {
  return (
    <>
      {menuItems.map(
        ({ key, label, iconName, dataTestId, showTab, configName }) =>
          tabsToShow[showTab] &&
          generateMenuItem({
            name: key,
            label,
            iconName,
            dataTestId,
            active: activeItem === key,
            onClick: handleItemClick,
            disable: !config?.AllSessionsTabs[configName],
            showIcon
          })
      )}
    </>
  );
};

export default DetailsMenuItems;
