import { styled } from '@mui/styles';

export const StyledDescriptionWrap = styled('div')(() => ({
  overflow: 'hidden',
  maxWidth: '100%',
  float: 'left',
  paddingTop: '0.4rem'
}));

export const StyledDescription = styled('div')(() => ({
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: 'vertical'
}));
