import React, { useEffect } from 'react';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import CalendarFilterForOneDate from '@components/Filters/CalendarFilterForOneDate';
import {
  FiltersGrid,
  FiltersContainer,
  FiltersColumnContainer
} from '@components/StyledComponents/Filters.styled';
import predefinedFilterValues from '@config/predefinedFilterValues.config.json';
import { getInitialFilterConfiguration } from '@pages/Catalog/Util.js';

import AccordionFilter from './AccordionFilter.jsx';
import * as constants from './Constants.js';
import DropdownFilter from './DropdownFilter.jsx';
import PriceFilter from './PriceFilter.jsx';
import ToggleFilter from './ToggleFilter.jsx';
import {
  getEventTypes,
  getEventStatuses,
  getEventCities,
  getEventCountries,
  getEventPricesAndDates,
  getTrainingNumbers,
  getContentTypes,
  getEventVenues,
  getEventStartDate,
  filterHandlers
} from './Util.js';

const Filters = (props) => {
  const {
    allEvents,
    filters,
    setFilters,
    events,
    eventTypes,
    eventStatuses,
    configuration,
    device,
    visible,
    onSearchFn,
    predefinedFilters,
    filtersRef,
    shouldResetFilters,
    coursesLanguages,
    coursesSkills,
    coursesTags,
    coursesTopics,
    coursesTypes
  } = props;

  const [isImmediateSearch] = React.useState(configuration?.isImmediateSearch);
  const [eventTypeOptions, setEventTypeOptions] = React.useState([]);
  const [eventStatusOptions, setEventStatusOptions] = React.useState([]);
  const [eventCityOptions, setEventCityOptions] = React.useState([]);
  const [eventCountryOptions, setEventCountryOptions] = React.useState([]);
  const [eventVenueOptions, setEventVenueOptions] = React.useState([]);
  const [priceRange, setPriceRange] = React.useState(constants.initialPrice);
  const [dateRange, setDateRange] = React.useState(constants.initialDateRange);
  const [startDate, setStartDate] = React.useState(constants.initialStartDate);
  const [trainingNumberOptions, setTrainingNumberOptions] = React.useState([]);
  const [contentTypeOptions, setContentTypeOptions] = React.useState([]);
  const [localFilters, setLocalFilters] = React.useState({});
  const [localPredefinedFilters, setLocalPredefinedFilters] = React.useState('');

  const [coursesLanguagesOptions, setCoursesLanguagesOptions] = React.useState([]);
  const [coursesSkillsOptions, setCoursesSkillsOptions] = React.useState([]);
  const [coursesTagsOptions, setCoursesTagsOptions] = React.useState([]);
  const [coursesTopicsOptions, setCoursesTopicsOptions] = React.useState([]);
  const [coursesTypesOptions, setCoursesTypesOptions] = React.useState([]);

  useEffect(() => {
    if (events?.length) {
      const eventCities = getEventCities(events, filters.city);
      setEventCityOptions(eventCities);
      const eventCountries = getEventCountries(events, filters.country);
      setEventCountryOptions(eventCountries);

      const trainingNumbers = getTrainingNumbers(events, filters.trainingNumber);
      setTrainingNumberOptions(trainingNumbers);

      const contentTypes = getContentTypes(events);
      setContentTypeOptions(contentTypes);

      const pricesAndDates = getEventPricesAndDates(events);
      setDateRange({
        ...dateRange,
        from: filters?.minDate ? filters.minDate : undefined,
        to: filters?.maxDate ? filters.maxDate : undefined,
        initStartDate: pricesAndDates.date.from,
        initEndDate: pricesAndDates.date.to,
        startTimestamp: pricesAndDates.date.startTimestamp,
        endTimestamp: pricesAndDates.date.endTimestamp
      });
      setPriceRange({
        ...priceRange,
        initMinPrice: pricesAndDates.price.initMinPrice,
        initMaxPrice: pricesAndDates.price.initMaxPrice,
        min: pricesAndDates.price.minPrice,
        max: pricesAndDates.price.maxPrice
      });
    }
  }, [events]);

  useEffect(() => {
    if (allEvents?.length) {
      const startDateData = getEventStartDate(allEvents);
      setStartDate({
        from: startDateData.date.from ? startDateData.date.from : undefined,
        initStartDate: startDateData.date.from,
        startTimestamp: startDateData.date.startTimestamp
      });

      const eventVenues = getEventVenues(allEvents, filters.venues);
      setEventVenueOptions(eventVenues);
    }
  }, [allEvents]);

  useEffect(() => {
    if (eventTypes?.length) {
      setEventTypeOptions(eventTypes);
    }
  }, [eventTypes]);

  useEffect(() => {
    if (eventStatuses && eventStatuses.length) {
      setEventStatusOptions(eventStatuses);
    }
  }, [eventStatuses]);

  useEffect(() => {
    if (coursesLanguages?.length) {
      setCoursesLanguagesOptions(coursesLanguages);
    }
  }, [coursesLanguages]);

  useEffect(() => {
    if (coursesSkills?.length) {
      setCoursesSkillsOptions(coursesSkills);
    }
  }, [coursesSkills]);

  useEffect(() => {
    if (coursesTags?.length) {
      setCoursesTagsOptions(coursesTags);
    }
  }, [coursesTags]);

  useEffect(() => {
    if (coursesTopics?.length) {
      setCoursesTopicsOptions(coursesTopics);
    }
  }, [coursesTopics]);

  useEffect(() => {
    if (coursesTypes?.length) {
      setCoursesTypesOptions(coursesTypes);
    }
  }, [coursesTypes]);

  useEffect(() => {
    // repack filters configuration without search from input
    const filtersConfig = getInitialFilterConfiguration(configuration?.filtersConfig);
    delete filtersConfig.searchString;
    setLocalFilters(filtersConfig);
  }, [configuration]);

  useEffect(() => {
    if (isImmediateSearch) {
      //search should occur on filter change
      onSearchFn();
    }
  }, [localFilters]);

  useEffect(() => {
    setLocalPredefinedFilters(predefinedFilters);
  }, [predefinedFilters]);

  useEffect(() => {
    // predefined status filter
    if (localPredefinedFilters && eventStatusOptions.length) {
      let statuses = [...eventStatusOptions];
      const filterValue =
        predefinedFilterValues.filters[Object.keys(localPredefinedFilters)[0]][
          Object.values(localPredefinedFilters)[0]
        ];
      let completed = statuses.filter((el) => el.key === filterValue);
      if (!completed[0].checked) {
        completed[0].checked = true;
        setEventStatusOptions(statuses);
      }
    }
  }, [localPredefinedFilters, eventStatusOptions]);

  useEffect(() => {
    if (filtersRef) {
      filtersRef.current = clearFilters;
    }
  }, []);

  const clearFilters = (loadedEvents, evtTypes, evtStatuses, appliedFilters) => {
    if (loadedEvents.length) {
      const types = getEventTypes([...evtTypes], appliedFilters.type);
      setEventTypeOptions(types);

      const statuses = getEventStatuses([...evtStatuses], appliedFilters.status);
      setEventStatusOptions(statuses);

      const eventCities = getEventCities(loadedEvents, appliedFilters.city);
      setEventCityOptions(eventCities);
      const eventCountries = getEventCountries(loadedEvents, appliedFilters.country);
      setEventCountryOptions(eventCountries);
      const trainingNumbers = getTrainingNumbers(loadedEvents, appliedFilters.trainingNumber);
      setTrainingNumberOptions(trainingNumbers);
      const contentTypes = getContentTypes(events);
      setContentTypeOptions(contentTypes);
      const pricesAndDates = getEventPricesAndDates(loadedEvents);
      setDateRange({
        ...dateRange,
        from: appliedFilters?.minDate ? appliedFilters.minDate : undefined,
        to: appliedFilters?.maxDate ? appliedFilters.maxDate : undefined,
        initStartDate: pricesAndDates.date.from,
        initEndDate: pricesAndDates.date.to,
        startTimestamp: pricesAndDates.date.startTimestamp,
        endTimestamp: pricesAndDates.date.endTimestamp
      });
      setPriceRange({
        ...priceRange,
        initMinPrice: pricesAndDates.price.initMinPrice,
        initMaxPrice: pricesAndDates.price.initMaxPrice,
        min: pricesAndDates.price.minPrice,
        max: pricesAndDates.price.maxPrice
      });

      const startDateData = getEventStartDate(allEvents);
      setStartDate({
        from: startDateData.date.from ? startDateData.date.from : undefined,
        initStartDate: startDateData.date.from,
        startTimestamp: startDateData.date.startTimestamp
      });

      const eventVenues = getEventVenues(allEvents, appliedFilters.venues);
      setEventVenueOptions(eventVenues);
    }
  };

  const handleFilterChange = (config, data) => {
    const handler = filterHandlers[config.handlerName];
    const handlerFn = handler ? handler[config?.handlerFn] : null;

    if (localPredefinedFilters && !data.checked) {
      // check if predefined filter is same as un-checked filter
      if (config.name === Object.keys(localPredefinedFilters)[0]) {
        setLocalPredefinedFilters('');
      }
    }

    if (config?.subtype === 'checkBox') {
      switch (config.name) {
        case 'country':
          handlerFn(
            config,
            data,
            eventCountryOptions,
            setEventCountryOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'city':
          handlerFn(
            config,
            data,
            eventCityOptions,
            setEventCityOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'eventType':
        case 'category':
          handlerFn(
            config,
            data,
            eventTypeOptions,
            setEventTypeOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'eventStatus':
        case 'status':
          handlerFn(
            config,
            data,
            eventStatusOptions,
            setEventStatusOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'trainingNumber':
          handlerFn(
            config,
            data,
            trainingNumberOptions,
            setTrainingNumberOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'venueName':
          handlerFn(
            config,
            data,
            eventVenueOptions,
            setEventVenueOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'courseTypes':
          handlerFn(
            config,
            data,
            coursesTypesOptions,
            setCoursesTypesOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'courseLanguages':
          handlerFn(
            config,
            data,
            coursesLanguagesOptions,
            setCoursesLanguagesOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'courseTopics':
          handlerFn(
            config,
            data,
            coursesTopicsOptions,
            setCoursesTopicsOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'courseTags':
          handlerFn(
            config,
            data,
            coursesTagsOptions,
            setCoursesTagsOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        case 'courseSkills':
          handlerFn(
            config,
            data,
            coursesSkillsOptions,
            setCoursesSkillsOptions,
            filters,
            setFilters,
            localFilters,
            setLocalFilters
          );
          break;
        default:
          return null;
      }
    } else {
      switch (config.name) {
        case 'country':
          handlerFn(data, filters, setFilters);
          break;
        case 'date':
          handlerFn(data.value ? data.value : null, filters, setFilters);
          setDateRange({
            ...dateRange,
            from: data.value ? data.value[0] : undefined,
            to: data.value ? data.value[1] : undefined
          });
          break;
        case 'dateMobile':
          handlerFn(data[0] ? data : null, filters, setFilters);
          setDateRange({
            ...dateRange,
            from: data.value ? data.value[0] : undefined,
            to: data.value ? data.value[1] : undefined
          });
          break;
        case 'startDate':
          handlerFn(data ? data : null, filters, setFilters, localFilters, setLocalFilters);
          setStartDate({
            ...startDate,
            from: data ? data : ''
          });
          break;
        case 'startDateMobile':
          handlerFn(data ? data : null, filters, setFilters, localFilters, setLocalFilters);
          setStartDate({
            ...startDate,
            from: data ? data : ''
          });
          break;
        case 'city':
          handlerFn(data, filters, setFilters);
          break;
        case 'lang':
          handlerFn(data, filters, setFilters);
          break;
        case 'price':
          handlerFn(data, filters, setFilters);
          setPriceRange({
            ...priceRange,
            min: data[0],
            max: data[1]
          });
          break;
        case 'type':
        case 'category':
          handlerFn(data, eventTypeOptions, filters, setEventTypeOptions, setFilters);
          break;
        case 'evtType':
          handlerFn(data, filters, setFilters);
          break;
        case 'hasWaitingList':
          handlerFn(data, filters, setFilters, localFilters, setLocalFilters);
          break;
        default:
          return null;
      }
    }
  };

  const getFilterDataSource = (dataSourceName, transformDataFn, transformDataFnName) => {
    switch (dataSourceName) {
      case 'eventTypeOptions':
        if (transformDataFn) {
          return filterHandlers.transformFn[transformDataFn][transformDataFnName](eventTypeOptions);
        }
        return eventTypeOptions;
      case 'eventStatusOptions':
        return eventStatusOptions;
      case 'eventCityOptions':
        return eventCityOptions;
      case 'eventCountryOptions':
        return eventCountryOptions;
      case 'priceRange':
        return priceRange;
      case 'dateRange':
        return dateRange;
      case 'startDate':
        return startDate;
      case 'constants.langs':
        return constants[dataSourceName.split('.')[1]];
      case 'trainingNumberOptions':
        return trainingNumberOptions;
      case 'contentTypeOptions':
        return contentTypeOptions;
      case 'venueNameOptions':
        return eventVenueOptions;
      case 'courseTypesOptions':
        return coursesTypesOptions;
      case 'courseLanguagesOptions':
        return coursesLanguagesOptions;
      case 'courseTopicsOptions':
        return coursesTopicsOptions;
      case 'courseTagsOptions':
        return coursesTagsOptions;
      case 'courseSkillsOptions':
        return coursesSkillsOptions;
      default:
        return null;
    }
  };

  const getFilterRow = (flt) => {
    const dataSource = getFilterDataSource(
      flt.dataSource,
      flt.transformDataFn,
      flt.transformDataFnName
    );

    switch (flt.type) {
      case 'accordion':
        return (
          <AccordionFilter
            key={flt.name}
            active={flt?.active || false}
            config={flt}
            dataSource={dataSource}
            onFilterChange={handleFilterChange}
            shouldResetFilters={shouldResetFilters}
          />
        );
      case 'dropdown':
        return (
          <DropdownFilter
            key={flt.name}
            config={flt}
            dataSource={dataSource}
            onFilterChange={handleFilterChange}
          />
        );
      case 'calendarDropdown':
        return (
          <CalendarFilterForOneDate
            key={flt.name}
            data-test-id={'catalogFiltersCalendarFilter'}
            config={flt}
            dataSource={dataSource}
            onFilterChange={handleFilterChange}
            shouldResetFilters={shouldResetFilters}
          />
        );
      case 'toggle':
        return (
          <ToggleFilter
            key={flt.name}
            config={flt}
            dataSource={dataSource}
            onFilterChange={handleFilterChange}
          />
        );

      case 'range':
        if (events.length && dataSource.min > 0) {
          return (
            <PriceFilter
              key={flt.name}
              config={flt}
              dataSource={dataSource}
              onFilterChange={handleFilterChange}
            />
          );
        }
        return null;

      default:
        return null;
    }
  };

  return (
    <>
      <FiltersGrid stretched data-testid={'filtersContainer'}>
        <Grid.Column
          computer={16}
          largeScreen={16}
          widescreen={16}
          tablet={16}
          only='computer tablet'
        >
          <FiltersContainer customstyle={configuration?.customStyle?.filtersContainer}>
            {/* left column */}

            {configuration?.leftColumn && (
              <FiltersColumnContainer>
                {configuration?.leftColumn.filters.map((flt) => getFilterRow(flt))}
              </FiltersColumnContainer>
            )}

            {/* right column */}

            {configuration?.rightColum && (
              <FiltersColumnContainer
                margintop={configuration?.rightColum?.containerStyle?.marginTop}
                justifycontent={configuration?.rightColum?.containerStyle?.justifyContent}
              >
                {configuration?.rightColum?.filters.map((flt) => getFilterRow(flt))}
              </FiltersColumnContainer>
            )}
          </FiltersContainer>
        </Grid.Column>

        {device?.toLowerCase() === 'mobile' && visible && (
          <Grid.Column only='mobile' mobile={15}>
            <FiltersContainer>
              <FiltersColumnContainer>
                {configuration?.leftColumn.filters.map((flt) => getFilterRow(flt))}
              </FiltersColumnContainer>
            </FiltersContainer>
          </Grid.Column>
        )}
      </FiltersGrid>
    </>
  );
};
export default React.memo(Filters);
