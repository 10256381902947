import React from 'react';

import { get } from 'lodash';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
const FormInput = ({
  field = {},
  form: { touched, errors } = {},
  fieldwidth = 16,
  label = '',
  placeholder = '',
  className = '',
  ...rest
}) => {
  return (
    <>
      <Form.Field width={fieldwidth} className={className}>
        <Form.Input
          {...field}
          {...rest}
          label={label}
          placeholder={placeholder}
          error={get(touched, field?.name) && get(errors, field?.name)}
        />
      </Form.Field>
    </>
  );
};

export default FormInput;
