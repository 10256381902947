import React from 'react';
import Localize from 'react-intl-universal';
import { Link } from 'react-router-dom';

// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';

import styles from '../style/AllSessionDetails.module.scss';

const IltSessionLayoutLink = ({ previousPathName, previousPath }) => {
  return (
    <Form.Group className={styles.linkContainer}>
      <Form.Field className={styles.linkPart}>
        <Link
          to={{
            pathname: previousPath,
            state: {
              from: Localize.get('EventDetails.detailsInfo')
            }
          }}
        >
          {Localize.get(`AppNavBar${previousPath?.replace(/\//g, '.')}`)} /
        </Link>
      </Form.Field>
      <Form.Field className={styles.linkPart}>
        <Link
          to={{
            pathname: previousPath,
            state: {
              from: previousPathName,
              path: previousPath,
              activeIndex: 1
            }
          }}
        >
          {previousPathName} /
        </Link>
      </Form.Field>
      <Form.Field data-testid='allSessionDetailsLink'>
        {Localize.get('EventDetails.detailsInfo')}
      </Form.Field>
    </Form.Group>
  );
};

export default IltSessionLayoutLink;
