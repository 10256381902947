import { patchService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const editParticipantService = (
  sessionId,
  participantId,
  sessionDayId,
  body,
  callBackError
) => {
  const path = `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventDetails}${sessionId}/${serviceConstants.MEA_SERVER_CONFIGURATION.resource.participants}${participantId}?sessionDayId=${sessionDayId}`;

  return patchService(path, body, callBackError);
};
