export const status = {
  success: 'success',
  error: 'error',
  none: undefined
};

export const initialSnackbarState = {
  open: false,
  message: '',
  status: status.none
};

export const successSnackbarState = {
  open: true,
  message: '',
  status: status.success
};

export const errorSnackbarState = {
  open: true,
  message: '',
  status: status.error
};
