import React from 'react';
import Localize from 'react-intl-universal';

import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';

import { employeesList } from '../config/employeesList';

const EmployeesDialog = ({
  showEmployeesDialog,
  setShowEmployeesDialog,
  loadingEmployees,
  allEmployees,
  setParticipantDetails,
  participantIndex
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      onClose={() => setShowEmployeesDialog(false)}
      open={showEmployeesDialog}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {Localize.get('TeamArea.employeesTabTitle')}
      </DialogTitle>
      <DialogContent>
        {loadingEmployees && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}
        <DataGrid
          rows={allEmployees}
          columns={[
            ...employeesList.map((column) => ({
              ...column,
              headerName: Localize.get(column.headerName)
            }))
          ]}
          getRowId={(params) => params?.employeeId ?? ''}
          onRowSelectionModelChange={(value) => {
            if (allEmployees?.length && value?.length) {
              const selectedEmployee = allEmployees.find(
                (employee) => employee.employeeId === value[0]
              );
              setParticipantDetails((prevState) => {
                const updatedParticipants = [...prevState.participants];
                updatedParticipants[participantIndex] = {
                  ...updatedParticipants[participantIndex],
                  participantInfo: selectedEmployee,
                  name: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`
                };
                return {
                  ...prevState,
                  participants: updatedParticipants
                };
              });
              setShowEmployeesDialog(false);
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EmployeesDialog;
