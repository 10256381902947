export const BASE = {
  method: {
    get: 'GET',
    put: 'PUT',
    post: 'POST',
    patch: 'PATCH',
    delete: 'DELETE'
  },
  protocol: {
    http: 'http',
    https: 'https'
  }
};

export const CREDENTIALS = {
  user: 'S0015085033',
  password: '2.SAPIND'
};

export const MEA_SERVER_CONFIGURATION = {
  method: BASE.method.get,
  protocol: process.env.REACT_APP_API_PROTOCOL,
  host: process.env.REACT_APP_API_HOST,
  port: process.env.REACT_APP_API_PORT,
  headers: [],
  apiPath: '/api/v1/',
  resource: {
    signup: 'auth/signup/',
    signupConfirm: 'auth/confirm/',
    signUpResendEmail: 'auth/sendemail/',
    captchaCreate: 'auth/captcha/create/',
    captchaVerify: 'auth/captcha/verify/',
    login: 'auth/login/',
    reactivate: 'auth/reactivate/',
    refreshToken: 'auth/refresh-token',
    forgotPass: 'auth/forgot-pass',
    resetPass: 'auth/reset-pass',
    countries: 'countries/',
    sessionAttachment: 'session-card/session-attachments/',
    register: 'register/',
    catalog: 'catalog/',
    catalogTypes: 'catalog/types/',
    catalogStatuses: 'catalog/statuses/',
    catalogEvents: 'catalog/events/',
    catalogUnassignedCourses: 'courses/unassigned',
    catalogCoursesFilters: 'courses/filters',
    catalogAssignedCourses: 'courses/assigned',
    myEvents: 'my-events/',
    myAreaOverviewUpcomingEvents: 'my-area/overview/upcomingEvents/',
    myAreaOverviewOnDateEvents: 'my-area/overview/eventsOnDate/',
    trainerAreaOverview: 'trainer-area/overview/upcomingSessions/',
    trainerAreaOverviewOnDate: 'trainer-area/overview/sessionsOnDate/',
    trainerAreaSessions: 'trainer-area/catalog/sessions/',
    trainerAreaSessionDays: '/sessionDays',
    eventStatuses: 'my-area/training/eventsStatus/',
    eventsAndSessionsRange: 'my-area/overview/eventsAndSessionsRange/',
    eventsAndSessionsOnDate: 'my-area/overview/eventsAndSessionsOnDate/',
    myAreaTrainingEvents: 'my-area/overview/trainingEvents/',
    eventDetails: 'event-details/',
    courses: 'courses/',
    learningPaths: 'learning-paths/',
    assigned: 'assigned/',
    agenda: 'agenda/',
    attachments: 'attachments/',
    eventTopics: 'event-details/event-topics/',
    notes: 'notes/',
    sessionCard: 'session-card/',
    speakerAvatar: 'session-card/avatar/',
    home: 'home/',
    managerEmployees: 'team-area/manager-team/',
    managerEmployeesWithCourses: 'team-area/manager-team-courses/',
    registerToCourse: 'register/courses/',
    participants: 'participants/',
    participantsExports: 'participants/exports',
    myAreaIltSessions: 'my-area/ilt-sessions/',
    litmosUsers: 'users/litmos/',
    usersCoursesAndLPs: 'users/courses-and-learning-paths/',
    temUser: 'users/tem-and-litmos-user',
    samlLogoutLink: '/users/saml-logout'
  },
  query: null
};

export const HOERMANN_LINKS = {
  achievementsAndCertificates: `${process.env.REACT_APP_HOERMANN_API}/achievements`,
  teamAreaAchievements: `${process.env.REACT_APP_HOERMANN_API}/admin/people/DirectReports`,
  managersReportEngine: `${process.env.REACT_APP_HOERMANN_API}/admin/reports/quick/BuildReport`
};

export const TEM_USER_ID = 1;
