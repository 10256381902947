import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Image from 'semantic-ui-react/dist/commonjs/elements/Image';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';
import Progress from 'semantic-ui-react/dist/commonjs/modules/Progress';
import Card from 'semantic-ui-react/dist/commonjs/views/Card';
import styled, { css } from 'styled-components';

const textEllipsis = css`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
`;

export const StyledCard = styled(Card)`
  cursor: pointer;
`;

export const StyledCardHeader = styled(Card.Content)`
  display: ${(props) => (props?.config?.header ? 'block' : 'none')} !important;
  ${(props) =>
    props?.config?.header?.color &&
    css`
      color: ${props.config.header.color} !important;
    `}
  ${(props) =>
    props?.config?.header?.background &&
    css`
      background-color: ${props.config.header.background} !important;
    `}
`;

export const StyledCardContent = styled(Card.Content)`
  height: 9.5rem !important;
  padding-bottom: 0 !important;
  @media only screen and (max-width: 1281px) and (min-width: 991px) {
    padding-right: 0.3rem !important;
    padding-left: 0.5rem !important;
  }
`;

export const CardHeaderTypeColumn = styled(Grid.Column)`
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
  @media only screen and (max-width: 280px) {
    padding-right: 0 !important;
  }
`;

export const TextShrink = styled.div`
  ${textEllipsis}
`;

export const CardHeaderPopup = styled(Popup)`
  ${(props) =>
    props?.config?.header?.color &&
    css`
      color: ${props.config.header.color} !important;
    `}
  ${(props) =>
    props?.config?.header?.background &&
    css`
      background-color: ${props.config.header.background} !important;
    `}
`;

export const CardHeaderCourseFlagColumn = styled(Grid.Column)`
  margin-left: 0.8rem;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    float: left;
  }

  @media only screen and (max-width: 1350px) and (min-width: 992px) {
    float: right !important;
    text-align: end;
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 1300px) and (min-width: 991px) {
    padding-left: 0.5rem !important;
  }

  @media only screen and (max-width: 280px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    float: left;
  }
`;

export const CardHeaderFlagColumn = styled(Grid.Column)`
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    float: left;
  }

  @media only screen and (max-width: 1350px) and (min-width: 992px) {
    float: right !important;
    text-align: end;
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 1300px) and (min-width: 991px) {
    padding-left: 0.5rem !important;
  }

  @media only screen and (max-width: 280px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    float: left;
  }
`;

export const CardHeaderDateColumn = styled(Grid.Column)`
  padding-right: 0 !important;

  @media only screen and (max-width: 1300px) and (min-width: 767px) {
    padding-left: 0.3rem !important;
  }

  @media only screen and (max-width: 280px) {
    text-align: left !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: max-content !important;
  }
`;

export const CardHeaderCalendarColumn = styled(Grid.Column)`
  margin-left: 0 !important;

  @media only screen and (max-width: 1300px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    padding-right: 2rem !important;
  }

  @media only screen and (max-width: 280px) {
    padding-left: 0.5rem !important;
  }
`;

export const CardContentImage = styled(Image)`
  display: ${(props) => (props?.config?.content?.img ? 'flex' : 'none')} !important;
  background-color: #cccccc !important;
  background-repeat: no-repeat !important;
  height: 127px !important;
  ${(props) => !props?.config?.content?.notDefaultImg && 'height: 75%'};
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-top: 15% !important'};
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-bottom: 11% !important'};
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-left: 30% !important'};
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-right: 30% !important'};
  ${(props) =>
    props?.label &&
    props?.label.markcolor &&
    props?.label.markcolortext &&
    css`
      &&& .ui.ribbon.label {
        color: ${(props) => props?.label.markcolortext} !important;
        background-color: ${(props) => props.label.markcolor} !important;
        border-color: ${(props) => props.label.markcolor} !important;
        left: calc(0.05rem - 1.2em);
      }
    `}
`;

export const PlainCardContentImage = styled(CardContentImage)`
  display: ${(props) => (props?.config?.content?.img ? 'flex' : 'none')} !important;
  background-color: #cccccc !important;
  background-repeat: no-repeat !important;
  max-height: 10rem;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  padding-left: 13% !important;
  padding-right: 13% !important;
  ${(props) =>
    props?.label &&
    props?.label.markcolor &&
    props?.label.markcolortext &&
    css`
      &&& .ui.ribbon.label {
        color: ${(props) => props?.label.markcolortext} !important;
        background-color: ${(props) => props.label.markcolor} !important;
        border-color: ${(props) => props.label.markcolor} !important;
      }
    `}

  @media only screen and (max-width: 767px) and (min-width: 500px) {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }
  @media only screen and (max-width: 767px) and (min-width: 425px) {
    max-height: 13rem;
  }
`;

export const CardContentImageDiv = styled.div`
  display: ${(props) => (props?.config?.content?.img ? 'flex' : 'none')} !important;
  padding-top: 5%;
  height: 75%;
  background-color: #cccccc !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat !important;
  background-image: ${({ url }) => `url(${url})`};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const CardContentGrid = styled(Grid)`
  height: 9.5rem !important;
`;

export const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContentEventProps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const ContentEventRating = styled.div`
  padding-left: 2rem;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.3rem !important;
  }
  @media only screen and (max-width: 1550px) and (min-width: 767px) {
    zoom: 0.75 !important;
    -moz-transform: scale(0.75) !important;
    padding-left: 0.3rem !important;
  }
`;

export const ContentEventRatingCount = styled.div`
  padding-left: 0.2rem;
  color: #4183c4;
`;

export const CardDescriptionName = styled.div`
  padding-bottom: ${(props) => (props?.namelength > 53 ? '0' : '0.4rem')} !important;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-bottom: 0.5rem !important;
  }
`;

export const ContentDescriptionName = styled.div`
  padding-top: 1rem;
  padding-bottom: ${(props) => (props?.namelength > 53 ? '0.5rem' : '0.2rem')} !important;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-bottom: 1rem !important;
  }
`;

export const EvtNameHeader = styled(Header)`
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) =>
    props?.config?.content?.numberOfLines ? props.config.content.numberOfLines : 1} !important;
  line-clamp: ${(props) =>
    props?.config?.content?.numberOfLines ? props.config.content.numberOfLines : 1} !important;
  -webkit-box-orient: vertical;
  max-height: 2.87142858em;
  font-size: 1.18571429em !important;
  white-space: ${(props) =>
    props?.config?.content?.numberOfLines && props.config.content.numberOfLines === 1
      ? 'normal'
      : 'wrap'} !important;
  transition: max-height 0.3s ease;
  ${(props) =>
    props?.itemcontent === 'grid' &&
    props?.namelength > 20 * props?.config?.content?.numberOfLines &&
    css`
      &:hover::before {
        content: attr(data-text);
        position: absolute;
        left: 0;
        background-color: #ffff;
        padding: 0.5rem;
        border: 1px solid #ccc;
        white-space: normal;
      }
      &:hover {
        max-height: none;
      }
    `}
  ${(props) =>
    props?.itemcontent === 'list' &&
    props?.namelength > 125 * props?.config?.content?.numberOfLines &&
    css`
      &:hover::before {
        content: attr(data-text);
        position: absolute;
        left: 0;
        background-color: #ffff;
        padding: 0.5rem;
        border: 1px solid #ccc;
        white-space: normal;
      }
      &:hover {
        max-height: none;
      }
    `}
`;

export const EvtNameBare = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2 !important;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  max-height: 2.57142858em;
  white-space: nowrap !important;
`;

export const EvtNameBareIcon = styled(Icon)`
  float: left !important;
  margin-top: 0.5em !important;
`;

export const ContentDescription = styled.div`
  padding-top: ${(props) =>
    props?.desclength > 55 && props?.namelength < 53 ? '0.5rem' : '1rem'} !important;

  padding-bottom: 1rem;
  flex: 1 1 auto;

  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-top: ${(props) => (props?.namelength > 16 ? '0.5rem' : '2rem')} !important;
  }
`;

export const ContentDescriptionNameBare = styled.div`
  padding: 0;
  padding-bottom: 0.5em;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-bottom: 0;
  }
`;

export const DescriptionSubHeader = styled(Header.Subheader)`
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: ${(props) => (props?.maxlines ? props.maxlines : 1)} !important;
  line-clamp: ${(props) => (props?.maxlines ? props.maxlines : 1)} !important;
  white-space: ${(props) =>
    props?.maxlines && props.maxlines === 1 ? 'normal' : 'wrap'} !important;
  -webkit-box-orient: vertical;

  ${(props) =>
    props?.inheritcolor &&
    css`
      color: inherit !important;
    `}

  @media only screen and (max-width: 991px) and (min-width: 767px) {
    -webkit-line-clamp: 1 !important;
    line-clamp: 1 !important;
  }
`;

export const CardContentDescriptionContainer = styled(Grid.Row)`
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    ${(props) =>
      props?.maxlines &&
      props.maxlines === 1 &&
      css`
        padding-top: 0 !important;
      `}
  }
`;

export const CardFooter = styled(Card.Content)`
  color: ${(props) => props?.config?.footer.color} !important;
  @media only screen and (max-width: 1281px) and (min-width: 991px) {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
  }
  ${(props) =>
    !props?.config?.footer?.topBorder &&
    css`
      &&& {
        border-top: 0 !important;
      }
    `}
`;

export const CardPriceLabel = styled(Label)`
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;

  @media only screen and (max-width: 991px) and (min-width: 801px) {
    padding-right: inherit !important;
  }

  @media only screen and (max-width: 1154px) and (min-width: 991px) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    width: 100% !important;
  }
`;

export const CardFooterDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterGlobe = styled.div`
  display: ${(props) => (props?.visible ? 'block' : 'none')} !important;
  align-self: center;
`;

export const FooterLocation = styled.div`
  display: ${(props) => (props?.visible ? 'block' : 'none')} !important;
  flex: 1 0 0;
  padding-left: ${(props) => (props?.needsPadding ? '2rem' : '0')} !important;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: ${(props) => (props?.needsPadding ? '0.5rem' : '0')} !important;
  }
`;

export const FooterCity = styled.div`
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  height: 1.2em;
`;

export const FooterCountry = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  height: 1.2em;
  white-space: nowrap;
`;

export const FooterCoursePrice = styled.div`
  height: 2.2em;
  display: ${(props) => (props?.visible ? 'block' : 'none')} !important;
  align-self: center;
  margin-right: 1rem;
  @media only screen and (max-width: 800px) and (min-width: 767px) {
    zoom: 0.75 !important;
    -moz-transform: scale(0.75) !important;
    margin-right: 0.2rem;
  }
  @media only screen and (max-width: 1400px) and (min-width: 801px) {
    zoom: 0.7 !important;
    -moz-transform: scale(0.75) !important;
    margin-right: 0.1rem;
  }
  @media only screen and (max-width: 1550px) and (min-width: 1401px) {
    zoom: 0.8 !important;
    -moz-transform: scale(0.75) !important;
    margin-right: 0.1rem;
  }
`;

export const FooterPrice = styled.div`
  display: ${(props) => (props?.visible ? 'block' : 'none')} !important;
  align-self: center;
  margin-right: 1rem;
  @media only screen and (max-width: 800px) and (min-width: 767px) {
    zoom: 0.75 !important;
    -moz-transform: scale(0.75) !important;
    margin-right: 0.2rem;
  }
  @media only screen and (max-width: 1400px) and (min-width: 801px) {
    zoom: 0.7 !important;
    -moz-transform: scale(0.75) !important;
    margin-right: 0.1rem;
  }
  @media only screen and (max-width: 1550px) and (min-width: 1401px) {
    zoom: 0.8 !important;
    -moz-transform: scale(0.75) !important;
    margin-right: 0.1rem;
  }
`;

export const FooterProgress = styled.div`
  display: ${(props) => (props?.visible ? 'flex' : 'none')} !important;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  padding-right: 0.5rem;
`;

export const StyledProgress = styled(Progress)`
  flex: 1 0 0;
  margin-right: 0.5rem !important;
  margin-bottom: inherit !important;
  background: #4183c420 !important;
  &&& .bar {
    background: #4183c4 !important;
  }
`;

export const FooterProgressPercentage = styled.div`
  color: #4183c4;
`;

export const FooterFavorite = styled.div`
  align-self: center;
  display: ${(props) => (props?.visible ? 'block' : 'none')} !important;
`;

export const FooterShare = styled.div`
  display: ${(props) => (props?.visible ? 'block' : 'none')} !important;
  align-self: center;
`;

export const FooterStyledIcon = styled(Icon)`
  color: ${(props) => props?.config?.footer.iconColor} !important;
`;

export const StyledCardContentTraining = styled(Card.Content)`
  height: 11.5rem !important;
  padding-bottom: 0 !important;
  @media only screen and (max-width: 1200px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
`;

export const StyledCardContentPlainTraining = styled(Card.Content)`
  padding-bottom: 0 !important;
  @media only screen and (max-width: 1200px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
`;

export const TrainingContentDescriptionName = styled(ContentDescriptionName)`
  min-height: 4.75rem !important;
`;

export const PlainTrainingContentDescriptionName = styled(ContentDescriptionName)`
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
`;

export const TrainingExpDates = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: ${(props) => (props?.justify ? 'flex-end' : 'normal')} !important;
  align-self: ${(props) => (props?.align ? 'auto' : 'center')} !important;
`;

export const TrainingCardContentEventProps = styled(CardContentEventProps)`
  padding-bottom: 1rem !important;
  min-height: 2.125rem !important;
  @media only screen and (max-width: 1200px) and (min-width: 767px) {
    zoom: 0.9 !important;
    -moz-transform: scale(0.9) !important;
  }
  @media only screen and (max-width: 766px) and (min-width: 280px) {
    zoom: 0.75 !important;
    -moz-transform: scale(0.75) !important;
  }
`;

export const PlainTrainingCardContentEventProps = styled(CardContentEventProps)`
  padding-bottom: 1rem !important;
  min-height: 2.125rem !important;
`;

export const TrainingContentEventRating = styled(ContentEventRating)`
  padding-left: 0rem !important;
`;

export const TrainingCardHeaderDateColumn = styled(CardHeaderDateColumn)`
  color: ${(props) => props?.textcolor};
  margin-right: -0.2rem !important;
`;

export const TrainingCardHeaderCalendarColumn = styled(CardHeaderCalendarColumn)`
  color: ${(props) => props?.textcolor};
  margin-left: 0.5rem !important;
  @media only screen and (max-width: 1200px) and (min-width: 767px) {
    padding-left: 0.2rem !important;
  }

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 280px) {
    padding-left: 0.5rem !important;
  }
`;

export const TrainingLocationDatesContainer = styled.div`
  min-height: 3.5rem !important;
  margin-bottom: 0.7rem !important;
  display: flex !important;
`;

export const PlainTrainingLocationContainer = styled.div`
  color: ${(props) => props?.color};
  display: flex !important;
  flex-direction: row;
  column-gap: 0.5rem;
  margin-bottom: 0.7rem !important;
`;

export const TrainingLocationContainer = styled.div`
  flex: 1;
  color: ${(props) => props?.color};
  align-self: flex-end;
  ${textEllipsis}
`;

export const TrainingCityText = styled.div`
  font-weight: bold;
  ${textEllipsis}
`;

export const TrainingExpDatesText = styled.div`
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  zoom: 0.8;
  -moz-transform: scale(0.8);
  margin-bottom: -0.1rem;
  @media only screen and (max-width: 1200px) and (min-width: 767px) {
    zoom: 0.6 !important;
    -moz-transform: scale(0.6) !important;
  }
  @media only screen and (max-width: 766px) and (min-width: 280px) {
    zoom: 0.65 !important;
    -moz-transform: scale(0.65) !important;
  }
`;

export const TrainingOverdueText = styled(TrainingExpDatesText)`
  border-radius: ${(props) => props?.radius};
  color: ${(props) => props?.color};
  background-color: ${(props) => props?.background};
  margin-top: -1rem;
`;

export const TrainingExpText = styled(TrainingExpDatesText)`
  border-radius: ${(props) => props?.radius};
  color: ${(props) => props?.color};
  background-color: ${(props) => props?.background};
`;

export const SeatsAvailableText = styled(TrainingExpDatesText)`
  border-radius: ${(props) => props?.radius};
  color: ${(props) => props?.color};
  background-color: ${(props) => props?.background};
`;

// List view

const flexRowDisplay = css`
  display: flex;
  flex-direction: row !important;
`;

export const StyledCardListView = styled(Card)`
  ${flexRowDisplay}
  &&& .image {
    border-top-right-radius: 0 !important;
  }
  ${(props) =>
    props?.name &&
    !props?.name.includes('myArea') &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 767px) {
        min-height: 9.2rem !important;
      }
    `}
`;

export const StyledCardContentListView = styled(Card.Content)`
  padding-bottom: 0 !important;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
  }
`;

export const CardImageListView = styled(Image)`
  display: ${(props) => (props?.config?.content?.img ? 'flex' : 'none')} !important;
  background-color: #cccccc !important;
  background-repeat: no-repeat !important;
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-left: 2% !important'};
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-right: 2% !important'};
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-top: 1% !important'};
  ${(props) => !props?.config?.content?.notDefaultImg && 'padding-bottom: 1% !important'};
  height: 119px !important;
  width: 167px;
  @media only screen and (max-width: 1200px) and (min-width: 767px) {
    ${(props) => !props?.config?.content?.notDefaultImg && 'padding-top: 2% !important'};
    ${(props) => !props?.config?.content?.notDefaultImg && 'padding-bottom: 2% !important'};
  }
  ${(props) =>
    props?.label &&
    props?.label.markcolor &&
    props?.label.markcolortext &&
    css`
      &&& .ui.ribbon.label {
        color: ${(props) => props?.label.markcolortext} !important;
        background-color: ${(props) => props.label.markcolor} !important;
        border-color: ${(props) => props.label.markcolor} !important;
        left: calc(0.05rem - 1.2em);
      }
    `}
`;

export const CardImagePlainListView = styled(CardImageListView)`
  max-height: 6.429rem;
`;

export const CardContentListViewGrid = styled(Grid)`
  margin: inherit !important;
  height: 100%;
`;

export const CardContentPlainListViewGrid = styled(CardContentListViewGrid)`
  @media only screen and (max-width: 990px) and (min-width: 767px) {
    max-width: 27vw;
  }
`;

export const ListViewNameRow = styled(Grid.Row)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const ListViewFavColumn = styled(Grid.Column)`
  ${flexRowDisplay}
  align-self: flex-start !important;
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    padding-left: 1.5rem !important;
  }
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    padding-left: 0 !important;
  }
`;

export const ListViewDetailRow = styled(Grid.Row)`
  padding-bottom: 0 !important;
`;

export const PlainListViewDetailRow = styled(ListViewDetailRow)`
  padding-top: 0 !important;
`;

export const ListViewRatingContainer = styled.div`
  ${flexRowDisplay}
  align-items: center;
`;

export const ListViewRatingCount = styled.div`
  margin-left: 0.2rem;
  color: #4183c4;
`;

export const ListViewDetailColumn = styled(Grid.Column)`
  padding-right: 0 !important;
`;

export const PlainListViewDetailColumn = styled(ListViewDetailColumn)`
  @media only screen and (max-width: 990px) and (min-width: 767px) {
    min-width: 100% !important;
  }
`;

export const ListViewOverdueDateColumn = styled(ListViewDetailColumn)`
  align-self: center !important;
  ${(props) =>
    props?.needtoppadding &&
    css`
      @media only screen and (max-width: 991px) and (min-width: 767px) {
        padding-top: 0.5rem !important;
      }
    `}
`;

export const ListViewExpDateColumn = styled(Grid.Column)`
  align-self: center !important;
  ${(props) =>
    props?.needtoppadding &&
    props?.needtoppadding.includes('tablet') &&
    css`
      @media only screen and (max-width: 991px) and (min-width: 767px) {
        padding-top: 0.5rem !important;
      }
    `}
  ${(props) =>
    props?.needtoppadding &&
    props?.needtoppadding.includes('computer') &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 992px) {
        padding-top: 0.5rem !important;
      }
    `}
`;

export const ListViewDateContainer = styled.div`
  display: ${(props) => (props?.noFlex ? 'block' : 'flex')} !important;
  flex-direction: row !important;
  justify-content: space-between;
  color: ${(props) => props?.textcolor};
`;

export const PlanListViewDateContainer = styled(ListViewDateContainer)`
  column-gap: 0.5rem;
  @media only screen and (min-width: 991px) {
    justify-content: flex-start;
  }
`;

export const ListViewCalendarIcon = styled(Icon)`
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    margin-right: 1rem !important;
  }
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    margin-right: 1rem !important;
  }
`;

export const ListViewCourseCalendarIcon = styled(Icon)`
  margin-right: 3rem !important;
  padding-left: 0.4rem;
  @media only screen and (max-width: 1200px) and (min-width: 992px) {
    margin-right: 1rem !important;
  }
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    margin-right: 1rem !important;
  }
`;

export const ListViewLocationContainer = styled.div`
  ${flexRowDisplay}
  justify-content: space-evenly;
  color: ${(props) => props?.textcolor};
  ${textEllipsis}
`;

export const ListViewCountryHolder = styled.div`
  flex: 1;
  padding-left: 0.5rem;
  ${textEllipsis}
`;

export const ListViewOverdueDate = styled(TrainingOverdueText)`
  margin-top: 0;
  width: fit-content;
  zoom: 0.8 !important;
  -moz-transform: scale(0.8) !important;
`;

export const ListViewExpDate = styled(TrainingExpText)`
  width: fit-content;
  zoom: 0.8 !important;
  -moz-transform: scale(0.8) !important;
`;

export const ListViewProgress = styled.div`
  display: ${(props) => (props?.visible ? 'flex' : 'none')} !important;
  align-items: center;
  flex: 1 0 0;
  padding-right: 0.5rem;
`;
