import Localize from 'react-intl-universal';

import { entityTypes } from '@common/Constants';

import * as constants from './Constants';

export const getMenuItems = (activeItem, handleItemClickLocally, entity) => {
  if (!activeItem || !handleItemClickLocally) {
    return null;
  }

  return [
    {
      name: constants.tabOptions.information,
      active: activeItem === constants.tabOptions.information,
      onClick: handleItemClickLocally,
      key: Localize.get('EventDetails.information'),
      text: Localize.get('EventDetails.information'),
      value: Localize.get('EventDetails.information'),
      icon: { name: 'info' },
      selected: null,
      disabled: false
    },
    {
      name: constants.tabOptions.agenda,
      active: activeItem === constants.tabOptions.agenda,
      onClick: handleItemClickLocally,
      key: Localize.get('EventDetails.agenda'),
      text: Localize.get('EventDetails.agenda'),
      value: Localize.get('EventDetails.agenda'),
      icon: { name: 'calendar outline' },
      selected: null,
      disabled: false
    },
    {
      name: constants.tabOptions.attachments,
      active: activeItem === constants.tabOptions.attachments,
      onClick: entity !== 'course' ? handleItemClickLocally : null,
      key: Localize.get('EventDetails.attachments'),
      text: Localize.get('EventDetails.attachments'),
      value: Localize.get('EventDetails.attachments'),
      icon: { name: 'attach' },
      selected: null,
      disabled: entity === entityTypes.course || entity === entityTypes.learningPath
    },
    {
      name: constants.tabOptions.notes,
      active: activeItem === constants.tabOptions.notes,
      onClick: entity !== 'course' ? handleItemClickLocally : null,
      key: Localize.get('EventDetails.notes'),
      text: Localize.get('EventDetails.notes'),
      value: Localize.get('EventDetails.notes'),
      icon: { name: 'clipboard outline' },
      selected: null,
      disabled: entity === entityTypes.course || entity === entityTypes.learningPath
    }
  ];
};
