// known issue with styled components and using property 'as' in wrapped component
// https://github.com/styled-components/styled-components/issues/2129

import React from 'react';

const forwardAsProp =
  (Component) =>
  // eslint-disable-next-line react/display-name
  ({ as, ...props }) =>
    <Component forwardedAs={as} {...props} />;

export default forwardAsProp;
