import React from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import style from './style/ImageColumnContentPlaceholder.module.scss';

const ImageColumnContentPlaceholder = ({ content }) => {
  return (
    <>
      <div className={`ui two column equal height grid ${style.containerPage}`}>
        <div
          className={`nine wide computer column computer only' ${style.containerImgColumn}`}
        ></div>
        <div className='seven wide computer column sixteen wide tablet column sixteen wide mobile column'>
          <Grid.Column
            computer={7}
            tablet={16}
            mobile={16}
            className={style.loginContainer}
            data-test-id={'contentContainer'}
          >
            {content}
          </Grid.Column>
        </div>
      </div>
    </>
  );
};
export default ImageColumnContentPlaceholder;
