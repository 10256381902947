import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createEventService = async () => {
  return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.home);
};

export const getTemUser = async () => {
  return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.temUser);
};

export const getSamlLogoutLink = async () => {
  return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.samlLogoutLink);
};
