import React, { useEffect, useRef, useState } from 'react';
import Localize from 'react-intl-universal';

import { isEqual } from 'lodash';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';

import style from '@components/CourseListView/CourseList/style/CourseList.module.scss';
import EventItem from '@components/EventListView/EventListItem/EventItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import {
  EventListContainer,
  EventListMainContainer,
  EventListItemStyled
} from '@components/StyledComponents/EventList.styled';
import { InfinityObserverList } from '@hooks/Observer/InfinityObserver';

const EventListInfinity = (props) => {
  const {
    events,
    onItemClick,
    configuration,
    pagination,
    fetchEvents,
    totalPages,
    currentPage,
    setCurrentPage,
    searchTerm,
    filters,
    originalFilterData,
    sortingOption,
    listView,
    infoType,
    deviceSize
  } = props;
  const loader = useRef(null);
  const [allEvents, setAllEvents] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [, setSortOptionChanged] = useState(false);
  const [, setSortOptionOrig] = useState(sortingOption);
  const paginationLimit = pagination[listView][deviceSize];

  useEffect(() => {
    setSortOptionOrig((prevSortOption) => {
      if (!isEqual(prevSortOption, sortingOption)) {
        setSortOptionChanged(true);
        setAllEvents([]);
        setCurrentPage(1);
        setBtnDisabled(false);
        return sortingOption;
      }
    });
  }, [sortingOption]);

  useEffect(() => {
    // Handle reset of filters
    if (isEqual(originalFilterData, filters)) {
      setBtnDisabled(false);
    }
  }, [filters]);

  useEffect(() => {
    // Handle reset of search
    if (searchTerm?.length === 0 && isEqual(originalFilterData, filters)) {
      setAllEvents([]);
      setCurrentPage(1);
      fetchEvents();
    }
  }, [searchTerm]);

  useEffect(() => {
    if (events && events.length <= paginationLimit) {
      if (searchTerm?.length || !isEqual(originalFilterData, filters)) {
        setAllEvents(events);
      } else {
        // Make unique list of events
        setAllEvents((prevEvents) => [
          ...new Map([...prevEvents, ...events].map((item) => [item['ID'], item])).values()
        ]);
      }
    } else {
      setAllEvents([]);
    }
  }, [events]);

  // Needed for infinity scroll
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };

    const observer = InfinityObserverList(
      totalPages,
      currentPage,
      setCurrentPage,
      setBtnDisabled,
      options
    );

    if (loader.current) {
      observer.observe(loader.current);
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    await fetchEvents();
  };

  const loadMore = () => {
    if (totalPages > currentPage) {
      setCurrentPage((prevPageNumber) => prevPageNumber + 1);
    } else {
      setBtnDisabled(true);
    }
  };

  return (
    <>
      <EventListMainContainer stackable columns={1}>
        <EventListContainer stretched only='computer tablet'>
          {allEvents && allEvents.length ? (
            <>
              {allEvents.map((evt, index) => (
                <EventListItemStyled key={index}>
                  <EventItem
                    config={configuration}
                    key={evt.ID}
                    eventId={evt.ID}
                    eventTypeName={evt.EVENT_TYPE_NAME}
                    eventName={evt.DESCRIPTION}
                    startDate={evt.EVENT_START}
                    endDate={evt.END_DATE}
                    eventStartString={evt.EVENT_START_STRING}
                    city={evt.CITY}
                    langId={evt.COUNTRY_NAME?.toLowerCase()}
                    description={evt.EVENT_DESCRIPTION}
                    price={
                      evt.EVENT_PRICE_LATEST ? parseFloat(evt.EVENT_PRICE_LATEST).toFixed(2) : ''
                    }
                    currency={evt.EVENT_PRICE_CURRENCY ? evt.EVENT_PRICE_CURRENCY : ''}
                    country={evt.COUNTRY_NAME}
                    maxParticipantsNo={evt.MAX_NO_PARTICIPANTS}
                    regParticipantsNo={evt.NO_OF_PTCPS_REG}
                    onItemClick={onItemClick}
                    combinedVisit={evt.COMBINED_VISIT}
                    automaticConf={evt.AUTOMATIC_CONFIRMATION}
                    guidedReq={evt.GUIDE_REQUIRED}
                    nameTag={evt.NAME_TAG_PRINTING}
                    image={evt.EVENT_THUMBNAIL}
                  />
                </EventListItemStyled>
              ))}
              <div className={style.centralized}>
                <Button active={true} disabled={btnDisabled} onClick={loadMore}>
                  {Localize.get('Catalog.loadMore')}
                </Button>
              </div>
            </>
          ) : (
            <NoContentComponent
              data-test-id={'eventListInfinityNoContent'}
              infoType={infoType}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </EventListMainContainer>
    </>
  );
};

export default EventListInfinity;
