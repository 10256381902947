import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

import { statusMappings } from './Constants';

export const createEventTypesService = async () => {
  return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogTypes);
};

export const createEventStatusesService = async () => {
  return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogStatuses);
};

export const createAllEventsService = async () => {
  return getService(serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogEvents);
};

export const createEventService = async (
  shouldResolve = false,
  resolveFilters,
  filters,
  page,
  filtersConfig,
  contextData,
  listView,
  searchTerm,
  sortingOption
) => {
  let filterParams;
  if (shouldResolve) {
    filterParams = resolveFilters(filters, filtersConfig);
    filterParams = filterParams.length
      ? encodeURIComponent(JSON.stringify({ advancedFilters: filterParams }))
      : [];
  }

  const paginationLimit = contextData?.size
    ? contextData?.configuration?.PAGINATION.eventsLimit[listView][
        contextData.size.device.toLowerCase()
      ]
    : contextData?.configuration?.PAGINATION.eventsLimit[listView]['computer'];
  let paginationPart =
    searchTerm && searchTerm.length
      ? `?page=${page}&limit=${paginationLimit}&search=${searchTerm}`
      : `?page=${page}&limit=${paginationLimit}`;

  if (sortingOption?.sortValue) {
    const sortParams = sortingOption.sortValue.split(' ');
    paginationPart += `&sortBy=${sortParams[0]}&sortDirection=${sortParams[1]}`;
  }

  const path = filterParams?.length
    ? `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogEvents}${paginationPart}&filters=${filterParams}`
    : `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogEvents}${paginationPart}`;
  return getService(path);
};

export const createUnassignedCoursesService = async (email, page, contextData, listView) => {
  const paginationLimit = contextData?.size
    ? contextData?.configuration?.PAGINATION.coursesLimit[listView][
        contextData.size.device.toLowerCase()
      ]
    : contextData?.configuration?.PAGINATION.coursesLimit[listView]['computer'];

  const paginationPart = `?page=${page}&limit=${paginationLimit}`;

  const path =
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogUnassignedCourses}/${email}` +
    `${paginationPart}`;

  return getService(path);
};

export const createCourseFiltersService = async (email) => {
  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogCoursesFilters}/${email}`
  );
};

export const createAllCoursesService = async (email) => {
  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogUnassignedCourses}/${email}`
  );
};

export const createAllAssignedCoursesService = async (email, progress, status) => {
  let apiPath = `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.catalogAssignedCourses}/${email}`;

  if (status) {
    apiPath += `?status=${statusMappings[status]}`;
  }

  if (progress && progress.length === 2) {
    const [minProgress, maxProgress] = progress;
    const progressQuery = `minProgress=${minProgress}&maxProgress=${maxProgress}`;

    apiPath += statusMappings[status] ? `&${progressQuery}` : `?${progressQuery}`;
  }

  return getService(apiPath);
};
