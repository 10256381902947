import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

// Components
import { getRouteDisplayName } from '@common/Util.js';
import AppContainer from '@components/AppContainer/AppContainer';
// import HumanVerificationPuzzle from '@components/HumanVerificationPuzzle/HumanVerificationPuzzle.jsx';
import LegalNotice from '@components/LegalNotice/LegalNotice';
import PrivacyPolicy from '@components/PrivacyPolicy/PrivacyPolicy';
import ScrollToTop from '@components/ScrollToTop/ScrollToTop.jsx';
import TermsAndConditions from '@components/TermsAndConditions/TermsAndConditions';
import AccountCreationMessage from '@components/UserMessagesComponent/AccountCreationMessage.jsx';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import AllSessionDetails from '@pages/AllSession/AllSessionDetails';
import CatalogMain from '@pages/Catalog/CatalogMain';
import CourseDetails from '@pages/CourseDetails/CourseDetails.jsx';
import EventDetails from '@pages/EventDetails/EventDetails.jsx';
import EventsMain from '@pages/Events/EventsMain';
// import ForgotPassword from '@pages/ForgotPassword/ForgotPassword';
import GeneralInformation from '@pages/GeneralInformation/GeneralInformation';
import Home from '@pages/Home/Home';
import TemPage from '@pages/Home/TemPage';
import LearningPathDetails from '@pages/LearningPathDetails/LearningPathDetails';
import LocationArea from '@pages/LocationArea/LocationArea';
import LogIn from '@pages/LogIn/LogIn.jsx';
import MyArea from '@pages/MyArea/MyArea';
import MyEvents from '@pages/MyEvents/MyEvents';
// import Register from '@pages/Register/Register.jsx';
// import ResetPassword from '@pages/ResetPassword/ResetPassword';
// import SignUp from '@pages/SignUp/SignUp.jsx';
import TeamArea from '@pages/TeamArea/TeamArea';
import TrainerArea from '@pages/TrainerArea/TrainerArea';

import * as constants from './Constants';

const AppRouter = ({ configuration }) => {
  const PublicRoute = ({ component: Component, ...rest }) => {
    // return <Route {...rest} component={Component} />;
    const [contextData] = useGlobalContext();
    console.log('contextData --> ', contextData);
    return contextData.loggedIn &&
      (rest.path.includes(constants.routes.name.login) ||
        rest.path.includes(constants.routes.name.signup) ||
        rest.path.includes(constants.routes.name.forgotPass) ||
        rest.path.includes(constants.routes.name.resetPass)) ? (
      <Redirect
        to={{
          pathname: constants.routes.path.myarea,
          state: {
            from: getRouteDisplayName(constants.routes.path.login),
            path: constants.routes.path.login
          }
        }}
      />
    ) : (
      <>
        <ScrollToTop />
        <AppContainer rest={rest} component={Component} configuration={configuration} />
      </>
    );

    // // return <AppContainer rest={rest} component={Component} configuration={configuration} />;
  };

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const [contextData] = useGlobalContext();
    return !contextData.loggedIn ? (
      <Redirect
        to={{
          pathname: constants.routes.path.home,
          state: {
            from: getRouteDisplayName(rest.path),
            path: rest.path
          }
        }}
      />
    ) : (
      <>
        <ScrollToTop />
        <AppContainer rest={rest} component={Component} configuration={configuration} />
      </>
    );
  };

  return (
    <BrowserRouter data-test-id={'appRouter'}>
      <Switch>
        <PrivateRoute exact path={constants.routes.name.home} component={MyArea} />
        <PrivateRoute exact path={constants.routes.path.catalog} component={CatalogMain} />
        <PrivateRoute exact path={constants.routes.path.events} component={EventsMain} />
        <PrivateRoute exact path={constants.routes.path.eventDetails} component={EventDetails} />
        <PrivateRoute exact path={constants.routes.path.courseDetails} component={CourseDetails} />
        {/*<PublicRoute exact path={constants.routes.path.register} component={Register} />*/}
        <PrivateRoute
          exact
          path={constants.routes.path.learningPathDetails}
          component={LearningPathDetails}
        />
        <PrivateRoute exact path={constants.routes.path.myevents} component={MyEvents} />
        <PrivateRoute exact path={constants.routes.path.privacyPolicy} component={PrivacyPolicy} />
        <PrivateRoute exact path={constants.routes.path.legalNotice} component={LegalNotice} />
        <PrivateRoute
          exact
          path={constants.routes.path.termsAndConditions}
          component={TermsAndConditions}
        />
        <PrivateRoute
          exact
          path={constants.routes.path.generalinfo}
          component={GeneralInformation}
        />
        {/*<PublicRoute exact path={constants.routes.path.signup} component={SignUp} />*/}
        {/*<PublicRoute
          exact
          path={constants.routes.path.verify}
          component={HumanVerificationPuzzle}
        />*/}
        <PrivateRoute
          exact
          path={constants.routes.path.confirm}
          component={AccountCreationMessage}
        />
        {/*<PublicRoute exact path={constants.routes.path.forgotPass} component={ForgotPassword} />*/}
        {/*<PublicRoute exact path={constants.routes.path.resetPass} component={ResetPassword} />*/}
        <PrivateRoute exact path={constants.routes.path.login} component={LogIn} />
        {/*<PublicRoute exact path={constants.routes.path.mailLogin} component={LogIn} />*/}
        {/*<PublicRoute exact path={constants.routes.path.mailConfirmation} component={LogIn} />*/}
        <PrivateRoute exact path={constants.routes.path.myarea} component={MyArea} />
        <PrivateRoute exact path={constants.routes.path.teamArea} component={TeamArea} />
        <PrivateRoute exact path={constants.routes.path.trainerArea} component={TrainerArea} />
        <PublicRoute exact path={constants.routes.path.tem} component={TemPage} />
        <PrivateRoute
          exact
          path={constants.routes.path.iltSessionDetails}
          component={AllSessionDetails}
        />
        <PrivateRoute exact path={constants.routes.path.locationArea} component={LocationArea} />
        <PublicRoute exact path='*' component={Home} />
      </Switch>
    </BrowserRouter>
  );
};
export default AppRouter;
