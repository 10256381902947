import Localize from 'react-intl-universal';

import { formatDateYMDDashDelimiter } from '@common/Util';
import * as config from '@config/index.js';

export const getEventTypes = (eventTypes) => {
  if (!eventTypes) {
    return [];
  }

  const eventTypesVariable = eventTypes.map((type) => {
    let item = {};
    item.key = type.Id;
    item.text = type.Value;
    item.value = type.Id;
    item.checked = false;
    return item;
  });

  eventTypesVariable.push({
    key: Localize.get('App.all'),
    text: Localize.get('App.all'),
    checked: false
  });

  return eventTypesVariable;
};

export const repackEventTypes = (events, eventTypes) => {
  if (!events || !eventTypes) {
    return null;
  }
  events.forEach((event) => {
    if (!event.EVENT_TYPE_NAME) {
      const typeName = eventTypes.find(
        (types) => types.key.toString() === event.EVENT_TYPE_ID.toString()
      );
      if (typeName) {
        event.EVENT_TYPE_NAME = typeName.text;
      }
    }
  });
};

export const getInitialFilterConfiguration = (filtersConfigName) => {
  const initFilters = {};
  if (filtersConfigName && config.filterConfig[filtersConfigName]) {
    config.filterConfig[filtersConfigName].forEach((filterType) => {
      if (Array.isArray(filterType.filterName)) {
        filterType.filterName.forEach(
          (filterPart) => (initFilters[filterPart] = filterType.filterValue)
        );
      } else {
        initFilters[filterType.filterName] = filterType.filterValue;
      }
    });
  }

  return initFilters;
};

const getFiltersUrlPartTypeInt = (filters, config, filterUrlPart) => {
  let filterString = `${filterUrlPart}`;
  if (filters.length) {
    filterString += '(';
    filters.forEach((type, ind, arr) => {
      if (parseInt(type, 10)) {
        filterString += `${config.filterFieldName} eq ${parseInt(type, 10)}`;
        if (ind !== arr.length - 1) {
          filterString += ' or ';
        }
      }
    });

    filterString += ')';
  }
  return filterString;
};

const getFiltersUrlPartTypeString = (filters, config, filterUrlPart) => {
  let filterString = filterUrlPart;
  if (filters.length) {
    filterString += '(';
    filters.forEach((filterValue, ind, arr) => {
      filterString += `${config.filterFieldName} eq '${filterValue}'`;
      if (ind !== arr.length - 1) {
        filterString += ' or ';
      }
    });
    filterString += ')';
  }
  return filterString;
};

const getFiltersUrlPartTypeInterval = (filterMin, filterMax, config, filterUrlPart) => {
  let filterString = filterUrlPart;
  if (filterMin && filterMax && config.filterTransformFn) {
    if (config.filterFieldType === 'string') {
      filterString += `(${config.filterFieldName} ge '${config.filterTransformFn(
        filterMin,
        true
      )}' and ${config.filterFieldName} le '${config.filterTransformFn(filterMax, false)}')`;
    } else {
      filterString += `(${config.filterFieldName} ge ${config.filterTransformFn(
        filterMin,
        true
      )} and ${config.filterFieldName} le ${config.filterTransformFn(filterMax, false)})`;
    }
  }
  return filterString;
};

const getFiltersUrlPartTypeSubstring = (filters, config, filterUrlPart) => {
  let filterString = filterUrlPart;
  if (filters.length) {
    filterString += '(';
    config.filterFieldName.forEach((fieldName, ind, arr) => {
      filterString += `substringof('${filters.toLowerCase()}',  tolower(${fieldName})) eq true`;
      if (ind !== arr.length - 1) {
        filterString += ' or ';
      } else {
        filterString += ')';
      }
    });
  }
  return filterString;
};

const resolveFiltersUrlPart = (filters, config, filterUrlPart) => {
  let filterString = filterUrlPart;

  if (filterString) {
    filterString += ' and ';
  }

  switch (config.filterType) {
    case 'int':
      filterString = getFiltersUrlPartTypeInt(filters[0], config, filterString);
      break;
    case 'string':
      filterString = getFiltersUrlPartTypeString(filters[0], config, filterString);
      break;
    case 'interval':
      filterString = getFiltersUrlPartTypeInterval(filters[0], filters[1], config, filterString);
      break;
    case 'substring':
      filterString = getFiltersUrlPartTypeSubstring(filters[0], config, filterString);
      break;
    default:
      filterString = getFiltersUrlPartTypeString(filters[0], config, filterString);
      break;
  }
  return filterString;
};

const defineFilterOperation = (filterValue, config) => {
  switch (config.filterFieldType) {
    case 'string':
    case 'number':
      return 'equal';
    case 'boolean':
      return filterValue ? 'is' : 'is_not';
    case 'date':
      return 'is_on';
    default:
      return 'equal';
  }
};

// Method for building filters params for URL
const buildFiltersUrl = (filterValues, config) => {
  const formattedFilters = [];
  const filterOperationValue = defineFilterOperation(filterValues, config);

  if (Array.isArray(filterValues)) {
    filterValues.map((value) => {
      if (value && value !== Localize.get('App.all')) {
        formattedFilters.push({
          key: config.filterFieldNameDef,
          operation: filterOperationValue,
          value
        });
      }
    });
  } else {
    formattedFilters.push({
      key: config.filterFieldNameDef,
      operation: filterOperationValue,
      value:
        config.filterFieldType === 'date' ? formatDateYMDDashDelimiter(filterValues) : filterValues
    });
  }
  return formattedFilters;
};

export const getFiltersUrlPart = (filters, filtersConfigName) => {
  let filterUrlPart = [];
  const filterKeys = config.filterConfig[filtersConfigName].map(
    (filterConfig) => filterConfig.filterName
  );
  filterKeys.forEach((filterKey) => {
    if (Array.isArray(filterKey)) {
      if (filters[filterKey[0]] && filters[filterKey[1]]) {
        filterUrlPart = resolveFiltersUrlPart(
          [filters[filterKey[0]], filters[filterKey[1]]],
          config.filterConfig[filtersConfigName].find((flt) => flt.filterName === filterKey),
          filterUrlPart
        );
      }
    } else {
      if (
        filters[filterKey].length ||
        filters[filterKey] instanceof Date ||
        typeof filters[filterKey] == 'boolean'
      ) {
        const filterUrl = buildFiltersUrl(
          filters[filterKey],
          config.filterConfig[filtersConfigName].find((flt) => flt.filterName === filterKey)
        );
        filterUrlPart.push(...filterUrl);
      }
    }
  });
  return filterUrlPart;
};

export const resetFilters = (
  setPredefinedFilter,
  coursesLanguages,
  setCoursesLanguages,
  coursesSkills,
  setCoursesSkills,
  coursesTags,
  setCoursesTags,
  coursesTopics,
  setCoursesTopics,
  coursesTypes,
  setCoursesTypes
) => {
  // resets predefined filter
  setPredefinedFilter('');

  // resets course languages options
  let langs = [...coursesLanguages];
  langs.forEach((lang) => {
    lang.checked = false;
    lang.disabled = false;
  });
  setCoursesLanguages(langs);

  // resets course skills options
  let skills = [...coursesSkills];
  skills.forEach((skill) => {
    skill.checked = false;
    skill.disabled = false;
  });
  setCoursesSkills(skills);

  // resets course tags options
  let tags = [...coursesTags];
  tags.forEach((tag) => {
    tag.checked = false;
    tag.disabled = false;
  });
  setCoursesTags(tags);

  // resets course topics options
  let topics = [...coursesTopics];
  topics.forEach((topic) => {
    topic.checked = false;
    topic.disabled = false;
  });
  setCoursesTopics(topics);

  // resets course topics options
  let types = [...coursesTypes];
  types.forEach((type) => {
    type.checked = false;
    type.disabled = false;
  });
  setCoursesTypes(types);
};

export const searchCourses = (
  coursesLanguages,
  coursesSkills,
  coursesTags,
  coursesTopics,
  coursesTypes,
  filters,
  currentPosts,
  setCourses,
  setFilteredCourses,
  setTotalPages,
  allCourses,
  contextData,
  listView,
  setCurrentPage,
  indexOfFirstPost,
  indexOfLastPost
) => {
  let filteredLanguages = null;
  if (filters?.languages?.length) {
    filteredLanguages = coursesLanguages
      .filter((lang) => filters.languages.includes(lang.key))
      .map((lang) => lang.text);
  }

  let filteredSkills = null;
  if (filters?.skills?.length) {
    filteredSkills = coursesSkills
      .filter((skill) => filters.skills.includes(skill.key))
      .map((skill) => skill.text);
  }

  let filteredTags = null;
  if (filters?.tags?.length) {
    filteredTags = coursesTags
      .filter((tag) => filters.tags.includes(tag.key))
      .map((tag) => tag.text);
  }

  let filteredTopics = null;
  if (filters?.topics?.length) {
    filteredTopics = coursesTopics
      .filter((topic) => filters.topics.includes(topic.key))
      .map((topic) => topic.text);
  }

  let filteredTypes = null;
  if (filters?.types?.length) {
    filteredTypes = coursesTypes
      .filter((type) => filters.types.includes(type.key))
      .map((type) => type.text.replace(/[-\s]/g, '').toLowerCase());
  }

  // Reset list of courses if all filters are deselected
  if (
    filteredLanguages === null &&
    filteredSkills === null &&
    filteredTags === null &&
    filteredTopics === null &&
    filteredTypes === null
  ) {
    setCourses(currentPosts);
    setFilteredCourses([]);

    setTotalPages(
      Math.ceil(
        allCourses.length /
          contextData?.configuration?.PAGINATION.eventsLimit[listView][
            contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
          ]
      )
    );
  } else {
    const filteredCourses = allCourses.filter(
      (course) =>
        (filteredTopics &&
          filteredTopics.some((el) => course.TOPICS && course.TOPICS.includes(el))) ||
        (filteredLanguages &&
          filteredLanguages.some((el) => course.LANGUAGES && course.LANGUAGES.includes(el))) ||
        (filteredSkills &&
          filteredSkills.some((el) => course.SKILLS && course.SKILLS.includes(el))) ||
        (filteredTags && filteredTags.some((el) => course.TAGS && course.TAGS.includes(el))) ||
        (filteredTypes && filteredTypes.includes(course.COURSE_TYPE_NAME.toLowerCase()))
    );
    setFilteredCourses(filteredCourses);
    setCourses(filteredCourses.slice(indexOfFirstPost, indexOfLastPost));

    setCurrentPage(1);
    setTotalPages(
      Math.ceil(
        filteredCourses.length /
          contextData?.configuration?.PAGINATION.eventsLimit[listView][
            contextData.size.device.toLowerCase()
          ]
      )
    );
  }
};
