import React, { useState } from 'react';

import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';

import style from '@components/Filters/style/Filters.module.scss';
import { AccordionTitle } from '@components/StyledComponents/Filters.styled';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

const RangeSlider = ({
  title = '',
  ariaLabel = 'Range',
  value = '',
  marks = false,
  handleChange = () => {}
}) => {
  const [openAccordion, setOpenAccordion] = useState(true);

  const onAccordionClick = () => {
    setOpenAccordion(!openAccordion);
  };

  const rangeValuetext = (value) => {
    return `${value}%`;
  };

  return (
    <Grid.Row data-testid='RangeSlider' className={style.rangeSlider}>
      <Grid.Column width={16}>
        <Accordion data-testid='accordionRangeGroup' fluid styled>
          <AccordionTitle
            data-testid='accordionRangeTitle'
            active={openAccordion}
            onClick={onAccordionClick}
          >
            <Icon name='dropdown' />
            {title}
          </AccordionTitle>
          <Accordion.Content data-testid='accordionRangeContent' active={openAccordion}>
            <Form className={style.rangeSliderForm}>
              <div className={style.rangeSliderFormWrap}>
                <Grid container spacing={2}>
                  <Slider
                    getAriaLabel={() => {
                      ariaLabel;
                    }}
                    marks={marks}
                    value={value}
                    step={1}
                    onChange={handleChange}
                    valueLabelDisplay='auto'
                    getAriaValueText={rangeValuetext}
                  />
                </Grid>
              </div>

              <Typography gutterBottom className={style.rangeSliderTypography}>
                {ariaLabel}: {value[0]} - {value[1]} %
              </Typography>
            </Form>
          </Accordion.Content>
        </Accordion>
      </Grid.Column>
    </Grid.Row>
  );
};

export default RangeSlider;
