import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import { bookingStatus } from '@common/Constants';
import { bookingType } from '@pages/CourseDetails/Constants';

import style from '../style/RegistrationCourse.module.scss';

const BookingHeader = ({ config, bookingInformationType }) => {
  const statusLegendSummaryPageText = {
    [bookingStatus.successful]: Localize.get('RegistrationForm.seatsAvailable'),
    [bookingStatus.waitingList]: Localize.get('RegistrationForm.waitingListAvailable'),
    [bookingStatus.decline]: Localize.get('RegistrationForm.noSeatsAvailable')
  };

  const statusLegendConfirmationPageText = {
    [bookingStatus.successful]: Localize.get('RegistrationForm.bookingWasSuccessful'),
    [bookingStatus.waitingList]: Localize.get('RegistrationForm.bookedOnWaitingList'),
    [bookingStatus.decline]: Localize.get('RegistrationForm.bookingFailed')
  };
  return (
    <Grid data-testid={'BookingHeader'}>
      <Grid.Row>
        {config?.configuration?.Registration?.showModule && (
          <Grid.Column width={7}>
            <b>{Localize.get('RegistrationForm.selectedILTModules')}</b>
          </Grid.Column>
        )}
        {config?.configuration?.Registration?.showHotelRequired && (
          <Grid.Column width={1} textAlign={'right'}>
            <b>{Localize.get('RegistrationForm.hotel')}</b>
          </Grid.Column>
        )}
        {config?.configuration?.Registration?.showHotelRequired &&
          config?.configuration?.Registration?.showArrivalAndDepartureDate && (
            <Grid.Column width={5}>
              <b>{Localize.get('RegistrationForm.arrivalDepartureDate')}</b>
            </Grid.Column>
          )}
        {config?.configuration?.Registration?.showStatus && (
          <Grid.Column width={2}>
            <b>{Localize.get('RegistrationForm.status')}</b>
            <Popup
              content={
                bookingInformationType === bookingType.confirmationPage ? (
                  <>
                    <div>{statusLegendConfirmationPageText[bookingStatus.successful]}</div>
                    <div>{statusLegendConfirmationPageText[bookingStatus.waitingList]}</div>
                    <div>{statusLegendConfirmationPageText[bookingStatus.decline]}</div>
                  </>
                ) : (
                  <>
                    <div>{statusLegendSummaryPageText[bookingStatus.successful]}</div>
                    <div>{statusLegendSummaryPageText[bookingStatus.waitingList]}</div>
                    <div>{statusLegendSummaryPageText[bookingStatus.decline]}</div>
                  </>
                )
              }
              size='small'
              position='bottom left'
              inverted
              trigger={
                <Icon
                  name='question circle outline'
                  size='large'
                  className={style.registrationWarningColor}
                />
              }
            />
          </Grid.Column>
        )}
      </Grid.Row>
    </Grid>
  );
};
export default BookingHeader;
