import React, { useState, useEffect } from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Item from 'semantic-ui-react/dist/commonjs/views/Item';

import * as globalConstants from '@common/Constants.js';
import Logger from '@common/Logger.js';
import LoadingPlaceholder from '@components/LoadingPlaceholder/LoadingPlaceholder.jsx';
import useFetching from '@hooks/Fetching/Fetching.js';
import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

import style from './style/SessionSpeakers.module.scss';

const SessionSpeakers = (props) => {
  const { speakers } = props;

  const createSessionDetailsService = async (id) => {
    return getService(`${serviceConstants.MEA_SERVER_CONFIGURATION.resource.speakerAvatar}${id}`);
  };
  const [imageId] = useState(speakers ? speakers.PERSON_GUID : null);
  const [imageSource, setImageSource] = useState(null);
  const [avatarImage, isLoading, , fetchAvatar] = useFetching(
    createSessionDetailsService.bind(null, imageId)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (imageId) {
          fetchAvatar();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (avatarImage?.data?.data) {
          let imageSource = '';
          const fileNameFull = avatarImage.headers['content-disposition'];
          const fileExtension = fileNameFull.split('.');

          if (
            globalConstants.supportedAvatarImageExtension.includes(fileExtension[1].toLowerCase())
          ) {
            imageSource = `data:image/${fileExtension[1].toLowerCase()};base64,${
              avatarImage.data.data
            }`;
          } else {
            imageSource = `data:image/png;base64,${avatarImage.data.data}`;
          }
          setImageSource(imageSource);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [avatarImage]);

  return (
    <>
      <Item className={style.speakerContainer} data-test-id={'sessionSpeakerContainer'}>
        <Grid columns={16}>
          <Grid.Column width={4} verticalAlign='middle' className={style.avatarContainer}>
            {isLoading || isLoading ? (
              <LoadingPlaceholder needImagePlaceHolder={true} />
            ) : (
              <>
                {speakers && imageSource ? (
                  <Item.Image size='mini' src={imageSource} className={style.avatarStyle} />
                ) : (
                  <Icon bordered size='large' className={`user outline ${style.noAvatarColor}`} />
                )}
              </>
            )}
          </Grid.Column>
          <Grid.Column width={12} className={style.detailsContainer}>
            <Item.Content className={style.speakerDetails}>
              <Item.Description className={style.speakerDetailsStyle}>
                {speakers.NAME_FIRST} {speakers.NAME_LAST}
              </Item.Description>
              <Item.Description>{speakers.ORGANISATION_NAME}</Item.Description>
              <Item.Meta className={style.speakerIconColorStyle}>{speakers.PARTNER_DESC}</Item.Meta>
            </Item.Content>
          </Grid.Column>
        </Grid>
      </Item>
    </>
  );
};

export default SessionSpeakers;
