import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Rating from 'semantic-ui-react/dist/commonjs/modules/Rating';

import { defaultThumbnail } from '@common/Constants';
import {
  formatDateStringToDateObject,
  formatDateDMYSlashDelimiter,
  momentFormatDateDMYSlashDelimiter,
  formatDateDMY,
  formatLongDateStringToDateObject,
  formatDateTimeStringToDateTimeObject
} from '@common/Util';
import {
  StyledCardContentListView,
  CardImageListView,
  CardImagePlainListView,
  CardContentListViewGrid,
  CardContentPlainListViewGrid,
  ListViewNameRow,
  EvtNameHeader,
  ListViewFavColumn,
  ListViewDetailRow,
  PlainListViewDetailRow,
  TextShrink,
  ListViewRatingContainer,
  ListViewRatingCount,
  ListViewDateContainer,
  PlanListViewDateContainer,
  ListViewCalendarIcon,
  ListViewDetailColumn,
  PlainListViewDetailColumn,
  ListViewLocationContainer,
  ListViewCountryHolder,
  TrainingCityText,
  ListViewOverdueDateColumn,
  ListViewOverdueDate,
  ListViewExpDateColumn,
  ListViewExpDate,
  FooterFavorite,
  FooterShare,
  FooterStyledIcon,
  StyledProgress,
  FooterProgressPercentage,
  ListViewProgress,
  CardPriceLabel,
  SeatsAvailableText,
  ListViewCourseCalendarIcon
} from '@components/StyledComponents/EventItem.styled';
// Fields COMBINED_VISIT, AUTOMATIC_CONFIRMATION, GUIDE_REQUIRED and NAME_TAG_PRINTING
// are used as indicators for rating, date and location, overdue date and access expired date respectively.
// After integration for Litmos, should be replaced with actual rating, date and location, overdue date and access expired date data
const EventItemContent = (props) => {
  const {
    eventTypeName,
    city,
    country,
    startDate,
    endDate,
    eventName,
    image, // TODO: after integration with litmos, image should part of the data
    config,
    price,
    currency,
    maxParticipantsNo,
    regParticipantsNo,
    combinedVisit, // rating availability;
    automaticConf, // date and location availability;
    guidedReq, // overdue date availability;
    nameTag, // access expired date availability;
    eventStartString
  } = props;

  const [favIcon, setFavIcon] = React.useState(
    config?.footer?.favIconSelected ? config?.footer?.favIconSelected : 'heart outline'
  );
  const customConfig = config;

  const onFavIconClick = (e) => {
    e.stopPropagation();
    if (favIcon.includes('outline')) {
      setFavIcon(customConfig?.footer?.favIcon ? customConfig?.footer?.favIcon : 'heart');
    } else {
      setFavIcon(
        customConfig?.footer?.favIconSelected
          ? customConfig?.footer?.favIconSelected
          : 'heart outline'
      );
    }

    // TODO after integration with litmos
    // update users favorite events
  };

  const userRatingCount = 1; // TODO: after integration with litmos, no of users rated event should be part of the data
  const percentageCompleted = '75%'; // TODO: after integration with litmos, percentage completed should be part of the data

  const getImageLabel = () => {
    // as we don't know yet how litmos will give data for events,
    // for now it is hardcoded only for seminars and roadshows
    if (config?.content?.markLabel && config.content.markLabel[eventTypeName]) {
      return {
        content: `${eventTypeName}`,
        ribbon: true,
        markcolor: `${config.content.markLabel[eventTypeName].markLabelBackgroundText}`,
        markcolortext: `${config.content.markLabel[eventTypeName].markLabelColorText}`
      };
    }
    return null;
  };

  const getImageSource = () => {
    if (image && image !== defaultThumbnail.name) {
      customConfig.content.notDefaultImg = true;
      return image;
    } else {
      customConfig.content.notDefaultImg = false;
      return require(`../../../assets/Images/${customConfig.content.img}`).default;
    }
  };

  let currencySymbol = null;
  if (price && price.length && currency) {
    const priceFormatted = Number().toLocaleString(window.navigator.language, {
      style: 'currency',
      currency: currency
    });

    if (Number.isInteger(parseInt(priceFormatted.substring(0, 1), 10))) {
      currencySymbol = priceFormatted.substring(priceFormatted.length - 1, priceFormatted.length);
    } else {
      currencySymbol = priceFormatted.substring(0, 1);
    }
  }

  const getContent = () => {
    switch (config?.name) {
      case 'catalog':
        return (
          <>
            <CardImageListView
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentListView data-test-id={'eventListViewContent'}>
              <CardContentListViewGrid stretched>
                <ListViewNameRow>
                  <Grid.Column floated='left' computer={14} tablet={14}>
                    <EvtNameHeader>{eventName}</EvtNameHeader>
                  </Grid.Column>

                  <ListViewFavColumn floated='right' computer={2} tablet={2}>
                    <FooterFavorite visible={true}>
                      <FooterStyledIcon
                        config={customConfig}
                        name={favIcon}
                        onClick={(evt) => onFavIconClick(evt)}
                      />
                    </FooterFavorite>
                    <FooterShare visible={true}>
                      <FooterStyledIcon config={customConfig} name='share alternate' />
                    </FooterShare>
                  </ListViewFavColumn>
                </ListViewNameRow>

                <ListViewDetailRow>
                  {combinedVisit && (
                    <Grid.Column computer={3} tablet={4} largeScreen={3} widescreen={3}>
                      <ListViewRatingContainer>
                        <Rating icon='star' maxRating={5} disabled />
                        <ListViewRatingCount>({userRatingCount})</ListViewRatingCount>
                      </ListViewRatingContainer>
                    </Grid.Column>
                  )}
                  <ListViewDetailColumn
                    computer={4}
                    tablet={5}
                    largeScreen={3}
                    widescreen={3}
                    verticalAlign='middle'
                  >
                    <ListViewDateContainer textcolor={customConfig.content.dateTextColor}>
                      <TextShrink>
                        {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                      </TextShrink>
                      <ListViewCalendarIcon name='calendar alternate outline' />
                    </ListViewDateContainer>
                  </ListViewDetailColumn>
                  <ListViewDetailColumn
                    computer={8}
                    tablet={3}
                    largeScreen={8}
                    widescreen={8}
                    verticalAlign='middle'
                  >
                    <ListViewLocationContainer textcolor={customConfig.content.locationColor}>
                      <TrainingCityText>{city}</TrainingCityText>
                      <ListViewCountryHolder>{country}</ListViewCountryHolder>
                    </ListViewLocationContainer>
                  </ListViewDetailColumn>
                  {price && price.length && currency && (
                    <ListViewDetailColumn
                      computer={2}
                      tablet={2}
                      largeScreen={2}
                      widescreen={2}
                      verticalAlign='middle'
                    >
                      <CardPriceLabel basic color='orange' size='large'>
                        {price + ' ' + currencySymbol}
                      </CardPriceLabel>
                    </ListViewDetailColumn>
                  )}
                </ListViewDetailRow>
                <ListViewDetailRow>
                  <ListViewDetailColumn width={3}>
                    <TextShrink>{eventTypeName}</TextShrink>
                  </ListViewDetailColumn>
                  {maxParticipantsNo && regParticipantsNo && (
                    <ListViewDetailColumn width={13}>
                      <SeatsAvailableText>
                        {maxParticipantsNo - regParticipantsNo + '/' + maxParticipantsNo}{' '}
                        {Localize.get('Catalog.seatsAvailable').toUpperCase()}
                      </SeatsAvailableText>
                    </ListViewDetailColumn>
                  )}
                </ListViewDetailRow>
              </CardContentListViewGrid>
            </StyledCardContentListView>
          </>
        );
      case 'training':
        return (
          <>
            <CardImageListView
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentListView data-test-id={'eventListViewContent'}>
              <CardContentListViewGrid stretched>
                <ListViewNameRow>
                  <Grid.Column floated='left' computer={15} tablet={14}>
                    <EvtNameHeader>{eventName}</EvtNameHeader>
                  </Grid.Column>
                  <ListViewFavColumn floated='right' computer={1} tablet={2}>
                    <FooterFavorite visible={true}>
                      <FooterStyledIcon
                        config={customConfig}
                        name={favIcon}
                        onClick={(evt) => onFavIconClick(evt)}
                      />
                    </FooterFavorite>
                    <FooterShare visible={true}>
                      <FooterStyledIcon config={customConfig} name='share alternate' />
                    </FooterShare>
                  </ListViewFavColumn>
                </ListViewNameRow>
                <ListViewDetailRow>
                  {combinedVisit && (
                    <Grid.Column computer={3} tablet={4} largeScreen={3} widescreen={3}>
                      <ListViewRatingContainer>
                        <Rating icon='star' maxRating={5} disabled />
                        <ListViewRatingCount>({userRatingCount})</ListViewRatingCount>
                      </ListViewRatingContainer>
                    </Grid.Column>
                  )}
                  <ListViewDetailColumn
                    computer={3}
                    tablet={4}
                    largeScreen={2}
                    widescreen={2}
                    verticalAlign='middle'
                  >
                    <ListViewDateContainer textcolor={customConfig.content.dateTextColor}>
                      <TextShrink>
                        {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                      </TextShrink>
                      <ListViewCalendarIcon name='calendar alternate outline' />
                    </ListViewDateContainer>
                  </ListViewDetailColumn>
                  <ListViewDetailColumn
                    computer={3}
                    tablet={4}
                    largeScreen={3}
                    widescreen={3}
                    verticalAlign='middle'
                  >
                    <ListViewLocationContainer textcolor={customConfig.content.locationColor}>
                      <TrainingCityText>{city}</TrainingCityText>
                      <ListViewCountryHolder>{country}</ListViewCountryHolder>
                    </ListViewLocationContainer>
                  </ListViewDetailColumn>
                  {guidedReq && (
                    <ListViewOverdueDateColumn
                      computer={4}
                      largeScreen={4}
                      widescreen={3}
                      tablet={6}
                      verticalAlign='middle'
                      needtoppadding={combinedVisit && automaticConf ? 1 : 0}
                    >
                      <ListViewOverdueDate
                        color={config.content.overdueTextColor}
                        background={config.content.overdueTextBackground}
                        radius={config.content.overdueExpTextCornerRadius}
                      >
                        {Localize.get('App.overdue').toUpperCase()}{' '}
                        {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                      </ListViewOverdueDate>
                    </ListViewOverdueDateColumn>
                  )}
                  {nameTag && (
                    <ListViewExpDateColumn
                      computer={5}
                      tablet={6}
                      largeScreen={4}
                      widescreen={4}
                      verticalAlign='middle'
                      needtoppadding={
                        combinedVisit && automaticConf && guidedReq
                          ? 'computer tablet'
                          : (combinedVisit && automaticConf) || (automaticConf && guidedReq)
                          ? 'tablet'
                          : 0
                      }
                    >
                      <ListViewExpDate
                        color={config.content.accessExpTextColor}
                        background={config.content.accessExpTextBackground}
                        radius={config.content.overdueExpTextCornerRadius}
                      >
                        {Localize.get('Training.accessExp').toUpperCase()}{' '}
                        {formatDateDMYSlashDelimiter(formatDateStringToDateObject(startDate))}
                      </ListViewExpDate>
                    </ListViewExpDateColumn>
                  )}
                </ListViewDetailRow>
                <ListViewDetailRow>
                  <ListViewDetailColumn width={16}>
                    <ListViewProgress visible={true}>
                      <StyledProgress size='tiny' />
                      <FooterProgressPercentage>{percentageCompleted}</FooterProgressPercentage>
                    </ListViewProgress>
                  </ListViewDetailColumn>
                </ListViewDetailRow>
              </CardContentListViewGrid>
            </StyledCardContentListView>
          </>
        );
      case 'myArea':
        return (
          <>
            <CardImagePlainListView
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentListView data-test-id={'eventListViewContent'}>
              <CardContentPlainListViewGrid stretched stackable>
                <ListViewNameRow>
                  <Grid.Column floated='left' computer={15} tablet={15}>
                    <EvtNameHeader>{eventName}</EvtNameHeader>
                  </Grid.Column>
                </ListViewNameRow>
                <PlainListViewDetailRow>
                  <ListViewDetailColumn
                    computer={5}
                    tablet={8}
                    largeScreen={5}
                    widescreen={5}
                    verticalAlign='middle'
                  >
                    <PlanListViewDateContainer textcolor={customConfig.content.dateTextColor}>
                      {formatDateTimeStringToDateTimeObject(startDate)}
                      <ListViewCalendarIcon name='calendar alternate outline' />
                    </PlanListViewDateContainer>
                  </ListViewDetailColumn>

                  <PlainListViewDetailColumn
                    computer={11}
                    tablet={9}
                    largeScreen={11}
                    widescreen={11}
                    verticalAlign='middle'
                  >
                    <ListViewLocationContainer textcolor={customConfig.content.locationColor}>
                      <TrainingCityText>{city}</TrainingCityText>
                      <ListViewCountryHolder>{country}</ListViewCountryHolder>
                    </ListViewLocationContainer>
                  </PlainListViewDetailColumn>
                </PlainListViewDetailRow>
              </CardContentPlainListViewGrid>
            </StyledCardContentListView>
          </>
        );
      case 'trainerArea':
        return (
          <>
            <CardImagePlainListView
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentListView data-test-id={'eventListViewContent'}>
              <CardContentPlainListViewGrid stretched stackable>
                <ListViewNameRow>
                  <Grid.Column floated='left' computer={15} tablet={15}>
                    <EvtNameHeader>{eventName}</EvtNameHeader>
                  </Grid.Column>
                </ListViewNameRow>
                <PlainListViewDetailRow>
                  <ListViewDetailColumn
                    computer={5}
                    tablet={8}
                    largeScreen={5}
                    widescreen={5}
                    verticalAlign='middle'
                  >
                    <PlanListViewDateContainer textcolor={customConfig.content.dateTextColor}>
                      {momentFormatDateDMYSlashDelimiter(eventStartString)}
                      {eventStartString && (
                        <ListViewCalendarIcon name='calendar alternate outline' />
                      )}
                    </PlanListViewDateContainer>
                  </ListViewDetailColumn>
                </PlainListViewDetailRow>
              </CardContentPlainListViewGrid>
            </StyledCardContentListView>
          </>
        );
      case 'allSessions':
        return (
          <>
            <CardImageListView
              config={customConfig}
              wrapped
              src={getImageSource()}
              label={getImageLabel()}
            />
            <StyledCardContentListView data-test-id={'eventListViewContent'}>
              <CardContentListViewGrid stretched style={{ height: 'auto' }}>
                <ListViewNameRow>
                  <Grid.Column floated='left' computer={15} tablet={14}>
                    <EvtNameHeader>{eventName}</EvtNameHeader>
                  </Grid.Column>
                </ListViewNameRow>
                <ListViewDetailRow>
                  {combinedVisit && (
                    <Grid.Column computer={3} tablet={4} largeScreen={3} widescreen={3}>
                      <ListViewRatingContainer>
                        <Rating icon='star' maxRating={5} disabled />
                        <ListViewRatingCount>({userRatingCount})</ListViewRatingCount>
                      </ListViewRatingContainer>
                    </Grid.Column>
                  )}
                  <ListViewDetailColumn
                    computer={4}
                    tablet={3}
                    largeScreen={4}
                    widescreen={4}
                    verticalAlign='middle'
                  >
                    <ListViewDateContainer textcolor={customConfig.content.dateTextColor}>
                      {startDate && endDate
                        ? formatDateDMY(formatLongDateStringToDateObject(startDate)) +
                          '-' +
                          formatDateDMY(formatLongDateStringToDateObject(endDate))
                        : ''}
                      {startDate && endDate ? (
                        <ListViewCourseCalendarIcon name='calendar alternate outline' />
                      ) : (
                        ''
                      )}
                    </ListViewDateContainer>
                  </ListViewDetailColumn>
                  <ListViewDetailColumn
                    computer={3}
                    tablet={4}
                    largeScreen={3}
                    widescreen={3}
                    verticalAlign='middle'
                  >
                    <ListViewLocationContainer textcolor={customConfig.content.locationColor}>
                      <TrainingCityText>{city}</TrainingCityText>
                      <ListViewCountryHolder>{country}</ListViewCountryHolder>
                    </ListViewLocationContainer>
                  </ListViewDetailColumn>
                </ListViewDetailRow>
                <ListViewDetailRow>
                  <ListViewDetailColumn width={3}>
                    <TextShrink>
                      {' '}
                      {eventTypeName ? Localize.get(`TrainerArea.${eventTypeName}`) : null}
                    </TextShrink>
                  </ListViewDetailColumn>
                </ListViewDetailRow>
              </CardContentListViewGrid>
            </StyledCardContentListView>
          </>
        );
      default:
        return null;
    }
  };

  return getContent();
};

export default EventItemContent;
