import React from 'react';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import Rating from 'semantic-ui-react/dist/commonjs/modules/Rating';

const RatingFilter = (props) => {
  const { config, onFilterChange } = props;

  //TODO will be implemented with litmos integration
  // eslint-disable-next-line no-unused-vars
  const [filterValue, setFilterValue] = React.useState(false);

  const onChange = (e, data) => {
    setFilterValue(data.value);
    onFilterChange(config, data);
  };

  return (
    <>
      <Grid data-test-id={'ratingFilterContainer'}>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              fitted
              checked={filterValue}
              onChange={onChange}
              disabled={config.disabled}
            ></Checkbox>
          </Grid.Column>
          <Grid.Column>
            <Rating icon='star' maxRating={5} rating={5} disabled />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              fitted
              checked={filterValue}
              onChange={onChange}
              disabled={config.disabled}
            ></Checkbox>
          </Grid.Column>
          <Grid.Column>
            <Rating icon='star' maxRating={5} rating={4} disabled />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              fitted
              checked={filterValue}
              onChange={onChange}
              disabled={config.disabled}
            ></Checkbox>
          </Grid.Column>
          <Grid.Column>
            <Rating icon='star' maxRating={5} rating={3} disabled />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              fitted
              checked={filterValue}
              onChange={onChange}
              disabled={config.disabled}
            ></Checkbox>
          </Grid.Column>
          <Grid.Column>
            <Rating icon='star' maxRating={5} rating={2} disabled />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Checkbox
              fitted
              checked={filterValue}
              onChange={onChange}
              disabled={config.disabled}
            ></Checkbox>
          </Grid.Column>
          <Grid.Column>
            <Rating icon='star' maxRating={5} rating={1} disabled />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
export default React.memo(RatingFilter);
