import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import { formatLongDateTimeStringToDateTimeObject } from '@common/Util.js';
import { notesTypes } from '@pages/AllSession/Constants';

import style from '../../EventDetails/Tabs/style/Information.module.scss';
import SessionDetailsNote from '../SessionDetailsNote.jsx';

const Information = (props) => {
  const { iltSession, config, isLoading, notesData } = props;

  const stringItem = (item, index) => {
    const contentClassName = ['instructors', 'location'].includes(item.name) ? style.newRow : '';
    return (
      <Segment basic key={index}>
        <Grid className={style.groupStyle} centered>
          <Grid.Column className={style.columnStyle} computer={4} mobile={6}>
            {Localize.get(item.label)}
          </Grid.Column>
          <Grid.Column only='computer' computer={1} />
          {['instructors', 'location'].includes(item.name) && isLoading && (
            <Dimmer active inverted>
              <Loader size='large'>{Localize.get('App.loading')}</Loader>
            </Dimmer>
          )}
          <Grid.Column
            verticalAlign='middle'
            textAlign='left'
            computer={7}
            mobile={10}
            className={contentClassName}
          >
            {item.beValueSuffix
              ? iltSession[item.beValue] && iltSession[item.beValue][item.beValueSuffix]
                ? iltSession[item.beValue][item.beValueSuffix]
                : ''
              : iltSession[item.beValue]}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  };

  const dateItem = (item, index) => {
    return (
      <Segment key={index} basic>
        <Grid className={style.groupStyle} centered>
          <Grid.Column className={style.columnStyle} computer={4} mobile={6}>
            {Localize.get(item.label)}
          </Grid.Column>
          <Grid.Column only='computer' computer={1} />
          <Grid.Column verticalAlign='middle' textAlign='left' computer={7} mobile={10}>
            {formatLongDateTimeStringToDateTimeObject(iltSession[item.beValue])}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  };

  return (
    <>
      <Grid
        data-testid={'allSessionInformationTab'}
        className={style.columnTextAlign}
        style={{ background: config?.configuration?.AllSessionDetailsContent.background }}
      >
        <Grid.Row centered className={`${style.mobileContainer}`}>
          <Grid.Column computer={8} mobile={16}>
            {config?.configuration?.AllSessionInfoTab.items
              .sort((a, b) => a.order - b.order)
              .map((item, index) => {
                switch (item.name) {
                  case 'iltSession':
                    return stringItem(item, index);
                  case 'iltModule':
                    return stringItem(item, index);
                  case 'course':
                    return stringItem(item, index);
                  case 'type':
                    return stringItem(item, index);
                  case 'seminarNumber':
                    return stringItem(item, index);
                  case 'instructors':
                    return stringItem(item, index);
                  case 'startDate':
                    return dateItem(item, index);
                  case 'endDate':
                    return dateItem(item, index);
                  case 'location':
                    return stringItem(item, index);
                }
              })}
          </Grid.Column>

          <Grid.Column
            data-testid={'allSessionInformationTabDetails'}
            computer={8}
            mobile={16}
            className={style.detailsNote}
            style={{ background: config?.configuration?.AllSessionDetailsContent.background }}
          >
            <SessionDetailsNote
              notesData={notesData}
              isLoading={isLoading}
              noteType={notesTypes.agenda}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Information;
