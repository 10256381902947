import React, { Fragment } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import * as globalConstants from '@common/Constants.js';
import { formatDateDMYHMWithDayName, getDateByMetricType } from '@common/Util';

import styles from '../style/AllSessionDetails.module.scss';

const IltSessionRegistrationControl = ({
  iltSession,
  participantStatusKey,
  iltSessionStatus,
  config
}) => {
  const participantStatusRegistered =
    participantStatusKey === globalConstants.registrationStatuses.registered;

  const cancellationDate = getDateByMetricType(
    iltSession.startDateTime,
    iltSession.cancellationBeforeStartMetricType?.id,
    iltSession.cancellationBeforeStart
  );

  const cancellationAllowed =
    iltSession?.hasCancellationDeadline &&
    Number.isInteger(iltSession?.cancellationBeforeStart) &&
    Number.isInteger(iltSession?.cancellationBeforeStartMetricType?.id) &&
    new Date(cancellationDate) > new Date();

  const buttonConfig = {
    color: config?.configuration?.Registration?.stepperButtonColor,
    cancel: Localize.get('CourseDetails.cancel'),
    edit: Localize.get('CourseDetails.edit'),
    register: Localize.get('App.register')
  };

  return (
    <Fragment>
      {/* Display the registration control section only if the ILT session status is 'registered' or 'available'
          and the participant status is not 'completed'.*/}
      {(iltSessionStatus === globalConstants.iltSessionsStatus.registered ||
        iltSessionStatus === globalConstants.iltSessionsStatus.available) &&
        participantStatusKey !== globalConstants.registrationStatuses.completed && (
          <>
            {/* desktop && tablet layout*/}
            {(config?.size?.device === globalConstants.deviceSize.computer ||
              config?.size?.device === globalConstants.deviceSize.tablet) && (
              <Grid relaxed centered stackable data-testid='iltSessionRegistrationControlDesktop'>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Grid className={styles.textBlock}>
                      <Grid.Column width={1} verticalAlign='middle'>
                        <Icon name='info circle' />
                      </Grid.Column>
                      {cancellationAllowed ? (
                        <Grid.Column width={13} textAlign='center'>
                          <Grid.Row>{`${Localize.get(
                            'CourseDetails.possibleCancellationDeadline'
                          )} `}</Grid.Row>
                          <Grid.Row>{`${formatDateDMYHMWithDayName(
                            new Date(cancellationDate)
                          )}`}</Grid.Row>
                        </Grid.Column>
                      ) : (
                        <Grid.Column width={14} textAlign='center'>
                          <Grid.Row>
                            {`${Localize.get('CourseDetails.cancellationDeadlinePassed')}`}
                          </Grid.Row>
                          <Grid.Row>{Localize.get('CourseDetails.pleaseContact')}</Grid.Row>
                          <span className={styles.emailLink}>
                            {config?.configuration?.CourseDetailsContent?.supportContactLink}
                          </span>
                        </Grid.Column>
                      )}
                    </Grid>
                  </Grid.Column>
                  {participantStatusRegistered ? (
                    <Fragment>
                      <Grid.Column width={3} verticalAlign='middle'>
                        <Button
                          color={buttonConfig.color}
                          content={buttonConfig.cancel}
                          disabled={!cancellationAllowed}
                        />
                      </Grid.Column>
                      <Grid.Column width={1} verticalAlign='middle'>
                        <Button
                          color={buttonConfig.color}
                          content={buttonConfig.edit}
                          disabled={!cancellationAllowed}
                        />
                      </Grid.Column>
                    </Fragment>
                  ) : (
                    <Grid.Column width={1} verticalAlign='middle'>
                      <Button
                        color={buttonConfig.color}
                        content={buttonConfig.register}
                        disabled={true}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            )}

            {/* mobile layout */}
            {config?.size?.device === globalConstants.deviceSize.mobile && (
              <Grid relaxed centered stackable data-testid='iltSessionRegistrationControlMobile'>
                <Grid.Column></Grid.Column>
                <Grid.Row>
                  <Grid className={styles.textBlock} centered>
                    <Grid.Column width={1} verticalAlign='middle'>
                      <Icon name='info circle' />
                    </Grid.Column>
                    {cancellationAllowed ? (
                      <Grid.Column width={13} textAlign='center'>
                        <Grid.Row>{`${Localize.get(
                          'CourseDetails.possibleCancellationDeadline'
                        )} `}</Grid.Row>
                        <Grid.Row>{`${formatDateDMYHMWithDayName(
                          new Date(cancellationDate)
                        )}`}</Grid.Row>
                      </Grid.Column>
                    ) : (
                      <Grid.Column width={13} textAlign='center'>
                        <Grid.Row>
                          {`${Localize.get('CourseDetails.cancellationDeadlinePassed')}`}
                        </Grid.Row>
                        <Grid.Row>{`${Localize.get('CourseDetails.pleaseContact')} `}</Grid.Row>
                        <Grid.Row>
                          <span className={styles.emailLink}>
                            {config?.configuration?.CourseDetailsContent?.supportContactLink}
                          </span>
                        </Grid.Row>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column></Grid.Column>
                  {participantStatusRegistered ? (
                    <Fragment>
                      <Button
                        color={buttonConfig.color}
                        content={buttonConfig.cancel}
                        disabled={!cancellationAllowed}
                      />
                      <Button
                        color={buttonConfig.color}
                        content={buttonConfig.edit}
                        disabled={!cancellationAllowed}
                      />
                    </Fragment>
                  ) : (
                    <Button
                      color={buttonConfig.color}
                      content={buttonConfig.register}
                      disabled={true}
                    />
                  )}
                </Grid.Row>
                <Grid.Column></Grid.Column>
              </Grid>
            )}
          </>
        )}
    </Fragment>
  );
};

export default IltSessionRegistrationControl;
