import React from 'react';
import Localize from 'react-intl-universal';

import {
  FilterButtonsContainer,
  StyledFilterButtons
} from '@components/StyledComponents/Training.styled';

const FilterButtons = ({ onResetFilters, onSearchEvents }) => {
  return (
    <FilterButtonsContainer>
      <StyledFilterButtons onClick={onResetFilters}>
        {Localize.get('App.reset')}
      </StyledFilterButtons>
      <StyledFilterButtons onClick={onSearchEvents}>
        {Localize.get('App.apply')}
      </StyledFilterButtons>
    </FilterButtonsContainer>
  );
};

export default FilterButtons;
