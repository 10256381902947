import React, { useEffect, useState } from 'react';

// semantic
import Table from 'semantic-ui-react/dist/commonjs/collections/Table/Table';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';

import * as globalConstants from '@common/Constants.js';
import Logger from '@common/Logger.js';
import { getAttachmentMimeType } from '@common/Util.js';
import useFetching from '@hooks/Fetching/Fetching.js';

import { createAttachmentContentService } from './AttachmentsHelper.js';
import style from './style/Attachments.module.scss';

const AttachmentItem = (props) => {
  const { attachment } = props;

  const [attachmentId] = useState(attachment ? attachment.ID : '');

  const [attachmentData, , , fetchAttachmentData] = useFetching(
    createAttachmentContentService.bind(null, attachmentId),
    true
  );
  const [attachmentDownloadData, , , fetchAttachmentDownloadData] = useFetching(
    createAttachmentContentService.bind(null, attachmentId),
    true
  );

  const onOpenFile = () => {
    if (getIsPreviewAvailable()) {
      fetchAttachmentData();
    }
  };

  const onDownloadFile = () => {
    fetchAttachmentDownloadData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (attachmentDownloadData && attachmentDownloadData.data) {
          const fileNameFull = attachmentDownloadData.headers['content-disposition'];
          const fileName = fileNameFull.split('=');

          const temp = window.URL.createObjectURL(
            new Blob([attachmentDownloadData.data], { type: 'application/pdf' })
          );
          const link = document.createElement('a');
          link.href = temp;
          link.setAttribute('download', fileName[1]);
          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [attachmentDownloadData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (attachmentData && attachmentData.data) {
          const fileNameFull = attachmentData.headers['content-disposition'];
          const fileExtension = fileNameFull.split('.');

          const file = new Blob([attachmentData.data], {
            type: getAttachmentMimeType(fileExtension[fileExtension.length - 1])
          });

          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);

          window.open(fileURL);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentData]);

  const getIsPreviewAvailable = () => {
    return (
      globalConstants.supportedAvatarImageExtension.includes(attachment.EXTENSION.toLowerCase()) ||
      attachment.EXTENSION.toLowerCase() === 'pdf'
    );
  };

  return (
    <Table.Row data-test-id={'attachmentRow'}>
      <Table.Cell>{attachment.NAME}</Table.Cell>
      <Table.Cell>{attachment.EXTENSION}</Table.Cell>
      <Table.Cell className={style.sizeColumn}>{attachment.SIZE}</Table.Cell>
      <Table.Cell>
        <Icon
          name='eye'
          onClick={onOpenFile}
          className={getIsPreviewAvailable() ? style.availPreviewIconStyle : ''}
        />
        <Icon name='download' className={style.downloadIconStyle} onClick={onDownloadFile} />
      </Table.Cell>
    </Table.Row>
  );
};

export default AttachmentItem;
