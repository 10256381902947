import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Logger from '@common/Logger.js';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import useFetching from '@hooks/Fetching/Fetching.js';

import { createAttachmentService } from './AttachmentsHelper.js';
import AttachmentsTable from './AttachmentsTable.jsx';
import style from './style/Attachments.module.scss';

const Attachments = (props) => {
  const { guid } = props;

  const [attachments, isLoadingAttachments, , fetchAttachments] = useFetching(
    createAttachmentService.bind(null, guid),
    true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (guid) {
          fetchAttachments();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid]);

  return (
    <>
      <Grid columns={1}>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          {isLoadingAttachments && (
            <Dimmer active inverted>
              <Loader size='large'>{Localize.get('App.loading')}</Loader>
            </Dimmer>
          )}
          <Grid.Row only='tablet computer'>
            <Divider hidden />
          </Grid.Row>
          {attachments?.data?.attachments?.results?.length ? (
            <Grid columns={3} className={style.tableContainer}>
              <Grid.Column only='tablet computer' computer={1} tablet={1} />
              <Grid.Column computer={14} tablet={14} mobile={16}>
                <AttachmentsTable attachments={attachments.data.attachments} />
              </Grid.Column>
              <Grid.Column only='tablet computer' computer={1} tablet={1} />
            </Grid>
          ) : (
            <div>
              {/* computer & tablet layout */}
              <Grid columns={3} stackable className='tablet computer only'>
                <Grid.Column computer={1} tablet={1} />
                <Grid.Column computer={14} tablet={14} className={style.noAttachmentContainer}>
                  <NoContentComponent
                    data-test-id={'noAttachmentsMessage'}
                    infoType={Localize.get('EventDetails.noAttachments')}
                  />
                </Grid.Column>
                <Grid.Column only='tablet computer' computer={1} tablet={1} />
              </Grid>
              {/* mobile layout */}
              <Grid columns={1} className='mobile only'>
                <Grid.Column mobile={16} className={style.noAttachmentContainer}>
                  <NoContentComponent infoType={Localize.get('EventDetails.noAttachments')} />
                </Grid.Column>
              </Grid>
            </div>
          )}
        </Grid.Column>
      </Grid>
    </>
  );
};

export default Attachments;
