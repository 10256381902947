import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import styled from 'styled-components';

const justify = 'space-around';

export const MyAreaGridContainer = styled(Grid)`
  margin: auto !important;
`;

export const MyAreaGridColumnContainer = styled(Grid.Column)`
  padding: 0 !important;
`;

export const MyAreaContentContainer = styled(MyAreaGridContainer)`
  min-height: 81vh;
`;

export const MyAreaColumnContentContainer = styled(Grid.Column)`
  padding-bottom: 0 !important;
`;

export const MyAreaRowContentContainer = styled(Grid.Row)`
  padding-bottom: 0 !important;
`;

export const StyledTabMenu = styled(Menu)`
  justify-content: ${justify} !important;
  @media only screen and (min-width: 767px) {
    &&& .item {
      padding-left: 1rem !important;
    }
  }
`;

export const StyledTabMenuItem = styled(Menu.Item)`
  min-width: fit-content;
  justify-content: stretch !important;
  flex: 1 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const MyAreaMobileMenu = styled(Dropdown.Menu)`
  max-height: fit-content !important;
`;
