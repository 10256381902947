import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

export const StyledHeading = styled(Typography)(() => ({
  fontSize: '1.15rem',
  fontWeight: '500'
}));

export const StyledSpan = styled('span')(() => ({
  color: '#af9191',
  paddingRight: '0.2rem',
  paddingLeft: '0.2rem'
}));

export const StyledTypography = styled(Typography)(() => ({
  color: '#af9191'
}));

export const StyledTypographyPadding = styled(Typography)(() => ({
  color: '#af9191',
  paddingRight: '2rem'
}));
