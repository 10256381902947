import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useHistory } from 'react-router-dom';

import { cloneDeep } from 'lodash';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import { courseType } from '@common/Constants';
import Logger from '@common/Logger';
import CourseGridList from '@components/CourseGridListView/CourseGridList/CourseGridList';
import CourseGridListInfinity from '@components/CourseGridListView/CourseGridList/CourseGridListInfinity';
import CourseList from '@components/CourseListView/CourseList/CourseList';
import CourseListInfinity from '@components/CourseListView/CourseList/CourseListInfinity';
import Filters from '@components/Filters/Filters';
import GridListSwitch from '@components/GridListSwitch/GridListSwitch';
import Pagination from '@components/Pagination/Pagination';
import {
  CatalogListContainer,
  FilterSegment,
  FilterHeader,
  FilterContainerHeader,
  FilterButtonsContainer,
  StyledFilterButtons,
  FilterSortIconsContainer,
  CatalogPaginationContainer,
  CatalogSearchRowContainer,
  SortersMobileRowContainer,
  FiltersMobileRowContainer,
  FlexRowContainer,
  CatalogColumn
} from '@components/StyledComponents/Catalog.styled.js';
import predefinedFilterValues from '@config/predefinedFilterValues.config.json';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants';
import { createAllCoursesService, createCourseFiltersService } from '@pages/Catalog/MainHelper';
import style from '@pages/Catalog/style/Catalog.module.scss';
import { getInitialFilterConfiguration, resetFilters, searchCourses } from '@pages/Catalog/Util';

const CoursesTab = (props) => {
  const { contextData } = props;

  let history = useHistory();
  const [filtersConfig, setFiltersConfig] = React.useState(
    contextData?.configuration?.CourseFilters.filtersConfig
  );
  const [courses, setCourses] = React.useState([]);
  const [allCourses, setAllCourses] = React.useState([]);
  const [filteredCourses, setFilteredCourses] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [filters, setFilters] = React.useState({ searchString: '' });
  const [listView, setListView] = React.useState('grid');
  const [totalPages, setTotalPages] = React.useState(0);
  const [activeFiltersMobile, setActiveFiltersMobile] = React.useState(false);
  const [showResetButtonIcon] = React.useState(false);
  const [shouldResetFilters, setShouldResetFilters] = React.useState(false);
  const [predefinedFilter, setPredefinedFilter] = React.useState('');

  const [coursesLanguages, setCoursesLanguages] = React.useState([]);
  const [coursesSkills, setCoursesSkills] = React.useState([]);
  const [coursesTags, setCoursesTags] = React.useState([]);
  const [coursesTopics, setCoursesTopics] = React.useState([]);
  const [coursesTypes, setCoursesTypes] = React.useState([]);

  const searchCompRef = React.useRef(null);
  const searchMobRef = React.useRef(null);
  const filtersRef = React.useRef(null);

  const postsPerPage =
    contextData?.configuration?.PAGINATION.eventsLimit[listView][
      contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
    ];

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = allCourses.slice(indexOfFirstPost, indexOfLastPost);

  // Get all courses to display data when filters are activated, not to call external service for each filter
  const [allCoursesData, isLoading, setIsLoading, fetchAllCourses] = useFetching(
    createAllCoursesService.bind(
      null,
      contextData?.user ? contextData.user : 'tobias.rothley@contrimo.com'
    ),
    true
  );
  const [coursesFiltersData, , , fetchCoursesFilters] = useFetching(
    createCourseFiltersService.bind(
      null,
      contextData?.user ? contextData.user : 'tobias.rothley@contrimo.com' // TODO: remove temporary email when login is in function
    ),
    true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        fetchAllCourses();
        fetchCoursesFilters();
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const courseListViewLocalStorage = window.localStorage.getItem('courseListView');
    setListView(courseListViewLocalStorage ? courseListViewLocalStorage : 'grid');
  }, []);

  useEffect(() => {
    if (contextData?.configuration) {
      setFiltersConfig(contextData?.configuration?.CourseFilters.filtersConfig);
    }
  }, [contextData.configuration]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (filtersConfig) {
          let fltConfig = getInitialFilterConfiguration(filtersConfig);
          if (!Object.keys(predefinedFilter).length) {
            setFilters(fltConfig);
          } else {
            const filterValue =
              predefinedFilterValues.filters[Object.keys(predefinedFilter)[0]][
                Object.values(predefinedFilter)[0]
              ];
            let filtersInitConfig = cloneDeep(fltConfig);
            filtersInitConfig[Object.keys(predefinedFilter)[0]].push(filterValue);

            setFilters(filtersInitConfig);
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [filtersConfig, predefinedFilter]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size) {
          const indexOfLastPost = currentPage * postsPerPage;
          const indexOfFirstPost = indexOfLastPost - postsPerPage;
          if (filteredCourses && filteredCourses.length) {
            setCourses(filteredCourses.slice(indexOfFirstPost, indexOfLastPost));
          } else {
            setCourses(allCourses.slice(indexOfFirstPost, indexOfLastPost));
          }

          if (searchCompRef?.current?.inputRef?.current) {
            searchCompRef?.current.inputRef.current.scrollIntoView();
          }
          if (searchMobRef?.current?.inputRef?.current) {
            searchMobRef?.current.inputRef.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, contextData]);

  useEffect(() => {
    window.localStorage.setItem('courseListView', listView);
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listView]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (allCoursesData?.data?.courses) {
          setAllCourses(allCoursesData.data.courses);

          if (filteredCourses && filteredCourses.length) {
            setCourses(filteredCourses.slice(indexOfFirstPost, indexOfLastPost));

            setTotalPages(
              Math.ceil(
                filteredCourses.length /
                  contextData?.configuration?.PAGINATION.eventsLimit[listView][
                    contextData.size.device.toLowerCase()
                  ]
              )
            );
          } else {
            setCourses(allCoursesData.data.courses.slice(indexOfFirstPost, indexOfLastPost));

            setTotalPages(
              Math.ceil(
                allCoursesData.data.coursesCount /
                  contextData?.configuration?.PAGINATION.eventsLimit[listView][
                    contextData.size.device.toLowerCase()
                  ]
              )
            );
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listView, allCoursesData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (coursesFiltersData?.data) {
          setCoursesLanguages(
            coursesFiltersData.data.languages ? coursesFiltersData.data.languages : []
          );
          setCoursesSkills(coursesFiltersData.data.skills ? coursesFiltersData.data.skills : []);
          setCoursesTags(coursesFiltersData.data.tags ? coursesFiltersData.data.tags : []);
          setCoursesTopics(coursesFiltersData.data.topics ? coursesFiltersData.data.topics : []);
          setCoursesTypes(coursesFiltersData.data.types ? coursesFiltersData.data.types : []);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [coursesFiltersData]);

  const getFilterButtons = () => {
    return (
      <FilterButtonsContainer>
        <StyledFilterButtons onClick={onResetFilters} data-testid={'resetFiltersBtn'}>
          {Localize.get('App.reset')}
        </StyledFilterButtons>
      </FilterButtonsContainer>
    );
  };

  const onSearchFieldChange = (e) => {
    if (e.key === 'Enter') {
      if (searchTerm.length) {
        // Enter is pressed by user
        // and there is at least 1 character in search field
        setCurrentPage(1);
      }
    } else {
      const reg = /'/g;
      // eslint-disable-next-line quotes
      setSearchTerm(e.target.value.replace(reg, "''").trim());

      setFilters({
        ...filters
      });
    }
  };

  const handlersMain = {
    courseList: {
      onItemClick: (courseId, courseTypeName) => {
        history.push({
          pathname:
            courseTypeName === Localize.get(`App.${courseType.learningPath}`)
              ? routerConstants.routes.name.learningPathDetails + '/' + courseId
              : routerConstants.routes.name.courseDetails + '/' + courseId,
          state: {
            from: Localize.get('AppNavBar.learningContent'),
            path: routerConstants.routes.path.catalog,
            data: courseTypeName
          }
        });
        return null;
      }
    }
  };

  const onSearchCourses = () => {
    setIsLoading(false);
    setShouldResetFilters(false);
    setCurrentPage(1);

    searchCourses(
      coursesLanguages,
      coursesSkills,
      coursesTags,
      coursesTopics,
      coursesTypes,
      filters,
      currentPosts,
      setCourses,
      setFilteredCourses,
      setTotalPages,
      allCourses,
      contextData,
      listView,
      setCurrentPage,
      indexOfFirstPost,
      indexOfLastPost
    );
  };

  const onKeyUp = (e) => {
    onSearchFieldChange(e);
  };

  const getContent = () => {
    if (contextData?.size?.device !== 'Mobile') {
      if (listView === 'grid') {
        if (contextData?.configuration?.global.catalogInfinityScroll) {
          return (
            <CourseGridListInfinity
              courses={courses}
              configuration={contextData?.configuration?.CatalogCourseItem}
              pagination={contextData?.configuration?.PAGINATION}
              onItemClick={handlersMain.courseList.onItemClick}
              listColumnCount={contextData?.configuration?.CoursesColumnsWidth?.listCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              filteredCourses={filteredCourses}
              totalPages={totalPages}
              listView={listView}
            />
          );
        } else {
          return (
            <CourseGridList
              courses={courses}
              configuration={contextData?.configuration?.CatalogCourseItem}
              onItemClick={handlersMain.courseList.onItemClick}
              listColumnCount={contextData?.configuration?.CoursesColumnsWidth?.listCount}
            />
          );
        }
      } else {
        if (contextData?.configuration?.global.catalogInfinityScroll) {
          return (
            <CourseListInfinity
              courses={courses}
              configuration={contextData?.configuration?.CatalogCourseItem}
              pagination={contextData?.configuration?.PAGINATION}
              onItemClick={handlersMain.courseList.onItemClick}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              filteredCourses={filteredCourses}
              totalPages={totalPages}
              listView={listView}
            />
          );
        } else {
          return (
            <CourseList
              courses={courses}
              configuration={contextData?.configuration?.CatalogCourseItem}
              onItemClick={handlersMain.courseList.onItemClick}
            />
          );
        }
      }
    } else {
      if (contextData?.configuration?.global.catalogInfinityScroll) {
        return (
          <CourseGridListInfinity
            courses={courses}
            configuration={contextData?.configuration?.CatalogCourseItem}
            pagination={contextData?.configuration?.PAGINATION}
            onItemClick={handlersMain.courseList.onItemClick}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            filteredCourses={filteredCourses}
            totalPages={totalPages}
            listView={listView}
          />
        );
      } else {
        return (
          <CourseGridList
            courses={courses}
            configuration={contextData?.configuration?.CatalogCourseItem}
            onItemClick={handlersMain.courseList.onItemClick}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
          />
        );
      }
    }
  };

  const onResetFilters = () => {
    setShouldResetFilters(true);
    setFilteredCourses([]);

    setSearchTerm('');
    setFilters({
      ...getInitialFilterConfiguration(filtersConfig),
      searchString: filters.searchString
    });

    setCurrentPage(1);
    if (allCoursesData?.data?.coursesCount) {
      setTotalPages(
        Math.ceil(
          allCoursesData.data.coursesCount /
            contextData?.configuration?.PAGINATION.eventsLimit[listView][
              contextData.size.device.toLowerCase()
            ]
        )
      );
    }

    resetFilters(
      setPredefinedFilter,
      coursesLanguages,
      setCoursesLanguages,
      coursesSkills,
      setCoursesSkills,
      coursesTags,
      setCoursesTags,
      coursesTopics,
      setCoursesTopics,
      coursesTypes,
      setCoursesTypes
    );
    setCourses(currentPosts);
  };

  return (
    <CatalogColumn data-testid={'coursesTabCatalogGridColumnContainer'}>
      {/* desktop && tablet layout*/}
      {isLoading && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}

      {/* desktop && tablet layout - menu and content*/}
      <CatalogListContainer className={'computer only'} centered>
        <Grid.Row>
          <Grid.Column
            widescreen={contextData?.configuration?.CoursesColumnsWidth?.courseColumnWidth}
            computer={10}
            mobile={9}
          >
            <Input
              fluid
              disabled={true}
              icon='search'
              iconPosition='left'
              placeholder={Localize.get('Catalog.searchPlaceholder')}
              onKeyUp={onKeyUp}
              ref={searchCompRef}
            />
          </Grid.Column>

          <Grid.Column
            widescreen={contextData?.configuration?.CoursesColumnsWidth?.listCount}
            computer={4}
            mobile={2}
          >
            <GridListSwitch activeOption={listView} setView={setListView} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className={style.eventTabContent}>
          <Grid.Column
            widescreen={contextData?.configuration?.CoursesColumnsWidth?.filterColumnWidth}
            computer={4}
            mobile={2}
            className={style.filterScroll}
          >
            <FilterSegment>
              <FilterSortIconsContainer>{getFilterButtons()}</FilterSortIconsContainer>

              <FilterContainerHeader>
                <FilterHeader>{Localize.get('Catalog.filters')}</FilterHeader>
              </FilterContainerHeader>
              {contextData?.configuration && (
                <Filters
                  allEvents={null}
                  events={null}
                  eventTypes={null}
                  eventStatuses={null}
                  coursesLanguages={coursesLanguages}
                  coursesSkills={coursesSkills}
                  coursesTags={coursesTags}
                  coursesTopics={coursesTopics}
                  coursesTypes={coursesTypes}
                  filters={filters}
                  setFilters={setFilters}
                  configuration={contextData?.configuration?.CourseFilters}
                  device={contextData?.size?.device}
                  visible={true}
                  onSearchFn={onSearchCourses}
                  predefinedFilters={predefinedFilter}
                  filtersRef={filtersRef}
                  shouldResetFilters={shouldResetFilters}
                />
              )}
            </FilterSegment>
          </Grid.Column>

          <Grid.Column
            widescreen={contextData?.configuration?.CoursesColumnsWidth?.courseColumnWidth}
            computer={10}
            mobile={9}
          >
            {getContent()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          {!contextData?.configuration?.global.catalogInfinityScroll && !!totalPages && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          )}
        </Grid.Row>
      </CatalogListContainer>

      {/* mobile layout - content*/}
      <CatalogListContainer className={'tablet mobile only'} centered>
        <Grid.Column width={15}>
          <CatalogSearchRowContainer>
            <Input
              fluid
              disabled={true}
              icon='search'
              iconPosition='left'
              placeholder={Localize.get('Catalog.searchPlaceholder')}
              onKeyUp={(e) => onSearchFieldChange(e)}
              ref={searchMobRef}
            />
          </CatalogSearchRowContainer>
          <SortersMobileRowContainer />
          <FiltersMobileRowContainer>
            <Accordion styled fluid>
              <Accordion.Title
                data-testid={'catalogMainCourseFiltersMobile'}
                active={activeFiltersMobile}
                onClick={() => setActiveFiltersMobile(!activeFiltersMobile)}
              >
                <FlexRowContainer>
                  <FlexRowContainer>
                    <Icon name='caret right' />
                    <div>{Localize.get('Catalog.filters')}</div>
                  </FlexRowContainer>
                  {showResetButtonIcon && <Icon name='undo alternate' onClick={onResetFilters} />}
                </FlexRowContainer>
              </Accordion.Title>
              <Accordion.Content active={activeFiltersMobile}>
                {contextData?.configuration && (
                  <Filters
                    allEvents={null}
                    events={null}
                    eventTypes={null}
                    eventStatuses={null}
                    coursesLanguages={coursesLanguages}
                    coursesSkills={coursesSkills}
                    coursesTags={coursesTags}
                    coursesTopics={coursesTopics}
                    coursesTypes={coursesTypes}
                    filters={filters}
                    setFilters={setFilters}
                    configuration={contextData?.configuration?.CourseFilters}
                    device={contextData?.size?.device}
                    visible={true}
                    onSearchFn={onSearchCourses}
                    predefinedFilters={predefinedFilter}
                    filtersRef={filtersRef}
                    shouldResetFilters={shouldResetFilters}
                  />
                )}
                {getFilterButtons()}
              </Accordion.Content>
            </Accordion>
          </FiltersMobileRowContainer>
          <Grid.Row>{getContent()}</Grid.Row>
          <Grid.Row centered>
            <CatalogPaginationContainer centered>
              {!contextData?.configuration?.global.catalogInfinityScroll && !!totalPages && (
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  noSiblings={totalPages > 3}
                />
              )}
            </CatalogPaginationContainer>
          </Grid.Row>
        </Grid.Column>
      </CatalogListContainer>
    </CatalogColumn>
  );
};

export default CoursesTab;
