export const langs = [
  { text: '--', key: 0, value: '' },
  { text: 'DE', key: 1, value: 'De' }
];

export const initialTypeFilters = {
  type: [],
  lang: [],
  city: [],
  country: [],
  startDate: '',
  minDate: '',
  maxDate: '',
  minPrice: '',
  maxPrice: '',
  searchString: '',
  status: [],
  venueNames: []
};

export const initialDateRange = {
  from: undefined,
  to: undefined,
  initStartDate: undefined,
  initEndDate: undefined,
  startTimestamp: '',
  endTimestamp: ''
};

export const initialStartDate = {
  from: undefined,
  initStartDate: undefined,
  startTimestamp: ''
};

export const initialPrice = {
  initMinPrice: 0,
  initMaxPrice: 0,
  min: 0,
  max: 0
};
