import Logger from '@common/Logger.js';

const useDownloadItem = (downloadData) => {
  const downloadFile = async () => {
    try {
      if (downloadData?.data) {
        const fileNameFull = downloadData.headers['content-disposition'];
        const fileName = fileNameFull.split('=');
        const objectUrl = window.URL.createObjectURL(
          new Blob([downloadData.data], {
            type: downloadData.headers['content-type']
          })
        );
        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute('download', fileName[1].trim().replace(/['"]+/g, ''));
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      Logger.error(e);
    }
  };

  return { downloadFile };
};

export default useDownloadItem;
