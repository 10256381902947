import React from 'react';
import Localize from 'react-intl-universal';

import CourseItem from '@components/CourseListView/CourseListItem/CourseItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import {
  EventListContainer,
  EventListMainContainer,
  EventListItemStyled
} from '@components/StyledComponents/EventList.styled';

const CourseList = (props) => {
  const { courses, configuration, onItemClick } = props;

  return (
    <>
      <EventListMainContainer stackable columns={1}>
        <EventListContainer stretched only='computer tablet'>
          {courses && courses.length ? (
            courses.map((course, index) => (
              <EventListItemStyled key={index}>
                <CourseItem
                  config={configuration}
                  key={course.ID}
                  courseId={course.ID}
                  courseName={course.DESCRIPTION}
                  locationInfo={course.COURSE_LOCATIONS}
                  courseTypeName={course.COURSE_TYPE_NAME}
                  onItemClick={onItemClick}
                  price={course.COURSE_PRICE}
                  startDate={course.START_DATE}
                  endDate={course.END_DATE}
                  image={course.COURSE_IMAGE_URL}
                  courseTitle={course.TITLE}
                  progress={course.PROGRESS}
                />
              </EventListItemStyled>
            ))
          ) : (
            <NoContentComponent
              data-test-id={'courseListNoContent'}
              infoType={Localize.get('Catalog.noCourses')}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </EventListMainContainer>
    </>
  );
};

export default CourseList;
