import React from 'react';

import Header from 'semantic-ui-react/dist/commonjs/elements/Header';

import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useFetching from '@hooks/Fetching/Fetching';
import { getAxiosConfiguration, getServiceWithCallback } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

import style from './style/MyEvents.module.scss';

const MyEvents = () => {
  const [contextData, setContextData] = useGlobalContext();

  const callbackServiceCallError = (response) => {
    setContextData({
      ...contextData,
      user: null,
      loggedIn: false,
      userMsg: response?.data ? response.data : response
    });
  };

  const createMyEventService = (callBack, callBackError) => {
    const configuration = getAxiosConfiguration(
      serviceConstants.MEA_SERVER_CONFIGURATION.resource.myEvents
    );

    return getServiceWithCallback(configuration, callBack, callBackError);
  };

  const [eventData] = useFetching(
    createMyEventService.bind(null, null, callbackServiceCallError),
    false
  );

  return (
    <>
      <div data-test-id={'myEventsMain'} className={style.myEvents}>
        {!eventData ? 'this is MyEvents page!' : <Header as='h1'>{eventData.data.message}</Header>}
      </div>
    </>
  );
};
export default MyEvents;
