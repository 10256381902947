import {
  getService,
  getAxiosConfiguration,
  getServiceWithCallback,
  deleteService,
  patchService,
  getServiceByConfig
} from '@services/axiosUtil.js';
import * as constant from '@services/Constants';

export const createCaptchaCreatePath = () =>
  `${constant.MEA_SERVER_CONFIGURATION.protocol}://${constant.MEA_SERVER_CONFIGURATION.host}:${constant.MEA_SERVER_CONFIGURATION.port}${constant.MEA_SERVER_CONFIGURATION.apiPath}${constant.MEA_SERVER_CONFIGURATION.resource.captchaCreate}`;

export const createCaptchaVerifyPath = () =>
  `${constant.MEA_SERVER_CONFIGURATION.protocol}://${constant.MEA_SERVER_CONFIGURATION.host}:${constant.MEA_SERVER_CONFIGURATION.port}${constant.MEA_SERVER_CONFIGURATION.apiPath}${constant.MEA_SERVER_CONFIGURATION.resource.captchaVerify}`;

export const createSignUpService = (callbackSuccess, data) => {
  const configuration = getAxiosConfiguration(constant.MEA_SERVER_CONFIGURATION.resource.signup);
  configuration.method = constant.BASE.method.post;
  configuration.data = data;

  return getServiceWithCallback(configuration, callbackSuccess, callbackSuccess);
};

export const createSignUpConfirmService = (callbackSuccess, data, email) => {
  const pathParams = email
    ? `${constant.MEA_SERVER_CONFIGURATION.resource.signupConfirm}${data}/${email}`
    : `${constant.MEA_SERVER_CONFIGURATION.resource.signupConfirm}${data}`;
  const configuration = getAxiosConfiguration(pathParams);
  return getServiceWithCallback(configuration, callbackSuccess, callbackSuccess);
};

export const createSignUpReConfirmService = (callbackSuccess, email) => {
  const configuration = getAxiosConfiguration(
    constant.MEA_SERVER_CONFIGURATION.resource.signUpResendEmail
  );
  configuration.method = constant.BASE.method.post;
  configuration.data = { email: email };
  return getServiceWithCallback(configuration, callbackSuccess, callbackSuccess);
};

export const createGetAllCountries = async () => {
  return getService(constant.MEA_SERVER_CONFIGURATION.resource.countries);
};

export const createAttachmentDownloadService = (attachmentId, callBackError) => {
  let configuration = getAxiosConfiguration(
    `${constant.MEA_SERVER_CONFIGURATION.resource.attachments}${attachmentId}`
  );
  configuration.responseType = 'blob';
  return getServiceByConfig(configuration, callBackError);
};

export const createAttachmentDeleteByIdService = (iltSessionId, attachmentId, callBackError) => {
  const path = `${constant.MEA_SERVER_CONFIGURATION.resource.attachments}${iltSessionId}/ilt_session/${attachmentId}`;
  return deleteService(path, callBackError);
};

export const createAttachmentEditByIdService = (attachmentId, data, callBackError) => {
  const path = `${constant.MEA_SERVER_CONFIGURATION.resource.attachments}${attachmentId}`;
  return patchService(path, data, callBackError);
};
