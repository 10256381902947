const InfinityObserverList = (props) => {
  const { totalPages, currentPage, setCurrentPage, setBtnDisabled, options } = props;
  return new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      if (totalPages > currentPage) {
        setCurrentPage((prevPageNumber) => prevPageNumber + 1);
      } else {
        setBtnDisabled(true);
      }
    }
  }, options);
};

const InfinityObserverGrid = (props) => {
  const { totalPages, currentPage, setCurrentPage, setBtnDisabled, options } = props;
  return new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      if (totalPages > currentPage) {
        setCurrentPage((prevPageNumber) => {
          if (prevPageNumber < totalPages) {
            return prevPageNumber + 1;
          }
        });
      } else {
        setBtnDisabled(true);
      }
    }
  }, options);
};

export { InfinityObserverList, InfinityObserverGrid };
