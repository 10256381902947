import React from 'react';
import Localize from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';

import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

const Sorters = (props) => {
  const {
    pageName,
    configuration,
    fetchEvents,
    currentSorter,
    setCurrentSorter,
    setCurrentPage,
    mobileVersion,
    disabled = false
  } = props;

  const handleSorting = (e, data) => {
    ReactTooltip.hide();
    setCurrentSorter({
      name: data.name,
      iconName: data['data-icon'],
      sortValue: data.value
    });
    setCurrentPage(1);
    fetchEvents();
  };

  const getSorterRow = (sorter, index) => {
    return (
      <Dropdown.Item
        data-tip={Localize.get(`${pageName}.${sorter.name}`)}
        data-place={'left'}
        data-icon={`${sorter.iconName}`}
        key={index}
        name={`${sorter.name}`}
        value={`${sorter.value}`}
        text={mobileVersion ? Localize.get(`${pageName}.${sorter.name}`) : ''}
        active={currentSorter.sortValue === sorter.value}
        selected={currentSorter.sortValue === sorter.value}
        icon={!mobileVersion && <Icon name={`${sorter.iconName}`} size='large' />}
        onClick={handleSorting}
      />
    );
  };

  return (
    <>
      {!mobileVersion && <ReactTooltip />}
      {configuration?.sorters && configuration.sorters.length && (
        <Dropdown
          icon={
            <Icon
              name={mobileVersion ? 'sort' : `${currentSorter.iconName}`}
              size='large'
              disabled={false}
            />
          }
          text={mobileVersion ? Localize.get(`${pageName}.${currentSorter.name}`) : ''}
          size='large'
          floating
          className='icon'
          disabled={disabled}
          data-test-id={'sortersContainer'}
        >
          <Dropdown.Menu>
            {configuration?.sorters
              .filter((srt) => srt.visible)
              .map((sorter, index) => getSorterRow(sorter, index))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};

export default React.memo(Sorters);
