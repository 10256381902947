import * as globalConstants from '@common/Constants.js';
import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createSessionService = async (id) => {
  return getService(`${serviceConstants.MEA_SERVER_CONFIGURATION.resource.agenda}${id}`);
};

export const createILTSessionAgendaService = async (id) => {
  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaSessions}${id}${serviceConstants.MEA_SERVER_CONFIGURATION.resource.trainerAreaSessionDays}`
  );
};

export const calculateMenuItemsCount = (size, menuItems) => {
  // Menu contains dates menu items, and max number of
  // dates menu items on computer resolution can be 7,
  // for tablet resolutions can be 4,
  // and on mobile resolutions 3.
  // For different resolutions, calculation is done based on
  // actual viewport width
  let maxItems;

  if (size && size.device === globalConstants.deviceSize.mobile) {
    if (size && size.width >= 500 && size.width < 700) {
      maxItems = 2;
    } else if (size.width >= 700) {
      maxItems = 3;
    } else {
      maxItems = 1;
    }
  } else if (size && size.device === globalConstants.deviceSize.tablet) {
    if (size && size.width >= 768 && size.width < 875) {
      maxItems = 3;
    } else {
      maxItems = 4;
    }
  } else {
    maxItems = menuItems.length >= 7 ? 7 : menuItems.length;

    if (maxItems > 3) {
      if (size && size.width >= 1676 && size.width < 1868) {
        maxItems = maxItems - 1 > 0 ? maxItems - 1 : 1;
      }

      if (size && size.width >= 1397 && size.width < 1676) {
        maxItems = maxItems - 2 > 0 ? maxItems - 2 : 1;
      }

      if (size && size.width < 1397 && size.width >= 1168) {
        maxItems = maxItems - 3 > 0 ? maxItems - 3 : 1;
      }

      if (size && size.width >= 991 && size.width < 1168) {
        maxItems = maxItems - 4 > 0 ? maxItems - 4 : 1;
      }
    }
  }

  return maxItems;
};

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const getInstructorsNamesFromSessionDays = (sessionDays) => {
  return (sessionDays || [])
    .flatMap((sessionDay) => sessionDay.instructors || [])
    .reduce((instructors, instructor) => {
      if (!instructors.includes(instructor.fullName)) {
        instructors.push(instructor.fullName);
      }
      return instructors;
    }, [])
    .join(', \r\n');
};

export const getLocationInfoFromSessionDays = (sessionDays) => {
  const uniqueLocations = new Set();

  return (sessionDays || [])
    .map((sessionDay) => {
      if (sessionDay.venue) {
        if (sessionDay.room) {
          return `${sessionDay.venue}-${sessionDay.room}`;
        } else {
          return sessionDay.venue;
        }
      }
      return '';
    })
    .filter((location) => {
      if (!uniqueLocations.has(location) && location !== '') {
        uniqueLocations.add(location);
        return true;
      }
      return false;
    })
    .join(', \r\n');
};
