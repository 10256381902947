import React, { Fragment } from 'react';
import Localize from 'react-intl-universal';

import { Grid, Input, Checkbox, Icon } from 'semantic-ui-react';

import { courseType } from '@common/Constants';

import BookingHeader from './BookingHeader';
import BookingInformation from './BookingInformation';
import { bookingType } from '../../Constants';
import style from '../style/RegistrationCourse.module.scss';

const ParticipantInformation = ({
  participantDetails,
  setParticipantDetails,
  courseTypeName,
  config,
  finishedPrerequisites,
  checkBookingStatusForParticipant
}) => {
  return (
    <Grid
      data-testid={'ParticipantInformation'}
      className={`tablet computer only ${style.menuCenter}`}
      centered
      style={{ background: config?.configuration?.CourseDetailsContent.background }}
    >
      <Grid.Row>
        <Grid.Column width={4}>
          <b>{Localize.get('RegistrationForm.participant')}</b>
        </Grid.Column>
        <Grid.Column width={12}>
          {courseTypeName === Localize.get(`App.${courseType.course}`) && (
            <BookingHeader config={config} bookingInformationType={bookingType.forLoggedInUser} />
          )}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={4}>
          <Grid.Row>
            <Checkbox
              label=''
              checked={participantDetails.acceptParticipation}
              onChange={(_, v) =>
                setParticipantDetails({ ...participantDetails, acceptParticipation: v.checked })
              }
            />
            <Input
              disabled
              value={participantDetails.name}
              input={{
                style: {
                  backgroundColor: config?.configuration?.Registration.registrationDisabledColor,
                  background: config?.configuration?.Registration.registrationDisabledColor,
                  fontWeight: config?.configuration?.Registration.participantNameWeight
                }
              }}
            />
          </Grid.Row>
          <Grid.Row>
            {participantDetails.participantInfo !== undefined &&
              !finishedPrerequisites(
                participantDetails.participantInfo.courses,
                participantDetails.participantInfo.eLearnings
              ) && (
                <Fragment>
                  <Icon
                    name='exclamation triangle'
                    size='big'
                    className={style.registrationPrerequisitesWarning}
                  />
                  {Localize.get('RegistrationForm.doNotHavePrerequisites')}
                </Fragment>
              )}
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={12}>
          <Grid>
            {participantDetails?.iltModulesSessions?.length &&
            courseTypeName === Localize.get(`App.${courseType.course}`) ? (
              participantDetails.iltModulesSessions.map((iltModule, index) => (
                <BookingInformation
                  key={index}
                  participantDetails={participantDetails}
                  setParticipantDetails={setParticipantDetails}
                  iltModule={iltModule}
                  iltModuleIndex={index}
                  config={config}
                  bookingInformationType={bookingType.forLoggedInUser}
                  bookingInformationStatus={checkBookingStatusForParticipant(iltModule)}
                />
              ))
            ) : (
              <Fragment />
            )}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ParticipantInformation;
