import React from 'react';

// semantic
import Pagination from 'semantic-ui-react/dist/commonjs/addons/Pagination';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import { PaginationContainer } from '@components/StyledComponents/Pagination.styled';

const PaginationComponent = (props) => {
  const { totalPages, currentPage, setCurrentPage, noSiblings } = props;

  const handlePageChange = (evt, data) => {
    setCurrentPage(data.activePage);
  };

  const getBoundaryRange = () => {
    if (noSiblings === 'undefined' || noSiblings === null) {
      return 1;
    }
    return noSiblings ? 0 : 1;
  };
  return (
    <>
      <PaginationContainer
        computer={12}
        largeScreen={12}
        widescreen={12}
        mobile={15}
        textAlign='center'
        data-test-id={'paginationContainer'}
      >
        <Pagination
          ellipsisItem={undefined}
          firstItem={{ content: <Icon name='angle double left' />, icon: true }}
          lastItem={{ content: <Icon name='angle double right' />, icon: true }}
          prevItem={{ content: <Icon name='angle left' />, icon: true }}
          nextItem={{ content: <Icon name='angle right' />, icon: true }}
          pointing
          secondary
          activePage={currentPage}
          onPageChange={(evt, data) => handlePageChange(evt, data)}
          totalPages={totalPages}
          boundaryRange={getBoundaryRange()}
          siblingRange={1}
        />
      </PaginationContainer>
    </>
  );
};
export default PaginationComponent;
