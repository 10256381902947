import {
  getService,
  getAxiosConfiguration,
  getServiceByConfig,
  postService
} from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createParticipantService = (id, page, size) => {
  let paginationPart = `?page=${page}&limit=${size}`;

  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventDetails}${id}/participants${paginationPart}`
  );
};

export const createAttachmentServiceForIltSessions = (iltSessionId) => {
  return getService(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.attachments}${iltSessionId}/ilt_session`
  );
};

export const createParticipantsExportService = (
  eventId,
  type,
  iltSessionName,
  callBackError,
  page = null,
  limit = null,
  search = null, // ID
  match = null,
  searchValue = null,
  fieldsForExport = null
) => {
  let query = `?fileType=${type}&title=${iltSessionName.trim().replace(/[,\s]+|[,\s]+ /g, '_')}${
    page ? `&page=${page}` : ''
  }${limit ? `&limit=${limit}` : ''}${search ? `&search=${search}` : ''}${
    match ? `&match=${match}` : ''
  }${searchValue ? `&searchValue=${searchValue}` : ''}${
    fieldsForExport ? `&fieldsForExport=${fieldsForExport}` : ''
  }`;

  let configuration = getAxiosConfiguration(
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventDetails}${eventId}/${serviceConstants.MEA_SERVER_CONFIGURATION.resource.participantsExports}${query}`
  );
  configuration.responseType = 'blob';
  return getServiceByConfig(configuration, callBackError);
};

export const createAttachmentUploadService = (
  objectId,
  objectName,
  displayName = '',
  visibleToAdministrators = false,
  visibleToTrainers = false,
  visibleToParticipants = false,
  visibleToLocationResponsible = false,
  file
) => {
  let data = new FormData();

  data.append('objectId', objectId);
  data.append('objectName', objectName);
  data.append('displayName', displayName);

  data.append('visibleToAdministrators', visibleToAdministrators);
  data.append('visibleToTrainers', visibleToTrainers);
  data.append('visibleToParticipants', visibleToParticipants);
  data.append('visibleToLocationResponsible', visibleToLocationResponsible);

  data.append('file', file);
  return postService('attachments', data);
};
