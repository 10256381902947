import React, { useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { Link } from 'react-router-dom';

import { Formik } from 'formik';
// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Divider from 'semantic-ui-react/dist/commonjs/elements/Divider';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';

import * as commonConstants from '@common/Constants.js';
import { createSignUpConfirmService, createSignUpReConfirmService } from '@common/Helper.js';
import FormSubmitButton from '@components/Form/FormComponents/FormSubmitButton.jsx';
import { getTitle, getContent } from '@components/UserMessagesComponent/Util.js';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useFetching from '@hooks/Fetching/Fetching.js';
import * as constants from '@navigation/Constants.js';

import LogInForm from './LogInForm';
import style from './style/LogIn.module.scss';
import configLogInForm from './util/logInForm.config';
import initialValuesForm from './util/logInInitial.config';
import formValidationSchema from './util/logInValidation.schema';
import { createLoginService, createEnableAccountService } from './util/Util.js';

const LogIn = ({ match, history }) => {
  const [userData, setUserData] = React.useState(null);
  const formikRef = useRef(null);
  const callbackServiceCall = (response) => {
    setResponseMessage(response.data);
  };

  const loginServiceCallbackSuccess = (response) => {
    const newData = { ...contextData };
    newData.loggedIn = true;
    newData.email = response.data.email;
    setUserData(response.data.email);
  };

  const loginServiceCallbackFail = (response) => {
    setResponseMessage(response.data);
  };

  let previousPathName = history?.location?.state?.from
    ? history.location.state.from.includes('/')
      ? history.location.state.from
      : history.location.state.from + ' /'
    : '';
  let previousPath = history?.location?.state?.path ? history.location.state.path : '';
  const [initValues, setInitValues] = React.useState(initialValuesForm);
  const [contextData, setContextData] = useGlobalContext();
  const [confCode, setConfCode] = React.useState('');
  const [userEmail, setUserEmail] = React.useState(
    contextData?.userData?.email ? contextData.userData.email : ''
  );
  const [activationEnabled, setActivationEnabled] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(
    history?.location?.state?.data
      ? history.location.state.data
      : contextData?.userMsg
      ? contextData.userMsg
      : null
  );

  const [, , , confirmSignUpUser] = useFetching(
    createSignUpConfirmService.bind(null, callbackServiceCall, confCode, userEmail),
    true
  );
  const [, , , reSendConfirmationMail] = useFetching(
    createSignUpReConfirmService.bind(
      null,
      callbackServiceCall,
      userEmail ? userEmail : formikRef?.current?.values
    ),
    true
  );

  const [, , , reSendActivatedMail] = useFetching(
    createEnableAccountService.bind(
      null,
      callbackServiceCall,
      callbackServiceCall,
      formikRef?.current?.values
    ),
    true
  );

  if (
    previousPath.includes(constants.routes.path.login) ||
    previousPath.includes(constants.routes.path.verify)
  ) {
    previousPath = '';
    previousPathName = '';
  }

  const handleClick = () => {
    history.push({
      pathname: constants.routes.path.signup,
      state: {
        from: previousPathName.replace('/', '').trim(),
        path: previousPath
      }
    });
  };

  const handleOnConfirmationTokenExp = () => {
    reSendConfirmationMail();
  };

  const handleOnAccountDisabled = () => {
    reSendActivatedMail();
  };

  useEffect(() => {
    if (match?.params?.email !== commonConstants.confirmationCodes.confirm) {
      setUserEmail(match.params.email);
    }

    if (match?.params?.confirmationCode !== commonConstants.confirmationCodes.confirm) {
      setConfCode(match.params.confirmationCode);
      setActivationEnabled(true);
    }
  }, [match]);

  useEffect(() => {
    if (confCode && activationEnabled) {
      confirmSignUpUser();
    }
  }, [activationEnabled, confCode, confirmSignUpUser]);

  useEffect(() => {
    if (userData) {
      let newContext = { ...contextData };
      delete newContext.userMsg;
      setContextData({
        ...newContext,
        user: userData,
        loggedIn: true
      });
      if (previousPath && !previousPath.includes('home')) {
        history.push({
          pathname: previousPath,
          state: {
            from: previousPathName.replace('/', '').trim(),
            path: previousPath
          }
        });
      } else {
        history.push({
          pathname: '/',
          state: {
            from: 'Home',
            path: '/'
          }
        });
      }
    }
  }, [userData]);

  useEffect(() => {
    if (userEmail && formikRef.current.values.email.length === 0) {
      setInitValues({
        ...initValues,
        email: userEmail
      });
    }
  }, [userEmail]);

  const msgTitle = getTitle(responseMessage);
  let msgContent = getContent(msgTitle);

  return (
    <>
      <div className={`ui two column equal height grid ${style.containerPage}`}>
        <div
          className={`nine wide computer column computer only' ${style.containerImgColumn}`}
        ></div>
        <div className='seven wide computer column sixteen wide tablet column sixteen wide mobile column'>
          <Grid.Column computer={7} tablet={16} mobile={16} className={style.loginContainer}>
            <Grid stretched>
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Grid.Row only='tablet computer'>
                <Grid.Column computer={10} tablet={10} className={style.homeIco}>
                  <Icon name='home' size='big' onClick={() => history.push('/home')} />
                </Grid.Column>

                <Grid.Column width={4} textAlign='right' className={style.linkContainer}>
                  <Form.Group className={style.linkHolder}>
                    <Form.Field className={style.linkPart}>
                      <Link
                        to={{
                          pathname: previousPath,
                          state: {
                            from: previousPathName.substring(0, previousPathName.length - 1),
                            path: previousPath
                          }
                        }}
                      >
                        {previousPathName}
                      </Link>
                    </Form.Field>
                    {previousPath && (
                      <Form.Field>
                        <div>{Localize.get('SignIn.logIn')}</div>
                      </Form.Field>
                    )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={2} />
              </Grid.Row>

              {responseMessage && (
                <Grid.Row centered className={style.messageContainerMobile}>
                  <Message positive size='large'>
                    <Grid>
                      <Grid.Row centered>
                        <p className={style.userMessageFontColor}>
                          {Localize.getHTML('ServerMessages.' + msgContent)}
                        </p>
                      </Grid.Row>
                      {(msgTitle === 'confirmationTokenExpired' ||
                        msgTitle === 'disabledAccount' ||
                        msgTitle === 'accountLoginNotActive') && (
                        <Grid.Row centered>
                          <p>
                            <Button
                              className={style.linkButton}
                              onClick={
                                msgTitle === 'confirmationTokenExpired' ||
                                msgTitle === 'accountLoginNotActive'
                                  ? handleOnConfirmationTokenExp
                                  : handleOnAccountDisabled
                              }
                            >
                              {Localize.get('MessagePage.reSendEmail')}
                            </Button>
                          </p>
                        </Grid.Row>
                      )}
                    </Grid>
                  </Message>
                </Grid.Row>
              )}
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Divider hidden />
              <Grid.Row centered>
                <Form.Field>
                  <Header as='h1'>{Localize.get('App.welcomeToApp')}</Header>
                </Form.Field>
              </Grid.Row>

              <Grid.Row centered>
                <Form.Field>
                  <label>{Localize.get('SignIn.userLoginPlaceholder')}</label>
                </Form.Field>
              </Grid.Row>

              <Divider hidden />
              <Divider hidden />

              <Grid.Row>
                <Grid.Column computer={4} tablet={4} mobile={1} />
                <Grid.Column
                  computer={8}
                  tablet={8}
                  mobile={14}
                  className={style.formContainerMobile}
                >
                  <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={initValues}
                    validationSchema={formValidationSchema}
                    innerRef={formikRef}
                    onSubmit={async (values) => {
                      await createLoginService(
                        loginServiceCallbackSuccess,
                        loginServiceCallbackFail,
                        values
                      );
                    }}
                  >
                    {({
                      isSubmitting,
                      handleChange,
                      handleSubmit,
                      values,
                      isValid,
                      setFieldValue,
                      setFieldTouched
                    }) => (
                      <>
                        <LogInForm config={configLogInForm}>
                          {{
                            isSubmitting,
                            handleChange,
                            handleSubmit,
                            values,
                            setFieldValue,
                            setFieldTouched
                          }}
                        </LogInForm>
                        <Form>
                          <Form.Group widths='equal' className={style.privacyTermsContainer}>
                            <Form.Field>
                              <Checkbox label={Localize.get('SignIn.rememberMe')} />
                            </Form.Field>
                            <Form.Field className={style.forgotPassText}>
                              <Link
                                to={{
                                  pathname: constants.routes.path.forgotPass,
                                  state: {
                                    from: '',
                                    path: ''
                                  }
                                }}
                              >
                                {Localize.get('SignIn.forgotPass')}
                              </Link>
                            </Form.Field>
                          </Form.Group>
                          <Form.Field>
                            <Divider hidden className={style.mobileDividers} />
                            <Divider hidden className={style.mobileDividers} />
                            <Divider hidden />
                          </Form.Field>
                        </Form>

                        <Grid columns={2}>
                          <FormSubmitButton
                            textAlign='right'
                            handleSubmit={handleSubmit}
                            disabled={!isValid}
                            content={Localize.get('SignIn.logIn')}
                            fluid={false}
                          />
                          <Grid.Column textAlign='left'>
                            <Button data-test-id={'signUpButton'} primary onClick={handleClick}>
                              {Localize.get('SignIn.signUp')}
                            </Button>
                          </Grid.Column>
                        </Grid>
                      </>
                    )}
                  </Formik>

                  <Divider hidden />

                  <Divider horizontal>
                    <Header as='h5'>{Localize.get('SignIn.continueWith')}</Header>
                  </Divider>

                  <Grid columns={2}>
                    <Grid.Column textAlign='right'>
                      <Icon name='google' size='large' />
                    </Grid.Column>
                    <Grid.Column textAlign='left'>
                      <Icon name='facebook f' size='large' />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} />
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </div>
      </div>
    </>
  );
};
export default LogIn;
