// Available filter values supported on BE
import { getGridStringOperators, getGridNumericOperators } from '@mui/x-data-grid';

const availableFilterValues = [
  'notEquals',
  'equals',
  'contains',
  'startsWith',
  'endsWith',
  'isEmpty',
  'isNotEmpty'
];
const equalDoubleNumberOperators = ['>', '<', '>=', '<=', '!=', '='];

export const BACKEND_FILTER_VALUES = {
  contains: 'contains',
  equals: 'equal',
  notEquals: 'not_equal',
  startsWith: 'starts_with',
  endsWith: 'ends_with',
  isEmpty: 'is_null',
  isNotEmpty: 'is_not_null',
  '=': 'equal',
  '!=': 'not_equal',
  '>': 'greater_than',
  '>=': 'greater_than_equal',
  '<': 'less_than',
  '<=': 'less_than_equal',
  is: 'is_on',
  not: 'is_not_on',
  after: 'is_after',
  onOrAfter: 'is_on_or_after',
  before: 'is_before',
  onOrBefore: 'is_on_or_before'
};

export const defaultTableFilters = getGridStringOperators()
  .filter((operator) => availableFilterValues.includes(operator.value))
  .map((operator) => operator);

export const equalDoubleNumbersFilters = getGridNumericOperators().filter(({ value }) =>
  equalDoubleNumberOperators.includes(value)
);
