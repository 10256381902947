import { useState } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab';

import { iltSessionsStatus } from '@common/Constants';
import style from '@pages/MyArea/style/MyArea.module.scss';

import IltSessionsTable from '../Components/IltSessionsTable';

const useMyAreaIltSessionLogic = (config) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleIltSessionsTabsChange = (e, data) => {
    setActiveTabIndex(data.activeIndex);
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item
          key='registeredSessions'
          style={{
            backgroundColor:
              activeTabIndex === 0
                ? config?.configuration?.MyAreaIltSessionsTab?.selectedTabColor
                : null,
            color:
              activeTabIndex === 0
                ? config?.configuration?.MyAreaIltSessionsTab?.selectedTabTextColor
                : null
          }}
        >
          {Localize.get('MyAreaIltSessionsTab.registered')}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className={style.tabbedPane}>
          <IltSessionsTable
            iltSessionsToShow={iltSessionsStatus.registered}
            activeTabIndex={activeTabIndex}
            config={config}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item
          key='availableSessions'
          style={{
            backgroundColor:
              activeTabIndex === 1
                ? config?.configuration?.MyAreaIltSessionsTab?.selectedTabColor
                : null,
            color:
              activeTabIndex === 1
                ? config?.configuration?.MyAreaIltSessionsTab?.selectedTabTextColor
                : null
          }}
        >
          {Localize.get('MyAreaIltSessionsTab.available')}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className={style.tabbedPane}>
          <IltSessionsTable
            iltSessionsToShow={iltSessionsStatus.available}
            activeTabIndex={activeTabIndex}
            config={config}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item
          key='pastSessions'
          style={{
            backgroundColor:
              activeTabIndex === 2
                ? config?.configuration?.MyAreaIltSessionsTab?.selectedTabColor
                : null,
            color:
              activeTabIndex === 2
                ? config?.configuration?.MyAreaIltSessionsTab?.selectedTabTextColor
                : null
          }}
        >
          {Localize.get('MyAreaIltSessionsTab.past')}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className={style.tabbedPane}>
          <IltSessionsTable
            iltSessionsToShow={iltSessionsStatus.past}
            activeTabIndex={activeTabIndex}
            config={config}
          />
        </Tab.Pane>
      )
    }
  ];

  return { activeTabIndex, panes, handleIltSessionsTabsChange };
};

export default useMyAreaIltSessionLogic;
