import React from 'react';
import Localize from 'react-intl-universal';

import EventGridItem from '@components/EventGridListView/EventGridItem/EventItem';
import EventItem from '@components/EventListView/EventListItem/EventItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import {
  EventListContainer,
  EventListMainContainer,
  EventListItemStyled
} from '@components/StyledComponents/EventList.styled';

const EventSessionsList = (props) => {
  const { events, onItemClick, configuration, infoType } = props;
  return (
    <>
      <EventListMainContainer stackable columns={1} data-testid={'EventSessionsList'}>
        <EventListContainer stretched only='computer tablet'>
          {events && events.length ? (
            events.map((evt, index) => (
              <EventListItemStyled key={index}>
                <EventItem
                  key={evt.id}
                  config={configuration}
                  eventId={evt.id}
                  eventTypeName={evt.type}
                  eventName={evt.name}
                  startDate={evt.startDateTime}
                  endDate={evt.endDateTime}
                  onItemClick={onItemClick}
                />
              </EventListItemStyled>
            ))
          ) : (
            <NoContentComponent
              data-testid={'eventListNoContent'}
              infoType={infoType}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </EventListMainContainer>
      <EventListMainContainer stackable columns={1}>
        <EventListContainer stretched only='mobile'>
          {events && events.length ? (
            events.map((evt, index) => (
              <EventListItemStyled key={index}>
                <EventGridItem
                  key={evt.id}
                  config={configuration}
                  eventId={evt.id}
                  eventTypeName={evt.type}
                  eventName={evt.name}
                  startDate={evt.startDateTime}
                  endDate={evt.endDateTime}
                  onItemClick={onItemClick}
                />
              </EventListItemStyled>
            ))
          ) : (
            <NoContentComponent
              data-testid={'eventListNoContent'}
              infoType={infoType}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </EventListMainContainer>
    </>
  );
};

export default EventSessionsList;
