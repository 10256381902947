import styled, { css } from 'styled-components';

import FormInput from '@components/Form/FormComponents/FormInput';
import FormPasswordInput from '@components/Form/FormComponents/FormPasswordInput';

const marginBottomMobileStyle = css`
  @media only screen and (max-width: 767px) and (min-width: 320px) {
    margin-bottom: 1rem !important;
  }
`;
export const FormInputStyled = styled(FormInput)`
  ${marginBottomMobileStyle}
`;

export const FormPasswordStyled = styled(FormPasswordInput)`
  ${marginBottomMobileStyle}
`;
