import { useState, useEffect } from 'react';

export default function usePromptOnReload(shouldPrompt) {
  const [showReloadPrompt, setShowReloadPrompt] = useState(shouldPrompt);

  useEffect(() => {
    const callbackBeforeUnLoad = (event) => {
      if (showReloadPrompt) {
        const e = event || window.event;
        e.preventDefault();

        if (e) {
          e.returnValue = '';
        }
        return '';
      }
    };

    window.addEventListener('beforeunload', callbackBeforeUnLoad);

    return () => {
      window.removeEventListener('beforeunload', callbackBeforeUnLoad);
    };
  }, [showReloadPrompt]);

  return [setShowReloadPrompt];
}
