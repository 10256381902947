import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';

import { calculateMenuItemsCount, removeDuplicates } from '../../EventDetails/Tabs/AgendaHelper';
import AgendaTimelineMenu from '../../EventDetails/Tabs/AgendaTimelineMenu.jsx';
import style from '../../EventDetails/Tabs/style/Agenda.module.scss';
import IltSessionCard from '../IltSessionCard';

const Agenda = (props) => {
  const { sessionsData } = props;
  const [contextData] = useGlobalContext();
  const [itemsPerScreen, setItemsPerScreen] = useState(0);
  const [sessions, setSessions] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [firstMenuItem, setFirstMenuItem] = useState(0);
  const [lastMenuItem, setLastMenuItem] = useState(itemsPerScreen);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let menuDateItems;
    if (sessionsData) {
      menuDateItems = removeDuplicates(sessionsData.map((el) => el?.dayDate));
      setMenuItems(menuDateItems);
      setActiveMenuItem(menuDateItems[0]);
      setSessions(sessionsData);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (contextData.size && menuItems && menuItems.length) {
      const maxItems = calculateMenuItemsCount(contextData.size, menuItems);
      if (maxItems) {
        setItemsPerScreen(maxItems);
      }
    }
  }, [menuItems, contextData.size]);

  useEffect(() => {
    if (itemsPerScreen) {
      setLastMenuItem(itemsPerScreen);
    }
  }, [itemsPerScreen]);

  const updateMenuIndexes = (direction) => {
    switch (direction) {
      case 'forward':
        if (menuItems.length !== lastMenuItem) {
          setFirstMenuItem(firstMenuItem + 1);
          setLastMenuItem(lastMenuItem + 1);
        }
        break;
      case 'back':
        if (firstMenuItem !== 0) {
          setFirstMenuItem(firstMenuItem - 1);
          setLastMenuItem(lastMenuItem - 1);
        }
        break;
      case 'first':
        if (firstMenuItem !== 0) {
          setFirstMenuItem(0);
          setLastMenuItem(itemsPerScreen);
        }
        break;
      case 'last':
        if (menuItems.length !== lastMenuItem) {
          setFirstMenuItem(menuItems.length - itemsPerScreen);
          setLastMenuItem(menuItems.length);
        }
        break;
      default:
        setFirstMenuItem(0);
        setLastMenuItem(itemsPerScreen);
        break;
    }
  };

  const getSessions = () => {
    if (sessions && sessions.length) {
      let sessionDays;
      sessionDays = sessions.filter((session) => session.dayDate === activeMenuItem);
      return sessionDays.map((el, index) => (
        <IltSessionCard key={index} session={el} sessionId={el.id} contextData={contextData} />
      ));
    }

    return null;
  };

  return (
    <>
      {isLoading && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}
      {menuItems && menuItems.length !== 0 && (
        <Grid.Column computer={14} tablet={14} className={style.timeLinePosition}>
          <AgendaTimelineMenu
            menuItems={menuItems.slice(firstMenuItem, lastMenuItem)}
            activeItem={activeMenuItem}
            setActiveItem={setActiveMenuItem}
            updateMenuIndexes={updateMenuIndexes}
            disabledArrow={menuItems.length <= itemsPerScreen}
            disableBackArrow={firstMenuItem === 0}
            disableLastArrow={menuItems.length === lastMenuItem}
          />
        </Grid.Column>
      )}
      {sessions && sessions.length ? (
        <Grid.Column
          computer={14}
          tablet={14}
          className={style.columnMargin}
          data-testid={'agendaSessionsContainer'}
        >
          {getSessions()}
        </Grid.Column>
      ) : (
        <>
          <Grid.Column
            computer={14}
            tablet={14}
            style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
            className={style.columnHeight}
            data-testid={'agendaNoSessionsContainer'}
          >
            <NoContentComponent infoType={Localize.get('EventDetails.noSess')} />
          </Grid.Column>
        </>
      )}
    </>
  );
};

export default Agenda;
