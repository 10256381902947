import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';
import Item from 'semantic-ui-react/dist/commonjs/views/Item';

// eslint-disable-next-line import/order
import { getSessionStartTime } from '../Util.js';
import {
  getSessionDuration,
  getSessionBuilding,
  getSessionRoom,
  getSessionDescription
} from './SessionsHelper.js';
import style from './style/SessionDetails.module.scss';

const SessionDetails = (props) => {
  const { session, sessionData, roomsData } = props;

  return (
    <>
      <Grid.Row className={style.sessionDetailsContainer}>
        <Grid.Column computer={1} tablet={2} mobile={3}>
          <Label color='blue' ribbon content={getSessionStartTime(session.from)} />
        </Grid.Column>
        <Grid.Column computer={15} tablet={14} mobile={13} className={style.contentHolder}>
          <Item.Group data-test-id={'sessionDetailsContainer'}>
            <Item className={style.itemStyle}>
              <Item.Content>
                <Item.Header>{session.title}</Item.Header>
                <Item.Meta className={style.sessionDetailsStyle}>
                  <Grid stackable>
                    <Grid.Column className={style.durationContainer}>
                      {Localize.get('EventDetails.duration')} {getSessionDuration(session)}
                    </Grid.Column>
                    <Grid.Column className={style.locationContainer}>
                      <Icon name='map marker alternate' />
                      <div className={style.speakerIconTextDisplayStyle}>
                        {getSessionBuilding(sessionData)}
                      </div>
                    </Grid.Column>
                    {roomsData && roomsData.results && roomsData.results.length ? (
                      <Grid.Column
                        className={`${style.locationContainer} ${style.locationContainerMobile}`}
                      >
                        <Icon name='flag outline' />
                        <div className={style.speakerIconTextDisplayStyle}>
                          {getSessionRoom(roomsData)}
                        </div>
                      </Grid.Column>
                    ) : null}
                    <Grid.Column only='computer' className={style.locationContainer} />
                  </Grid>
                </Item.Meta>
                <Item.Description>{getSessionDescription(sessionData)}</Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default SessionDetails;
