import { useRef } from 'react';
import Localize from 'react-intl-universal';

import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import styles from './style/DragAndDropFile.module.scss';

const DragDropFiles = ({ config, file, setFile, showFormError, setShowFormError, isEditMode }) => {
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setShowFormError(false);
    setFile(event.dataTransfer.files[0]);
  };

  const handleButton = (event) => {
    event.preventDefault();
    setShowFormError(false);
    setFile(event.target.files[0]);
  };

  const handleDeleteFile = () => setFile(null);

  return (
    <>
      <Popup
        content={Localize.get('TrainerAreaAttachmentTable.addFile')}
        size='mini'
        className={styles.popupFont}
        open={showFormError}
        trigger={
          <Form.Field required>
            <Segment
              id='segment'
              data-testid={'allSessionDragAndDropSegment'}
              className={styles.dropzone}
              placeholder
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {file?.name ? (
                <>
                  <div>
                    <p className={styles.fileName}>{file.name}</p>
                    <Button
                      disabled={isEditMode}
                      circular
                      size='mini'
                      icon='delete'
                      className={styles.deleteBtn}
                      onClick={handleDeleteFile}
                    />
                  </div>
                </>
              ) : (
                <>
                  <Header>{Localize.get('TrainerAreaAttachmentTable.dragAndDrop')}</Header>
                  <input
                    type='file'
                    multiple
                    onChange={handleButton}
                    hidden
                    accept={config?.configuration?.AttachmentTabUploadAcceptType}
                    ref={inputRef}
                  />
                  <Button
                    basic
                    color='black'
                    icon
                    labelPosition='right'
                    onClick={() => inputRef.current.click()}
                  >
                    <Icon basic='true' name='upload' className={styles.uploadIcon} />
                    {Localize.get('TrainerAreaAttachmentTable.upload')}
                  </Button>
                </>
              )}
            </Segment>
          </Form.Field>
        }
      />
    </>
  );
};

export default DragDropFiles;
