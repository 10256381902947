import React from 'react';

import CourseGridList from '@components/CourseGridListView/CourseGridList/CourseGridList';
import CourseList from '@components/CourseListView/CourseList/CourseList';

const CourseListView = ({ courses, listView, contextData, handleCourseClick }) => {
  if (contextData?.size?.device !== 'Mobile') {
    if (listView === 'grid') {
      if (contextData?.configuration?.global?.catalogInfinityScroll) {
        // TO DO: CourseGridListInfinity
      } else {
        return (
          <CourseGridList
            courses={courses}
            configuration={contextData?.configuration?.TrainingCourseItem}
            onItemClick={handleCourseClick}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
          />
        );
      }
    } else {
      if (contextData?.configuration?.global.catalogInfinityScroll) {
        // TO DO: CourseListInfinity
      } else {
        return (
          <CourseList
            courses={courses}
            configuration={contextData?.configuration?.TrainingCourseItem}
            onItemClick={handleCourseClick}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
          />
        );
      }
    }
  } else {
    if (contextData?.configuration?.global.catalogInfinityScroll) {
      // TO DO: CourseGridListInfinity
    } else {
      // TO DO: CourseGridList
    }
  }

  // Return null or a default component if none of the conditions match
  return null;
};

export default CourseListView;
