import React from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import List from 'semantic-ui-react/dist/commonjs/elements/List';

import styles from '@pages/LearningPathDetails/Components/style/LearningPathDetails.module.scss';

const Information = ({ learningPath, config }) => {
  return (
    <Grid data-testid='learningPathInformationContainer' columns={1} stackable centered>
      <Grid.Column width={16}>
        {learningPath?.COURSES?.map(({ ID = '', DESCRIPTION = '', COURSE_PREREQUISITES = {} }) => (
          <React.Fragment key={ID}>
            <Message.Header
              style={{
                margin: config?.configuration?.LearningPathInformationTab?.mainTitleMargin
              }}
            >
              {DESCRIPTION}
            </Message.Header>

            <List celled>
              {COURSE_PREREQUISITES?.COURSES?.map(({ id = '', name = '' }) => (
                <List.Item key={id} className={styles.informationTabTableRow}>
                  <List.Content>
                    <List.Header className={styles.informationTabHeader}>
                      <Message.Content
                        style={{
                          width:
                            config?.configuration?.LearningPathInformationTab?.tableItemKeyWidth,
                          color: config?.configuration?.LearningPathInformationTab?.courseColor
                        }}
                      >
                        {Localize.get('App.Course')}
                      </Message.Content>
                      <Message.Content
                        style={{
                          width:
                            config?.configuration?.LearningPathInformationTab?.tableItemValueWidth
                        }}
                      >
                        {name}
                      </Message.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
              ))}
              {COURSE_PREREQUISITES?.LEARNING_PATHS?.map(({ id = '', name = '' }) => (
                <List.Item key={id} className={styles.informationTabTableRow}>
                  <List.Content>
                    <List.Header className={styles.informationTabHeader}>
                      <Message.Content
                        style={{
                          width:
                            config?.configuration?.LearningPathInformationTab?.tableItemKeyWidth,
                          color:
                            config?.configuration?.LearningPathInformationTab?.learningPathColor
                        }}
                      >
                        {Localize.get('App.LearningPath')}
                      </Message.Content>
                      <Message.Content
                        style={{
                          width:
                            config?.configuration?.LearningPathInformationTab?.tableItemValueWidth
                        }}
                      >
                        {name}
                      </Message.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </React.Fragment>
        ))}
      </Grid.Column>
    </Grid>
  );
};

export default Information;
