import React, { useState } from 'react';

//semantic
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import {
  CardFooter,
  CardPriceLabel,
  CardFooterDiv,
  FooterGlobe,
  FooterLocation,
  FooterCity,
  FooterPrice,
  FooterProgress,
  StyledProgress,
  FooterProgressPercentage,
  FooterFavorite,
  FooterShare,
  FooterStyledIcon,
  FooterCountry
} from '@components/StyledComponents/EventItem.styled';

const EventItemFooter = (props) => {
  const { city, price, currency, country, config } = props;

  const [favIcon, setFavIcon] = useState(
    config?.footer?.favIconSelected ? config?.footer?.favIconSelected : 'heart outline'
  );
  const customConfig = config;

  const onFavIconClick = (e) => {
    e.stopPropagation();
    if (favIcon.includes('outline')) {
      setFavIcon(customConfig?.footer?.favIcon ? customConfig?.footer?.favIcon : 'heart');
    } else {
      setFavIcon(
        customConfig?.footer?.favIconSelected
          ? customConfig?.footer?.favIconSelected
          : 'heart outline'
      );
    }

    // TODO after integration with litmos
    // update users favorite events
  };
  let currencySymbol = null;
  if (price && price.length && currency) {
    const priceFormatted = Number().toLocaleString(window.navigator.language, {
      style: 'currency',
      currency: currency
    });

    if (Number.isInteger(parseInt(priceFormatted.substring(0, 1), 10))) {
      currencySymbol = priceFormatted.substring(priceFormatted.length - 1, priceFormatted.length);
    } else {
      currencySymbol = priceFormatted.substring(0, 1);
    }
  }
  return (
    <>
      <CardFooter extra config={customConfig} data-test-id={'eventItemFooter'}>
        <CardFooterDiv>
          <FooterGlobe visible={customConfig?.footer?.columns.includes('globeIcon')}>
            <Icon name='globe' />
          </FooterGlobe>
          <FooterLocation
            visible={customConfig?.footer?.columns.includes('location')}
            needsPadding={customConfig?.footer?.columns.includes('globeIcon')}
          >
            <FooterCity>{city}</FooterCity>
            <FooterCountry>{country}</FooterCountry>
          </FooterLocation>
          <FooterPrice visible={customConfig?.footer?.columns.includes('price')}>
            {price && price.length && currency && (
              <CardPriceLabel basic color='orange' size='large'>
                {price + ' ' + currencySymbol}
              </CardPriceLabel>
            )}
          </FooterPrice>
          <FooterProgress visible={customConfig?.footer?.columns.includes('progress')}>
            <StyledProgress size='tiny' />
            <FooterProgressPercentage>75%</FooterProgressPercentage>
          </FooterProgress>
          <FooterFavorite visible={customConfig?.footer?.columns.includes('favorite')}>
            <FooterStyledIcon
              config={customConfig}
              name={favIcon}
              onClick={(evt) => onFavIconClick(evt)}
            />
          </FooterFavorite>
          <FooterShare visible={customConfig?.footer?.columns.includes('share')}>
            <FooterStyledIcon config={customConfig} name='share alternate' />
          </FooterShare>
        </CardFooterDiv>
      </CardFooter>
    </>
  );
};

export default EventItemFooter;
