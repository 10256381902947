import React from 'react';
import Localize from 'react-intl-universal';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import EventGridListInfinity from '@components/EventGridListView/EventGridList/EventGridListInfinity';
import EventGridList from '@components/EventGridListView/EventGridList/EventList';
import EventList from '@components/EventListView/EventList/EventList';
import EventListInfinity from '@components/EventListView/EventList/EventListInfinity';
import Filters from '@components/Filters/Filters';
import GridListSwitch from '@components/GridListSwitch/GridListSwitch';
import Pagination from '@components/Pagination/Pagination';
import Sorters from '@components/Sorters/Sorters';
import {
  CatalogListContainer,
  CatalogSearchRowContainer,
  FiltersMobileRowContainer,
  SortersMobileRowContainer,
  FlexRowContainer,
  StyledFilterButtons,
  CatalogPaginationContainer,
  FilterSegment,
  FilterContainerHeader,
  FilterHeader,
  CatalogColumn
} from '@components/StyledComponents/Catalog.styled.js';
import * as routerConstants from '@navigation/Constants';
import style from '@pages/Catalog/style/Catalog.module.scss';
import { getInitialFilterConfiguration } from '@pages/Catalog/Util';

const EventsTab = (props) => {
  const {
    contextData,
    isLoading,
    allEvents,
    events,
    filtersRef,
    searchCompRef,
    searchMobRef,
    totalPages,
    currentPage,
    setCurrentPage,
    fetchAllEvents,
    fetchEvents,
    filters,
    setFilters,
    originalFilterData,
    listView,
    setListView,
    searchTerm,
    setSearchTerm,
    setIsLoading,
    setShouldResetFilters,
    setPredefinedFilter,
    filtersConfig,
    eventTypeOptions,
    setEventTypeOptions,
    eventStatusesOptions,
    setEventStatusesOptions,
    predefinedFilter,
    shouldResetFilters,
    sortingOption,
    setSortingOption,
    activeFiltersMobile,
    setActiveFiltersMobile,
    showResetButtonIcon,
    history
  } = props;

  const onSearchFieldChange = (e) => {
    if (e.key === 'Enter') {
      if (searchTerm.length) {
        // Enter is pressed by user
        // and there is at least 1 character in search field
        setIsLoading(true);
        setCurrentPage(1);
        fetchEvents();
      }
    } else {
      const reg = /'/g;
      // eslint-disable-next-line quotes
      setSearchTerm(e.target.value.replace(reg, "''").trim());

      setFilters({
        ...filters
      });
    }
  };

  const onSearchEvents = () => {
    setShouldResetFilters(false);
    setIsLoading(true);
    setCurrentPage(1);

    fetchEvents();
  };

  const onResetFilters = () => {
    setIsLoading(true);
    setShouldResetFilters(true);

    setSearchTerm('');
    setFilters({
      ...getInitialFilterConfiguration(filtersConfig),
      searchString: filters.searchString
    });
    setCurrentPage(1);
    // resets predefined filter
    setPredefinedFilter('');
    // resets event type options
    let types = [...eventTypeOptions];
    types.forEach((type) => {
      type.checked = false;
      type.disabled = false;
    });
    setEventTypeOptions(types);

    // resets event status options
    let statuses = [...eventStatusesOptions];
    statuses.forEach((status) => {
      status.checked = false;
      status.disabled = false;
    });
    setEventStatusesOptions(statuses);

    fetchAllEvents();
    fetchEvents();
  };

  const getFilterButtons = () => {
    return (
      <StyledFilterButtons onClick={onResetFilters}>
        {Localize.get('App.reset')}
      </StyledFilterButtons>
    );
  };

  const handlersMain = {
    eventList: {
      onItemClick: (eventId, eventTypeName) => {
        history.push({
          pathname: routerConstants.routes.name.eventDetails + '/' + eventId,
          state: {
            from: Localize.get('EventDetails.eventDetails'),
            path: routerConstants.routes.name.eventDetails + '/' + eventId,
            data: eventTypeName
          }
        });
      }
    }
  };

  const getContent = () => {
    if (contextData?.size?.device !== 'Mobile') {
      if (listView === 'grid') {
        if (contextData?.configuration?.global.customerEventsInfinityScroll) {
          return (
            <EventGridListInfinity
              events={events}
              configuration={contextData?.configuration?.CatalogEventItem}
              pagination={contextData?.configuration?.PAGINATION.eventsLimit}
              listColumnCount={contextData?.configuration?.CoursesColumnsWidth?.listCount}
              onItemClick={handlersMain.eventList.onItemClick}
              fetchEvents={fetchEvents}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchTerm={searchTerm}
              filters={filters}
              originalFilterData={originalFilterData}
              sortingOption={sortingOption}
              listView={listView}
              deviceSize={
                contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
              }
            />
          );
        } else {
          return (
            <EventGridList
              events={events}
              configuration={contextData?.configuration?.CatalogEventItem}
              listColumnCount={contextData?.configuration?.CoursesColumnsWidth?.listCount}
              onItemClick={handlersMain.eventList.onItemClick}
            />
          );
        }
      } else {
        if (contextData?.configuration?.global.customerEventsInfinityScroll) {
          return (
            <EventListInfinity
              events={events}
              configuration={contextData?.configuration?.CatalogEventItem}
              pagination={contextData?.configuration?.PAGINATION.eventsLimit}
              onItemClick={handlersMain.eventList.onItemClick}
              fetchEvents={fetchEvents}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchTerm={searchTerm}
              filters={filters}
              originalFilterData={originalFilterData}
              sortingOption={sortingOption}
              listView={listView}
              infoType={Localize.get('Catalog.noEvents')}
              deviceSize={
                contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
              }
            />
          );
        } else {
          return (
            <EventList
              events={events}
              configuration={contextData?.configuration?.CatalogEventItem}
              onItemClick={handlersMain.eventList.onItemClick}
              infoType={Localize.get('Catalog.noEvents')}
            />
          );
        }
      }
    } else {
      if (contextData?.configuration?.global.customerEventsInfinityScroll) {
        return (
          <EventGridListInfinity
            events={events}
            configuration={contextData?.configuration?.CatalogEventItem}
            pagination={contextData?.configuration?.PAGINATION.eventsLimit}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
            onItemClick={handlersMain.eventList.onItemClick}
            fetchEvents={fetchEvents}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchTerm={searchTerm}
            filters={filters}
            originalFilterData={originalFilterData}
            sortingOption={sortingOption}
            listView={listView}
            deviceSize={
              contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
            }
          />
        );
      } else {
        return (
          <EventGridList
            events={events}
            configuration={contextData?.configuration?.CatalogEventItem}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
            onItemClick={handlersMain.eventList.onItemClick}
          />
        );
      }
    }
  };

  return (
    <CatalogColumn width={16} data-testid={'eventsTabCatalogGridColumnContainer'}>
      {/* desktop && tablet layout*/}
      {isLoading && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}

      {/* desktop && tablet layout - menu and content*/}
      <CatalogListContainer className={'computer only'} centered>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column widescreen={1} computer={2}>
            {getFilterButtons()}
          </Grid.Column>
          <Grid.Column widescreen={7} computer={8}>
            <Grid>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column width={1}>
                  {contextData?.configuration && (
                    <Sorters
                      pageName={'Catalog'}
                      configuration={contextData?.configuration?.CatalogSorters}
                      fetchEvents={fetchEvents}
                      currentSorter={sortingOption}
                      setCurrentSorter={setSortingOption}
                      setCurrentPage={setCurrentPage}
                      mobileVersion={false}
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={15}>
                  <Input
                    fluid
                    icon='search'
                    iconPosition='left'
                    placeholder={Localize.get('Catalog.searchPlaceholder')}
                    onKeyUp={(e) => onSearchFieldChange(e)}
                    ref={searchCompRef}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column widescreen={2} computer={4}>
            <GridListSwitch activeOption={listView} setView={setListView} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={style.eventTabContent}>
          <Grid.Column
            className={style.filterScroll}
            widescreen={contextData?.configuration?.CoursesColumnsWidth?.filterColumnWidth}
            computer={4}
            mobile={2}
            textAlign='center'
          >
            <FilterSegment>
              <FilterContainerHeader>
                <FilterHeader>{Localize.get('Catalog.filters')}</FilterHeader>
              </FilterContainerHeader>
              {contextData?.configuration && (
                <Filters
                  allEvents={allEvents}
                  events={events}
                  eventTypes={eventTypeOptions}
                  eventStatuses={eventStatusesOptions}
                  coursesLanguages={null}
                  coursesSkills={null}
                  coursesTags={null}
                  coursesTopics={null}
                  coursesTypes={null}
                  filters={filters}
                  setFilters={setFilters}
                  configuration={contextData?.configuration?.CatalogFilters}
                  device={contextData?.size?.device}
                  visible={true}
                  onSearchFn={onSearchEvents}
                  predefinedFilters={predefinedFilter}
                  filtersRef={filtersRef}
                  shouldResetFilters={shouldResetFilters}
                />
              )}
            </FilterSegment>
          </Grid.Column>
          <Grid.Column
            id='catalog-content-row'
            widescreen={contextData?.configuration?.CoursesColumnsWidth?.courseColumnWidth}
            computer={10}
            mobile={9}
          >
            {getContent()}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row centered>
          {!contextData?.configuration?.global.customerEventsInfinityScroll && !!totalPages && (
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          )}
        </Grid.Row>
      </CatalogListContainer>

      {/* mobile layout - content*/}
      <CatalogListContainer className={'tablet mobile only'} centered>
        <Grid.Column width={15}>
          <CatalogSearchRowContainer>
            <Input
              fluid
              icon='search'
              iconPosition='left'
              placeholder={Localize.get('Catalog.searchPlaceholder')}
              onKeyUp={(e) => onSearchFieldChange(e)}
              ref={searchMobRef}
            />
          </CatalogSearchRowContainer>
          <SortersMobileRowContainer>
            {contextData?.configuration && (
              <Sorters
                pageName={'Catalog'}
                configuration={contextData?.configuration?.CatalogSorters}
                fetchEvents={fetchEvents}
                currentSorter={sortingOption}
                setCurrentSorter={setSortingOption}
                setCurrentPage={setCurrentPage}
                mobileVersion={true}
              />
            )}
          </SortersMobileRowContainer>
          <FiltersMobileRowContainer>
            <Accordion styled fluid>
              <Accordion.Title
                data-testid={'catalogMainFiltersMobile'}
                active={activeFiltersMobile}
                onClick={() => setActiveFiltersMobile(!activeFiltersMobile)}
              >
                <FlexRowContainer>
                  <FlexRowContainer>
                    <Icon name='caret right' />
                    <div>{Localize.get('Catalog.filters')}</div>
                  </FlexRowContainer>
                  {showResetButtonIcon && <Icon name='undo alternate' onClick={onResetFilters} />}
                </FlexRowContainer>
              </Accordion.Title>
              <Accordion.Content active={activeFiltersMobile}>
                {contextData?.configuration && (
                  <Filters
                    allEvents={allEvents}
                    events={events}
                    eventTypes={eventTypeOptions}
                    eventStatuses={eventStatusesOptions}
                    coursesLanguages={null}
                    coursesSkills={null}
                    coursesTags={null}
                    coursesTopics={null}
                    coursesTypes={null}
                    filters={filters}
                    setFilters={setFilters}
                    configuration={contextData?.configuration?.CatalogFilters}
                    device={contextData?.size?.device}
                    visible={true}
                    predefinedFilters={predefinedFilter}
                    onSearchFn={onSearchEvents}
                  />
                )}
                {getFilterButtons()}
              </Accordion.Content>
            </Accordion>
          </FiltersMobileRowContainer>
          <Grid.Row>{getContent()}</Grid.Row>
          <Grid.Row centered>
            <CatalogPaginationContainer centered>
              {!contextData?.configuration?.global.customerEventsInfinityScroll && !!totalPages && (
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                  noSiblings={totalPages > 3}
                />
              )}
            </CatalogPaginationContainer>
          </Grid.Row>
        </Grid.Column>
      </CatalogListContainer>
    </CatalogColumn>
  );
};

export default EventsTab;
