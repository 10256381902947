import { formatEmployeesFilter } from '@pages/TeamArea/Util';
import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createManagerEmployeesService = async (email, page, contextData, searchTerm) => {
  const paginationLimit = contextData?.size
    ? contextData?.configuration?.PAGINATION.managerEmployeesLimit['grid'][
        contextData.size.device.toLowerCase()
      ]
    : contextData?.configuration?.PAGINATION.managerEmployeesLimit['grid']['computer'];

  const paginationPart = `?page=${page}&limit=${paginationLimit}`;

  const searchPart = searchTerm?.length ? `&search=${searchTerm}` : '';

  const path = `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.managerEmployees}${email}${paginationPart}${searchPart}`;

  return getService(path);
};

export const createManagerEmployeesWithCoursesService = async (
  email,
  page,
  contextData,
  searchTerm,
  sortingOption,
  filterOption
) => {
  const paginationLimit = contextData?.size
    ? contextData?.configuration?.PAGINATION.managerEmployeesCoursesLimit['grid'][
        contextData.size.device.toLowerCase()
      ]
    : contextData?.configuration?.PAGINATION.managerEmployeesCoursesLimit['grid']['computer'];

  const paginationPart = `?page=${page}&limit=${paginationLimit}`;

  const sortingPart = sortingOption?.field
    ? `&sortBy=${sortingOption.field}&sortDirection=${sortingOption.sort}`
    : '';

  const encodedFilter = encodeURIComponent(
    JSON.stringify({ advancedFilters: [formatEmployeesFilter(filterOption)] })
  );
  const filterPart = filterOption?.field && filterOption?.value ? `&filters=${encodedFilter}` : '';

  const searchPart = searchTerm?.length ? `&search=${searchTerm}` : '';

  const path =
    `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.managerEmployeesWithCourses}` +
    `${email}${paginationPart}${searchPart}${sortingPart}${filterPart}`;

  return getService(path);
};
