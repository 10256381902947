import { useState, useEffect } from 'react';
import Localize from 'react-intl-universal';

import get from 'lodash/get';
import reduce from 'lodash/reduce';

import { courseType } from '@common/Constants';
import { bookingStatus, httpStatusCodes } from '@common/Constants';
import { initialSnackbarState, errorSnackbarState } from '@components/Snackbar/Constants';

import { moduleType, steps } from '../../Constants';
import { createRegistrationForCoursesService } from '../MainHelper';

const useRegistrationCourseLogic = (
  course,
  setShowConfirmationPage,
  participantDetails,
  setParticipantDetails,
  setRegistrationResponse,
  setIsResponseStatusLoading
) => {
  const [page, setPage] = useState(() => {
    return course.COURSE_TYPE_NAME === Localize.get(`App.${courseType.course}`) ? 0 : 1;
  });
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  const [iltModulesSessions, setIltModulesSessions] = useState([]);

  const [numberOfModules, setNumberOfModules] = useState(0);
  const [currentInnerStep, setCurrentInnerStep] = useState(1);
  const [currentModule, setCurrentModule] = useState(null);
  const [selectedIltSessionForModule, setSelectedIltSessionForModule] = useState({});
  const [registrationSnackbarState, setRegistrationSnackbarState] = useState(initialSnackbarState);

  const [stepperState, setStepperState] = useState(() => {
    return course.COURSE_TYPE_NAME === Localize.get(`App.${courseType.course}`)
      ? {
          0: {
            completed: false,
            active: true,
            disabled: false
          },

          1: {
            completed: false,
            active: false,
            disabled: true
          },

          2: {
            completed: false,
            active: false,
            disabled: true
          }
        }
      : {
          0: {
            completed: false,
            active: false,
            disabled: true
          },
          1: {
            completed: false,
            active: true,
            disabled: false
          },

          2: {
            completed: false,
            active: false,
            disabled: true
          }
        };
  });

  const handlePageChange = (action) => {
    if (action === steps.next) {
      if (page === 0) {
        innerStepNext();
      }

      if (page === 1) {
        setPage(2);
        setStepperState({
          ...stepperState,
          1: {
            active: false,
            disabled: false
          },
          2: {
            active: true,
            disabled: false
          }
        });
      }

      if (page === 2) {
        handleConfirmRegistration();
      }
    }

    if (action === steps.back) {
      if (page === 2 || page === 1) {
        setPage(page - 1);
        setStepperState({
          ...stepperState,
          [page]: {
            active: false,
            disabled: false
          },
          [page - 1]: {
            active: true,
            disabled: false
          }
        });
      }

      if (page === 0) {
        innerStepBack();
      }
    }

    if (action === steps.skip) {
      innerStepNext();
    }
  };

  const findModuleForStep = (step) => {
    const value = reduce(
      selectedIltSessionForModule,
      (acc, val, key) => {
        if (val.step === step) {
          return key;
        }
        return acc;
      },
      ''
    );

    if (value) return value;
    return null;
  };

  const innerStepNext = () => {
    if (currentInnerStep >= numberOfModules) {
      setPage(1);
      setStepperState({
        ...stepperState,
        0: {
          completed: true,
          active: false,
          disabled: false
        },
        1: {
          active: true,
          disabled: false
        }
      });
      return;
    }
    setCurrentModule(findModuleForStep(currentInnerStep + 1));
    setCurrentInnerStep(currentInnerStep + 1);
  };

  const innerStepBack = () => {
    if (currentInnerStep !== 1) {
      setCurrentModule(findModuleForStep(currentInnerStep - 1));
      setCurrentInnerStep(currentInnerStep - 1);
    }
  };

  const handleIltModulesAndSessions = (course) => {
    const ModulesWithSessions = course.COURSE_MODULES.reduce((acc, courseModule) => {
      if (courseModule.MODULE_TYPE_NAME === moduleType.iltModule) {
        const moduleIltSessions = course.COURSE_SESSIONS.filter((courseSession) => {
          if (courseSession.module.id === courseModule.ID) return courseSession;
        });
        if (moduleIltSessions.length) {
          return [...acc, { ...courseModule, iltSessions: [...moduleIltSessions] }];
        }
      }

      return acc;
    }, []);

    const modules = [...ModulesWithSessions];

    return {
      ModuleWithSessions: modules,
      numberOfModules: modules.length
    };
  };

  const iltSessionsForModule = (moduleId) => {
    const module = iltModulesSessions.filter((val) => val.ID === moduleId);

    if (module[0]) return module[0].iltSessions;
    return [];
  };

  const finishedPrerequisites = (coursesOfEmployee, eLearningsOfEmployee) => {
    const employeesCoursesAndELearningsMap = new Map(
      [...coursesOfEmployee, ...eLearningsOfEmployee].map((item) => [item.id, item.complete])
    );

    const allPrerequisites = [
      ...course.COURSE_PREREQUISITES.COURSES,
      ...course.COURSE_PREREQUISITES.LEARNING_PATHS
    ];

    return allPrerequisites.every((learningMaterial) =>
      employeesCoursesAndELearningsMap.get(learningMaterial.id)
    );
  };

  const handleConfirmRegistration = async () => {
    setIsResponseStatusLoading(true);
    await createRegistrationForCoursesService(course?.ID, participantDetails)
      .then((response) => {
        if (response?.status === httpStatusCodes.ok) {
          setRegistrationResponse(response?.data || []);
          setShowConfirmationPage(true);
        } else {
          setRegistrationSnackbarState({
            ...errorSnackbarState,
            message: Localize.get('RegistrationForm.registrationFailed')
          });
        }
      })
      .catch(() => {
        setRegistrationSnackbarState({
          ...errorSnackbarState,
          message: Localize.get('RegistrationForm.registrationFailed')
        });
      })
      .finally(() => {
        setIsResponseStatusLoading(false);
      });
  };

  const setRegistrationSnackbarToInit = () => {
    setRegistrationSnackbarState(initialSnackbarState);
  };

  const checkBookingStatusForParticipant = (iltModule) => {
    const availableSeats = get(iltModule, 'iltSession.availableSeats', 0);
    if (availableSeats > 0) {
      return bookingStatus.successful;
    } else if (availableSeats === 0 && get(iltModule, 'iltSession.hasWaitingList', false)) {
      return bookingStatus.waitingList;
    }
    return bookingStatus.decline;
  };

  const checkBookingStatusForTeamMembers = (iltModule, alreadyBookedMembers) => {
    const managerSeat = 1;
    const availableSeats =
      get(iltModule, 'iltSession.availableSeats', 0) - managerSeat - alreadyBookedMembers;
    if (availableSeats > 0) {
      return bookingStatus.successful;
    } else if (availableSeats === 0 && get(iltModule, 'iltSession.hasWaitingList', false)) {
      return bookingStatus.waitingList;
    }
    return bookingStatus.decline;
  };

  useEffect(() => {
    const { ModuleWithSessions, numberOfModules } = handleIltModulesAndSessions(course);

    setIltModulesSessions(ModuleWithSessions);
    setNumberOfModules(numberOfModules);
    if (numberOfModules > 0) setCurrentInnerStep(1);

    setSelectedIltSessionForModule(
      ModuleWithSessions.reduce((acc, val, index) => {
        return { ...acc, [val.ID]: { sessionId: null, step: index + 1 } };
      }, {})
    );

    setCurrentModule(ModuleWithSessions[0] ? ModuleWithSessions[0].ID : '');
  }, [course]);

  useEffect(() => {
    if (page === 1) {
      const iltModuleWithSelectedSessions = iltModulesSessions.reduce((acc, currentModule) => {
        const selectedIltSession = selectedIltSessionForModule[currentModule.ID];

        if (selectedIltSession?.sessionId !== null) {
          const selectedSession = currentModule.iltSessions.find(
            (courseSession) => courseSession.id === selectedIltSession.sessionId
          );
          const filteredCurrentModule = {
            ID: currentModule.ID,
            NAME: currentModule.NAME,
            iltSession: {
              id: selectedSession.id,
              seminarNumber: selectedSession.seminarNumber,
              startDateTime: selectedSession.startDateTime,
              endDateTime: selectedSession.endDateTime,
              type: selectedSession.type,
              availableSeats: selectedSession.availableSeats,
              hasWaitingList: selectedSession.hasWaitingList,
              iltPrices: selectedSession.iltPrices,
              enableHotelRequest: selectedSession.enableHotelRequest,
              hasAutomaticWaitingList: selectedSession.hasAutomaticWaitingList,
              location: selectedSession.location,
              venues: selectedSession.venues
            }
          };

          return [...acc, { ...filteredCurrentModule }];
        }

        return acc;
      }, []);

      const { hotelsRequired, dates } = participantDetails;
      for (
        let i = participantDetails.hotelsRequired.length;
        i < iltModuleWithSelectedSessions.length;
        i++
      ) {
        hotelsRequired.push(false);
        dates.push({ arrivalDate: '', departureDate: '' });
      }

      const updateParticipants = participantDetails.participants.length
        ? participantDetails.participants.map((participant) => {
            const { hotelsRequired, dates } = participant;
            for (
              let i = participant.hotelsRequired.length;
              i < iltModuleWithSelectedSessions.length;
              i++
            ) {
              hotelsRequired.push(false);
              dates.push({ arrivalDate: '', departureDate: '' });
            }
            return {
              ...participant,
              hotelsRequired: hotelsRequired,
              dates: dates,
              iltModulesSessions: iltModuleWithSelectedSessions
            };
          })
        : [];

      setParticipantDetails({
        ...participantDetails,
        hotelsRequired: hotelsRequired,
        dates: dates,
        iltModulesSessions: iltModuleWithSelectedSessions,
        participants: updateParticipants
      });
    }
  }, [page]);

  return {
    stepperState,
    setStepperState,
    page,
    setPage,
    handlePageChange,
    nextButtonEnabled,
    setNextButtonEnabled,
    iltModulesSessions,
    numberOfModules,
    currentInnerStep,
    selectedIltSessionForModule,
    setSelectedIltSessionForModule,
    iltSessionsForModule,
    currentModule,
    finishedPrerequisites,
    registrationSnackbarState,
    setRegistrationSnackbarToInit,
    checkBookingStatusForParticipant,
    checkBookingStatusForTeamMembers
  };
};

export default useRegistrationCourseLogic;
