import React, { useEffect, useState, useRef } from 'react';
import Localize from 'react-intl-universal';

import { get } from 'lodash';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { capitalizeFirstLetter, formatDateTimeStringToDateObject } from '@common/Util';
import {
  StyledDescription,
  StyledDescriptionWrap
} from '@components/StyledComponents/EmployeesCoursesTable.styled';
import TableToolbar from '@components/TableToolbar/TableToolbar';
import { defaultTableFilters, equalDoubleNumbersFilters } from '@config/filterOperators';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DataGrid } from '@mui/x-data-grid';
import { formatSortingFieldName } from '@pages/TeamArea/Util';

export const PAGE_SIZE_OPTIONS = [5, 10, 15, 20, 100];

const Row = ({ row, columnVisibilityModel }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  display:
                    columnVisibilityModel.employee || columnVisibilityModel.employee === undefined
                      ? 'table-cell'
                      : 'none',
                  width: 280,
                  maxWidth: 280,
                  minWidth: 280,
                  padding: '0 !important'
                }}
              >
                <IconButton
                  sx={{ float: 'left' }}
                  aria-label='expand row'
                  size='small'
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
                <StyledDescriptionWrap>
                  <StyledDescription>{`${row.firstName} ${row.lastName}`}</StyledDescription>
                </StyledDescriptionWrap>
              </TableCell>
              <TableCell
                sx={{
                  display:
                    columnVisibilityModel.course || columnVisibilityModel.course === undefined
                      ? 'table-cell'
                      : 'none',
                  height: 31.5,
                  width: 270,
                  maxWidth: 270,
                  minWidth: 270,
                  padding: '0 0 0 8px !important'
                }}
              >
                <Box>{row.course?.name}</Box>
              </TableCell>
              <TableCell
                sx={{
                  display:
                    columnVisibilityModel.registrationDate ||
                    columnVisibilityModel.registrationDate === undefined
                      ? 'table-cell'
                      : 'none',
                  height: 31.5,
                  width: 140,
                  maxWidth: 140,
                  minWidth: 140,
                  padding: '0 0 0 8px !important'
                }}
              >
                <Box>{formatDateTimeStringToDateObject(row.course?.assignedDate)}</Box>
              </TableCell>
              <TableCell
                sx={{
                  display:
                    columnVisibilityModel.status || columnVisibilityModel.status === undefined
                      ? 'table-cell'
                      : 'none',
                  height: 31.5,
                  width: 135,
                  maxWidth: 135,
                  minWidth: 135,
                  padding: '0 0 0 8px !important'
                }}
              >
                <Box>{capitalizeFirstLetter(row.course?.status)}</Box>
              </TableCell>
              <TableCell
                sx={{
                  display:
                    columnVisibilityModel.progress || columnVisibilityModel.progress === undefined
                      ? 'table-cell'
                      : 'none',
                  height: 31.5,
                  width: 100,
                  maxWidth: 100,
                  minWidth: 100,
                  padding: '0 0 0 8px !important'
                }}
              >
                <Box>{row.course?.percentageComplete}</Box>
              </TableCell>
              <TableCell
                sx={{
                  display:
                    columnVisibilityModel.completedDate ||
                    columnVisibilityModel.completedDate === undefined
                      ? 'table-cell'
                      : 'none',
                  height: 31.5,
                  width: 150,
                  maxWidth: 150,
                  minWidth: 150,
                  padding: '0 0 0 8px !important'
                }}
              >
                <Box>{formatDateTimeStringToDateObject(row.course?.dateCompleted)}</Box>
              </TableCell>
              <TableCell
                sx={{
                  display:
                    columnVisibilityModel.price || columnVisibilityModel.price === undefined
                      ? 'table-cell'
                      : 'none',
                  height: 31.5,
                  width: 75,
                  maxWidth: 75,
                  minWidth: 75,
                  padding: '0 0 0 8px !important'
                }}
              >
                <Box>{row.course?.price}</Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ borderBottom: 'unset', padding: '0 !important' }}>
                <Collapse in={open} timeout='auto' unmountOnExit>
                  <Table>
                    <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                      <TableRow>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.iltModuleName')}
                        </TableCell>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.iltSessionName')}
                        </TableCell>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.startDate')}
                        </TableCell>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.endDate')}
                        </TableCell>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.location')}
                        </TableCell>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.status')}
                        </TableCell>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.result')}
                        </TableCell>
                        <TableCell sx={{ width: 150, maxWidth: 150 }}>
                          {Localize.get('ILTSession.hotelRequest')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>

                  {row?.course?.iltSessionsDtos?.map((iltSession) => (
                    <SessionRow
                      key={iltSession?.id}
                      session={iltSession}
                      participant={row.eventParticipantDto}
                    />
                  ))}
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const SessionRow = ({ session = {}, participant }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>{session?.module?.name}</TableCell>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>{session?.name}</TableCell>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>
            {formatDateTimeStringToDateObject(session?.startDateTime)}
          </TableCell>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>
            {formatDateTimeStringToDateObject(session?.endDateTime)}
          </TableCell>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>{session?.location}</TableCell>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>{session?.status?.key}</TableCell>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>{participant?.result?.value}</TableCell>
          <TableCell sx={{ width: 150, maxWidth: 150 }}>
            {participant?.hotelRequest ? Localize.get('App.yes') : Localize.get('App.no')}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const EmployeesCoursesTable = (props) => {
  const {
    isLoading,
    isMobile,
    data,
    totalPages,
    totalElements,
    setTotalElements,
    fetchEmployeesCourses,
    setSortingOption,
    filterOption,
    setFilterOption,
    currentPage
  } = props;

  const prevFilterOptionRef = useRef();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    employee: true,
    course: true,
    registrationDate: true,
    status: true,
    progress: true,
    completedDate: true,
    price: true
  });

  useEffect(() => {
    // Handle click on close button for Filters
    if (
      prevFilterOptionRef.current !== undefined &&
      prevFilterOptionRef.current.value !== filterOption.value
    ) {
      fetchEmployeesCourses();
    }
    // Assign the ref's current value to the filterOption Hook
    prevFilterOptionRef.current = filterOption;
  }, [filterOption]);

  useEffect(() => {
    setTotalElements((prevTotalElements) =>
      totalElements !== undefined ? totalElements : prevTotalElements
    );
  }, [totalElements, setTotalElements]);
  const columns = [
    {
      field: 'employee',
      headerName: 'EmployeeCourseTableLabels.employee',
      editable: false,
      headerAlign: 'left',
      width: 265
    },
    {
      field: 'course',
      headerName: 'EmployeeCourseTableLabels.course',
      editable: false,
      headerAlign: 'left',
      width: 265
    },
    {
      field: 'registrationDate',
      headerName: 'EmployeeCourseTableLabels.registrationDate',
      editable: false,
      headerAlign: 'left',
      width: 150,
      type: 'dateTime'
    },
    {
      field: 'status',
      headerName: 'EmployeeCourseTableLabels.status',
      editable: false,
      headerAlign: 'left',
      width: 125
    },
    {
      field: 'progress',
      headerName: 'EmployeeCourseTableLabels.progress',
      editable: false,
      headerAlign: 'left',
      width: 100,
      type: 'number'
    },
    {
      field: 'completedDate',
      headerName: 'EmployeeCourseTableLabels.completedDate',
      editable: false,
      headerAlign: 'left',
      width: 150,
      type: 'dateTime'
    },
    {
      field: 'price',
      headerName: 'EmployeeCourseTableLabels.price',
      editable: false,
      headerAlign: 'left',
      width: 75,
      type: 'number'
    }
  ];
  const handleSortModelChange = React.useCallback((sortModel) => {
    if (sortModel.length) {
      setSortingOption({
        field: formatSortingFieldName(sortModel[0].field),
        sort: sortModel[0].sort
      });
      fetchEmployeesCourses();
    }
  }, []);

  const handleFilterModelChange = React.useCallback(
    (filterModel) => {
      if (
        filterModel.items.length &&
        (filterModel.items[0].value ||
          filterModel.items[0].operator === 'isEmpty' ||
          filterModel.items[0].operator === 'isNotEmpty')
      ) {
        // This check is needed when user switch filter fields from String type to Number type
        if (['progress', 'price'].includes(filterModel.items[0].field)) {
          filterModel.items[0].value = Number.isNaN(Number(filterModel.items[0].value))
            ? null
            : filterModel.items[0].value;
        }
        setFilterOption(filterModel.items[0]);
        fetchEmployeesCourses();
      } else {
        setFilterOption({ field: null, value: null, operator: null });

        if (prevFilterOptionRef.current.value !== filterOption.value) {
          fetchEmployeesCourses();
        }
      }
    },
    [data]
  );

  return (
    <>
      <div style={{ height: isMobile ? 600 : 'auto', width: '100%' }}>
        <DataGrid
          sx={{
            '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': { visibility: 'visible' },
            '.MuiTablePagination-selectLabel': { margin: '0 !important' },
            '.MuiTablePagination-displayedRows': { margin: '0 !important' }
          }}
          loading={isLoading}
          initialState={{
            pagination: {
              pageSize: totalPages,
              rowCount: totalElements,
              page: currentPage
            }
          }}
          components={{
            Toolbar: () => <TableToolbar isDensityVisible={false} />,
            Row: (props) => <Row {...props} columnVisibilityModel={columnVisibilityModel} />
          }}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: false,
              disableShowAllButton: false
            }
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
          autoHeight={false}
          autoPageSize={false}
          checkboxSelection={false}
          checkboxSelectionVisibleOnly={false}
          columnBuffer={1}
          rowBuffer={2}
          rowSelection={false}
          density={'standard'}
          disableColumnMenu
          disableColumnSelector={false}
          disableMultipleColumnsFiltering
          disableMultipleRowSelection
          disableMultipleColumnsSorting
          disableRowSelectionOnClick
          filterMode={'server'}
          keepNonExistentRowsSelected
          pagination
          paginationMode={'client'}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          rowSpacingType={'border'}
          showCellVerticalBorder={false}
          showColumnVerticalBorder={false}
          sortingMode={'server'}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          disableColumnReorder
          disableColumnResize
          keepColumnPositionIfDraggedOutside
          columns={[
            ...columns.map((column) =>
              column.field === 'progress' || column.field === 'price'
                ? {
                    ...column,
                    headerName: Localize.get(column.headerName),
                    filterOperators: equalDoubleNumbersFilters,
                    renderCell: (params) => (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {get(params.row, params.field)}
                      </div>
                    )
                  }
                : column.field === 'employee' ||
                  column.field === 'course' ||
                  column.field === 'status'
                ? {
                    ...column,
                    headerName: Localize.get(column.headerName),
                    filterOperators: defaultTableFilters,
                    renderCell: (params) => (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {get(params.row, params.field)}
                      </div>
                    )
                  }
                : {
                    ...column,
                    headerName: Localize.get(column.headerName),
                    renderCell: (params) => (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {get(params.row, params.field)}
                      </div>
                    )
                  }
            )
          ]}
          rows={data ? data : []}
          localeText={getLocalesText(Localize)}
          rowCount={totalElements}
        />
      </div>
    </>
  );
};

export default EmployeesCoursesTable;
