import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import List from 'semantic-ui-react/dist/commonjs/elements/List';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import style from '../../EventDetails/Tabs/style/Information.module.scss';

const Hotel = (props) => {
  const { hotelData, config, isLoading } = props;

  const stringItem = (item, index) => {
    const contentClassName = ['instructors', 'location'].includes(item.name) ? style.newRow : '';
    return (
      <Segment basic key={index}>
        <Grid className={style.groupStyle} centered>
          <Grid.Column className={style.columnStyle} computer={4} mobile={6}>
            {Localize.get(item.label)}
          </Grid.Column>
          <Grid.Column only='computer' computer={1} />
          {['instructors', 'location'].includes(item.name) && isLoading && (
            <Dimmer active inverted>
              <Loader size='large'>{Localize.get('App.loading')}</Loader>
            </Dimmer>
          )}
          <Grid.Column
            verticalAlign='middle'
            textAlign='left'
            computer={7}
            mobile={10}
            className={contentClassName}
          >
            {item.beValueSuffix
              ? hotelData[item.beValue] && hotelData[item.beValue][item.beValueSuffix]
                ? hotelData[item.beValue][item.beValueSuffix]
                : ''
              : hotelData[item.beValue]}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  };

  // --- TO DO --- Use this code after BE is finished or delete it ---
  //   const dateItem = (item, index) => {
  //     return (
  //       <Segment key={index} basic>
  //         <Grid className={style.groupStyle} centered>
  //           <Grid.Column className={style.columnStyle} computer={4} mobile={6}>
  //             {Localize.get(item.label)}
  //           </Grid.Column>
  //           <Grid.Column only='computer' computer={1} />
  //           <Grid.Column verticalAlign='middle' textAlign='left' computer={7} mobile={10}>
  //             {formatLongDateTimeStringToDateTimeObject(hotelData[item.beValue])}
  //           </Grid.Column>
  //         </Grid>
  //       </Segment>
  //     );
  //   };

  const getDescriptionItem = (label, text, isBullet, index) => (
    <List.Item className={style.itemStyle} key={index}>
      <List.Header>{Localize.get(label)}</List.Header>
      <List.Description className={style.description}>
        {isBullet && <Icon name='circle' size='mini' className={style.middle} />}
        {text}
      </List.Description>
    </List.Item>
  );

  return (
    <>
      <Grid
        data-testid={'allSessionHotelTab'}
        className={style.columnTextAlign}
        style={{ background: config?.configuration?.AllSessionDetailsContent.background }}
      >
        <Grid.Row centered className={`${style.mobileContainer}`}>
          {hotelData ? (
            <Grid.Column computer={8} mobile={16}>
              {config?.configuration?.AllSessionHotelTab.items
                .sort((a, b) => a.order - b.order)
                .map((item, index) => {
                  switch (item.name) {
                    case 'checkIn':
                      return stringItem(item, index); // there is also dateItem if data is not string
                    case 'checkOut':
                      return stringItem(item, index);
                    case 'assignedHotel':
                      return stringItem(item, index);
                    case 'roomCategory':
                      return stringItem(item, index);
                    case 'address':
                      return stringItem(item, index);
                    case 'services':
                      return stringItem(item, index);
                    case 'facilities':
                      return stringItem(item, index);
                  }
                })}
            </Grid.Column>
          ) : (
            <Grid.Column
              data-testid={'allSessionHotelTab'}
              width={14}
              textAlign='center'
              verticalAlign='middle'
              className={style.noHotelInfo}
              style={{ background: config?.configuration?.AllSessionDetailsContent.background }}
            >
              <div>{Localize.get('ILTSession.noHotel1')}</div>
              <div>{Localize.get('ILTSession.noHotel2')}</div>
            </Grid.Column>
          )}
          {hotelData && (
            <Grid.Column
              data-testid={'allSessionHotelTabDetails'}
              computer={8}
              mobile={16}
              style={{ background: config?.configuration?.AllSessionDetailsContent.background }}
            >
              <Segment
                secondary
                className={style.contentHeight}
                data-testid={'sessionHotelDetails'}
              >
                <Header as='h4' className={style.headerMargin}>
                  {Localize.get('ILTSession.hotelDesc')}
                </Header>

                <div data-testid={'iltSessionHotelDetails'} className={style.notesPlaceholder}>
                  <List>
                    {config?.configuration?.AllSessionHotelTabDesc.items
                      .sort((a, b) => a.order - b.order)
                      .map((item, index) => {
                        switch (item.name) {
                          case 'checkInTimes': {
                            let text = `${Localize.get('ILTSession.checkInTimesFrom')} ${
                              hotelData.checkInTimesStart
                            } ${Localize.get('ILTSession.until')} ${hotelData.checkInTimesEnd}`;
                            return getDescriptionItem(item.label, text, true, index);
                          }
                          case 'checkOutTimes': {
                            let text = `${Localize.get('ILTSession.checkOutTimesUntil')} ${
                              hotelData.checkOutTime
                            }`;
                            return getDescriptionItem(item.label, text, true, index);
                          }
                          case 'breakfast': {
                            let text = `${Localize.get('ILTSession.breakfastFrom')} ${
                              hotelData.brekfastStart
                            } ${Localize.get('ILTSession.until')} ${hotelData.brekfastEnd}`;
                            return getDescriptionItem(item.label, text, false, index);
                          }
                          case 'additionalInfo':
                            return getDescriptionItem(
                              item.label,
                              hotelData.additionalInformation,
                              false,
                              index
                            );

                          case 'other':
                            return getDescriptionItem(item.label, hotelData.other, false, index);
                        }
                      })}
                  </List>
                </div>
              </Segment>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Hotel;
