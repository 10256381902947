import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import styled from 'styled-components';

export const PaginationContainer = styled(Grid.Column)`
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    padding-left: 0 !important;
    padding-bottom: 1rem;
  }
  @media only screen and (max-width: 767px) and (min-width: 321px) {
    zoom: 0.75 !important;
    -moz-transform: scale(0.85);
  }
  @media only screen and (max-width: 320px) and (min-width: 280px) {
    zoom: 0.8 !important;
    -moz-transform: scale(0.8);
  }
`;
