import React from 'react';

import Menu from '@mui/material/Menu';

const StyledMenu = ({ anchorEl, open = false, onClose = () => {}, children }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 25,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            },
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: '0.6rem'
          }
        }
      }}
    >
      {children}
    </Menu>
  );
};

export default StyledMenu;
