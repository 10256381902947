import React from 'react';
import Localize from 'react-intl-universal';

import { StyledTabMenuItem } from '@components/StyledComponents/MyArea.styled';

import { tabMenuItems } from './Constants';

const TabMenuItems = (props) => {
  const { activeItem, handleItemClick } = props;
  return (
    <>
      {tabMenuItems.map((el) => (
        <StyledTabMenuItem
          key={el.name}
          data-test-id={el.test}
          name={el.name}
          active={activeItem === el.name}
          onClick={handleItemClick}
        >
          {Localize.get(`TrainerArea.${el.name}`)}
        </StyledTabMenuItem>
      ))}
    </>
  );
};

export default TabMenuItems;
