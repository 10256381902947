import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';

import {
  CheckBoxColumnContainer,
  CheckBoxRowContainer
} from '@components/StyledComponents/Filters.styled';

const CheckboxFilter = (props) => {
  const { config, dataSource, onFilterChange } = props;

  const [dataSourceLocal, setDataSourceLocal] = React.useState(dataSource);
  const [searchValue, setSearchValue] = React.useState('');
  const [noSearchResults, setNoSearchResults] = React.useState('');

  React.useEffect(() => {
    if (dataSource) {
      setDataSourceLocal(dataSource);
      setSearchValue('');
      setNoSearchResults('');
    }
  }, [dataSource]);

  const device = config.device.join(' ');

  const onSearchChange = (e, data) => {
    if (data.value.length) {
      const filteredDataSource = dataSource.filter((source) =>
        source.text.toString().toLowerCase().includes(data.value.toLowerCase())
      );
      setSearchValue(data.value);
      setDataSourceLocal(filteredDataSource);
      if (!filteredDataSource.length) {
        setNoSearchResults('No results found');
      } else {
        setNoSearchResults('');
      }
    } else {
      setSearchValue('');
      setDataSourceLocal(dataSource);
      setNoSearchResults('');
    }
  };

  const onChange = (data) => {
    const current = [...dataSourceLocal];
    let changed = current.find((el) => el.value === data.value);
    changed.checked = !changed.checked;
    onFilterChange(config, changed);
  };

  const getComponent = () => {
    return (
      <>
        {config?.haveSearch && (
          <Grid.Row>
            <CheckBoxColumnContainer>
              <Input
                fluid
                icon='search'
                onChange={onSearchChange}
                value={searchValue}
                placeholder={Localize.get(`App.${config.name}`)}
              />
            </CheckBoxColumnContainer>
          </Grid.Row>
        )}
        {noSearchResults.length > 0 && (
          <Grid.Row>
            <CheckBoxColumnContainer>
              <Label>{noSearchResults}</Label>
            </CheckBoxColumnContainer>
          </Grid.Row>
        )}
        <CheckBoxRowContainer only={`${device}`}>
          {dataSourceLocal &&
            dataSourceLocal.length > 0 &&
            dataSourceLocal
              .sort((a, b) => a?.orderNumber - b?.orderNumber)
              .map((type) => (
                <CheckBoxColumnContainer stretched width={4} key={type.key}>
                  <div className='ui checkbox'>
                    <input
                      data-test-id={'checkBoxFilter'}
                      type='checkbox'
                      id={type.key}
                      name={type.text}
                      value={type.text ? type.text : ''}
                      checked={type.checked}
                      disabled={config.disabled}
                      tabIndex='0'
                      onChange={() => onChange(type)}
                    />
                    <label>{type.text}</label>
                  </div>
                </CheckBoxColumnContainer>
              ))}
        </CheckBoxRowContainer>
      </>
    );
  };

  return getComponent();
};
export default React.memo(CheckboxFilter);
