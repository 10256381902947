const legalNoticeDeConfig = () => (
  <>
    <h1>Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>
      H&ouml;rmann KG Verkaufsgesellschaft
      <br />
      Upheider Weg 94-98
      <br />
      33803 Steinhagen
    </p>

    <p>
      Handelsregister: HRA 4529
      <br />
      Registergericht: Amtsgericht G&uuml;tersloh
    </p>

    <p>
      <strong>Vertreten durch:</strong>
      <br />
      Pers&ouml;nlich haftende Gesellschafter Thomas J. H&ouml;rmann, Martin J. H&ouml;rmann,
      Christoph H&ouml;rmann
    </p>

    <h2>Kontakt</h2>
    <p>
      Telefon: 05204-915 7110
      <br />
      Telefax: 05204-915 107
      <br />
      E-Mail: akademie@hoermann.de
    </p>

    <h2>Umsatzsteuer-ID</h2>
    <p>
      Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:
      <br />
      DE-126 951 398
    </p>

    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle teilzunehmen.
    </p>
  </>
);

export default legalNoticeDeConfig;
