import React, { useEffect, useState } from 'react';

import * as globalConstants from '@common/Constants';
import { useGlobalContext } from '@hooks/Context/GlobalContext';
import useFetching from '@hooks/Fetching/Fetching';
import * as constants from '@pages/EventDetails/Constants';
import Information from '@pages/LearningPathDetails/Tabs/Information';
import Overview from '@pages/LearningPathDetails/Tabs/Overview';

import LearningPathDetailsDesktop from './Components/LearningPathDetailsDesktop';
import LearningPathDetailsMobile from './Components/LearningPathDetailsMobile';
import { getLearningPathDetails } from './MainHelper';

const LearningPathDetails = ({ match, history }) => {
  const [learningPath, setLearningPath] = useState(null);
  const [contextData] = useGlobalContext();
  const [activeItem, setActiveItem] = useState(constants.tabOptions.overview);
  const [learningPathID] = useState(match?.params?.id ? match.params.id : '');

  const redirectToHome = () => {
    return history.push({
      pathname: '/',
      state: {
        from: 'Home',
        path: '/'
      }
    });
  };

  const [learningPathResponse, isLoading] = useFetching(
    getLearningPathDetails.bind(null, learningPathID, redirectToHome),
    false
  );

  useEffect(() => {
    if (learningPathResponse?.data?.learningPath) {
      setLearningPath(learningPathResponse?.data.learningPath);
    }
  }, [learningPathResponse]);

  const handleActiveComponent = (component) => {
    switch (component) {
      case constants.tabOptions.information:
        return <Information learningPath={learningPath} config={contextData} />;
      default:
        return <Overview learningPath={learningPath} config={contextData} />;
    }
  };

  const handleTabClick = (e, data) => setActiveItem(data.name);

  return (
    <div data-testid='learningPathDetails'>
      <LearningPathDetailsDesktop
        learningPath={learningPath}
        contextData={contextData}
        isLoading={isLoading}
        handleActiveComponent={handleActiveComponent}
        handleTabClick={handleTabClick}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />

      {/* mobile layout - content*/}
      {contextData.size && contextData.size.device === globalConstants.deviceSize.mobile && (
        <LearningPathDetailsMobile
          learningPath={learningPath}
          contextData={contextData}
          isLoading={isLoading}
          handleActiveComponent={handleActiveComponent}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />
      )}
    </div>
  );
};

export default LearningPathDetails;
