import React from 'react';
import Localize from 'react-intl-universal';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { Grid, Checkbox, Icon } from 'semantic-ui-react';

import { bookingStatus } from '@common/Constants';
import {
  formatDateDMY,
  formatDateDMYHMWithDayName,
  getIltSessionPrice,
  getSessionLocation
} from '@common/Util';

import { bookingType, courseSessionType, days } from '../../Constants';
import style from '../style/RegistrationCourse.module.scss';

const BookingInformation = ({
  participantDetails,
  setParticipantDetails,
  iltModule,
  iltModuleIndex,
  config,
  participantIndex,
  bookingInformationType,
  bookingInformationStatus
}) => {
  const selectedIltSession = iltModule?.iltSession ? iltModule.iltSession : null;
  let minDate = new Date();
  if (selectedIltSession?.startDateTime.length) {
    minDate = new Date(selectedIltSession.startDateTime);
    minDate.setDate(minDate.getDate() - days.four);
  }
  let maxDate = new Date();
  if (selectedIltSession?.endDateTime.length) {
    maxDate = new Date(selectedIltSession.endDateTime);
    maxDate.setDate(maxDate.getDate() + days.three);
  }

  const statusColors = {
    [bookingStatus.successful]: config?.configuration?.Registration?.greenLightColor,
    [bookingStatus.waitingList]: config?.configuration?.Registration?.yellowLightColor,
    [bookingStatus.decline]: config?.configuration?.Registration?.redLightColor
  };

  const checkIfHotelIsNeeded = (sessionType) => {
    return sessionType?.key === courseSessionType.classroom;
  };

  const showHotelAndDatesFields = (hotelRequired) => {
    return bookingInformationType === bookingType.forSummary ||
      bookingInformationType === bookingType.confirmationPage
      ? hotelRequired
      : true;
  };

  const handleHotelRequiredChange = (event, { checked }) => {
    event.preventDefault();
    setParticipantDetails((prevState) => {
      if (bookingInformationType === bookingType.forLoggedInUser) {
        const updatedHotelsRequired = [...participantDetails.hotelsRequired];
        updatedHotelsRequired[iltModuleIndex] = checked;
        return {
          ...prevState,
          hotelsRequired: updatedHotelsRequired
        };
      } else if (bookingInformationType === bookingType.forMyTeam) {
        const updatedParticipants = [...prevState.participants];
        const updatedHotelsRequired = updatedParticipants[participantIndex].hotelsRequired;
        updatedHotelsRequired[iltModuleIndex] = checked;
        updatedParticipants[participantIndex] = {
          ...updatedParticipants[participantIndex],
          hotelsRequired: updatedHotelsRequired
        };
        return {
          ...prevState,
          participants: updatedParticipants
        };
      }
      return prevState;
    });
  };

  const handleDateChange = (event, { value, name }) => {
    setParticipantDetails((prevState) => {
      if (bookingInformationType === bookingType.forLoggedInUser) {
        const updatedDates = [...participantDetails.dates];
        updatedDates[iltModuleIndex][name] = value;
        return {
          ...prevState,
          dates: updatedDates
        };
      } else if (bookingInformationType === bookingType.forMyTeam) {
        const updatedParticipants = [...prevState.participants];
        const updatedDates = updatedParticipants[participantIndex].dates;
        updatedDates[iltModuleIndex][name] = value;
        updatedParticipants[participantIndex] = {
          ...updatedParticipants[participantIndex],
          dates: updatedDates
        };
        return {
          ...prevState,
          participants: updatedParticipants
        };
      }
      return prevState;
    });
  };

  return (
    <Grid.Row key={iltModuleIndex} data-testid={'BookingInformation'}>
      {config?.configuration?.Registration?.showModule && (
        <Grid.Column width={7}>
          <Grid.Row className={style.girdRowContainer}>{iltModule.NAME}</Grid.Row>
          {selectedIltSession && (
            <Grid.Row className={style.girdRowContainer}>
              {`${formatDateDMYHMWithDayName(
                new Date(selectedIltSession?.startDateTime)
              )} - ${formatDateDMYHMWithDayName(new Date(selectedIltSession?.endDateTime))}`}
            </Grid.Row>
          )}
          {selectedIltSession && (
            <Grid.Row className={style.girdRowContainer}>
              {`${getSessionLocation(selectedIltSession)} | ${selectedIltSession?.seminarNumber}`}
            </Grid.Row>
          )}
          {iltModule?.iltSession && (
            <Grid.Row>
              <Grid>
                {config?.configuration?.Registration?.showSeats && (
                  <Grid.Column width={2}>
                    <Icon
                      name={config?.configuration?.header?.userIconName}
                      size={config?.configuration?.header?.userIconSizeDesktop}
                    />
                  </Grid.Column>
                )}
                {config?.configuration?.Registration?.showSeats && (
                  <Grid.Column width={4}>{`${Localize.get('CourseDetails.Seats')} ${
                    selectedIltSession?.availableSeats
                  }`}</Grid.Column>
                )}
                {config?.configuration?.Registration?.showWaitingList && (
                  <Grid.Column width={5}>
                    {selectedIltSession?.hasWaitingList &&
                      Localize.get('CourseDetails.waitingList')}
                  </Grid.Column>
                )}
                {config?.configuration?.Registration?.showPrice && (
                  <Grid.Column width={5}>{`${Localize.get(
                    'CourseDetails.price'
                  )} ${getIltSessionPrice(selectedIltSession)}`}</Grid.Column>
                )}
              </Grid>
            </Grid.Row>
          )}
        </Grid.Column>
      )}
      {config?.configuration?.Registration?.showHotelRequired && iltModule?.iltSession && (
        <Grid.Column width={1} textAlign={'right'}>
          {checkIfHotelIsNeeded(selectedIltSession?.type) &&
            selectedIltSession.enableHotelRequest &&
            showHotelAndDatesFields(participantDetails.hotelsRequired[iltModuleIndex]) && (
              <Checkbox
                label=''
                checked={participantDetails.hotelsRequired[iltModuleIndex]}
                disabled={
                  bookingInformationType === bookingType.forSummary ||
                  bookingInformationType === bookingType.confirmationPage
                }
                onClick={handleHotelRequiredChange}
              />
            )}
        </Grid.Column>
      )}
      {config?.configuration?.Registration?.showHotelRequired &&
        config?.configuration?.Registration?.showArrivalAndDepartureDate &&
        iltModule?.iltSession && (
          <Grid.Column width={5}>
            {checkIfHotelIsNeeded(selectedIltSession?.type) &&
              selectedIltSession.enableHotelRequest &&
              showHotelAndDatesFields(participantDetails.hotelsRequired[iltModuleIndex]) &&
              (bookingInformationType !== bookingType.forSummary &&
              bookingInformationType !== bookingType.confirmationPage ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={3}>
                      {Localize.get('RegistrationForm.arrivalDateFrom')}
                    </Grid.Column>
                    <Grid.Column width={13}>
                      <SemanticDatepicker
                        name='arrivalDate'
                        icon='calendar alternate outline'
                        value={participantDetails.dates[iltModuleIndex].arrivalDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        placeholder='--'
                        locale={config?.configuration?.Registration.dateLocale}
                        onChange={handleDateChange}
                        type='basic'
                        datePickerOnly
                        format={config?.configuration?.Registration.dateFormat}
                        showToday={config?.configuration?.Registration.showTodayDate}
                        size='mini'
                        disabled={!participantDetails.hotelsRequired[iltModuleIndex]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3}>
                      {Localize.get('RegistrationForm.DepartureDateUntil')}
                    </Grid.Column>
                    <Grid.Column width={13}>
                      <SemanticDatepicker
                        name='departureDate'
                        icon='calendar alternate outline'
                        value={participantDetails.dates[iltModuleIndex].departureDate}
                        minDate={
                          participantDetails.dates[iltModuleIndex].arrivalDate
                            ? participantDetails.dates[iltModuleIndex].arrivalDate
                            : minDate
                        }
                        maxDate={maxDate}
                        placeholder='--'
                        locale={config?.configuration?.Registration.dateLocale}
                        onChange={handleDateChange}
                        type='basic'
                        datePickerOnly
                        format={config?.configuration?.Registration.dateFormat}
                        showToday={config?.configuration?.Registration.showTodayDate}
                        size='mini'
                        disabled={!participantDetails.hotelsRequired[iltModuleIndex]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={3}>
                      {Localize.get('RegistrationForm.arrivalDateFrom')}
                    </Grid.Column>
                    <Grid.Column width={13}>
                      {formatDateDMY(
                        new Date(participantDetails.dates[iltModuleIndex]?.arrivalDate)
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={3}>
                      {Localize.get('RegistrationForm.DepartureDateUntil')}
                    </Grid.Column>
                    <Grid.Column width={13}>
                      {formatDateDMY(
                        new Date(participantDetails.dates[iltModuleIndex]?.departureDate)
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ))}
          </Grid.Column>
        )}
      {config?.configuration?.Registration?.showStatus && (
        <Grid.Column width={1} textAlign={'center'}>
          <Icon
            name='circle'
            size='big'
            style={{
              color:
                statusColors[bookingInformationStatus] ||
                config?.configuration?.Registration?.redLightColor
            }}
          />
        </Grid.Column>
      )}
    </Grid.Row>
  );
};
export default BookingInformation;
