import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Logger from '@common/Logger.js';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer.jsx';
import useFetching from '@hooks/Fetching/Fetching.js';
import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

const Notes = (props) => {
  const { guid } = props;

  const createNotesService = async (guid) => {
    return getService(`${serviceConstants.MEA_SERVER_CONFIGURATION.resource.notes}${guid}`);
  };

  const [notes, isLoadingNotes, , fetchNotes] = useFetching(
    createNotesService.bind(null, guid),
    true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (guid) {
          fetchNotes();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid]);

  const noteText = notes?.data?.notes?.results?.length ? notes.data.notes.results[0].NOTE_TEXT : '';

  return (
    <>
      <Grid columns={3} centered>
        <Grid.Column only='tablet computer' width={1} />
        <Grid.Column computer={14} tablet={14} mobile={16}>
          {isLoadingNotes && (
            <Dimmer active inverted>
              <Loader size='large'>{Localize.get('App.loading')}</Loader>
            </Dimmer>
          )}

          {noteText?.length ? (
            <Message info>
              <Message.Content>
                <p>{noteText}</p>
              </Message.Content>
            </Message>
          ) : (
            <Grid columns={1} stackable>
              <NoContentComponent
                data-test-id={'noNotesMessage'}
                infoType={Localize.get('EventDetails.noNotes')}
              />
            </Grid>
          )}
        </Grid.Column>
        <Grid.Column only='tablet computer' width={1} />
      </Grid>
    </>
  );
};

export default Notes;
