import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { cloneDeep } from 'lodash';

import Logger from '@common/Logger';
import { MainEventGrid } from '@components/StyledComponents/Catalog.styled';
import predefinedFilterValues from '@config/predefinedFilterValues.config.json';
import { useGlobalContext } from '@hooks/Context/GlobalContext';
import useFetching from '@hooks/Fetching/Fetching';
import {
  createAllEventsService,
  createEventService,
  createEventStatusesService,
  createEventTypesService
} from '@pages/Catalog/MainHelper';
import { getFiltersUrlPart, getInitialFilterConfiguration } from '@pages/Catalog/Util';
import EventsTab from '@pages/Events/Tabs/EventsTab';

const EventsMain = () => {
  const [contextData] = useGlobalContext();

  const [filtersConfig, setFiltersConfig] = React.useState(
    contextData?.configuration?.CatalogFilters.filtersConfig
  );
  const [predefinedFilter, setPredefinedFilter] = React.useState('');
  const [filters, setFilters] = React.useState({ searchString: '' });
  const [listView, setListView] = React.useState('grid');
  const [searchTerm, setSearchTerm] = React.useState('');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [events, setEvents] = React.useState([]);
  const [allEvents, setAllEvents] = React.useState([]);
  const [eventTypeOptions, setEventTypeOptions] = React.useState([]);
  const [eventStatusesOptions, setEventStatusesOptions] = React.useState([]);

  const [activeFiltersMobile, setActiveFiltersMobile] = React.useState(false);
  const [showResetButtonIcon, setShowResetButtonIcon] = React.useState(false);
  const [shouldResetFilters, setShouldResetFilters] = React.useState(false);
  const [sortingOption, setSortingOption] = React.useState({
    name: 'startDateDesc',
    iconName: 'sort content descending',
    sortValue: 'startDate desc'
  });

  const resolveFilters = (filterObject = filters, filtersConfigName = filtersConfig) => {
    let filterParams = '';
    if (filterObject && filtersConfigName) {
      filterParams = getFiltersUrlPart(filters, filtersConfigName);
    }
    setShowResetButtonIcon(filterParams !== '');
    return filterParams;
  };

  // Get all events data to display data in filters for all events, not only for loaded events
  const [allEventsData, , , fetchAllEvents] = useFetching(createAllEventsService);

  const [eventData, isLoading, setIsLoading, fetchEvents] = useFetching(
    createEventService.bind(
      null,
      true,
      resolveFilters,
      filters,
      currentPage,
      filtersConfig,
      contextData,
      listView,
      searchTerm,
      sortingOption
    ),
    true
  );

  let history = useHistory();
  const [typesData, , ,] = useFetching(createEventTypesService);
  const [statusesData, , ,] = useFetching(createEventStatusesService);

  const searchCompRef = React.useRef(null);
  const searchMobRef = React.useRef(null);
  const filtersRef = React.useRef(null);
  const [originalFilterData, setOriginalFilterData] = React.useState(null);

  useEffect(() => {
    const eventListViewLocalStorage = window.localStorage.getItem('eventListView');
    setListView(eventListViewLocalStorage ? eventListViewLocalStorage : 'grid');
  }, []);

  useEffect(() => {
    if (contextData?.configuration) {
      setFiltersConfig(contextData?.configuration?.CatalogFilters.filtersConfig);
    }
  }, [contextData.configuration]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (filtersConfig) {
          let fltConfig = getInitialFilterConfiguration(filtersConfig);
          if (!Object.keys(predefinedFilter).length) {
            setFilters(fltConfig);
            setOriginalFilterData(fltConfig);
          } else {
            const filterValue =
              predefinedFilterValues.filters[Object.keys(predefinedFilter)[0]][
                Object.values(predefinedFilter)[0]
              ];
            let filtersInitConfig = cloneDeep(fltConfig);
            filtersInitConfig[Object.keys(predefinedFilter)[0]].push(filterValue);

            setFilters(filtersInitConfig);
            setOriginalFilterData(filtersInitConfig);
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersConfig, predefinedFilter]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size) {
          setIsLoading(true);
          fetchEvents();
          if (searchCompRef?.current?.inputRef?.current) {
            searchCompRef?.current.inputRef.current.scrollIntoView();
          }
          if (searchMobRef?.current?.inputRef?.current) {
            searchMobRef?.current.inputRef.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, contextData]);

  useEffect(() => {
    window.localStorage.setItem('eventListView', listView);
    setCurrentPage(1);
    const fetchData = async () => {
      try {
        fetchEvents();
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listView]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (allEventsData?.data?.events) {
          setAllEvents(allEventsData.data.events);
        }
        if (eventData?.data?.events) {
          setEvents(eventData.data.events);

          setTotalPages(
            Math.ceil(
              eventData.data.eventsCount /
                contextData?.configuration?.PAGINATION.eventsLimit[listView][
                  contextData.size.device.toLowerCase()
                ]
            )
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, listView, allEventsData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          typesData &&
          typesData.data &&
          typesData.data.types &&
          typesData.data.types.length &&
          statusesData &&
          statusesData.data &&
          statusesData.data.statuses &&
          statusesData.data.statuses.length &&
          eventData &&
          eventData.data &&
          eventData.data.events &&
          eventData.data.events.length
        ) {
          setEventTypeOptions(typesData.data.types);
          setEventStatusesOptions(statusesData.data.statuses);
          fetchAllEvents();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
  }, [typesData, statusesData, eventData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // after 3 characters are provided,
        // search will be executed
        if (searchTerm.length > 2 || searchTerm.length === 0) {
          setIsLoading(true);
          setCurrentPage(1);
          fetchEvents();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <MainEventGrid data-test-id={'eventsMainGridContainer'}>
      <EventsTab
        contextData={contextData}
        isLoading={isLoading}
        allEvents={allEvents}
        events={events}
        searchCompRef={searchCompRef}
        searchMobRef={searchMobRef}
        filtersRef={filtersRef}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fetchAllEvents={fetchAllEvents}
        fetchEvents={fetchEvents}
        filters={filters}
        setFilters={setFilters}
        originalFilterData={originalFilterData}
        listView={listView}
        setListView={setListView}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setIsLoading={setIsLoading}
        setShouldResetFilters={setShouldResetFilters}
        setPredefinedFilter={setPredefinedFilter}
        filtersConfig={filtersConfig}
        eventTypeOptions={eventTypeOptions}
        setEventTypeOptions={setEventTypeOptions}
        eventStatusesOptions={eventStatusesOptions}
        setEventStatusesOptions={setEventStatusesOptions}
        predefinedFilter={predefinedFilter}
        shouldResetFilters={shouldResetFilters}
        sortingOption={sortingOption}
        setSortingOption={setSortingOption}
        activeFiltersMobile={activeFiltersMobile}
        setActiveFiltersMobile={setActiveFiltersMobile}
        showResetButtonIcon={showResetButtonIcon}
        history={history}
      />
    </MainEventGrid>
  );
};

export default EventsMain;
