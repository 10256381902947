/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { Link } from 'react-router-dom';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

// TO DO: Change logic for initParticipantDetails when we get logged in user token
import { initParticipantDetails } from '@common/Constants';
import * as globalConstants from '@common/Constants';
import * as countriesCodes from '@common/Countries';
import Logger from '@common/Logger';
import { useGlobalContext } from '@hooks/Context/GlobalContext';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants';
import Agenda from '@pages/CourseDetails/Tabs/Agenda';
import Information from '@pages/CourseDetails/Tabs/Information';
import Overview from '@pages/CourseDetails/Tabs/Overview';
import * as constants from '@pages/EventDetails/Constants';
import DetailsMenuMobile from '@pages/EventDetails/DetailsMenuMobile';
import { createEventService } from '@pages/EventDetails/EventDetailsHelper';
import styles from '@pages/EventDetails/style/EventDetails.module.scss';
import tokenUtil from '@services/tokenUtil';

import ConfirmationPageDesktop from './Components/ConfirmationPageDesktop';
import CourseDetailsDesktop from './Components/CourseDetailsDesktop';
import {
  createAssignedCourseDetailsService,
  createLitmosUsersService,
  createUsersCoursesAndLPsService
} from './MainHelper';

const CourseDetails = ({ match, history }) => {
  const appUser = tokenUtil.getUser();
  const [registrationActive, setRegistrationActive] = useState(false);
  const [activeItem, setActiveItem] = useState(constants.tabOptions.overview);
  const [courseID, setCourseID] = useState(
    match && match.params && match.params.id ? match.params.id : ''
  );
  let previousPath = '';
  let previousPathName = '';
  const [contextData] = useGlobalContext();
  const [course, setCourse] = useState({});
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const [participantDetails, setParticipantDetails] = useState({
    ...initParticipantDetails,
    userName: appUser.email,
    bookingRole: appUser.role ? appUser.role.split(',') : []
  });
  const [disableRegistrationButton, setDisableRegistrationButton] = useState(true);
  const [courseDetailsType, setCourseDetailsType] = useState(undefined);
  const [registrationResponse, setRegistrationResponse] = useState([]);
  const [isResponseStatusLoading, setIsResponseStatusLoading] = useState(false);

  const redirectToHome = () => {
    return history.push({
      pathname: '/',
      state: {
        from: 'Home',
        path: '/'
      }
    });
  };

  const [unassignedCourseData, isUnassignedLoading, , fetchUnassignedCourse] = useFetching(
    createEventService.bind(null, courseID, 'course', redirectToHome),
    true
  );

  const [assignedCourseData, isAssignedLoading, , fetchAssignedCourse] = useFetching(
    createAssignedCourseDetailsService.bind(
      null,
      courseID,
      // 'tobias.rothley@contrimo.com', // TODO: remove temporary email when login is in function
      appUser.email,
      redirectToHome
    ),
    true
  );

  const [litmosUserData, isLitmosUserLoading, , fetchLitmosUser] = useFetching(
    createLitmosUsersService.bind(null, participantDetails.userName, redirectToHome),
    true
  );

  const [userCoursesAndLPsData, isUserCoursesAndLPsLoading, , fetchUserCoursesAndLPs] = useFetching(
    createUsersCoursesAndLPsService.bind(null, participantDetails.userName, redirectToHome),
    true
  );

  useEffect(() => {
    if (courseID && courseID.length) {
      previousPath === routerConstants.routes.name.catalog
        ? fetchUnassignedCourse()
        : fetchAssignedCourse();
      fetchLitmosUser();
      fetchUserCoursesAndLPs();
    }
  }, [courseID]);

  useEffect(() => {
    const fetchUserInformation = () => {
      try {
        if (litmosUserData?.data?.litmosUser?.id) {
          setParticipantDetails((prevData) => ({
            ...prevData,
            participantId: litmosUserData.data.litmosUser.id,
            name: litmosUserData.data.litmosUser.fullName
          }));
        }
        if (userCoursesAndLPsData?.data?.learningMaterial) {
          setParticipantDetails((prevData) => ({
            ...prevData,
            participantInfo: {
              courses: userCoursesAndLPsData.data.learningMaterial.courses,
              eLearnings: userCoursesAndLPsData.data.learningMaterial.eLearnings
            }
          }));
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchUserInformation();
  }, [litmosUserData, userCoursesAndLPsData]);

  useEffect(() => {
    const fetchCourse = () => {
      try {
        if (unassignedCourseData?.data?.course) {
          setCourse(unassignedCourseData.data.course);
          setDisableRegistrationButton(false);
          setCourseDetailsType(globalConstants.courseDetailsType.unassigned);
        } else if (assignedCourseData?.data?.course) {
          setCourse(assignedCourseData.data.course);
          setCourseDetailsType(globalConstants.courseDetailsType.assigned);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchCourse();
  }, [unassignedCourseData, assignedCourseData]);

  useEffect(() => {
    if (!courseID && match && match.params && match.params.id) {
      setCourseID(match.params.id);
    }
  }, [courseID, match]);

  const informationComponent = <Information course={course} config={contextData} />;
  const agendaComponent = <Agenda course={course} config={contextData} />;
  const overviewComponent = (
    <Overview course={course} config={contextData} courseDetailsType={courseDetailsType} />
  );

  const handleActiveComponent = (component) => {
    switch (component) {
      case constants.tabOptions.information:
        return informationComponent;
      case constants.tabOptions.agenda:
        return agendaComponent;
      case constants.tabOptions.attachments:
        return <></>;
      case constants.tabOptions.notes:
        return <></>;
      case constants.tabOptions.overview:
        return overviewComponent;
      default:
        return overviewComponent;
    }
  };

  const handleItemClick = (e, data) => {
    setActiveItem(data.name);
  };

  const handleRegisterButtonClick = () => {
    setRegistrationActive(!registrationActive);
  };

  if (history?.location?.state?.from) {
    previousPathName = history.location.state.from + ' /';
    previousPath = history.location.state.path;
  }

  return (
    <>
      {/* desktop && tablet layout*/}
      {!showConfirmationPage && (
        <CourseDetailsDesktop
          course={course}
          config={contextData}
          disableRegistrationButton={disableRegistrationButton}
          isLoading={
            isUnassignedLoading ||
            isAssignedLoading ||
            isLitmosUserLoading ||
            isUserCoursesAndLPsLoading
          }
          setShowConfirmationPage={setShowConfirmationPage}
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          handleActiveComponent={handleActiveComponent}
          handleRegisterButtonClick={handleRegisterButtonClick}
          handleItemClick={handleItemClick}
          registrationActive={registrationActive}
          activeItem={activeItem}
          courseDetailsType={courseDetailsType}
          history={history}
          setRegistrationResponse={setRegistrationResponse}
          setIsResponseStatusLoading={setIsResponseStatusLoading}
        />
      )}

      {/* Confirm page */}
      {courseDetailsType === globalConstants.courseDetailsType.unassigned &&
        showConfirmationPage && (
          <ConfirmationPageDesktop
            course={course}
            config={contextData}
            isLoading={isUnassignedLoading || isResponseStatusLoading}
            setShowConfirmationPage={setShowConfirmationPage}
            participantDetails={participantDetails}
            setParticipantDetails={setParticipantDetails}
            setRegistrationActive={setRegistrationActive}
            registrationResponse={registrationResponse}
          />
        )}

      {/* mobile layout */}
      <Grid centered container className={`mobile only ${styles.gridMobileTop}`}>
        {/* mobile layout - link */}
        <Grid.Row className={styles.navLinkContainerMobile}>
          <Link
            to={{
              pathname: previousPath,
              state: {
                from: previousPath,
                path: previousPath
              }
            }}
          >
            {previousPathName}
          </Link>
          <div className={styles.mobileLinkHolder}>{Localize.get('EventDetails.detailsInfo')}</div>
        </Grid.Row>

        {/* mobile layout - message*/}
        <Grid.Row only='mobile' centered className={styles.messageMobileContainer}>
          <Grid.Column width={16}>
            <Message
              data-testid={'courseDetailsMessageMobile'}
              style={{
                background: contextData?.configuration?.CourseDetailsMessage.background,
                boxShadow: contextData?.configuration?.CourseDetailsMessage.boxShadow
              }}
            >
              <Message.Header className={styles.messageHeaderStyle}>
                {course.DESCRIPTION}
              </Message.Header>
              <Message.Content className={styles.messageHeaderContainer}>
                <Grid>
                  <Grid.Column className={styles.messageTypeContainerMobile}>
                    {course.COURSE_TYPE_NAME}{' '}
                  </Grid.Column>
                  <Grid.Column>{course.COUNTRY_NAME && <Icon name='world' />}</Grid.Column>
                  <Grid.Column
                    textAlign='left'
                    className={`${styles.messageTypeContainerMobile} ${styles.messageHeaderStyle}`}
                  >
                    {course.COUNTRY_NAME}
                  </Grid.Column>
                  <Grid.Column>
                    <Flag
                      name={
                        course && course.COUNTRY_NAME
                          ? countriesCodes.countryCodeByCountry[course.COUNTRY_NAME.toLowerCase()]
                          : ''
                      }
                    />
                  </Grid.Column>
                </Grid>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile' centered>
          <Grid.Column width={15} stretched>
            <Button
              color={contextData?.configuration?.Registration?.stepperButtonColor}
              content={
                registrationActive ? Localize.get('App.close') : Localize.get('App.register')
              }
              className={`${styles.btnHeader} ${styles.btnMobile}`}
              onClick={handleRegisterButtonClick}
              disabled={true}
            />
          </Grid.Column>
        </Grid.Row>

        {!registrationActive && (
          <Grid.Row only='mobile' centered>
            <Grid.Column width={14} stretched className={styles.menuMobileCenter}>
              <DetailsMenuMobile
                activeItem={activeItem}
                handleItemClick={handleItemClick}
                entity={'course'}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {/* mobile layout - content*/}
        {!registrationActive &&
          contextData &&
          contextData.size &&
          contextData.size.device === globalConstants.deviceSize.mobile && (
            <Grid
              stackable
              container
              centered
              className={`mobile only ${styles.tabContainer}`}
              style={{ background: contextData?.configuration?.CourseDetailsContent.background }}
            >
              <Grid.Column width={12}>{handleActiveComponent(activeItem)}</Grid.Column>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default CourseDetails;
