import React from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';

const FormSubmitButton = (props) => {
  const { textAlign, handleSubmit, disabled, content, fluid } = props;
  return (
    <>
      <Grid.Column textAlign={textAlign}>
        <Button type='submit' fluid={fluid} onClick={handleSubmit} disabled={disabled}>
          {content}
        </Button>
      </Grid.Column>
    </>
  );
};

export default FormSubmitButton;
