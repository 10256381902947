export const employeesList = [
  {
    field: 'firstName',
    headerName: 'RegistrationForm.firstName',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false
  },
  {
    field: 'lastName',
    headerName: 'RegistrationForm.lastName',
    editable: false,
    defaultValue: '',
    headerAlign: 'left',
    sortable: false
  }
];
