import messages from '@common/MessagesObjects.js';

export const getTitle = (message) => {
  return message ? (message.code ? message.code : message) : 'somethingWentWrongMsg';
};

export const getContent = (messageTitle) => {
  return messages[messageTitle] ? messages[messageTitle] : 'somethingWentWrongMsgContent';
};

export const getColumnsCount = (size) => {
  let columnsCount = {
    computerColumnCount: 10
  };

  if (size && size.width && size.width > 991 && size.width < 1215) {
    columnsCount.computerColumnCount = 16;
  }
  return columnsCount;
};
