import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import EmployeeGridItem from '@components/EmployeeGridListView/EmployeeGridItem/EmployeeGridItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer';
import {
  EventListContainer,
  EventListItemStyled
} from '@components/StyledComponents/EventList.styled';

const EmployeeGridList = (props) => {
  const { employees, configuration, listColumnCount } = props;

  return (
    <>
      <Grid stackable columns={listColumnCount ? listColumnCount : 3}>
        <EventListContainer stretched>
          {employees && employees.length ? (
            employees.map((employee, index) => (
              <EventListItemStyled key={index}>
                <EmployeeGridItem
                  config={configuration}
                  employeeFirstName={employee.firstName}
                  employeeLastName={employee.lastName}
                  employeeEmail={employee.email}
                  employeePhoneWork={employee.phoneWork}
                  employeePhoneMobile={employee.phoneMobile}
                  employeeCoursesCount={employee.numberOfCourses}
                  employeeCertificatesCount={employee.numberOfCertificates}
                />
              </EventListItemStyled>
            ))
          ) : (
            <NoContentComponent
              data-test-id={'teamAreaEmployeesListNoContent'}
              infoType={Localize.get('TeamArea.noEmployees')}
              infoMessage={Localize.get('Catalog.selectNewCriteria')}
            />
          )}
        </EventListContainer>
      </Grid>
    </>
  );
};

export default EmployeeGridList;
