import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

import AppNavBar from '@components/AppNavBar/AppNavBar';
import AppNavBarMenuItems from '@components/AppNavBar/AppNavBarMenuItems';
import {
  HeaderGrid,
  HeaderGridColumn,
  LogoColumn,
  LogoContainer,
  IconFirstColumn,
  GreetingColumn,
  IconGreetingColumn,
  StyledDropDownMenu,
  GreetingDiv,
  GreetingDivWithOverflow
} from '@components/StyledComponents/Header.styled';
import * as routerConstants from '@navigation/Constants.js';

const DesktopHeader = (props) => {
  const { config, user, handleClick, handleLogoClick } = props;

  const [dropDownMenuOpen, setDropDownMenuOpen] = React.useState(false);
  const [, setVisible] = React.useState(false);

  const greeting = user ? `${Localize.get('App.greeting')}, ${user}` : Localize.get('App.greeting');

  const logoName = `${config.header.logo}`;

  return (
    <>
      {/* desktop layout */}
      <HeaderGrid configuration={config}>
        <LogoColumn
          only='computer'
          computer={3}
          largeScreen={3}
          widescreen={3}
          verticalAlign='middle'
        >
          <LogoContainer
            data-test-id={'appHeaderLogoImg'}
            src={require('../../assets/Images/' + logoName).default}
            size='small'
            onClick={handleLogoClick}
          />
        </LogoColumn>
        <HeaderGridColumn
          only='computer'
          computer={9}
          largeScreen={9}
          widescreen={10}
          floated='right'
        >
          <AppNavBar config={config} dropDownMenu={false} />
        </HeaderGridColumn>

        {config?.header?.userIconFirst ? (
          <>
            <IconGreetingColumn
              computer={4}
              largeScreen={4}
              widescreen={3}
              verticalAlign='middle'
              user={user}
            >
              <Grid columns={2} verticalAlign='middle'>
                <IconFirstColumn only='computer' verticalAlign='middle'>
                  <Icon
                    name={config?.header?.userIconName}
                    size={config?.header?.userIconSizeDesktop}
                  />
                </IconFirstColumn>
                <GreetingColumn only='computer' verticalAlign='middle'>
                  <GreetingDiv configuration={config}>
                    <Dropdown
                      text={user ? user : greeting}
                      icon={dropDownMenuOpen ? 'caret up' : 'caret down'}
                      closeOnBlur
                      onOpen={() => setDropDownMenuOpen(true)}
                      onClose={() => setDropDownMenuOpen(false)}
                    >
                      <StyledDropDownMenu configuration={config} direction='left'>
                        <AppNavBarMenuItems
                          setMenuVisible={setVisible}
                          configuration={config?.header?.userMenuItems}
                          dropDownMenu={true}
                          user={user}
                        />
                      </StyledDropDownMenu>
                    </Dropdown>
                  </GreetingDiv>
                </GreetingColumn>
              </Grid>
            </IconGreetingColumn>
          </>
        ) : (
          <>
            <HeaderGridColumn
              only='computer'
              computer={3}
              largeScreen={3}
              widescreen={2}
              verticalAlign='middle'
            >
              <GreetingDivWithOverflow>{greeting}</GreetingDivWithOverflow>
            </HeaderGridColumn>

            <HeaderGridColumn only='computer' width={1} verticalAlign='middle'>
              <Icon
                name={config?.header?.userIconName}
                size={config?.header?.userIconSizeDesktop}
                onClick={() => handleClick(routerConstants.routes.path.login)}
              />
            </HeaderGridColumn>
          </>
        )}
      </HeaderGrid>
    </>
  );
};
export default DesktopHeader;
