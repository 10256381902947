//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import styled, { css } from 'styled-components';

const active = css`
  border-top-color: #154194;
  border-bottom-color: #154194;
`;

const nonactive = css`
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 0.15rem;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 0.15rem;
  cursor: pointer;
  @media only screen and (max-width: 1919px) and (min-width: 991px) {
    padding-left: 0.3rem !important;
  }
`;

export const SwitchOptionsContainer = styled('div')`
  display: flex;
  justify-content: end;
`;

export const ListOptionContainer = styled(Grid.Column)`
  padding-right: 0 !important;
  padding-left: 0 !important;
  @media only screen and (max-width: 1919px) and (min-width: 991px) {
    padding-left: 1.5rem !important;
  }
`;

export const GridOptionContainer = styled(Grid.Column)`
  padding-left: 0 !important;
`;

export const SelectedOption = styled.div`
  ${nonactive}
  ${(props) =>
    props?.active &&
    css`
      ${active}
    `}
`;
