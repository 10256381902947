import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab';

import Logger from '@common/Logger';
import { MainCatalogGrid } from '@components/StyledComponents/Catalog.styled';
import {
  MyAreaContentContainer,
  MyAreaGridContainer
} from '@components/StyledComponents/MyArea.styled';
import { useGlobalContext } from '@hooks/Context/GlobalContext';
import useFetching from '@hooks/Fetching/Fetching';
import { createManagerEmployeesService } from '@pages/TeamArea/MainHelper';
import style from '@pages/TeamArea/style/TeamArea.module.scss';
import EmployeesTab from '@pages/TeamArea/Tabs/EmployeesTab';
import TrainingsTab from '@pages/TeamArea/Tabs/TrainingsTab';
import { HOERMANN_LINKS } from '@services/Constants';

const TeamArea = () => {
  const [contextData] = useGlobalContext();

  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPagesEmp, setTotalPagesEmp] = React.useState(0);

  const [employees, setEmployees] = React.useState([]);
  const [employeesCount, setEmployeesCount] = React.useState(0);

  const searchCompRefEmp = React.useRef(null);
  const searchMobRefEmp = React.useRef(null);

  const [employeesData, isLoading, setIsLoading, fetchEmployees] = useFetching(
    createManagerEmployeesService.bind(
      null,
      contextData?.user,
      currentPage,
      contextData,
      searchTerm
    ),
    true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size && activeTabIndex === 0) {
          setIsLoading(true);
          fetchEmployees();
          if (searchCompRefEmp?.current?.inputRef?.current) {
            searchCompRefEmp?.current.inputRef.current.scrollIntoView();
          }
          if (searchMobRefEmp?.current?.inputRef?.current) {
            searchMobRefEmp?.current.inputRef.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, contextData, activeTabIndex]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (employeesData?.data?.managerEmployees) {
          setEmployeesCount(employeesData.data.managerEmployeesCount);

          setEmployees(employeesData.data.managerEmployees);

          setTotalPagesEmp(
            Math.ceil(
              employeesData.data.managerEmployeesCount /
                contextData?.configuration?.PAGINATION.managerEmployeesLimit.grid[
                  contextData.size.device.toLowerCase()
                ]
            )
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesData, activeTabIndex]);

  const handleTeamAreaTabsChange = (e, data) => {
    // Redirecting to external link when clicks on tab Achievements
    if (data.activeIndex === 2) {
      window.open(HOERMANN_LINKS.teamAreaAchievements, '_blank');
    } else if (data.activeIndex === 3) {
      window.open(HOERMANN_LINKS.managersReportEngine, '_blank');
    } else {
      setActiveTabIndex(data.activeIndex);
    }
    // Reset search term on tab change
    if (searchTerm.length) {
      setSearchTerm('');
    }
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key='employeesTabTitle'>
          {Localize.get('TeamArea.employeesTabTitle')}
          <Label
            style={{
              background: contextData?.configuration?.tabLabel.background,
              color: contextData?.configuration?.tabLabel.color
            }}
          >
            {employeesCount}
          </Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane className={style.tabbedPane}>
          <EmployeesTab
            contextData={contextData}
            activeTabIndex={activeTabIndex}
            searchCompRef={searchCompRefEmp}
            searchMobRef={searchMobRefEmp}
            fetchEmployees={fetchEmployees}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            totalPages={totalPagesEmp}
            employees={employees}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setSearchTerm={setSearchTerm}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        <Menu.Item key='trainingsTabTitle'>{Localize.get('TeamArea.trainingsTabTitle')}</Menu.Item>
      ),
      render: () => (
        <Tab.Pane className={style.tabbedPane}>
          <TrainingsTab contextData={contextData} activeTabIndex={activeTabIndex} />
        </Tab.Pane>
      )
    },
    {
      menuItem: Localize.get('TeamArea.achievementsTabTitle'),
      render: () => <Tab.Pane className={style.tabbedPane} />
    },
    {
      menuItem: Localize.get('TeamArea.reportsTabTitle'),
      render: () => <Tab.Pane className={style.tabbedPane} />
    }
  ];

  return (
    <MainCatalogGrid data-test-id={'teamAreaGridContainer'}>
      <MyAreaContentContainer className={'tablet computer only'} centered>
        <Tab
          className={style.tabbedContent}
          activeIndex={activeTabIndex}
          panes={panes}
          menu={{ attached: false, widths: 4 }}
          onTabChange={handleTeamAreaTabsChange}
        />
      </MyAreaContentContainer>
      <MyAreaGridContainer centered className={'mobile only'}>
        <Tab
          className={style.tabbedContent}
          activeIndex={activeTabIndex}
          panes={panes}
          menu={{ attached: false, widths: 4, stackable: true }}
          onTabChange={handleTeamAreaTabsChange}
        />
      </MyAreaGridContainer>
    </MainCatalogGrid>
  );
};

export default TeamArea;
