import { useState, useEffect } from 'react';

const useRegistrationEventLogic = (testParticipantDetails, setRegistrationActive, setIsLoading) => {
  const [page, setPage] = useState(0);
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [participantDetails, setParticipantDetails] = useState(testParticipantDetails);
  const [stepperState, setStepperState] = useState({
    0: {
      completed: false,
      active: true,
      disabled: false
    },
    1: {
      completed: false,
      active: false,
      disabled: true
    },
    2: {
      completed: false,
      active: false,
      disabled: true
    },
    3: {
      completed: false,
      active: false,
      disabled: true
    }
  });

  const handlePageChange = (action) => {
    if (action === 'next') {
      if (page === 0) {
        setPage(1);
        setStepperState({
          ...stepperState,
          0: {
            // completed: true,
            active: false,
            disabled: false
          },
          1: {
            // completed: false,
            active: true,
            disabled: false
          }
        });
      }

      if (page === 1) {
        setPage(2);
        setStepperState({
          ...stepperState,
          1: {
            // completed: true,
            active: false,
            disabled: false
          },
          2: {
            // completed: false,
            active: true,
            disabled: false
          }
        });
      }

      if (page === 2) {
        setPage(3);
        setStepperState({
          ...stepperState,
          2: {
            // completed: true,
            active: false,
            disabled: false
          },
          3: {
            // completed: true,
            active: true,
            disabled: false
          }
        });
        setIsLoading(true);
        getLoaderTimeout();
      }

      if (page === 3) {
        setRegistrationActive(false);
      }
    }

    if (action === 'back') {
      if (page === 3) {
        setPage(2);
        setStepperState({
          ...stepperState,
          3: {
            // completed: false,
            active: false,
            disabled: false
          },
          2: {
            // completed: true,
            active: true,
            disabled: false
          }
        });
      }

      if (page === 2) {
        setPage(1);
        setStepperState({
          ...stepperState,
          2: {
            // completed: false,
            active: false,
            disabled: false
          },
          1: {
            // completed: true,
            active: true,
            disabled: false
          }
        });
      }

      if (page === 1) {
        setPage(0);
        setStepperState({
          ...stepperState,
          1: {
            // completed: false,
            active: false,
            disabled: false
          },
          0: {
            // completed: false,
            active: true,
            disabled: false
          }
        });
      }

      if (page === 0) {
        setPage(0);
        setStepperState({
          ...stepperState,
          0: {
            // completed: false,
            active: true,
            disabled: false
          }
        });
      }
    }
  };

  // remove after demo //
  const getLoaderTimeout = () => {
    setTimeout(() => {
      setIsLoading(false);
      setStepperState({
        ...stepperState,
        2: { ...stepperState[2], active: false },
        3: { active: true, completed: true, disabled: false }
      });
      setNextButtonEnabled(true);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(getLoaderTimeout);
    };
  }, []);

  return {
    stepperState,
    setStepperState,
    page,
    setPage,
    handlePageChange,
    participantDetails,
    setParticipantDetails,
    nextButtonEnabled,
    setNextButtonEnabled,
    getLoaderTimeout
  };
};

export default useRegistrationEventLogic;
