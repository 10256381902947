import React, { forwardRef } from 'react';

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import style from './style/Snackbar.module.scss';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function CustomizedSnackbar({
  open = false,
  handleClose = () => {},
  message = '',
  status = undefined,
  autoHideDuration = 2000
}) {
  return (
    <Stack spacing={2} className={style.stackContainer}>
      {status !== undefined && (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
          <Alert severity={status} onClose={handleClose}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
}
