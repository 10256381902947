import React, { Fragment, useEffect } from 'react';
import Localize from 'react-intl-universal';

import moment from 'moment';
import { Step, Button, Container, Icon } from 'semantic-ui-react';

import { courseType } from '@common/Constants';

import IltSessions from './components/IltSessions';
import ParticipantInformation from './components/ParticipantInformation';
import ParticipantInformationManager from './components/ParticipantInformationManager';
import SummaryPage from './components/SummaryPage';
import useRegistrationCourseLogic from './hooks/useRegistrationCourseLogic';
import style from './style/RegistrationCourse.module.scss';
import { bookingRole, steps } from '../Constants';

const RegistrationCourse = ({
  course,
  setShowConfirmationPage,
  config,
  participantDetails,
  setParticipantDetails,
  setRegistrationResponse,
  setIsResponseStatusLoading
}) => {
  const {
    stepperState,
    setStepperState,
    page,
    nextButtonEnabled,
    setNextButtonEnabled,
    handlePageChange,
    iltModulesSessions,
    numberOfModules,
    currentInnerStep,
    selectedIltSessionForModule,
    setSelectedIltSessionForModule,
    iltSessionsForModule,
    currentModule,
    finishedPrerequisites,
    registrationSnackbarState,
    setRegistrationSnackbarToInit,
    checkBookingStatusForParticipant,
    checkBookingStatusForTeamMembers
  } = useRegistrationCourseLogic(
    course,
    setShowConfirmationPage,
    participantDetails,
    setParticipantDetails,
    setRegistrationResponse,
    setIsResponseStatusLoading
  );

  const selectedAtLeastOneIltSession = () => {
    return Object.values(selectedIltSessionForModule).some((module) => module.sessionId !== null);
  };

  // If hotel is required arrival and departure dates needs to be filled.
  // If hotel is not required then dates are not needed
  const checkFulfilledDates = (arrivalDate, departureDate) => {
    const arrival = moment(arrivalDate);
    const departure = moment(departureDate);
    return (
      arrivalDate !== '' &&
      arrivalDate !== null &&
      departureDate !== '' &&
      departureDate !== null &&
      departure.isAfter(arrival)
    );
  };

  const checkParticipantsHotelRequired = (participant) => {
    const { hotelsRequired, dates } = participant;
    return hotelsRequired.every((isRequired, i) => {
      if (isRequired) {
        const { arrivalDate, departureDate } = dates[i];
        return checkFulfilledDates(arrivalDate, departureDate);
      }

      return true;
    });
  };

  const participantsHotelRequiredValidation = () => {
    return participantDetails.participants.length
      ? participantDetails.participants.every((participant) => {
          return checkParticipantsHotelRequired(participant);
        })
      : checkParticipantsHotelRequired(participantDetails);
  };

  const participantsAcceptParticipation = () => {
    return participantDetails.participants.length
      ? participantDetails.participants[participantDetails.participants.length - 1]
          .participantInfo !== undefined && participantDetails.acceptParticipation
      : participantDetails.acceptParticipation;
  };

  const participantAcceptPrivacyPolicyAndTermsAndConditions = () => {
    return participantDetails.privacyPolicyMessage && participantDetails.termsAndConditions;
  };

  const prerequisitesFinished = () => {
    if (participantDetails?.bookingRole?.includes(bookingRole.manager)) {
      const managerFinishedPrerequisites = finishedPrerequisites(
        participantDetails.participantInfo.courses,
        participantDetails.participantInfo.eLearnings
      );
      const participantsFinishedPrerequisites = participantDetails.participants.length
        ? participantDetails.participants.reduce((acc, participant) => {
            return participant.participantInfo !== undefined &&
              !finishedPrerequisites(
                participant.participantInfo.courses,
                participant.participantInfo.eLearnings
              )
              ? false
              : acc;
          }, true)
        : true;

      return managerFinishedPrerequisites && participantsFinishedPrerequisites;
    } else {
      return finishedPrerequisites(
        participantDetails.participantInfo.courses,
        participantDetails.participantInfo.eLearnings
      );
    }
  };

  useEffect(() => {
    let stepperStateNew = { ...stepperState };
    if (page === 0 && selectedAtLeastOneIltSession()) stepperStateNew[0].completed = true;
    else if (page === 1) {
      participantsAcceptParticipation() &&
      participantsHotelRequiredValidation() &&
      prerequisitesFinished()
        ? (stepperStateNew[1].completed = true)
        : (stepperStateNew[1].completed = false);
    } else if (page === 2) {
      participantAcceptPrivacyPolicyAndTermsAndConditions()
        ? (stepperStateNew[2].completed = true)
        : (stepperStateNew[2].completed = false);
    }
    let nextButtonEnabled;
    if (page === 0 && stepperStateNew[0].completed) nextButtonEnabled = true;
    else if (page === 1 && stepperStateNew[1].completed) nextButtonEnabled = true;
    else if (page === 2 && stepperStateNew[2].completed) nextButtonEnabled = true;

    setStepperState(stepperStateNew);
    setNextButtonEnabled(nextButtonEnabled);
  }, [page, selectedIltSessionForModule, participantDetails]);

  return (
    <div className={style.registrationContainer} data-testid={'RegistrationCourse'}>
      {page === 0 && (
        <IltSessions
          iltModulesSessions={iltModulesSessions}
          selectedIltSessionForModule={selectedIltSessionForModule}
          setSelectedIltSessionForModule={setSelectedIltSessionForModule}
          iltSessionsForModule={iltSessionsForModule}
          nonEditMode={false}
          currentModule={currentModule}
          config={config}
        />
      )}
      {page === 1 && (
        <ParticipantInformation
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          courseTypeName={course.COURSE_TYPE_NAME}
          config={config}
          finishedPrerequisites={finishedPrerequisites}
          checkBookingStatusForParticipant={checkBookingStatusForParticipant}
        />
      )}
      {page === 1 && participantDetails?.bookingRole?.includes(bookingRole.manager) && (
        <ParticipantInformationManager
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          courseTypeName={course.COURSE_TYPE_NAME}
          config={config}
          finishedPrerequisites={finishedPrerequisites}
          checkBookingStatusForTeamMembers={checkBookingStatusForTeamMembers}
        />
      )}
      {page === 2 && (
        <SummaryPage
          participantDetails={participantDetails}
          setParticipantDetails={setParticipantDetails}
          courseTypeName={course.COURSE_TYPE_NAME}
          config={config}
          registrationSnackbarState={registrationSnackbarState}
          setRegistrationSnackbarToInit={setRegistrationSnackbarToInit}
          checkBookingStatusForParticipant={checkBookingStatusForParticipant}
          checkBookingStatusForTeamMembers={checkBookingStatusForTeamMembers}
        />
      )}
      <Container className={style.stepperFooter}>
        {((page === 0 && currentInnerStep > 1) ||
          (page === 1 && course.COURSE_TYPE_NAME === Localize.get(`App.${courseType.course}`)) ||
          page === 2) && (
          <Button
            content={Localize.get('App.back')}
            floated='left'
            className={style.registerButton}
            onClick={() => handlePageChange(steps.back)}
          />
        )}
        <div className={style.stepperContainer}>
          <Step.Group stackable='tablet' size='tiny' attached='bottom'>
            {course.COURSE_TYPE_NAME === Localize.get(`App.${courseType.course}`) && (
              <Step
                completed={stepperState[0].completed}
                active={stepperState[0].active}
                disabled={stepperState[0].disabled}
              >
                <Step.Content>
                  <Step.Title>{Localize.get('RegistrationForm.dates')}</Step.Title>
                  <Step.Description>
                    {Localize.get('RegistrationForm.selectDateAndLocations')}
                  </Step.Description>
                  <Step.Description>
                    {' '}
                    {currentInnerStep}/{numberOfModules}{' '}
                  </Step.Description>
                </Step.Content>
              </Step>
            )}

            <Step
              completed={stepperState[1].completed}
              active={stepperState[1].active}
              disabled={stepperState[1].disabled}
            >
              <Step.Content>
                <Step.Title>{Localize.get('RegistrationForm.bookingFor')}</Step.Title>
                <Step.Description>{Localize.get('RegistrationForm.meAndMyTeam')}</Step.Description>
              </Step.Content>
            </Step>

            <Step
              completed={stepperState[2].completed}
              active={stepperState[2].active}
              disabled={stepperState[2].disabled}
            >
              <Step.Content>
                <Step.Title>{Localize.get('RegistrationForm.confirmation')}</Step.Title>
                <Step.Description>{Localize.get('RegistrationForm.summary')}</Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
        </div>
        <div>
          <Button
            content={
              page === 0 || page === 1
                ? Localize.get('App.next')
                : Localize.get('RegistrationForm.confirm')
            }
            className={style.registerButton}
            onClick={() => handlePageChange(steps.next)}
            disabled={!nextButtonEnabled}
          />
          {page === 0 && (
            <>
              <span
                className={style.bookLaterLabel}
                onClick={() => handlePageChange(steps.skip)}
                onKeyDown={() => {}}
                onKeyUp={() => {}}
                role='button'
                tabIndex={0}
              >
                {Localize.get('RegistrationForm.bookLater')}
              </span>
              <Icon
                name='question circle outline'
                size='large'
                className={style.registrationWarningColor}
              />
            </>
          )}
          {page === 1 && !prerequisitesFinished() && (
            <Fragment>
              <span className={style.removeParticipantWarning}>
                {Localize.get('RegistrationForm.removeParticipant')}
              </span>
              <Icon
                name='exclamation triangle'
                size='big'
                className={style.registrationPrerequisitesColor}
              />
            </Fragment>
          )}
        </div>
      </Container>
    </div>
  );
};

export default RegistrationCourse;
