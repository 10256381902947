import React from 'react';

import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header/Header';
import Item from 'semantic-ui-react/dist/commonjs/views/Item/Item';

import config from './termsAndConditionsDe.config.json';

const TermsAndConditionsDe = () => {
  return (
    <Grid textAlign='justified' centered padded data-testid={'TermsAndConditionsDe'}>
      <Grid.Column width={12}>
        <Header as='h1' color='blue'>
          {config.hoermann.heading}
        </Header>
        {config?.hoermann &&
          config.hoermann.paragraphs.map((paragraph, index) => (
            <Item.Group key={index} divided relaxed>
              <Item>
                <Item.Content verticalAlign='middle'>
                  <Item.Header floated='right'>{paragraph.heading}</Item.Header>
                  <Item.Description>{paragraph.content}</Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          ))}
      </Grid.Column>
    </Grid>
  );
};

export default TermsAndConditionsDe;
