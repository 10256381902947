import React from 'react';

import CourseItemContent from '@components/CourseGridListView/CourseGridItems/CourseItemContent';
import CourseItemFooter from '@components/CourseGridListView/CourseGridItems/CourseItemFooter';
import { StyledCard } from '@components/StyledComponents/EventItem.styled';

const CourseGridItem = (props) => {
  const {
    config,
    courseId,
    courseTypeName,
    courseLocation,
    onItemClick,
    image,
    language,
    startDate,
    endDate,
    courseName,
    price,
    courseTitle,
    progress
  } = props;

  return (
    <>
      <StyledCard
        data-testid={'courseGridItemContent'}
        fluid
        onClick={() => onItemClick && onItemClick(courseId, courseTypeName)}
      >
        {/* content */}
        <CourseItemContent
          config={config}
          image={image}
          courseTypeName={courseTypeName}
          courseName={courseName}
          langId={language}
          startDate={startDate}
          endDate={endDate}
          location={courseLocation}
          courseTitle={courseTitle}
        />
        {/* footer */}
        {config && config.footer && (
          <CourseItemFooter price={price} config={config} progress={progress} />
        )}
      </StyledCard>
    </>
  );
};

export default CourseGridItem;
