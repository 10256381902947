import React from 'react';

import EmployeeItemContent from '@components/EmployeeGridListView/EmployeeGridItems/EmployeeItemContent';
import EmployeeItemFooter from '@components/EmployeeGridListView/EmployeeGridItems/EmployeeItemFooter';
import { StyledCard } from '@components/StyledComponents/EventItem.styled';

const EmployeeGridItem = (props) => {
  const {
    config,
    employeeFirstName,
    employeeLastName,
    employeeEmail,
    employeePhoneWork,
    employeePhoneMobile,
    employeeCoursesCount,
    employeeCertificatesCount
  } = props;

  return (
    <>
      <StyledCard data-test-id={'employeeGridItemContent'} fluid onClick={null}>
        {/* content */}
        <EmployeeItemContent
          firstName={employeeFirstName}
          lastName={employeeLastName}
          email={employeeEmail}
          phoneWork={employeePhoneWork}
          phoneMobile={employeePhoneMobile}
        />
        {/* footer */}
        {config && config.footer && (
          <EmployeeItemFooter
            config={config}
            coursesCount={employeeCoursesCount}
            certificatesCount={employeeCertificatesCount}
          />
        )}
      </StyledCard>
    </>
  );
};

export default EmployeeGridItem;
