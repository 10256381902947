import React, { Fragment } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import * as globalConstants from '@common/Constants';
import { registrationStatuses } from '@common/Constants';
import { formatDateDMYHMWithDayName, getIltSessionPrice, getSessionLocation } from '@common/Util';

import style from './style/SessionCard.module.scss';

const SessionDescription = (props) => {
  const { itemNo, session, config, courseDetailsType, userAlreadyRegistered } = props;
  let isNotCompletedParticipant = false;
  const sessionRegistered = session.sessionParticipantDtos?.find(
    (item) => item.status?.key === registrationStatuses.registered
  );

  if (session.sessionParticipantDtos?.length) {
    isNotCompletedParticipant = session.sessionParticipantDtos.find(
      (item) => item.status?.key !== globalConstants.registrationStatuses.completed
    );
  }

  // Check if cancellation process is allowed
  const cancellationAllowed =
    userAlreadyRegistered &&
    !!isNotCompletedParticipant &&
    session?.hasCancellationDeadline &&
    new Date(session?.cancellationDeadline) > new Date();

  const getSeminarNumber = () => {
    return session?.seminarNumber !== 'undefined'
      ? session.seminarNumber
      : Localize.get('App.missingField');
  };

  return (
    <Grid.Row data-testid={'courseSessionDescriptionDateContainer'} key={itemNo}>
      <Grid.Column width={5}>
        <Grid.Row>
          <Grid.Column>
            {formatDateDMYHMWithDayName(new Date(session?.startDateTime))}
            {' - '}
            {formatDateDMYHMWithDayName(new Date(session?.endDateTime))}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>{`${getSessionLocation(session)} | ${getSeminarNumber()}`}</Grid.Column>
        </Grid.Row>
      </Grid.Column>
      {courseDetailsType === globalConstants.courseDetailsType.unassigned && (
        <Fragment>
          <Grid.Column width={1} textAlign={'right'}>
            <Icon
              name={config?.configuration?.header?.userIconName}
              size={config?.configuration?.header?.userIconSizeDesktop}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Grid.Row>
              <Grid.Column>
                {`${Localize.get('CourseDetails.Seats')} ${session?.availableSeats}`}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {session?.hasWaitingList && (
                <Grid.Column>{Localize.get('CourseDetails.waitingList')}</Grid.Column>
              )}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={4}>
            <Grid.Row>
              {config?.configuration?.CourseDetailsContent?.showBookingUntil &&
                session?.hasRegistrationPeriod && (
                  <Grid.Column>
                    {`${Localize.get('CourseDetails.bookableUntil')} ${formatDateDMYHMWithDayName(
                      new Date(session?.registrationUntil)
                    )}`}
                  </Grid.Column>
                )}
            </Grid.Row>
            <Grid.Row>
              {session?.hasCancellationDeadline && (
                <Grid.Column>
                  {`${Localize.get('CourseDetails.cancellableUntil')} ${formatDateDMYHMWithDayName(
                    new Date(session?.cancellationDeadline)
                  )}`}
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={3}>
            {`${Localize.get('CourseDetails.price')} ${getIltSessionPrice(session)}`}
          </Grid.Column>
        </Fragment>
      )}

      {courseDetailsType === globalConstants.courseDetailsType.assigned &&
        (userAlreadyRegistered ? (
          <Fragment>
            <Grid.Column width={4}>
              <Grid.Row>
                {`${Localize.get('CourseDetails.price')} ${getIltSessionPrice(session)}`}
              </Grid.Row>
              <Grid.Row>
                {`${Localize.get('CourseDetails.status')} ${sessionRegistered?.status.value}`}
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={3} className={style.textBlock}>
              <Grid>
                <Grid.Column width={1} verticalAlign='middle'>
                  <Icon name='info circle' />
                </Grid.Column>
                {cancellationAllowed ? (
                  <Grid.Column width={13} textAlign='center'>
                    <Grid.Row>
                      {`${Localize.get('CourseDetails.possibleCancellationDeadline')} `}
                    </Grid.Row>
                    <Grid.Row>
                      {`${formatDateDMYHMWithDayName(new Date(session?.cancellationDeadline))}`}
                    </Grid.Row>
                  </Grid.Column>
                ) : (
                  <Grid.Column width={13} textAlign='center'>
                    {`${Localize.get('CourseDetails.cancellationDeadlinePassed')} ${Localize.get(
                      'CourseDetails.pleaseContact'
                    )} `}
                    <span className={style.emailLink}>
                      {config?.configuration?.CourseDetailsContent?.supportContactLink}
                    </span>
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Column>
            <Grid.Column width={2}>
              {/*TO DO: Cancellation process*/}
              <Button
                color={config?.configuration?.Registration?.stepperButtonColor}
                content={Localize.get('CourseDetails.cancel')}
                disabled={!cancellationAllowed}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              {/*TO DO: Edit process*/}
              <Button
                color={config?.configuration?.Registration?.stepperButtonColor}
                content={Localize.get('CourseDetails.edit')}
                disabled={!cancellationAllowed}
              />
            </Grid.Column>
          </Fragment>
        ) : (
          <Fragment>
            <Grid.Column width={7}>
              <Grid.Row>
                <Grid stackable>
                  <Grid.Column width={1}>
                    <Icon
                      name={config?.configuration?.header?.userIconName}
                      size={config?.configuration?.header?.userIconSizeDesktop}
                    />
                  </Grid.Column>
                  {config?.configuration?.CourseDetailsContent?.showSeats && (
                    <Grid.Column width={3}>{`${Localize.get('CourseDetails.Seats')} ${
                      session?.availableSeats
                    }`}</Grid.Column>
                  )}
                  {config?.configuration?.CourseDetailsContent?.showWaitingList &&
                    session?.hasWaitingList && (
                      <Grid.Column width={4}>
                        {Localize.get('CourseDetails.waitingList')}
                      </Grid.Column>
                    )}
                  {config?.configuration?.CourseDetailsContent?.showPrice && (
                    <Grid.Column width={6}>{`${Localize.get(
                      'CourseDetails.price'
                    )} ${getIltSessionPrice(session)}`}</Grid.Column>
                  )}
                </Grid>
              </Grid.Row>
              <Grid.Row>
                <Grid stackable>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      {config?.configuration?.CourseDetailsContent?.showBookingUntil &&
                        session?.hasRegistrationPeriod && (
                          <Grid.Column>{`${Localize.get(
                            'CourseDetails.bookableUntil'
                          )} ${formatDateDMYHMWithDayName(
                            new Date(session?.registrationUntil)
                          )}`}</Grid.Column>
                        )}
                      {config?.configuration?.CourseDetailsContent?.showCancellationDeadline &&
                        session?.hasCancellationDeadline && (
                          <Grid.Column>
                            {`${Localize.get(
                              'CourseDetails.cancellableUntil'
                            )} ${formatDateDMYHMWithDayName(
                              new Date(session?.cancellationDeadline)
                            )}`}
                          </Grid.Column>
                        )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row></Grid.Row>
                </Grid>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={2}>
              {/*TO DO: Registration process*/}
              <Button
                color={config?.configuration?.Registration?.stepperButtonColor}
                content={Localize.get('App.register')}
                disabled={true}
              />
            </Grid.Column>
            <Grid.Column width={2} />
          </Fragment>
        ))}
    </Grid.Row>
  );
};

export default SessionDescription;
