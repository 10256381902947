import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useHistory, useLocation } from 'react-router-dom';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';

import Logger from '@common/Logger.js';
import EventGridListInfinity from '@components/EventGridListView/EventGridList/EventGridListInfinity';
import EventGridList from '@components/EventGridListView/EventGridList/EventList';
import EventList from '@components/EventListView/EventList/EventList';
import EventListInfinity from '@components/EventListView/EventList/EventListInfinity';
import CheckboxFilterGroup from '@components/Filters/CheckboxFilterGroup';
import GridListSwitch from '@components/GridListSwitch/GridListSwitch';
import Pagination from '@components/Pagination/Pagination';
import Sorters from '@components/Sorters/Sorters';
import { SortersMobileRowContainer } from '@components/StyledComponents/Catalog.styled.js';
import {
  TrainingGridContainer,
  TrainingGridColumnContainer,
  TrainingListContainer,
  TrainingSearchColumnContainer,
  TrainingPaginationContainer,
  TrainingContentColumnContainer,
  TrainingContentRowContainer,
  TrainingContentListRowContainer,
  TrainingSearchRowContainer,
  StyledDimmer,
  StyledSidebar,
  FlexRowContainer,
  FilterSegment,
  FilterHeader,
  FilterContainerHeader,
  FilterContainerCloseIcon,
  FiltersMobileRowContainer,
  FilterSortIconsColumnContainer,
  FilterSortIconsContainer,
  FilterButtonsContainer,
  StyledFilterButtons
} from '@components/StyledComponents/Training.styled';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants';
import { getInitialFilterConfiguration } from '@pages/Catalog/Util';

import { createAllILTSessionsService } from '../../MyArea/MainHelper';
import { initFiltersState } from '../Constants';

const AllSessions = (props) => {
  const { contextData, overviewFilter, sortingOption, setSortingOption } = props;

  const location = useLocation();
  //we use same component on the Location Area page
  const isTrainerAreaPage = location.pathname === routerConstants.routes.path.trainerArea;
  const config = contextData?.configuration;

  const isInfinityScroll = isTrainerAreaPage
    ? config?.global.trainerSessionsInfinityScroll
    : config?.global.locationSessionsInfinityScroll;

  const initialFilter = isTrainerAreaPage
    ? config?.TrainerAreaAllSessionsFilters
    : config?.LocationAreaAllSessionsFilters;

  const initialFilterConfig = isTrainerAreaPage
    ? config?.TrainerAreaAllSessionsFilters?.filtersConfig
    : config?.LocationAreaAllSessionsFilters?.filtersConfig;

  const sessionsItems = isTrainerAreaPage
    ? config?.TrainerAllSessionsItem
    : config?.LocationAllSessionsItem;

  let history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1); // TO DO set to 0 after BE is finished
  const [allSessions, setAllSessions] = React.useState([]);
  const [filters, setFilters] = React.useState(initFiltersState);
  const [checkboxTypes, setCheckboxTypes] = React.useState([]);
  const [listView, setListView] = React.useState('grid');
  const [showFilters, setShowFilters] = React.useState(false);
  const [activeFiltersMobile, setActiveFiltersMobile] = React.useState(false);
  const [showResetButtonIcon] = React.useState(false);
  const [searchTerm] = React.useState('');
  const [originalFilterData, setOriginalFilterData] = React.useState(null);

  const [allILTSessionsData, isLoading, setIsLoading, fetchAllILTSessions] = useFetching(
    createAllILTSessionsService.bind(
      null,
      currentPage,
      contextData,
      listView,
      isTrainerAreaPage,
      sortingOption,
      filters
    ),
    true
  );

  const searchCompRef = React.useRef(null);
  const searchMobRef = React.useRef(null);

  useEffect(() => {
    const courseListViewLocalStorage = isTrainerAreaPage
      ? window.localStorage.getItem('trainerListView')
      : window.localStorage.getItem('locationListView');
    setListView(courseListViewLocalStorage ? courseListViewLocalStorage : 'grid');
    setCheckboxTypes(initialFilter?.leftColumn?.filters?.map(({ name }) => name));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (initialFilterConfig) {
          let fltConfig = getInitialFilterConfiguration(initialFilterConfig);
          setOriginalFilterData(fltConfig);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilterConfig]);

  useEffect(() => {
    if (overviewFilter) {
      fetchWithOverviewFilter();
    }
  }, [overviewFilter]);

  const fetchWithOverviewFilter = async () => {
    setFilters({ ...filters, sessions: overviewFilter.sessions, resetForm: false });
    handleCheckboxFilters(overviewFilter.sessions);
    await fetchAllILTSessions();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage && contextData?.size) {
          setIsLoading(true);
          fetchAllILTSessions();
          if (searchCompRef?.current?.inputRef?.current) {
            searchCompRef?.current.inputRef.current.scrollIntoView();
          }
          if (searchMobRef?.current?.inputRef?.current) {
            searchMobRef?.current.inputRef.current.scrollIntoView();
          }
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, contextData]);

  useEffect(() => {
    isTrainerAreaPage
      ? window.localStorage.setItem('trainerListView', listView)
      : window.localStorage.setItem('locationListView', listView);
    setCurrentPage(1);
    const fetchData = async () => {
      try {
        fetchAllILTSessions();
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listView]);

  const formatILTSessions = (iltSessions) => {
    if (iltSessions?.length) {
      return iltSessions.map((item) => ({
        ID: item.id,
        DESCRIPTION: item.name,
        EVENT_TYPE_NAME: item.type.key.toLowerCase(),
        EVENT_DESCRIPTION: item.location, // Location field
        EVENT_START: item.startDateTime,
        END_DATE: item.endDateTime
      }));
    }
    return [];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (allILTSessionsData?.data?.sessions) {
          setAllSessions(formatILTSessions(allILTSessionsData.data.sessions));

          setTotalPages(
            Math.ceil(
              allILTSessionsData.data.sessionsCount /
                contextData?.configuration?.PAGINATION.trainerAllILTSessionsLimit[listView][
                  contextData.size.device.toLowerCase()
                ]
            )
          );
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allILTSessionsData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // after 3 characters are provided,
        // search will be executed
        if (filters.searchString.length > 2 || filters.searchString.length === 0) {
          setIsLoading(true);
          setCurrentPage(1);
          fetchAllILTSessions();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.searchString]);

  const onSearchEvents = () => {
    setIsLoading(true);
    setCurrentPage(1);
    fetchAllILTSessions();
    setShowFilters(false);
  };

  const onResetFilters = () => {
    setIsLoading(true);
    setCurrentPage(1);
    setFilters({ searchString: '', sessions: '', resetForm: true });
    fetchAllILTSessions();
    setShowFilters(false);
  };

  const onSearchFieldChange = (e) => {
    if (e.key === 'Enter') {
      if (filters.searchString.length) {
        // Enter is pressed by user
        // and there is at least 1 character in search field
        setIsLoading(true);
        setCurrentPage(1);
        fetchAllILTSessions();
      }
    } else {
      const reg = /'/g;

      setFilters({
        ...filters,
        // eslint-disable-next-line quotes
        searchString: encodeURIComponent(e.target.value.replace(reg, "''").trim())
      });
    }
  };

  const handlersMain = {
    courseList: {
      onItemClick: (eventId, eventTypeName) => {
        history.push({
          pathname: routerConstants.routes.name.iltSessionDetails + '/' + eventId,
          state: {
            from: Localize.get('TrainerArea.allSessions'),
            path: isTrainerAreaPage
              ? routerConstants.routes.name.trainerArea
              : routerConstants.routes.name.locationArea,
            data: eventTypeName
          }
        });
      }
    }
  };

  const getContent = () => {
    if (contextData?.size?.device !== 'Mobile') {
      if (listView === 'grid') {
        if (isInfinityScroll) {
          return (
            <EventGridListInfinity
              events={allSessions}
              configuration={sessionsItems}
              pagination={contextData?.configuration?.PAGINATION.trainerAllILTSessionsLimit}
              listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
              onItemClick={handlersMain.courseList.onItemClick}
              fetchEvents={fetchAllILTSessions}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchTerm={searchTerm}
              filters={filters}
              originalFilterData={originalFilterData}
              sortingOption={sortingOption}
              listView={listView}
              deviceSize={
                contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
              }
            />
          );
        } else {
          return (
            <EventGridList
              events={allSessions}
              configuration={sessionsItems}
              listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
              onItemClick={handlersMain.courseList.onItemClick}
              infoType={Localize.get('TrainerArea.noSessions')}
            />
          );
        }
      } else {
        if (isInfinityScroll) {
          return (
            <EventListInfinity
              events={allSessions}
              configuration={sessionsItems}
              pagination={contextData?.configuration?.PAGINATION.trainerAllILTSessionsLimit}
              listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
              onItemClick={handlersMain.courseList.onItemClick}
              fetchEvents={fetchAllILTSessions}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              searchTerm={searchTerm}
              filters={filters}
              originalFilterData={originalFilterData}
              sortingOption={sortingOption}
              listView={listView}
              infoType={Localize.get('TrainerArea.noSessions')}
              deviceSize={
                contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
              }
            />
          );
        } else {
          return (
            <EventList
              events={allSessions}
              configuration={sessionsItems}
              listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
              onItemClick={handlersMain.courseList.onItemClick}
              infoType={Localize.get('TrainerArea.noSessions')}
            />
          );
        }
      }
    } else {
      if (isInfinityScroll) {
        return (
          <EventGridListInfinity
            events={allSessions}
            configuration={sessionsItems}
            pagination={contextData?.configuration?.PAGINATION.trainerAllILTSessionsLimit}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
            onItemClick={handlersMain.courseList.onItemClick}
            fetchEvents={fetchAllILTSessions}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            searchTerm={searchTerm}
            filters={filters}
            originalFilterData={originalFilterData}
            sortingOption={sortingOption}
            listView={listView}
            deviceSize={
              contextData?.size?.device ? contextData.size.device.toLowerCase() : 'computer'
            }
          />
        );
      } else {
        return (
          <EventGridList
            events={allSessions}
            configuration={sessionsItems}
            listColumnCount={contextData?.size && contextData.size.device === 'Computer' ? 4 : 3}
            onItemClick={handlersMain.courseList.onItemClick}
            noFlex={true} //just on mobile
          />
        );
      }
    }
  };

  const handleCheckboxFilters = (value) => {
    setFilters({ ...filters, sessions: value, resetForm: false });
    onSearchEvents();
  };

  return (
    <TrainingGridContainer data-testid={'sessionGridContainer'}>
      <TrainingGridColumnContainer
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout*/}
        {isLoading && (
          <StyledDimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </StyledDimmer>
        )}

        {/* desktop && tablet layout - menu and content*/}

        <TrainingListContainer className={'tablet computer only'} centered>
          <TrainingContentColumnContainer
            computer={16}
            largeScreen={16}
            widescreen={16}
            tablet={16}
          >
            <Sidebar.Pushable>
              <StyledSidebar
                animation='overlay'
                icon='labeled'
                onHide={() => setShowFilters(false)}
                visible={showFilters}
                width='wide'
              >
                <FilterSegment>
                  <FilterContainerHeader>
                    <FilterHeader>{Localize.get('Catalog.filters')}</FilterHeader>
                    <FilterContainerCloseIcon
                      name='close'
                      size='large'
                      onClick={() => setShowFilters(false)}
                    />
                  </FilterContainerHeader>
                  <CheckboxFilterGroup
                    title={Localize.get('TrainerArea.allSessions')}
                    page='TrainerArea'
                    defaultValue={filters.sessions}
                    types={checkboxTypes}
                    resetForm={filters.resetForm}
                    handleCheckboxFilters={handleCheckboxFilters}
                  />
                  <FilterButtonsContainer>
                    <StyledFilterButtons onClick={onResetFilters}>
                      {Localize.get('App.reset')}
                    </StyledFilterButtons>
                  </FilterButtonsContainer>
                </FilterSegment>
              </StyledSidebar>

              <Sidebar.Pusher dimmed={showFilters}>
                <Grid stackable container centered>
                  <TrainingContentRowContainer>
                    <Grid columns={3}>
                      <FilterSortIconsColumnContainer
                        computer={2}
                        largeScreen={2}
                        widescreen={1}
                        tablet={2}
                      >
                        <FilterSortIconsContainer>
                          <Icon
                            name='filter'
                            size='large'
                            onClick={() => setShowFilters(!showFilters)}
                          />

                          {contextData?.configuration && (
                            <Sorters
                              pageName={'TrainerArea'}
                              configuration={
                                contextData?.configuration?.TrainerAreaAllSessionsSorters
                              }
                              fetchEvents={fetchAllILTSessions}
                              currentSorter={sortingOption}
                              setCurrentSorter={setSortingOption}
                              setCurrentPage={setCurrentPage}
                              mobileVersion={false}
                              disabled={!isTrainerAreaPage}
                            />
                          )}
                        </FilterSortIconsContainer>
                      </FilterSortIconsColumnContainer>

                      <TrainingSearchColumnContainer
                        computer={12}
                        largeScreen={12}
                        widescreen={14}
                        tablet={12}
                      >
                        <Input
                          fluid
                          icon='search'
                          iconPosition='left'
                          placeholder={Localize.get('Catalog.searchPlaceholder')}
                          onKeyUp={(e) => onSearchFieldChange(e)}
                          ref={searchCompRef}
                        />
                      </TrainingSearchColumnContainer>
                      <Grid.Column computer={2} largeScreen={2} widescreen={1} tablet={2}>
                        <GridListSwitch activeOption={listView} setView={setListView} />
                      </Grid.Column>
                    </Grid>
                  </TrainingContentRowContainer>
                  <TrainingContentListRowContainer id='rooow'>
                    {getContent()}
                  </TrainingContentListRowContainer>
                </Grid>
                <TrainingPaginationContainer centered>
                  {!!totalPages && !isInfinityScroll && (
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                    />
                  )}
                </TrainingPaginationContainer>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </TrainingContentColumnContainer>
        </TrainingListContainer>

        {/* mobile layout - content*/}

        <TrainingListContainer
          centered
          className={'mobile only'}
          data-testid={'mobileSessionGridContainer'}
        >
          <Grid.Column width={15}>
            <TrainingSearchRowContainer>
              <Input
                fluid
                icon='search'
                iconPosition='left'
                placeholder={Localize.get('Catalog.searchPlaceholder')}
                onKeyUp={(e) => onSearchFieldChange(e)}
                ref={searchMobRef}
                disabled
              />
            </TrainingSearchRowContainer>
            <FiltersMobileRowContainer>
              <Accordion styled fluid>
                <Accordion.Title
                  data-testid={'trainingFiltersMobile'}
                  active={activeFiltersMobile}
                  onClick={() => setActiveFiltersMobile(!activeFiltersMobile)}
                >
                  <FlexRowContainer>
                    <FlexRowContainer>
                      <Icon name='caret right' />
                      <div>{Localize.get('Catalog.filters')}</div>
                    </FlexRowContainer>
                    {showResetButtonIcon && <Icon name='undo alternate' onClick={onResetFilters} />}
                  </FlexRowContainer>
                </Accordion.Title>
                <Accordion.Content active={activeFiltersMobile}>
                  <CheckboxFilterGroup
                    title={Localize.get('TrainerArea.allSessions')}
                    page='TrainerArea'
                    defaultValue={filters.sessions}
                    types={checkboxTypes}
                    resetForm={filters.resetForm}
                    handleCheckboxFilters={handleCheckboxFilters}
                  />
                  <FilterButtonsContainer>
                    <StyledFilterButtons onClick={onResetFilters}>
                      {Localize.get('App.reset')}
                    </StyledFilterButtons>
                  </FilterButtonsContainer>
                </Accordion.Content>
              </Accordion>
            </FiltersMobileRowContainer>
            <SortersMobileRowContainer>
              {contextData?.configuration && (
                <Sorters
                  pageName={'TrainerArea'}
                  configuration={contextData?.configuration?.TrainerAreaAllSessionsSorters}
                  fetchEvents={fetchAllILTSessions}
                  currentSorter={sortingOption}
                  setCurrentSorter={setSortingOption}
                  setCurrentPage={setCurrentPage}
                  mobileVersion={true}
                  disabled={!isTrainerAreaPage}
                />
              )}
            </SortersMobileRowContainer>
            <Grid.Row>{getContent()}</Grid.Row>
            <Grid.Row centered>
              <TrainingPaginationContainer centered>
                {!!totalPages && !isInfinityScroll && (
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    noSiblings={totalPages > 3}
                  />
                )}
              </TrainingPaginationContainer>
            </Grid.Row>
          </Grid.Column>
        </TrainingListContainer>
      </TrainingGridColumnContainer>
    </TrainingGridContainer>
  );
};

export default AllSessions;
