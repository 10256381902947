import React, { useState } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal/Modal';

import DragDropFiles from '@components/DragAndDropFile/DragAndDropFile';

import { uploadModalVisibilityItems } from './Constants';
import styles from './style/Attachments.module.scss';

const UploadModal = (props) => {
  const {
    open,
    setOpen,
    config,
    uploadFile,
    isEditMode,
    setIsEditMode,
    file,
    setFile,
    initialForm,
    attachForm,
    setAttachForm,
    editAttachment
  } = props;

  const [showFormError, setShowFormError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttachForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheck = (e, data) => {
    const { name, checked } = data;
    setAttachForm((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  const handleSave = () => {
    if (file) {
      if (isEditMode) {
        editAttachment();
        setIsEditMode(false);
      } else {
        uploadFile(attachForm, file);
      }
      setOpen(false);
      setAttachForm(initialForm);
      setFile(null);
      setShowFormError(false);
    } else {
      setShowFormError(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setShowFormError(false);
    setFile(null);
    setIsEditMode(false);
    setAttachForm(initialForm);
  };

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <Modal
      data-testid={'uploadAttachmentsModal'}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      className={styles.attachModal}
      dimmer='inverted'
    >
      <Modal.Header className={styles.centeredHeader}>
        {isEditMode
          ? Localize.get('TrainerAreaAttachmentTable.editAttachment')
          : Localize.get('TrainerAreaAttachmentTable.uploadNew')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor='first'>{Localize.get('TrainerAreaAttachmentTable.fileName')}</label>
            <input
              id='first'
              placeholder={Localize.get('TrainerAreaAttachmentTable.displayName')}
              name='displayName'
              onChange={handleChange}
              defaultValue={attachForm?.displayName}
            />
          </Form.Field>
          <label htmlFor='visibility' className={styles.visibilityLabel}>
            {Localize.get('TrainerAreaAttachmentTable.visibility')}
          </label>
          {uploadModalVisibilityItems.map((checkBoxItem) => (
            <Form.Field key={checkBoxItem?.name} id={checkBoxItem?.id} className={styles.check}>
              <Checkbox
                name={checkBoxItem?.name}
                label={Localize.get(`TrainerAreaAttachmentTable.${checkBoxItem?.label}`)}
                onChange={handleCheck}
                checked={attachForm[checkBoxItem.name]}
                disabled={checkBoxItem.disabled}
              />
            </Form.Field>
          ))}
          <DragDropFiles
            file={file}
            setFile={setFile}
            config={config}
            showFormError={showFormError}
            setShowFormError={setShowFormError}
            isEditMode={isEditMode}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions className={styles.modalAction}>
        <Button color='red' onClick={handleCancel} className={styles.modalButton}>
          {Localize.get('App.cancel').toUpperCase()}
        </Button>
        <Button color='blue' onClick={handleSave} className={styles.modalButton}>
          {Localize.get('App.save').toUpperCase()}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UploadModal;
