import React from 'react';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import Slider from '@components/SliderComponent/Slider.jsx';
import { PriceRow, PriceValueRow } from '@components/StyledComponents/Filters.styled';

const PriceFilter = (props) => {
  const { config, dataSource, onFilterChange } = props;

  const settings = {
    start: [dataSource.min, dataSource.max],
    min: dataSource.initMinPrice,
    max: dataSource.initMaxPrice,
    step: 1,
    onChange: (value, triggeredByUser) => {
      if (triggeredByUser.triggeredByUser) {
        onFilterChange(config, value);
      }
    }
  };

  const minPriceValueLabel = dataSource.min ? dataSource.min : settings.start[0];
  const maxPriceValueLabel = dataSource.max ? dataSource.max : settings.start[1];
  const device = config.device.join(' ');
  return (
    <>
      {settings.max && settings.min > 0 && (
        <PriceRow verticalAlign='bottom' only={device}>
          <Grid.Column computer={12} mobile={16} verticalAlign='bottom'>
            <PriceRow>
              <Grid.Column computer={12} mobile={16}>
                <Slider
                  data-test-id={'priceSlider'}
                  multiple
                  color='blue'
                  value={settings.start}
                  settings={settings}
                />

                <PriceValueRow>
                  <div>{minPriceValueLabel}</div>
                  <div>{maxPriceValueLabel}</div>
                </PriceValueRow>
              </Grid.Column>
            </PriceRow>
          </Grid.Column>
        </PriceRow>
      )}
    </>
  );
};
export default React.memo(PriceFilter);
