import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import styled from 'styled-components';

export const EventListContainer = styled(Grid.Row)`
  min-height: '100%';
`;

export const EventListMainContainer = styled(Grid)`
  margin-left: auto !important;
  margin-right: auto !important;
`;

export const EventListItemStyled = styled(Grid.Column)`
  padding-bottom: inherit;
  height: max-content !important;
  padding-right: 0 !important;
`;
