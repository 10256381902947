import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import AppNavBar from '@components/AppNavBar/AppNavBar';
import AppNavBarMenuItems from '@components/AppNavBar/AppNavBarMenuItems';
import {
  HeaderGrid,
  HeaderGridColumn,
  HeaderTabletUserDropdownColumn,
  HeaderTabletUserDropdownContainer,
  HeaderTabletNavBarColumn,
  LogoColumn,
  LogoContainer,
  StyledDropDownMenu,
  StyledDropDownText,
  GreetingDivWithUser
} from '@components/StyledComponents/Header.styled';
import * as routerConstants from '@navigation/Constants.js';

const TabletHeader = (props) => {
  const { config, user, handleClick, handleLogoClick } = props;
  const [dropDownMenuOpen, setDropDownMenuOpen] = React.useState(false);

  const greeting = user ? `${Localize.get('App.greeting')}, ${user}` : Localize.get('App.greeting');

  const logoName = `${config.header.logo}`;
  return (
    <>
      {/* tablet layout */}
      <HeaderGrid configuration={config}>
        <LogoColumn only='tablet' width={3} verticalAlign='middle'>
          <LogoContainer
            data-test-id={'appHeaderTabletLogoImg'}
            src={require('../../assets/Images/' + logoName).default}
            size='small'
            onClick={handleLogoClick}
          />
        </LogoColumn>
        <HeaderTabletNavBarColumn only='tablet' width={9} floated='left'>
          {user ? <AppNavBar config={config} dropDownMenu={false} /> : null}
        </HeaderTabletNavBarColumn>
        {config?.header?.userIconFirst ? (
          <>
            <HeaderTabletUserDropdownColumn
              only='tablet'
              width={4}
              verticalAlign='middle'
              textAlign='right'
            >
              <HeaderTabletUserDropdownContainer user={user}>
                <Icon
                  name={config?.header?.userIconName}
                  size={config?.header?.userIconSizeTablet}
                />
                <StyledDropDownText
                  configuration={config}
                  user={user}
                  text={user ? user : greeting}
                  icon={dropDownMenuOpen ? 'caret up' : 'caret down'}
                  closeOnBlur
                  onOpen={() => setDropDownMenuOpen(true)}
                  onClose={() => setDropDownMenuOpen(false)}
                >
                  <StyledDropDownMenu configuration={config} direction='left'>
                    <AppNavBarMenuItems
                      configuration={config?.header?.userMenuItems}
                      dropDownMenu={true}
                      user={user}
                    />
                  </StyledDropDownMenu>
                </StyledDropDownText>
              </HeaderTabletUserDropdownContainer>
            </HeaderTabletUserDropdownColumn>
          </>
        ) : (
          <>
            <HeaderGridColumn
              only='tablet'
              width={user ? 3 : 2}
              verticalAlign='middle'
              user={user}
              isgreeting='true'
              textAlign='right'
            >
              <GreetingDivWithUser user={user}>{greeting}</GreetingDivWithUser>
            </HeaderGridColumn>
            <HeaderGridColumn
              only='tablet'
              width={user ? 1 : 2}
              verticalAlign='middle'
              textAlign={user ? 'left' : 'center'}
            >
              <Icon
                name={config?.header?.userIconName}
                size={config?.header?.userIconSizeTablet}
                onClick={() => handleClick(routerConstants.routes.path.login)}
              />
            </HeaderGridColumn>
          </>
        )}
      </HeaderGrid>
    </>
  );
};
export default TabletHeader;
