import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';
import styled, { css } from 'styled-components';

import forwardAsProp from './forwardAsProp';

const noTopBottomPatting = css`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
`;

export const TrainingGridContainer = styled(Grid)`
  margin: auto !important;
`;

export const TrainingGridColumnContainer = styled(Grid.Column)`
  padding: 0 !important;
`;

export const TrainingListContainer = styled(TrainingGridContainer)`
  @media only screen and (max-width: 990px) and (min-width: 767px) {
    min-height: calc(100vh - 17rem);
  }
  @media only screen and (min-width: 991px) {
    min-height: 81vh;
  }
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    min-height: calc(100vh - 12rem);
  }
`;

export const TrainingPaginationContainer = styled(TrainingGridContainer)`
  @media only screen and (max-width: 766px) and (min-width: 280px) {
    padding-top: 2rem !important;
    margin-left: 0 !important;
  }
  @media only screen and (max-width: 1919px) and (min-width: 991px) {
    padding-bottom: 1rem !important;
  }
`;

export const TrainingContentColumnContainer = styled(Grid.Column)`
  padding: 0 !important;
`;

export const TrainingContentRowContainer = styled(Grid.Row)`
  @media only screen and (max-width: 2560px) and (min-width: 991px) {
    ${noTopBottomPatting}
  }
`;

export const TrainingContentListRowContainer = styled(TrainingContentRowContainer)`
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    min-height: 71vh;
  }
  @media only screen and (max-width: 1400px) and (min-width: 992px) {
    min-height: 67vh;
  }
  @media only screen and (max-width: 1599px) and (min-width: 1401px) {
    min-height: 68vh;
  }
  @media only screen and (max-width: 1919px) and (min-width: 1600px) {
    min-height: 71vh;
  }
  @media only screen and (max-width: 2560px) and (min-width: 1920px) {
    min-height: 71vh;
  }
  @media only screen and (max-height: 1300px) and (min-height: 1025px) {
    min-height: 76vh;
  }
  @media only screen and (min-height: 1301px) {
    min-height: 79vh;
  }
`;

export const TrainingSearchRowContainer = styled(Grid.Row)`
  padding-bottom: 1rem !important;
`;

export const TrainingSearchColumnContainer = styled(Grid.Column)`
  @media only screen and (max-width: 1919px) and (min-width: 991px) {
    padding-right: 0 !important;
  }
`;

export const StyledDimmer = styled(Dimmer)`
  @media only screen and (max-width: 2560px) and (min-width: 767px) {
    margin-top: -1rem !important;
  }
`;

export const FilterButtonsContainer = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
  column-gap: 1.5rem;
  @media only screen and (max-width: 766px) and (min-width: 280px) {
    padding-left: 0.8rem;
    padding-right: 0.4rem;
    margin-right: 0;
  }
`;

export const StyledFilterButtons = styled(Button)`
  width: 100%;
  background: #154194 !important;
  color: white !important;
`;

export const FlexRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FilterSegment = styled(Segment)`
  background: #f9f9f9 !important;
`;

export const FilterHeader = styled(Header)`
  color: #154194 !important;
`;

export const FilterContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 0.7rem;
`;

export const FilterContainerCloseIcon = styled(Icon)`
  color: gray;
  cursor: pointer;
`;

export const FilterSortIconsColumnContainer = styled(Grid.Column)`
  padding-bottom: 0 !important;
  padding-top: 1.5rem !important;
`;

export const FilterSortIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  cursor: pointer;
`;

export const FiltersMobileRowContainer = styled(Grid.Row)`
  padding-bottom: 1rem !important;
`;

export const StyledSidebar = forwardAsProp(styled(Sidebar)`
  padding-right: 1rem;
  padding-bottom: 2rem;
  background: white;
  overflow-x: hidden;
`);
