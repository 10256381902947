import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

export const createAssignedCourseDetailsService = (courseId, email, callBackError = null) => {
  if (courseId && email) {
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.courses}${courseId}/${serviceConstants.MEA_SERVER_CONFIGURATION.resource.assigned}${email}`,
      callBackError
    );
  }
};

export const createLitmosUsersService = (email, callBackError = null) => {
  if (email) {
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.litmosUsers}${email}`,
      callBackError
    );
  }
};

export const createUsersCoursesAndLPsService = (email, callBackError = null) => {
  if (email) {
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.usersCoursesAndLPs}${email}`,
      callBackError
    );
  }
};
