import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Item from 'semantic-ui-react/dist/commonjs/views/Item';

const Information = (props) => {
  const { course, config } = props;

  return (
    <Grid data-testid={'courseInformationDateContainer'} columns={1} stackable>
      <Message
        style={{
          background: config?.configuration?.CourseDetailsInfoMessage?.background,
          boxShadow: config?.configuration?.CourseDetailsInfoMessage?.boxShadow
        }}
      >
        <Message.Header
          style={{
            background: config?.configuration?.CourseDetailsInfoMessage?.background,
            boxShadow: config?.configuration?.CourseDetailsInfoMessage?.boxShadow
          }}
        >
          {Localize.get('CourseDetails.prerequisites')}
        </Message.Header>
        <Message.Content
          style={{
            paddingTop: config?.configuration?.CourseDetailsInfoContent?.paddingTop
          }}
        >
          <Grid.Column width={16}>
            <Segment data-testid={'courseInfoDateContainer'}>
              <Grid
                columns={16}
                style={{
                  boxShadow: config?.configuration?.CourseDetailsInfoContent.boxShadow
                }}
              >
                {course?.COURSE_PREREQUISITES?.COURSES.length &&
                  course.COURSE_PREREQUISITES?.COURSES.map((course) => (
                    <Grid.Row
                      key={course?.id}
                      style={{
                        background: config?.configuration?.CourseDetailsInfoContent?.background,
                        border: config?.configuration?.CourseDetailsInfoContent?.border
                      }}
                    >
                      <Grid.Column
                        width={2}
                        style={{
                          color: config?.configuration?.CourseDetailsInfoContent?.textColorCourse
                        }}
                      >
                        <Item.Content>
                          <Item.Description>
                            {Localize.get('CourseDetails.Course')}
                          </Item.Description>
                        </Item.Content>
                      </Grid.Column>
                      <Grid.Column width={14}>
                        <Item.Content>
                          <Item.Description>{course?.name}</Item.Description>
                        </Item.Content>
                      </Grid.Column>
                    </Grid.Row>
                  ))}
                {course?.COURSE_PREREQUISITES?.LEARNING_PATHS.length &&
                  course.COURSE_PREREQUISITES?.LEARNING_PATHS.map((learningPath) => (
                    <Grid.Row
                      key={learningPath?.id}
                      style={{
                        background: config?.configuration?.CourseDetailsInfoContent?.background,
                        border: config?.configuration?.CourseDetailsInfoContent?.border
                      }}
                    >
                      <Grid.Column
                        width={2}
                        style={{
                          color:
                            config?.configuration?.CourseDetailsInfoContent?.textColorLearningPath
                        }}
                      >
                        <Item.Content>
                          <Item.Description>
                            {Localize.get('CourseDetails.LearningPath')}
                          </Item.Description>
                        </Item.Content>
                      </Grid.Column>
                      <Grid.Column width={14}>
                        <Item.Content>
                          <Item.Description>{learningPath?.name}</Item.Description>
                        </Item.Content>
                      </Grid.Column>
                    </Grid.Row>
                  ))}
              </Grid>
            </Segment>
          </Grid.Column>
        </Message.Content>
      </Message>
    </Grid>
  );
};

export default Information;
