import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

// semantic
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import Logger from '@common/Logger.js';
import useFetching from '@hooks/Fetching/Fetching.js';
import { getService } from '@services/axiosUtil.js';
import * as serviceConstants from '@services/Constants';

import style from './style/EventTopics.module.scss';

const EventTopics = (props) => {
  const { eventId } = props;

  const createEventNotesService = async () => {
    return getService(
      `${serviceConstants.MEA_SERVER_CONFIGURATION.resource.eventTopics}${eventId}`
    );
  };

  const [notesData, isLoadingNotesData, , fetchNotesData] = useFetching(
    createEventNotesService,
    true
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (eventId) {
          fetchNotesData();
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  let note = '';
  if (notesData?.data?.notes) {
    note = notesData.data.notes[0].NOTE_TEXT;
  }

  return (
    <>
      {isLoadingNotesData && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}
      <Segment secondary className={style.topicStyle}>
        <Header as='h4'>{Localize.get('EventDetails.eventTopic')}</Header>
        {notesData?.data?.notes ? (
          <pre data-test-id={'topicContent'} className={style.topicFontStyle}>
            {note}
          </pre>
        ) : (
          <div data-test-id={'noTopicContainer'}>{Localize.get('EventDetails.noTopics')}</div>
        )}
      </Segment>
    </>
  );
};

export default EventTopics;
