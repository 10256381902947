import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Sidebar from 'semantic-ui-react/dist/commonjs/modules/Sidebar';

import AppNavBar from '@components/AppNavBar/AppNavBar';
import AppNavBarMenuItems from '@components/AppNavBar/AppNavBarMenuItems';
import {
  HeaderGridMobile,
  HeaderMobileGridColumn,
  StyledMobileSidebarPusher,
  StyledMobileSidebarSegment,
  StyledMenuItem,
  StyledMobileSidebar,
  GreetingDivContainer,
  StyledDropDownMenu,
  GreetingDiv,
  UserIcon,
  StyledDropDownText,
  HeaderDiv,
  ContentDiv
} from '@components/StyledComponents/Header.styled';
import * as routerConstants from '@navigation/Constants.js';

const MobileHeader = (props) => {
  const { config, user, content, handleClick } = props;
  const [visible, setVisible] = React.useState(false);
  const [dropDownMenuOpen, setDropDownMenuOpen] = React.useState(false);

  const handleMenuClick = () => {
    setVisible(!visible);
  };

  const closeMenu = () => {
    setVisible(false);
  };

  const greeting = user ? `${Localize.get('App.greeting')}, ${user}` : Localize.get('App.greeting');

  return (
    <>
      <HeaderGridMobile>
        <HeaderMobileGridColumn only='mobile' width={16}>
          <Sidebar.Pushable
            data-test-id={'appHeaderMobileSidebar'}
            as={StyledMobileSidebarSegment}
            style={{ background: config.global.background }}
          >
            <StyledMobileSidebar
              configuration={config}
              as={Menu}
              animation='overlay'
              inverted
              vertical
              onHide={closeMenu}
              visible={visible}
              width='thin'
            >
              {user ? (
                <AppNavBar setMenuVisible={setVisible} config={config} dropDownMenu={false} />
              ) : (
                ''
              )}
            </StyledMobileSidebar>

            <StyledMobileSidebarPusher>
              <HeaderDiv configuration={config}>
                <StyledMenuItem onClick={handleMenuClick}>
                  <Icon name='align justify' />
                  {Localize.get('AppNavBar.menuMobile')}
                </StyledMenuItem>

                {config?.header?.userIconFirst ? (
                  <>
                    <UserIcon name={config?.header?.userIconName} />
                    <GreetingDivContainer configuration={config}>
                      <StyledDropDownText
                        configuration={config}
                        text={user ? user : greeting}
                        icon={dropDownMenuOpen ? 'caret up' : 'caret down'}
                        closeOnBlur
                        onOpen={() => setDropDownMenuOpen(true)}
                        onClose={() => setDropDownMenuOpen(false)}
                      >
                        <StyledDropDownMenu configuration={config} direction='left'>
                          <AppNavBarMenuItems
                            configuration={config?.header?.userMenuItems}
                            dropDownMenu={true}
                            user={user}
                          />
                        </StyledDropDownMenu>
                      </StyledDropDownText>
                    </GreetingDivContainer>
                  </>
                ) : (
                  <>
                    <GreetingDivContainer user={user}>
                      <GreetingDiv user={user}>{greeting}</GreetingDiv>
                    </GreetingDivContainer>
                    <Icon
                      name={config?.header?.userIconName}
                      onClick={() => handleClick(routerConstants.routes.path.login)}
                    />
                  </>
                )}
              </HeaderDiv>
              <ContentDiv>{content}</ContentDiv>
            </StyledMobileSidebarPusher>
          </Sidebar.Pushable>
        </HeaderMobileGridColumn>
      </HeaderGridMobile>
    </>
  );
};
export default MobileHeader;
