import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Accordion from 'semantic-ui-react/dist/commonjs/modules/Accordion';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';
import styled, { css } from 'styled-components';

export const FiltersGrid = styled(Grid)`
  @media only screen and (max-width: 767px) {
    justify-content: center !important;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 990px) and (min-width: 767px) {
    ${(props) => props.customstyle?.tablet ?? {}}
  }
  @media only screen and (max-width: 1199px) and (min-width: 991px) {
    ${(props) => props.customstyle?.computer ?? {}}
  }
  @media only screen and (max-width: 1919px) and (min-width: 1200px) {
    ${(props) => props.customstyle?.large ?? {}}
  }
  @media only screen and (min-width: 1920px) {
    ${(props) => props.customstyle?.wide ?? {}}
  }
`;

export const FiltersColumnContainer = styled(Grid)`
  flex: 1;
  ${(props) =>
    props.margintop &&
    css`
      margin-top: ${props.margintop} !important;
    `}
  ${(props) =>
    props.justifycontent &&
    css`
      justify-content: ${props.justifycontent} !important;
    `}
`;

export const PriceRow = styled(Grid.Row)`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  @media only screen and (max-width: 767px) and (min-width: 280px) {
    padding-top: 0.7rem !important;
    padding-bottom: 0.5rem !important;
  }
`;

export const PriceValueRow = styled(PriceRow)`
  padding-top: 0.5rem !important;
  padding-bottom: 0 !important;
  display: flex;
  justify-content: space-between;
`;

export const StyledDropdown = styled(Dropdown)`
  ${(props) =>
    props.styledicon &&
    css`
      &&& .icon {
        float: right;
        padding-top: 0.5rem;
      }
      padding-right: 0.5rem !important;
    `}
`;

export const CalendarContainer = styled.div`
  display: flex;
  &&& .field {
    min-width: 100%;
  }

  &&& .field .input {
    min-width: 100%;
    cursor: pointer !important;
  }
  &&& .field .input > input {
    cursor: pointer !important;
  }
  &&& .clndr-cell-inrange {
    background-color: #2185d0 !important;
  }
`;

export const CheckBoxColumnContainer = styled(Grid.Column)`
  padding-bottom: 1rem !important;
  text-align: left;
`;

export const CheckBoxRowContainer = styled(Grid.Row)`
  padding-left: 1rem !important;
`;

export const ToggleRowContainer = styled(Grid.Row)`
  padding-top: 0 !important;
`;

export const ToggleColumnContainer = styled(Grid.Column)`
  padding-bottom: 1rem !important;
`;

export const AccordionTitle = styled(Accordion.Title)`
  color: gray !important;
  text-align: left !important;
`;
