import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Label from 'semantic-ui-react/dist/commonjs/elements/Label';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

import {
  formatDateStringToDateTimeObject,
  formatDateDMYHM,
  formatDateStringToDateObject,
  formatDateDMY
} from '@common/Util.js';

import EventTopics from './EventTopics.jsx';
import style from './style/Information.module.scss';

const Information = (props) => {
  const { event, config } = props;
  return (
    <Grid stackable container>
      <Grid.Column width={8} className={style.columnTextAlign}>
        <Grid className={`${style.mobileContainer}`}>
          <Grid.Column computer={16} mobile={16}>
            {config?.configuration?.EventDetailsInfoTab.items
              .sort((a, b) => a.order - b.order)
              .map((item, index) => {
                switch (item.name) {
                  case 'startDate':
                    return (
                      <Segment key={index} data-test-id={'infoDateContainer'} basic>
                        <Grid className={style.groupStyle}>
                          <Grid.Column className={style.columnStyle} computer={6} mobile={6}>
                            {Localize.get('EventDetails.startDate')}
                          </Grid.Column>
                          <Grid.Column only='computer' computer={1} />
                          <Grid.Column
                            verticalAlign='middle'
                            textAlign='left'
                            computer={9}
                            mobile={10}
                          >
                            {formatDateDMYHM(formatDateStringToDateTimeObject(event.EVENT_START))}
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    );
                  case 'endDate':
                    return (
                      <Segment basic key={index}>
                        <Grid className={style.groupStyle}>
                          <Grid.Column className={style.columnStyle} computer={6} mobile={6}>
                            {Localize.get('EventDetails.endDate')}
                          </Grid.Column>
                          <Grid.Column only='computer' computer={1} />
                          <Grid.Column
                            verticalAlign='middle'
                            textAlign='left'
                            computer={9}
                            mobile={10}
                          >
                            {formatDateDMYHM(formatDateStringToDateTimeObject(event.EVENT_END))}
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    );
                  case 'location':
                    return (
                      <Segment basic key={index}>
                        <Grid className={style.groupStyle}>
                          <Grid.Column className={style.columnStyle} computer={6} mobile={6}>
                            {Localize.get('App.location')}
                          </Grid.Column>
                          <Grid.Column only='computer' computer={1} />
                          <Grid.Column
                            verticalAlign='middle'
                            textAlign='left'
                            computer={9}
                            mobile={10}
                          >
                            {event.CITY && event.COUNTRY_NAME
                              ? `${event.CITY}, ${event.COUNTRY_NAME}`
                              : ''}
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    );
                  case 'venue':
                    return (
                      <Segment basic key={index}>
                        <Grid className={style.groupStyle}>
                          <Grid.Column className={style.columnStyle} computer={6} mobile={6}>
                            {Localize.get('EventDetails.venue')}
                          </Grid.Column>
                          <Grid.Column only='computer' computer={1} />
                          <Grid.Column
                            verticalAlign='middle'
                            textAlign='left'
                            computer={9}
                            mobile={10}
                          >
                            {event.EVENT_VENUES &&
                              event.EVENT_VENUES.length > 0 &&
                              event.EVENT_VENUES.map((venue, index) => (
                                <Grid.Row key={index}>{venue.name}</Grid.Row>
                              ))}
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    );
                  case 'address':
                    return (
                      <Segment basic key={index}>
                        <Grid className={style.groupStyle}>
                          <Grid.Column className={style.columnStyle} computer={6} mobile={6}>
                            {Localize.get('EventDetails.address')}
                          </Grid.Column>
                          <Grid.Column only='computer' computer={1} />
                          <Grid.Column
                            verticalAlign='middle'
                            textAlign='left'
                            computer={9}
                            mobile={10}
                          >
                            <Grid.Row>
                              {event.STREET} {event.NUMBER}
                            </Grid.Row>
                            <Grid.Row>
                              {event.POSTAL_CODE} {event.CITY}
                            </Grid.Row>
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    );
                  case 'lang':
                    return (
                      <Segment basic key={index}>
                        <Grid className={style.groupStyle}>
                          <Grid.Column className={style.columnStyle} computer={6} mobile={6}>
                            {Localize.get('App.lang')}
                          </Grid.Column>
                          <Grid.Column only='computer' computer={1} />
                          <Grid.Column
                            verticalAlign='middle'
                            textAlign='left'
                            computer={9}
                            mobile={10}
                          >
                            DE
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    );
                  case 'price':
                    if (event.EVENT_PRICE_LATEST && event.EVENT_PRICE_LATEST.length) {
                      return (
                        <Segment basic key={index}>
                          <Grid className={style.groupStyle}>
                            <Grid.Column className={style.columnStyle} computer={6} mobile={6}>
                              {Localize.get('EventDetails.price')}
                              <br />
                              <span className={style.spanPriceStyle}>
                                {Localize.get('EventDetails.validUntil')}{' '}
                                {formatDateDMY(formatDateStringToDateObject(event.EVENT_START))}
                              </span>
                            </Grid.Column>
                            <Grid.Column only='computer' computer={1} />
                            <Grid.Column
                              verticalAlign='middle'
                              textAlign='left'
                              computer={9}
                              mobile={10}
                            >
                              <Label as='a' basic color='yellow' className={style.priceLabelStyle}>
                                {event.EVENT_PRICE_LATEST +
                                  ' ' +
                                  Number()
                                    .toLocaleString(undefined, {
                                      style: 'currency',
                                      currency: event.EVENT_PRICE_CURRENCY
                                    })
                                    .slice(0, 1)}
                              </Label>
                            </Grid.Column>
                          </Grid>
                        </Segment>
                      );
                    }
                    break;
                }
              })}
          </Grid.Column>
        </Grid>
      </Grid.Column>
      <Grid.Column width={8} textAlign='left'>
        <EventTopics eventId={event.ID} />
      </Grid.Column>
    </Grid>
  );
};

export default Information;
