import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox';

const RadioButtonFilter = (props) => {
  const { config, onFilterChange, shouldResetFilters } = props;

  const [filterValue, setFilterValue] = React.useState('');

  useEffect(() => {
    if (shouldResetFilters) {
      setFilterValue('');
    }
  }, [shouldResetFilters]);

  const device = config.device.join(' ');

  const onChange = (e, data) => {
    setFilterValue(data.value);
    onFilterChange(config, data);
  };

  const getComponent = () => {
    return (
      <>
        <Grid.Column data-test-id={'radioButtonFilterContainer'}>
          <Grid>
            <Grid.Row only={`${device}`}>
              <Grid.Column tablet={8} computer={6}>
                <Checkbox
                  fitted
                  radio
                  label={Localize.get('App.yes')}
                  name='checkboxRadioGroup'
                  value={Localize.get('App.yes')}
                  checked={filterValue === Localize.get('App.yes')}
                  onChange={onChange}
                  disabled={config.disabled}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Checkbox
                  fitted
                  radio
                  label={Localize.get('App.no')}
                  name='checkboxRadioGroup'
                  value={Localize.get('App.no')}
                  checked={filterValue === Localize.get('App.no')}
                  onChange={onChange}
                  disabled={config.disabled}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </>
    );
  };

  return getComponent();
};
export default React.memo(RadioButtonFilter);
