import React from 'react';

import moment from 'moment';
import 'moment/locale/de'; // without this line it didn't work
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import style from './style/AgendaTimelineMenu.module.scss';

const lang = navigator.language;
moment.locale(lang);

const AgendaTimelineMenu = (props) => {
  const {
    menuItems,
    activeItem,
    setActiveItem,
    updateMenuIndexes,
    disabledArrow,
    disableBackArrow,
    disableLastArrow
  } = props;

  const goToFirstItem = (
    <Menu.Item
      key='first'
      name='first'
      active={false}
      disabled={disabledArrow || disableBackArrow}
      onClick={(e, data) => onMenuNavigationItem(e, data)}
    >
      <Icon name='angle double left' />
    </Menu.Item>
  );

  const goBackItem = (
    <Menu.Item
      key='back'
      name='back'
      active={false}
      disabled={disabledArrow || disableBackArrow}
      onClick={(e, data) => onMenuNavigationItem(e, data)}
    >
      <Icon name='angle left' />
    </Menu.Item>
  );

  const goToLastItem = (
    <Menu.Item
      key='last'
      name='last'
      active={false}
      disabled={disabledArrow || disableLastArrow}
      onClick={(e, data) => onMenuNavigationItem(e, data)}
    >
      <Icon name='angle double right' />
    </Menu.Item>
  );

  const goForwardItem = (
    <Menu.Item
      key='forward'
      name='forward'
      active={false}
      disabled={disabledArrow || disableLastArrow}
      onClick={(e, data) => onMenuNavigationItem(e, data)}
    >
      <Icon name='angle right' />
    </Menu.Item>
  );

  const onDateClick = (e, data) => {
    if (e && e.target) {
      setActiveItem(data.name);
    }
  };

  const onMenuNavigationItem = (e, data) => {
    updateMenuIndexes(data.name);
  };

  let items = [];

  if (menuItems && menuItems.length && activeItem) {
    items.push(goToFirstItem);
    items.push(goBackItem);
    menuItems.forEach((menuItem) => {
      items.push(
        <Menu.Item
          key={menuItem}
          name={menuItem}
          active={menuItem === activeItem}
          onClick={(e, data) => onDateClick(e, data)}
          className={style.menuItem}
        >
          {moment(menuItem).format('ddd MMM D')}
        </Menu.Item>
      );
    });

    items.push(goForwardItem);
    items.push(goToLastItem);
  }

  return (
    <>
      <Menu
        data-testid={'agendaMenu'}
        pointing
        compact
        items={items}
        className={style.menuMobileResolutions}
      />
    </>
  );
};

export default AgendaTimelineMenu;
