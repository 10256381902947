import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import CourseGridItem from '@components/CourseGridListView/CourseGridItem/CourseGridItem';
import NoContentComponent from '@components/NoContentComponent/NoContentComponentContainer';

const CourseGridList = (props) => {
  const { courses, configuration, onItemClick, listColumnCount } = props;

  return (
    <>
      <Grid stackable columns={listColumnCount ? listColumnCount : 3}>
        {courses && courses.length ? (
          courses.map((course, index) => (
            <Grid.Column key={index}>
              <CourseGridItem
                config={configuration}
                courseId={course.ID}
                courseName={course.DESCRIPTION}
                courseTypeName={course.COURSE_TYPE_NAME}
                courseLocation={course.COURSE_LOCATIONS}
                onItemClick={onItemClick}
                language={course.LANGUAGE}
                startDate={course.START_DATE}
                endDate={course.END_DATE}
                price={course.COURSE_PRICE}
                image={course.COURSE_IMAGE_URL}
                courseTitle={course.TITLE}
                progress={course.PROGRESS}
              />
            </Grid.Column>
          ))
        ) : (
          <NoContentComponent
            data-testid={'coursesGridListNoContent'}
            infoType={Localize.get('Catalog.noCourses')}
            infoMessage={Localize.get('Catalog.selectNewCriteria')}
          />
        )}
      </Grid>
    </>
  );
};

export default CourseGridList;
