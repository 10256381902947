import React from 'react';

//semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';

import style from './style/NoContentComponent.module.scss';

const NoContentComponent = (props) => {
  const { infoType, infoMessage } = props;

  return (
    <Segment secondary>
      <Grid>
        <Grid.Row>
          <Grid.Column computer={2} mobile={2} verticalAlign='middle' textAlign='center'>
            <Icon name='info' size='large' />
          </Grid.Column>
          <Grid.Column
            computer={13}
            mobile={12}
            verticalAlign={infoMessage ? 'bottom' : 'middle'}
            textAlign='left'
          >
            <Header data-testid={'noContentHeader'} as='h5' className={style.headerMargin}>
              {infoType}
            </Header>
            {infoMessage && <div className={style.messageFont}>{infoMessage}</div>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
export default NoContentComponent;
