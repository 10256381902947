export const tabOptions = {
  overview: 'overview',
  allSessions: 'allSessions'
};

export const tabMenuItems = [
  {
    test: 'overviewTabMenuItem',
    name: tabOptions.overview
  },
  {
    test: 'allSessions',
    name: tabOptions.allSessions
  }
];
