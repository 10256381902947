import React from 'react';
import Localize from 'react-intl-universal';
import { useHistory } from 'react-router-dom';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

import {
  MyAreaGridContainer,
  MyAreaColumnContentContainer,
  MyAreaGridColumnContainer,
  MyAreaRowContentContainer,
  MyAreaContentContainer,
  MyAreaMobileMenu,
  StyledTabMenu
} from '@components/StyledComponents/MyArea.styled';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import { HOERMANN_LINKS } from '@services/Constants';

import * as constants from './Constants';
import AllSessions from './Tabs/AllSessions';
import Overview from './Tabs/Overview';
import TabMenuItems from './TrainerAreaTabItems';

const TrainerArea = () => {
  const [contextData] = useGlobalContext();

  let history = useHistory();
  const [activeItem, setActiveItem] = React.useState(
    history?.location?.state?.from === Localize.get('TrainerArea.allSessions')
      ? constants.tabOptions.allSesions
      : constants.tabOptions.overview
  );
  const [overviewFilter, setOverviewFilter] = React.useState('');
  const [sortingOption, setSortingOption] = React.useState({
    name: 'startDateTimeAsc',
    iconName: 'sort content ascending',
    sortValue: 'startDateTime asc'
  });

  const handleActiveComponent = (component) => {
    let setComponent;
    switch (component) {
      case constants.tabOptions.overview:
        setComponent = (
          <Overview
            contextData={contextData}
            handleOverviewFilterClick={handleOverviewFilterClick}
          />
        );
        break;
      case constants.tabOptions.allSesions:
        setComponent = (
          <AllSessions
            contextData={contextData}
            overviewFilter={overviewFilter}
            sortingOption={sortingOption}
            setSortingOption={setSortingOption}
          />
        );
        break;
      default:
        setComponent = null;
        break;
    }
    return setComponent;
  };

  const handleItemClick = (e, data) => {
    if (data.name === constants.tabOptions.achievementsAndCertificates) {
      window.open(HOERMANN_LINKS.achievementsAndCertificates, '_blank');
    } else {
      setOverviewFilter('');
      setActiveItem(data.name);
    }
  };

  const handleOverviewFilterClick = (filter) => {
    switch (filter) {
      case 'future':
        setActiveItem(constants.tabOptions.allSesions);
        setOverviewFilter({ sessions: 'futureSessions' });
        break;
      case 'past':
        setActiveItem(constants.tabOptions.allSesions);
        setOverviewFilter({ sessions: 'pastSessions' });
        break;
      case 'cancelled':
        setActiveItem(constants.tabOptions.allSesions);
        setOverviewFilter({ sessions: 'cancelledSessions' });
        break;
      default:
        break;
    }
  };

  return (
    <MyAreaGridContainer data-test-id={'trainerAreaGridContainer'}>
      <MyAreaGridColumnContainer
        computer={16}
        largeScreen={16}
        widescreen={16}
        tablet={16}
        mobile={16}
      >
        {/* desktop && tablet layout - menu and content*/}
        <MyAreaContentContainer className={'tablet computer only'} centered>
          <MyAreaColumnContentContainer computer={16} largeScreen={14} widescreen={10} tablet={16}>
            <StyledTabMenu pointing secondary>
              <TabMenuItems activeItem={activeItem} handleItemClick={handleItemClick} />
            </StyledTabMenu>

            <Grid stackable container centered>
              <MyAreaRowContentContainer>
                {handleActiveComponent(activeItem)}
              </MyAreaRowContentContainer>
            </Grid>
          </MyAreaColumnContentContainer>
        </MyAreaContentContainer>

        {/* mobile layout - content*/}
        <MyAreaGridContainer centered className={'mobile only'}>
          <Grid.Column width={16}>
            <Grid.Row>
              <Dropdown
                placeholder={Localize.get(`TrainerArea.${activeItem}`)}
                fluid
                className='ui selection'
              >
                <MyAreaMobileMenu>
                  <TabMenuItems activeItem={activeItem} handleItemClick={handleItemClick} />
                </MyAreaMobileMenu>
              </Dropdown>
            </Grid.Row>
            <Grid.Row>{handleActiveComponent(activeItem)}</Grid.Row>
          </Grid.Column>
        </MyAreaGridContainer>
      </MyAreaGridColumnContainer>
    </MyAreaGridContainer>
  );
};

export default TrainerArea;
