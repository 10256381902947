import React from 'react';

import CourseItemContent from '@components/CourseListView/CourseListItems/CourseItemContent';
import { StyledCardListView } from '@components/StyledComponents/EventItem.styled';

const CourseItem = (props) => {
  const {
    config,
    courseId,
    onItemClick,
    courseName,
    locationInfo,
    image,
    price,
    startDate,
    endDate,
    courseTypeName,
    courseTitle,
    progress
  } = props;

  return (
    <>
      <StyledCardListView
        data-testid={'courseListItemContent'}
        config={config}
        fluid
        onClick={() => onItemClick && onItemClick(courseId, courseTypeName)}
      >
        {/* content */}
        <CourseItemContent
          courseName={courseName}
          locationInfo={locationInfo}
          startDate={startDate}
          endDate={endDate}
          image={image}
          config={config}
          price={price}
          courseTypeName={courseTypeName}
          courseTitle={courseTitle}
          progress={progress}
        />
      </StyledCardListView>
    </>
  );
};

export default CourseItem;
