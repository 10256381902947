import React from 'react';

import EventItemContent from '@components/EventListView/EventListItems/EventItemContent';
import { StyledCardListView } from '@components/StyledComponents/EventItem.styled';
const EventItem = (props) => {
  const {
    eventId,
    eventTypeName,
    eventName,
    startDate,
    endDate,
    city,
    description,
    country,
    price,
    currency,
    maxParticipantsNo,
    regParticipantsNo,
    onItemClick,
    image, // TODO: after integration with litmos, image should part of the data
    config,
    combinedVisit,
    automaticConf,
    guidedReq,
    nameTag,
    eventStartString
  } = props;

  const customConfig = config;

  return (
    <>
      <StyledCardListView
        data-test-id={'eventListItemContent'}
        config={customConfig}
        fluid
        link
        onClick={onItemClick ? () => onItemClick(eventId, eventTypeName) : null}
      >
        {/* content */}
        <EventItemContent
          startDate={startDate}
          endDate={endDate}
          eventTypeName={eventTypeName}
          eventName={eventName}
          description={description}
          eventStartString={eventStartString}
          image={image}
          config={customConfig}
          price={price}
          currency={currency}
          city={city}
          country={country}
          maxParticipantsNo={maxParticipantsNo}
          regParticipantsNo={regParticipantsNo}
          combinedVisit={combinedVisit}
          automaticConf={automaticConf}
          guidedReq={guidedReq}
          nameTag={nameTag}
        />
      </StyledCardListView>
    </>
  );
};

export default EventItem;
