import React from 'react';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';

import { notesTypes } from '@pages/AllSession/Constants';
import SessionDetailsNote from '@pages/AllSession/SessionDetailsNote';
import style from '@pages/EventDetails/Tabs/style/Information.module.scss';

import { TRAINER_AREA_PATH } from '../Constants';

const Notes = (props) => {
  const { notesData, config, isLoading, previousPath } = props;
  return (
    <>
      <Grid.Column
        data-testid={'allSessionNotesTab'}
        computer={14}
        mobile={16}
        className={`${style.mobileContainer}`}
        style={{ background: config?.configuration?.AllSessionDetailsContent.background }}
      >
        <SessionDetailsNote
          notesData={notesData}
          isLoading={isLoading}
          noteType={previousPath === TRAINER_AREA_PATH ? notesTypes.trainer : notesTypes.location}
        />
      </Grid.Column>
    </>
  );
};

export default Notes;
