export const countryCodeByCountry = {
  germany: 'de',
  deutschland: 'de',
  serbia: 'rs',
  serbien: 'rs'
};

export const countryISOCodeByCountry = {
  de: 'de',
  en: 'gb eng',
  rs: 'rs'
};
