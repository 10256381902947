import React, { useState } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { Grid, Input, Checkbox, Button, Icon } from 'semantic-ui-react';

const initialParticipantInfo = {
  contact: '',
  hotelRequired: false,
  arrivalDate: '',
  departureDate: ''
};

const ParticipantInformationManager = ({
  participantDetails,
  setParticipantDetails,
  nonEditMode
}) => {
  const [participantInfo, setParticipantInfo] = useState(initialParticipantInfo);
  const [bookMyself, setBookMyself] = useState(false);
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  const addParticipant = () => {
    setParticipantDetails({
      ...participantDetails,
      participants: [...participantDetails.participants, participantInfo]
    });
    setParticipantInfo(initialParticipantInfo);
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column computer={1} tablet={3} textAlign={'left'}>
          Customer No:
        </Grid.Column>
        <Grid.Column computer={3} tablet={6} textAlign={'left'}>
          <Input disabled value={participantDetails.customerNo} size='mini' />
        </Grid.Column>
        <Grid.Column computer={4} tablet={6} textAlign={'center'}></Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column computer={1} tablet={3} textAlign={'left'}>
          Manager:
        </Grid.Column>
        <Grid.Column computer={3} tablet={6} textAlign={'left'}>
          <Input disabled value={participantDetails.managerName} size='mini' />
        </Grid.Column>
        <Grid.Column computer={4} tablet={6} textAlign={'center'}></Grid.Column>
      </Grid.Row>

      {participantDetails?.participants.length > 0 && (
        <Grid.Row>
          <Grid.Column computer={2} tablet={3} textAlign={'left'}>
            <b>Contact</b>
          </Grid.Column>
          <Grid.Column computer={2} tablet={3} textAlign={'left'}>
            <b>Hotel Required</b>
          </Grid.Column>
          <Grid.Column computer={2} tablet={4} textAlign={'left'}>
            <b>Arival Date</b>
          </Grid.Column>
          <Grid.Column computer={2} tablet={5} textAlign={'left'}>
            <b>Departure Date</b>
          </Grid.Column>
        </Grid.Row>
      )}

      {participantDetails?.participants &&
        participantDetails.participants.map((participant, index) => (
          <Grid.Row key={index}>
            <Grid.Column computer={2} tablet={3} textAlign={'left'}>
              <Input value={participant.contact} size='mini' />
            </Grid.Column>
            <Grid.Column computer={2} tablet={3} textAlign={'left'}>
              <Checkbox disabled={true} checked={participant.hotelRequired} />
            </Grid.Column>
            <Grid.Column computer={2} tablet={4} textAlign={'left'}>
              <SemanticDatepicker
                icon='calendar alternate outline'
                value={participant.arrivalDate}
                placeholder='--'
                locale={'en-US'}
                type='basic'
                datePickerOnly
                format='DD.MM.YYYY'
                showToday={false}
                size='mini'
                disabled={true}
                clearable={false}
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={5} textAlign={'left'}>
              <SemanticDatepicker
                icon='calendar alternate outline'
                value={participant.departureDate}
                placeholder='--'
                locale={'en-US'}
                type='basic'
                datePickerOnly
                format='DD.MM.YYYY'
                showToday={true}
                size='mini'
                disabled={true}
                clearable={false}
              />
              {!nonEditMode && (
                <Icon
                  name='archive'
                  onClick={() => {
                    setParticipantDetails({
                      ...participantDetails,
                      participants: [
                        ...participantDetails.participants.filter((participant, i) => i !== index)
                      ]
                    });
                  }}
                  style={{ marginLeft: '5px', color: 'red', cursor: 'pointer' }}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        ))}

      {!nonEditMode && (
        <>
          <Grid.Row>
            <Grid.Column computer={1}></Grid.Column>
            <Grid.Column
              computer={10}
              tablet={15}
              style={{ borderTop: '2px solid gray', marginTop: '30px' }}
            ></Grid.Column>
            <Grid.Column computer={1} tablet={1}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Button
              color='blue'
              content='Add participant'
              icon='add'
              labelPosition='left'
              onClick={addParticipant}
              disabled={participantInfo.contact === ''}
            />
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={7} tablet={10} textAlign={'left'}>
              <Checkbox
                label='I want to book myself also to this training'
                checked={bookMyself}
                onChange={(e, v) => {
                  setParticipantInfo({
                    ...participantInfo,
                    contact: participantDetails.managerName
                  });
                  setBookMyself(v.checked);
                }}
              />
            </Grid.Column>
            <Grid.Column computer={1} tablet={5}></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={2} tablet={3} textAlign={'left'}>
              <b>Contact:</b>
            </Grid.Column>
            <Grid.Column computer={2} tablet={3} textAlign={'left'}>
              <b>Hotel Required:</b>
            </Grid.Column>
            <Grid.Column computer={2} tablet={4} textAlign={'left'}>
              <b>Arrival date:</b>
            </Grid.Column>
            <Grid.Column computer={2} tablet={5} textAlign={'left'}>
              <b>Departure date:</b>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column computer={2} tablet={3} textAlign={'left'}>
              <Input
                value={participantInfo.contact}
                size='mini'
                onChange={(e, v) => setParticipantInfo({ ...participantInfo, contact: v.value })}
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={3} textAlign={'left'}>
              <Checkbox
                // label='Hotel required'
                disabled={nonEditMode}
                checked={participantInfo.hotelRequired}
                onChange={(e, v) =>
                  setParticipantInfo({ ...participantInfo, hotelRequired: v.checked })
                }
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={4} textAlign={'left'}>
              <SemanticDatepicker
                icon='calendar alternate outline'
                value={participantInfo.arrivalDate}
                placeholder='--'
                locale={'en-US'}
                minDate={minDate}
                onChange={(e, v) =>
                  setParticipantInfo({ ...participantInfo, arrivalDate: v.value })
                }
                type='basic'
                datePickerOnly
                format='DD.MM.YYYY'
                showToday={false}
                size='mini'
                disabled={!participantInfo.hotelRequired}
              />
            </Grid.Column>
            <Grid.Column computer={2} tablet={5} textAlign={'left'}>
              <SemanticDatepicker
                icon='calendar alternate outline'
                value={participantInfo.departureDate}
                minDate={participantInfo.arrivalDate ? participantInfo.arrivalDate : minDate}
                placeholder='--'
                locale={'en-US'}
                onChange={(e, v) =>
                  setParticipantInfo({ ...participantInfo, departureDate: v.value })
                }
                type='basic'
                datePickerOnly
                format='DD.MM.YYYY'
                showToday={false}
                size='mini'
                disabled={!participantInfo.hotelRequired}
              />
            </Grid.Column>
          </Grid.Row>
        </>
      )}

      {nonEditMode && (
        <>
          <Grid.Row>
            <Grid.Column computer={7} tablet={11} textAlign={'left'}>
              <Checkbox
                label='I accept the terms and conditions'
                checked={participantDetails.turnsAndConditions}
                onChange={(e, v) =>
                  setParticipantDetails({ ...participantDetails, turnsAndConditions: v.checked })
                }
              />
            </Grid.Column>
            <Grid.Column computer={1} tablet={4}></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column computer={7} tablet={11} textAlign={'left'}>
              <Checkbox
                label='I have consent of the people involved to book for them and to provide relevant personal data in accordance with the privacy policy. Data Protection'
                checked={participantDetails.personalTurnsAndConditions}
                onChange={(e, v) =>
                  setParticipantDetails({
                    ...participantDetails,
                    personalTurnsAndConditions: v.checked
                  })
                }
              />
            </Grid.Column>
            <Grid.Column computer={1} tablet={4}></Grid.Column>
          </Grid.Row>
        </>
      )}
    </>
  );
};

export default ParticipantInformationManager;
