import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

import * as constants from './Constants';
import { getMobileMenuItems } from './DetailsMobileHelper';
import style from '../EventDetails/style/DetailsMenuItems.module.scss';

const DetailsMobileMenu = (props) => {
  const { activeItem, handleItemClick, config, tabsToShow } = props;

  const [headerContent, setHeaderContent] = React.useState(
    <Menu.Item data-testid={'headerContentTrigger'}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}>
            <Icon name='info' />
          </Grid.Column>
          <Grid.Column width={10}>{Localize.get('EventDetails.information')}</Grid.Column>
          <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
            <Icon name='dropdown' />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Menu.Item>
  );

  const handleActiveComponent = (component) => {
    switch (component) {
      case constants.tabOptions.information:
        setHeaderContent(
          <Menu.Item disabled={!config?.AllSessionsTabs.information}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='info' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.information')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.agenda:
        setHeaderContent(
          <Menu.Item disabled={!config?.AllSessionsTabs.agenda}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='calendar outline' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.agenda')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.attachments:
        setHeaderContent(
          <Menu.Item disabled={!config?.AllSessionsTabs.attachments}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='attach' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.attachments')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.notes:
        setHeaderContent(
          <Menu.Item disabled={!config?.AllSessionsTabs.notes}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='sticky note' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.notes')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.participants:
        setHeaderContent(
          <Menu.Item disabled={!config?.AllSessionsTabs.participants}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='user' />
                </Grid.Column>
                <Grid.Column width={10}>
                  {Localize.get('TrainerAreaDetails.participants')}
                </Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.hotel:
        setHeaderContent(
          <Menu.Item disabled={!config?.AllSessionsTabs.hotel}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='bed' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('TrainerAreaDetails.hotel')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      default:
        setHeaderContent(
          <Menu.Item disabled={!config?.AllSessionsTabs.information}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='info' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.information')}</Grid.Column>
                <Grid.Column width={2}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
    }
  };

  const handleItemClickLocally = (e, data) => {
    handleActiveComponent(data.name);
    handleItemClick(null, data);
  };

  return (
    <>
      <Menu compact vertical borderless className={`${style.gridMobile} ${style.menuMobileStyle}`}>
        <Dropdown
          trigger={headerContent}
          className={`selection ${style.menuMobileStyle} ${style.menuCompactStyle}`}
          icon={null}
          fluid
          options={getMobileMenuItems(activeItem, handleItemClickLocally, config, tabsToShow)}
        />
      </Menu>
    </>
  );
};

export default DetailsMobileMenu;
