export const tabOptions = {
  overview: 'overview',
  allSesions: 'allSessions'
};

export const tabMenuItems = [
  {
    test: 'overviewTabMenuItem',
    name: tabOptions.overview
  },
  {
    test: 'allSessions',
    name: tabOptions.allSesions
  }
];

export const initFiltersState = {
  searchString: '',
  sessions: '',
  resetForm: false
};
