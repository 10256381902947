import React from 'react';

//semantic
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';

import {
  SwitchOptionsContainer,
  SelectedOption
} from '@components/StyledComponents/GridListSwitch.styled';

const GridListSwitch = (props) => {
  const { activeOption, setView } = props;

  return (
    <SwitchOptionsContainer columns='equal' data-testid='gridListSwitchContainer'>
      <SelectedOption active={activeOption === 'list' ? 1 : 0}>
        <Icon
          name='list'
          size='large'
          onClick={() => setView('list')}
          data-testid='gridListSwitchToList'
        />
      </SelectedOption>
      <SelectedOption active={activeOption === 'grid' ? 1 : 0}>
        <Icon
          name='grid layout'
          size='large'
          onClick={() => setView('grid')}
          data-testid='gridListSwitchToGrid'
        />
      </SelectedOption>
    </SwitchOptionsContainer>
  );
};

export default GridListSwitch;
