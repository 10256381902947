import React from 'react';
import Localize from 'react-intl-universal';

// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

import { entityTypes } from '@common/Constants';

import * as constants from './Constants';
import { getMenuItems } from './DetailsMenuMobileHelper.js';
import style from './style/DetailsMenuItems.module.scss';

const DetailsMenuMobile = (props) => {
  const { activeItem, handleItemClick, entity } = props;

  const [headerContent, setHeaderContent] = React.useState(
    <Menu.Item>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}>
            <Icon name='info' />
          </Grid.Column>
          <Grid.Column width={10}>{Localize.get('EventDetails.information')}</Grid.Column>
          <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
            <Icon name='dropdown' />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Menu.Item>
  );

  const handleActiveComponent = (component) => {
    switch (component) {
      case constants.tabOptions.information:
        setHeaderContent(
          <Menu.Item>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='info' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.information')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.agenda:
        setHeaderContent(
          <Menu.Item>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='calendar outline' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.agenda')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.attachments:
        setHeaderContent(
          <Menu.Item
            disabled={entity === entityTypes.course || entity === entityTypes.learningPath}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='attach' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.attachments')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.notes:
        setHeaderContent(
          <Menu.Item
            disabled={entity === entityTypes.course || entity === entityTypes.learningPath}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='sticky note' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.notes')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.sessions:
        setHeaderContent(
          <Menu.Item
            disabled={entity === entityTypes.course || entity === entityTypes.learningPath}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='list' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.sessions')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.speakers:
        setHeaderContent(
          <Menu.Item
            disabled={entity === entityTypes.course || entity === entityTypes.learningPath}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='user outline' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.speakers')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.hotels:
        setHeaderContent(
          <Menu.Item
            disabled={entity === entityTypes.course || entity === entityTypes.learningPath}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='hotel' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.hotels')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
      case constants.tabOptions.messages:
        setHeaderContent(
          <Menu.Item
            disabled={entity === entityTypes.course || entity === entityTypes.learningPath}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='comment outline' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.messages')}</Grid.Column>
                <Grid.Column width={2} floated='right' className={style.menuButtonDropdown}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;

      default:
        setHeaderContent(
          <Menu.Item>
            <Grid>
              <Grid.Row>
                <Grid.Column width={2}>
                  <Icon name='info' />
                </Grid.Column>
                <Grid.Column width={10}>{Localize.get('EventDetails.information')}</Grid.Column>
                <Grid.Column width={2}>
                  <Icon name='dropdown' />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        );
        break;
    }
  };

  const handleItemClickLocally = (e, data) => {
    handleActiveComponent(data.name);
    handleItemClick(null, data);
  };

  return (
    <>
      <Menu compact vertical borderless className={`${style.gridMobile} ${style.menuMobileStyle}`}>
        <Dropdown
          data-test-id='menuMobileEventDetails'
          trigger={headerContent}
          className={`selection ${style.menuMobileStyle} ${style.menuCompactStyle}`}
          icon={null}
          fluid
          options={getMenuItems(activeItem, handleItemClickLocally, entity)}
        />
      </Menu>
    </>
  );
};

export default DetailsMenuMobile;
