import React from 'react';
import Localize from 'react-intl-universal';

import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

const TableButton = ({ params, name, handleButtonChange, switchLoader }) => {
  const getLabel = () => {
    switch (name) {
      case 'completion':
        return params?.value
          ? Localize.get('TrainerAreaParticipantsTable.complete')
          : Localize.get('TrainerAreaParticipantsTable.incomplete');
      default:
        return params?.row[name.toString()]
          ? Localize.get('TrainerAreaParticipantsTable.attended')
          : Localize.get('TrainerAreaParticipantsTable.absent');
    }
  };

  return (
    <FormGroup data-testid={'tableButton'}>
      {switchLoader?.loader &&
      switchLoader?.id === params?.id?.toString() &&
      name?.toString() === switchLoader?.name ? (
        <CircularProgress size={30} />
      ) : (
        <FormControlLabel
          control={
            <Switch
              data-testid={`${params.id}-${name}`}
              checked={params.value ? params.value : false}
              onChange={handleButtonChange}
              name={name?.toString()}
              id={params?.id.toString()}
              disabled={switchLoader?.loader}
            />
          }
          label={getLabel()}
        />
      )}
    </FormGroup>
  );
};

export default TableButton;
