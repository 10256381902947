/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { Link } from 'react-router-dom';

// semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';

import * as globalConstants from '@common/Constants.js';
import * as countriesCodes from '@common/Countries.js';
import Logger from '@common/Logger.js';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import useFetching from '@hooks/Fetching/Fetching';
import * as routerConstants from '@navigation/Constants.js';

import * as constants from './Constants';
import DetailsMenuItems from './DetailsMenuItems.jsx';
import DetailsMenuMobile from './DetailsMenuMobile.jsx';
import { createEventService } from './EventDetailsHelper.js';
import RegistrationEvent from './RegistrationEvent/RegistrationEvent.jsx';
import styles from './style/EventDetails.module.scss';
import Agenda from './Tabs/Agenda.jsx';
import Attachment from './Tabs/Attachments.jsx';
import Information from './Tabs/Information.jsx';
import Notes from './Tabs/Notes.jsx';
import { getIsEventPassed } from './Util.js';

const EventDetails = ({ match, history }) => {
  const [registrationActive, setRegistrationActive] = useState(false);
  const [activeItem, setActiveItem] = React.useState(constants.tabOptions.information);
  const [eventID, setEventID] = React.useState(
    match && match.params && match.params.id ? match.params.id : ''
  );
  let previousPath = '';
  let previousPathName = '';
  const [contextData] = useGlobalContext();
  const [event, setEvent] = React.useState({});

  const redirectToHome = () => {
    return history.push({
      pathname: '/',
      state: {
        from: 'Home',
        path: '/'
      }
    });
  };

  const [eventData, isLoading, , fetchEvent] = useFetching(
    createEventService.bind(null, eventID, 'event', redirectToHome),
    true
  );

  useEffect(() => {
    if (eventID && eventID.length) {
      fetchEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (eventData?.data?.event) {
          setEvent(eventData.data.event);
        }
      } catch (e) {
        Logger.error(e);
      }
    };
    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  useEffect(() => {
    if (!eventID && match && match.params && match.params.id) {
      setEventID(match.params.id);
    }
  }, [eventID, match]);

  const handleActiveComponent = (component) => {
    let setComponent;
    switch (component) {
      case constants.tabOptions.information:
        setComponent = <Information event={event} config={contextData} />;
        break;
      case constants.tabOptions.agenda:
        setComponent = <Agenda id={event.ORD_ID} />;
        break;
      case constants.tabOptions.attachments:
        setComponent = <Attachment guid={event.ID} />;
        break;
      case constants.tabOptions.notes:
        setComponent = <Notes guid={event.ID} />;
        break;
      case constants.tabOptions.sessions:
        setComponent = <div className={styles.emptyTabsStyle}>Sessions</div>;
        break;
      case constants.tabOptions.speakers:
        setComponent = <div className={styles.emptyTabsStyle}>Speakers</div>;
        break;
      case constants.tabOptions.hotels:
        setComponent = <div className={styles.emptyTabsStyle}>Hotels</div>;
        break;
      case constants.tabOptions.messages:
        setComponent = <div className={styles.emptyTabsStyle}>Messages</div>;
        break;

      default:
        setComponent = <Information event={event} config={contextData} />;
        break;
    }
    return setComponent;
  };

  const handleItemClick = (e, data) => {
    setActiveItem(data.name);
  };

  const handleRegisterButtonClick = () => {
    // history.push({
    //   pathname: routerConstants.routes.name.register,
    //   state: {
    //     from: Localize.get('EventDetails.eventDetails'),
    //     path: routerConstants.routes.name.eventDetails,
    //     data: event
    //   }
    // });
    setRegistrationActive(!registrationActive);
  };

  const handleLinkClick = () => {
    history.push({
      pathname: previousPath,
      state: {
        from: previousPathName.replace('/', '').trim(),
        path: previousPath
      }
    });
  };

  if (history?.location?.state?.from) {
    previousPathName = history.location.state.from + ' /';
    previousPath = history.location.state.path;
  }

  if (previousPath.includes(routerConstants.routes.name.eventDetails)) {
    previousPath = routerConstants.routes.path.events;
    previousPathName = Localize.get('AppNavBar.customerLoyaltyEvents') + ' / ';
  }

  return (
    <>
      {/* desktop && tablet layout*/}
      <Grid
        columns={1}
        centered
        relaxed
        className={`tablet computer only ${styles.gridMobileTop}`}
        stackable
      >
        {isLoading && (
          <Dimmer active inverted>
            <Loader size='large'>{Localize.get('App.loading')}</Loader>
          </Dimmer>
        )}

        {/* desktop && tablet layout - link*/}
        {previousPath && previousPathName && (
          <Grid.Row only='tablet computer'>
            <Grid.Column computer={2} tablet={2} />
            <Grid.Column computer={12} tablet={14} textAlign='right'>
              <Form.Group className={styles.linkContainer}>
                <Form.Field className={styles.linkPart}>
                  <Link
                    to={{
                      pathname: previousPath,
                      state: {
                        from: previousPathName,
                        path: previousPath
                      }
                    }}
                  >
                    {previousPathName}
                  </Link>
                </Form.Field>
                <Form.Field>
                  <div
                    data-test-id={'eventDetailsLink'}
                    role='link'
                    onClick={handleLinkClick}
                    onKeyDown={() => {}}
                    tabIndex={0}
                  >
                    {Localize.get('EventDetails.detailsInfo')}
                  </div>
                </Form.Field>
              </Form.Group>
            </Grid.Column>
            <Grid.Column width={2} />
          </Grid.Row>
        )}

        {/* desktop && tablet layout - message*/}
        <Grid.Row only='tablet computer'>
          <Grid.Column computer={16} largeScreen={16} widescreen={12} tablet={16}>
            <Message
              data-test-id={'eventDetailsMessage'}
              style={{
                background: contextData?.configuration?.EventsDetailsMessage.background,
                boxShadow: contextData?.configuration?.EventsDetailsMessage.boxShadow
              }}
            >
              <Message.Header className={styles.messageHeaderStyle}>
                {event.DESCRIPTION}
              </Message.Header>
              <Message.Content className={styles.messageHeaderContainer}>
                <Grid relaxed className={styles.messageHeaderContainerGrid}>
                  <Grid.Column className={styles.messageTypeContainer}>
                    {event.EVENT_TYPE_NAME}{' '}
                  </Grid.Column>
                  <Grid.Column
                    className={`${styles.messageLangContainer} ${styles.messageLangIcon}`}
                  >
                    {event.COUNTRY_NAME && <Icon name='world' />}
                  </Grid.Column>
                  <Grid.Column
                    textAlign='left'
                    className={`${styles.messageLangContainer} ${styles.messageHeaderStyle}`}
                  >
                    {event.COUNTRY_NAME}
                  </Grid.Column>
                  <Grid.Column className={styles.messageCountryContainer}>
                    <Flag
                      name={
                        event && event.COUNTRY_NAME
                          ? countriesCodes.countryCodeByCountry[event.COUNTRY_NAME.toLowerCase()]
                          : ''
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={12} className={styles.registerButtonContainer}>
                    <Button
                      color='yellow'
                      content={
                        registrationActive
                          ? Localize.get('App.close')
                          : Localize.get('App.register')
                      }
                      floated='right'
                      className={styles.registerButton}
                      onClick={handleRegisterButtonClick}
                      disabled={getIsEventPassed(event)}
                    />
                  </Grid.Column>
                </Grid>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>

      {/* desktop && tablet layout - menu and content*/}
      {!registrationActive &&
        (contextData?.size?.device === globalConstants.deviceSize.computer ||
          contextData?.size?.device === globalConstants.deviceSize.tablet) && (
          <Grid className={`tablet computer only ${styles.menuCenter}`} centered>
            <Grid.Column
              style={{ background: contextData?.configuration?.EventsDetailsContent.background }}
              computer={14}
              largeScreen={10}
              widescreen={10}
              tablet={14}
            >
              <Menu pointing secondary widths={4} className={styles.menuTabletResolution}>
                <DetailsMenuItems
                  activeItem={activeItem}
                  handleItemClick={handleItemClick}
                  showIcon={contextData.size.device === globalConstants.deviceSize.computer}
                  entity={'event'}
                  showItem={{
                    overview: contextData?.configuration?.EventsDetailsContent?.showOverviewTab,
                    information:
                      contextData?.configuration?.EventsDetailsContent?.showInformationTab,
                    agenda: contextData?.configuration?.EventsDetailsContent?.showAgendaTab,
                    attachments:
                      contextData?.configuration?.EventsDetailsContent?.showAttachmentsTab,
                    notes: contextData?.configuration?.EventsDetailsContent?.showNotes
                  }}
                />
              </Menu>

              <Grid stackable container centered>
                {handleActiveComponent(activeItem)}
              </Grid>
            </Grid.Column>
          </Grid>
        )}

      {registrationActive &&
        (contextData?.size?.device === globalConstants.deviceSize.computer ||
          contextData?.size?.device === globalConstants.deviceSize.tablet) && (
          <Grid
            className={`tablet computer only ${styles.menuCenter}`}
            centered
            style={{ background: contextData?.configuration?.EventsDetailsContent.background }}
          >
            <RegistrationEvent event={event} setRegistrationActive={setRegistrationActive} />
          </Grid>
        )}

      {/* mobile layout */}
      <Grid centered container className={`mobile only ${styles.gridMobileTop}`}>
        {/* mobile layout - link */}
        <Grid.Row className={styles.navLinkContainerMobile}>
          <Link
            to={{
              pathname: previousPath,
              state: {
                from: previousPath,
                path: previousPath,
                activeIndex: 0
              }
            }}
          >
            {previousPathName}
          </Link>
          <div className={styles.mobileLinkHolder}>{Localize.get('EventDetails.detailsInfo')}</div>
        </Grid.Row>

        {/* mobile layout - message*/}
        <Grid.Row only='mobile' centered className={styles.messageMobileContainer}>
          <Grid.Column width={16}>
            <Message
              style={{
                background: contextData?.configuration?.EventsDetailsMessage.background,
                boxShadow: contextData?.configuration?.EventsDetailsMessage.boxShadow
              }}
            >
              <Message.Header className={styles.messageHeaderStyle}>
                {event.DESCRIPTION}
              </Message.Header>
              <Message.Content className={styles.messageHeaderContainer}>
                <Grid>
                  <Grid.Column className={styles.messageTypeContainerMobile}>
                    {event.EVENT_TYPE_NAME}{' '}
                  </Grid.Column>
                  <Grid.Column>{event.COUNTRY_NAME && <Icon name='world' />}</Grid.Column>
                  <Grid.Column
                    textAlign='left'
                    className={`${styles.messageTypeContainerMobile} ${styles.messageHeaderStyle}`}
                  >
                    {event.COUNTRY_NAME}
                  </Grid.Column>
                  <Grid.Column>
                    <Flag
                      name={
                        event && event.COUNTRY_NAME
                          ? countriesCodes.countryCodeByCountry[event.COUNTRY_NAME.toLowerCase()]
                          : ''
                      }
                    />
                  </Grid.Column>
                </Grid>
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile' centered>
          <Grid.Column width={15} stretched>
            <Button
              color='yellow'
              content={
                registrationActive ? Localize.get('App.close') : Localize.get('App.register')
              }
              className={`${styles.btnHeader} ${styles.btnMobile}`}
              onClick={handleRegisterButtonClick}
              disabled={getIsEventPassed(event)}
            />
          </Grid.Column>
        </Grid.Row>

        {!registrationActive && (
          <Grid.Row only='mobile' centered>
            <Grid.Column width={14} stretched className={styles.menuMobileCenter}>
              <DetailsMenuMobile
                activeItem={activeItem}
                handleItemClick={handleItemClick}
                entity={'event'}
              />
            </Grid.Column>
          </Grid.Row>
        )}

        {/* mobile layout - content*/}
        {!registrationActive &&
          contextData &&
          contextData.size &&
          contextData.size.device === globalConstants.deviceSize.mobile && (
            <Grid
              stackable
              container
              centered
              className={`mobile only ${styles.tabContainer}`}
              style={{ background: contextData?.configuration?.EventsDetailsContent.background }}
            >
              <Grid.Column width={12}>{handleActiveComponent(activeItem)}</Grid.Column>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default EventDetails;
