import React from 'react';

import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';

const FormCheckBox = (props) => {
  const {
    field,
    label,
    placeholder,
    required,
    setFieldValue,
    fieldWidth = 16,
    className = ''
  } = props;
  return (
    <>
      <Form.Field width={fieldWidth} className={className}>
        <Form.Checkbox
          name={field.name}
          required={required}
          checked={field.value}
          onChange={() => setFieldValue(field.name, !field.value)}
          label={
            <label>
              {label}
              <a href='/'>{placeholder}</a>
            </label>
          }
        />
      </Form.Field>
    </>
  );
};

export default FormCheckBox;
