export default [
  {
    groups: [
      [
        {
          label: 'SignIn.username',
          placeholder: 'SignIn.mailFormat',
          name: 'email',
          required: true,
          disabled: false,
          component: 'text',
          fieldwidth: 16
        }
      ],
      [
        {
          label: 'SignIn.password',
          placeholder: 'SignIn.password',
          name: 'password',
          required: true,
          disabled: false,
          component: 'passwordInput',
          fieldwidth: 16
        }
      ]
    ]
  }
];
