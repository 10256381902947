import React from 'react';
import Localize from 'react-intl-universal';

//semantic
import Table from 'semantic-ui-react/dist/commonjs/collections/Table/Table';

import AttachmentItem from './AttachmentItem.jsx';
import style from './style/Attachments.module.scss';

const AttachmentsTable = (props) => {
  const { attachments } = props;

  return (
    <>
      <Table singleLine unstackable striped size='small' className={style.tableStyle}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{Localize.get('App.name')}</Table.HeaderCell>
            <Table.HeaderCell>{Localize.get('App.ext')}</Table.HeaderCell>
            <Table.HeaderCell className={style.sizeColumn}>
              {Localize.get('App.size')}
            </Table.HeaderCell>
            <Table.HeaderCell>{Localize.get('App.options')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body data-test-id={'attachmentsTable'}>
          {attachments.results.map((att, index) => (
            <AttachmentItem key={index} attachment={att} />
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default AttachmentsTable;
