import React from 'react';

import { get } from 'lodash';
//semantic
import Form from 'semantic-ui-react/dist/commonjs/collections/Form/Form';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  pointer-events: all !important;
  cursor: pointer !important;
`;

const FormPasswordInput = ({
  field = {},
  form: { touched, errors } = {},
  fieldWidth = 16,
  label = '',
  required = false,
  // eslint-disable-next-line no-unused-vars
  type = 'password', // need this, so Formik allow input filed to be an input and a password type
  className = '',
  ...rest
}) => {
  const [inputType, setInputType] = React.useState('password');
  const [inputIcon, setInputIcon] = React.useState('hide');

  const onIconClick = () => {
    switch (inputType) {
      case 'password':
        setInputType('text');
        setInputIcon('eye');
        break;
      case 'text':
        setInputType('password');
        setInputIcon('hide');
        break;
      default:
        setInputType('password');
        setInputIcon('hide');
        break;
    }
  };
  return (
    <Form.Field
      width={fieldWidth}
      required={required}
      className={className}
      error={Boolean(get(touched, field?.name) && get(errors, field?.name))}
    >
      <label>{label}</label>
      <div className='ui icon input'>
        <Input type={inputType} {...field} {...rest} />

        <StyledIcon data-test-id={'passHideIcon'} name={inputIcon} onClick={onIconClick} />
      </div>
      {get(touched, field?.name) && get(errors, field?.name) && (
        <div className='ui pointing above prompt label' role='alert' aria-atomic='true'>
          {errors[field?.name] ? errors[field?.name] : null}
        </div>
      )}
    </Form.Field>
  );
};
export default FormPasswordInput;
