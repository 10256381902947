import React from 'react';

import {
  CardContentContainer,
  ContentDescriptionNameBare,
  EvtNameBare,
  EvtNameBareIcon,
  EvtNameHeader,
  StyledCardContent
} from '@components/StyledComponents/EventItem.styled';
import ButtonMailto from '@navigation/ButtonMailto';

const EmployeeItemContent = (props) => {
  const { firstName, lastName, email, phoneMobile, phoneWork } = props;

  return (
    <>
      <StyledCardContent>
        <CardContentContainer>
          <ContentDescriptionNameBare namelength={firstName.length + lastName.length}>
            <EvtNameHeader>
              {firstName} {lastName}
            </EvtNameHeader>
          </ContentDescriptionNameBare>
          <ContentDescriptionNameBare namelength={phoneWork?.length}>
            <EvtNameBareIcon disabled name='phone' size='small' />
            <EvtNameBare>{phoneWork?.length ? phoneWork : '-'}</EvtNameBare>
          </ContentDescriptionNameBare>
          <ContentDescriptionNameBare namelength={phoneMobile?.length}>
            <EvtNameBareIcon disabled name='mobile' size='small' />
            <EvtNameBare>{phoneMobile?.length ? phoneMobile : '-'}</EvtNameBare>
          </ContentDescriptionNameBare>
          <ContentDescriptionNameBare namelength={email?.length}>
            <EvtNameBareIcon disabled name='mail outline' size='small' />
            <EvtNameBare>
              <ButtonMailto label={email} mailto={`mailto:${email}`} />
            </EvtNameBare>
          </ContentDescriptionNameBare>
        </CardContentContainer>
      </StyledCardContent>
    </>
  );
};

export default EmployeeItemContent;
