import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  CustomHeader,
  HeaderContainerGridRow,
  HeaderContainerGridColumn,
  HeaderTabletGridRow
} from '@components/StyledComponents/Header.styled';
import { useGlobalContext } from '@hooks/Context/GlobalContext.js';
import * as routerConstants from '@navigation/Constants.js';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import TabletHeader from './TabletHeader';

const Header = (props) => {
  const { config, content } = props;
  let history = useHistory();
  const [contextData] = useGlobalContext();
  const [user, setUser] = useState(null);
  const mainRoute = config?.header?.menuItems?.items.find((item) => item?.mainRoute);

  useEffect(() => {
    if (contextData?.user) {
      setUser(contextData.user);
    }
  }, [contextData]);

  const handleClick = (routePath) => {
    if (routePath === routerConstants.routes.path.login && contextData.loggedIn) {
      return;
    }
    history.push({
      pathname: routePath,
      state: {
        from: history?.location?.state?.from ? history.location.state.from : '',
        path: history?.location?.state?.path ? history.location.state.path : ''
      }
    });
  };

  const handleLogoClick = () => {
    history.push({
      pathname: routerConstants.routes.path[mainRoute?.route],
      state: {
        from: history?.location?.state?.from ? history.location.state.from : '',
        path: history?.location?.state?.path ? history.location.state.path : ''
      }
    });
  };

  return (
    <>
      <HeaderTabletGridRow stretched only='tablet computer' data-test-id={'headerGridRowDesktop'}>
        <HeaderContainerGridColumn computer={16} tablet={16}>
          <CustomHeader>
            <DesktopHeader
              config={config}
              user={user}
              handleClick={handleClick}
              handleLogoClick={handleLogoClick}
            />
          </CustomHeader>
          <CustomHeader>
            <TabletHeader
              config={config}
              user={user}
              handleClick={handleClick}
              handleLogoClick={handleLogoClick}
            />
          </CustomHeader>
        </HeaderContainerGridColumn>
      </HeaderTabletGridRow>

      <HeaderContainerGridRow stretched only='mobile'>
        <HeaderContainerGridColumn mobile={16}>
          <CustomHeader>
            <MobileHeader config={config} user={user} handleClick={handleClick} content={content} />
          </CustomHeader>
        </HeaderContainerGridColumn>
      </HeaderContainerGridRow>
    </>
  );
};
export default Header;
