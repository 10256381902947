import React from 'react';
import Localize from 'react-intl-universal';

import {
  StyledHeading,
  StyledTypographyPadding
} from '@components/StyledComponents/TableHeading.styled';
import Box from '@mui/material/Box';

const AttachmentDescriptionField = ({ params }) => {
  return (
    <Box data-testid={'attachmentDescriptionFields'}>
      <StyledHeading>{params?.description}</StyledHeading>
      <StyledTypographyPadding variant='caption'>{`${Localize.get(
        'TrainerAreaAttachmentTable.type'
      )}: ${params?.type}`}</StyledTypographyPadding>
      <StyledTypographyPadding variant='caption'>{`${Localize.get(
        'TrainerAreaAttachmentTable.fileName'
      )}: ${params?.fileName}`}</StyledTypographyPadding>
    </Box>
  );
};

export default AttachmentDescriptionField;
