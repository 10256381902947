import { format, startOfDay } from 'date-fns';

export const getEventStartTime = (event) => {
  if (!event || !event.EVENT_START) {
    return null;
  }

  return `${event.EVENT_START.substring(8, 10)}:${event.EVENT_START.substring(10, 12)}`;
};

export const calculateTotalPages = (coursesCount, contextData, listView) => {
  return Math.ceil(
    coursesCount /
      contextData?.configuration?.PAGINATION.myAreaOverviewLimit[listView][
        contextData.size.device.toLowerCase()
      ]
  );
};

export const handleSessionsAdvancedFilters = (filter, page, paginationLimit) => {
  let queryString = `?page=${page - 1}&limit=${paginationLimit}`;

  if (filter?.sessions.toLowerCase()) {
    switch (filter.sessions) {
      case 'futureSessions':
        queryString = `?filters=${formatFilters('startDate', 'is_after', 'date')}&page=${
          page - 1
        }&limit=${paginationLimit}`;
        break;
      case 'pastSessions':
        queryString = `?filters=${formatFilters('startDate', 'is_on_or_before', 'date')}&page=${
          page - 1
        }&limit=${paginationLimit}`;
        break;
      case 'cancelledSessions':
        queryString = `?filters=${formatFilters('status', 'equal', 'Cancelled')}&page=${
          page - 1
        }&limit=${paginationLimit}`;
        break;
    }
  }

  if (filter.searchString) {
    queryString += `&search=${filter.searchString}`;
  }

  return queryString;
};

const formatFilters = (keys, operations, values) => {
  const today = startOfDay(new Date());
  const currentDayFormatted = format(today, 'yyyy-MM-dd');
  const filters = [
    {
      key: keys,
      operation: operations,
      value: values === 'date' ? currentDayFormatted : 'Cancelled'
    }
  ];
  return encodeURIComponent(JSON.stringify({ advancedFilters: filters }));
};
