import React, { useState } from 'react';

import {
  CardFooter,
  CardPriceLabel,
  CardFooterDiv,
  FooterLocation,
  FooterCoursePrice,
  FooterFavorite,
  FooterShare,
  FooterStyledIcon,
  FooterProgress,
  StyledProgress,
  FooterProgressPercentage
} from '@components/StyledComponents/EventItem.styled';

const CourseItemFooter = (props) => {
  const { config, price, progress } = props;

  const [favIcon, setFavIcon] = useState(
    config?.footer?.favIconSelected ? config?.footer?.favIconSelected : 'heart outline'
  );

  const onFavIconClick = (e) => {
    e.stopPropagation();
    if (favIcon.includes('outline')) {
      setFavIcon(config?.footer?.favIcon ? config?.footer?.favIcon : 'heart');
    } else {
      setFavIcon(
        config?.footer?.favIconSelected ? config?.footer?.favIconSelected : 'heart outline'
      );
    }

    // TODO after integration with litmos
    // update users favorite events
  };

  return (
    <>
      <CardFooter extra config={config} data-test-id={'courseItemFooter'}>
        <CardFooterDiv>
          <FooterCoursePrice visible={config?.footer?.columns.includes('price')}>
            {price && price.length && (
              <CardPriceLabel basic color='orange' size='large'>
                {price}
              </CardPriceLabel>
            )}
          </FooterCoursePrice>
          <FooterProgress visible={config?.footer?.columns.includes('progress') && progress}>
            <StyledProgress size='tiny' percent={progress} />
            <FooterProgressPercentage>{`${Math.floor(progress)}%`}</FooterProgressPercentage>
          </FooterProgress>
          <FooterLocation visible={config?.footer?.columns.includes('location')} />
          <FooterFavorite
            visible={
              config?.footer?.columns.includes('favorite') && config?.content?.showFavouriteIcon
            }
          >
            <FooterStyledIcon
              config={config}
              name={favIcon}
              onClick={(evt) => onFavIconClick(evt)}
            />
          </FooterFavorite>
          <FooterShare
            visible={config?.footer?.columns.includes('share') && config?.content?.showShareIcon}
          >
            <FooterStyledIcon config={config} name='share alternate' />
          </FooterShare>
        </CardFooterDiv>
      </CardFooter>
    </>
  );
};

export default CourseItemFooter;
