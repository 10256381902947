import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { Grid, Input, Checkbox } from 'semantic-ui-react';

const ParticipantInformation = ({ participantDetails, setParticipantDetails, nonEditMode }) => {
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  return (
    <>
      <Grid.Row>
        <Grid.Column computer={3} tablet={5} textAlign={'left'}>
          Customer No: <Input disabled value={participantDetails.customerNo} size='mini' />
        </Grid.Column>
        <Grid.Column computer={3} tablet={5} textAlign={'left'}>
          Manager: <Input disabled value={participantDetails.managerName} size='mini' />
        </Grid.Column>
        <Grid.Column computer={3} tablet={5}></Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column computer={3} tablet={4} textAlign={'left'}>
          Contact: <Input disabled value={participantDetails.contact} size='mini' />
        </Grid.Column>
        <Grid.Column computer={2} tablet={4} textAlign={'left'}>
          <Checkbox
            label='Hotel required'
            disabled={nonEditMode}
            checked={participantDetails.hotelRequired}
            onChange={(e, v) =>
              setParticipantDetails({ ...participantDetails, hotelRequired: v.checked })
            }
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={7} textAlign={'right'}>
          <Grid.Row style={{ marginBottom: '5px' }}>
            Arrival date:
            <SemanticDatepicker
              icon='calendar alternate outline'
              value={participantDetails.arrivalDate}
              minDate={minDate}
              placeholder='--'
              locale={'en-US'}
              onChange={(e, v) =>
                setParticipantDetails({ ...participantDetails, arrivalDate: v.value })
              }
              type='basic'
              datePickerOnly
              format='DD.MM.YYYY'
              showToday={false}
              size='mini'
              disabled={nonEditMode || !participantDetails.hotelRequired}
            />
          </Grid.Row>
          <Grid.Row>
            Departure date:
            <SemanticDatepicker
              icon='calendar alternate outline'
              value={participantDetails.departureDate}
              minDate={participantDetails.arrivalDate ? participantDetails.arrivalDate : minDate}
              placeholder='--'
              locale={'en-US'}
              onChange={(e, v) =>
                setParticipantDetails({ ...participantDetails, departureDate: v.value })
              }
              type='basic'
              datePickerOnly
              format='DD.MM.YYYY'
              showToday={false}
              size='mini'
              disabled={nonEditMode || !participantDetails.hotelRequired}
            />
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>

      {nonEditMode && (
        <Grid.Row>
          <Grid.Column computer={6} tablet={10} textAlign={'left'}>
            <Checkbox
              label='I accept the terms and conditions'
              checked={participantDetails.turnsAndConditions}
              onChange={(e, v) =>
                setParticipantDetails({ ...participantDetails, turnsAndConditions: v.checked })
              }
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={5}></Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export default ParticipantInformation;
