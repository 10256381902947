/* eslint-disable complexity */
import React from 'react';
import Localize from 'react-intl-universal';
import ScrollToTop from 'react-scroll-to-top';

import get from 'lodash/get';
// semantic
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid/Grid';
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu/Menu';
import Message from 'semantic-ui-react/dist/commonjs/collections/Message/Message';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Flag from 'semantic-ui-react/dist/commonjs/elements/Flag';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer';
import Popup from 'semantic-ui-react/dist/commonjs/modules/Popup';

import * as globalConstants from '@common/Constants';
import * as countriesCodes from '@common/Countries';
import DetailsMenuItems from '@pages/EventDetails/DetailsMenuItems';
import styles from '@pages/EventDetails/style/EventDetails.module.scss';
import LearningPathDescription from '@pages/LearningPathDetails/Components/LearningPathDescription';

const LearningPathDetailsDesktop = ({
  learningPath = {},
  contextData = null,
  isLoading = false,
  handleActiveComponent = null,
  handleTabClick = null,
  activeItem = null
}) => {
  const arePrerequisitesAvailable =
    get(learningPath, 'COURSES[0]?.COURSE_PREREQUISITES?.COURSES?.length', 0) > 0 ||
    get(learningPath, 'COURSES[0]?.COURSE_PREREQUISITES?.LEARNING_PATHS?.length', 0) > 0;

  const flagName = () => {
    const countryName = get(learningPath, 'COURSES[0]?.COUNTRY_NAME', '');
    return countryName !== '' ? countriesCodes.countryISOCodeByCountry[countryName] : '';
  };

  return (
    <Grid
      columns={1}
      centered
      relaxed
      className={`tablet computer only ${styles.detailsPageContainer}`}
      stackable
    >
      {isLoading && (
        <Dimmer active inverted>
          <Loader size='large'>{Localize.get('App.loading')}</Loader>
        </Dimmer>
      )}

      <Grid.Row
        only='tablet computer'
        className={styles.girdRowContainer}
        style={{ background: contextData?.configuration?.LearningPathDetailsMessage.background }}
      >
        <Grid.Column width={12}>
          <Message
            data-testid='learningPathDetailsMessage'
            style={{
              background: contextData?.configuration?.LearningPathDetailsMessage.background,
              boxShadow: contextData?.configuration?.LearningPathDetailsMessage.boxShadow
            }}
          >
            <Message.Header className={styles.messageHeaderStyle}>
              {learningPath?.NAME}
            </Message.Header>
            <Message.Content className={styles.messageHeaderContainer}>
              <Grid relaxed centered stackable className={styles.messageHeaderContainerGrid}>
                <Grid.Column width={10} className={styles.messageCourseTypeContainer}>
                  {Localize.get('LearningPathDetails.title')}
                  <Flag className={styles.messageFlag} name={flagName()} />
                  {arePrerequisitesAvailable && (
                    <>
                      <Icon name='info' />
                      {Localize.get('LearningPathDetails.requiredPrerequisitesMessage')}
                    </>
                  )}
                </Grid.Column>
                <Grid.Column width={6}>
                  {contextData?.configuration?.LearningPathDetailsContent?.showAssignment && (
                    <Grid.Column>
                      <Button
                        color={contextData?.configuration?.Registration?.stepperButtonColor}
                        floated='right'
                        className={styles.registerButton}
                        disabled
                      >
                        {Localize.get('LearningPathDetails.assignment')}
                        <Popup
                          content={Localize.get('LearningPathDetails.assignment')}
                          size='small'
                          position='bottom left'
                          trigger={
                            <Icon
                              name='question circle outline'
                              size='large'
                              style={{ margin: 0, lineHeight: 'unset' }}
                            />
                          }
                        />
                      </Button>
                    </Grid.Column>
                  )}
                  <Grid centered stackable>
                    {contextData?.configuration?.LearningPathDetailsContent?.showRegistration && (
                      <Grid.Column>
                        <Button
                          disabled
                          color={contextData?.configuration?.Registration?.stepperButtonColor}
                          floated='right'
                          className={styles.registerButton}
                        >
                          {Localize.get('LearningPathDetails.registration')}
                          <Popup
                            content={Localize.get('LearningPathDetails.registration')}
                            size='small'
                            position='bottom left'
                            inverted
                            trigger={
                              <Icon
                                name='question circle outline'
                                size='large'
                                style={{ margin: 0, lineHeight: 'unset' }}
                              />
                            }
                          />
                        </Button>
                      </Grid.Column>
                    )}
                  </Grid>
                </Grid.Column>
              </Grid>
            </Message.Content>
            <Message.Content className={styles.messageHeaderContainer}>
              <LearningPathDescription description={learningPath?.DESCRIPTION} />
            </Message.Content>
          </Message>
        </Grid.Column>
      </Grid.Row>
      {contextData?.size?.device &&
        [globalConstants.deviceSize.computer, globalConstants.deviceSize.tablet].includes(
          contextData.size.device
        ) && (
          <>
            <Grid.Row
              className={styles.girdRowContainer}
              style={{
                background: contextData?.configuration?.LearningPathDetailsMessage.background
              }}
            >
              <Grid.Column computer={12} largeScreen={12} widescreen={12} tablet={12}>
                <Menu
                  pointing
                  secondary
                  className={styles.menuTabletResolution}
                  style={{
                    background:
                      contextData?.configuration?.LearningPathDetailsContent?.menuItemBackground
                  }}
                >
                  <DetailsMenuItems
                    activeItem={activeItem}
                    handleItemClick={handleTabClick}
                    showIcon={contextData.size.device === globalConstants.deviceSize.computer}
                    entity={globalConstants.entityTypes.learningPath}
                    showItem={{
                      overview:
                        contextData?.configuration?.LearningPathDetailsContent?.showOverviewTab,
                      information: arePrerequisitesAvailable
                    }}
                  />
                </Menu>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                style={{
                  background: contextData?.configuration?.LearningPathDetailsContent.background
                }}
                computer={12}
                largeScreen={12}
                widescreen={12}
                tablet={12}
              >
                <Grid columns={1} centered>
                  {handleActiveComponent && handleActiveComponent(activeItem)}
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </>
        )}

      <ScrollToTop
        smooth
        color={contextData?.configuration?.LearningPathDetailsContent?.scrollUpButton?.fill}
        viewBox={contextData?.configuration?.ScrollUpButton?.viewBox}
        svgPath={contextData?.configuration?.ScrollUpButton?.svgPath}
        style={{
          backgroundColor:
            contextData?.configuration?.LearningPathDetailsContent?.scrollUpButton?.backgroundColor
        }}
      />
    </Grid>
  );
};

export default LearningPathDetailsDesktop;
