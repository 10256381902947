import React from 'react';
import Localize from 'react-intl-universal';

import { StyledTabMenuItem } from '@components/StyledComponents/MyArea.styled';

import * as constants from './Constants';

const TeamAreaTabItems = (props) => {
  const { activeItem, handleItemClick } = props;

  return (
    <>
      <StyledTabMenuItem
        data-test-id={'coursesTabMenuItemTeamArea'}
        name={constants.tabOptions.coursesTab}
        active={activeItem === constants.tabOptions.coursesTab}
        onClick={handleItemClick}
      >
        {Localize.get('TeamArea.coursesTab')}
      </StyledTabMenuItem>
      <StyledTabMenuItem
        data-test-id={'learningPathsTabMenuItemTeamArea'}
        name={constants.tabOptions.learningPathsTab}
        active={activeItem === constants.tabOptions.learningPathsTab}
        onClick={handleItemClick}
      >
        {Localize.get('TeamArea.learningPathsTab')}
      </StyledTabMenuItem>
    </>
  );
};

export default TeamAreaTabItems;
