import React from 'react';

import style from './style/GeneralInformation.module.scss';

const GeneralInformation = () => {
  return (
    <>
      <div data-test-id={'genInfoMain'} className={style.generalInfo}>
        this is General Information page!
      </div>
    </>
  );
};
export default GeneralInformation;
